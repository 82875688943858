import React, { FC, useEffect, useState } from 'react';
import './styles.css';
import ManagementCourseBG from 'src/assets/img/management-courses1.jpg';
import NHGEnquiryForm from 'src/components/NHGEnquiryForm/GlobalPopupEnquiry';
import NHISnquiryForm from 'src/components/NHGEnquiryForm/NHISPopupEnquiry';
import { config, pConfig } from 'src/config';
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';
import Helmet from 'react-helmet';
import GetAppIcon from '@material-ui/icons/GetApp';

const SearchHeader: FC<{ schooldetail: any, slug: any }> = ({ schooldetail, slug }) => {
    const imageUrl = schooldetail.school_logo ? config.baseUrlfromconfig + schooldetail.school_logo : '';
    const [gurukul, setGurukul] = useState(false);
    const [nhis, setnhis] = useState(false);

    const linksToCheck = [
        'new-horizon-gurukul-school-bangalore',
        'new-horizon-vidya-mandir-nhvm-bangalore',
        'new-horizon-public-school-nhps-bangalore',
        // 'new-horizon-international-school-nhis-bangalore',
    ];
    const linksToCheck2 = [

        'new-horizon-international-school-nhis-bangalore',
    ];

    const getcheck = () => {
        try {
            const currentURL = window.location.href;
            const isGurukul = linksToCheck.some(link => currentURL.includes(link));
            const isNHIS = linksToCheck2.some(link => currentURL.includes(link));
            setGurukul(isGurukul);
            setnhis(isNHIS);
        } catch (error) {
            console.error('Error in URL checking:', error);
        }
    };

    useEffect(() => {
        getcheck();
    }, []);

    // Function to programmatically open the Reviews tab
    const handleClick = () => {
        const reviewTab = document.querySelector('#myTab a[href="#pg"]');
        if (reviewTab) {
            (reviewTab as HTMLElement).click(); // Programmatically click the Reviews tab
            reviewTab.scrollIntoView({ behavior: 'smooth' }); // Smooth scroll to the Reviews tab
        }
    };

    return (
        <>
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify(
                        {
                            "@context": "https://schema.org/",
                            "@type": "HighSchool",
                            "name": `${schooldetail.school_name}`,
                            "logo": `${imageUrl}`,
                            "url": `${pConfig.siteUrl}school/${slug}`,
                            "address": {
                                "@type": "PostalAddress",
                                "streetAddress": `${schooldetail.address}`
                            }
                        }
                    )}
                </script>
            </Helmet>
            <section className="inner-top-header" id="hero" style={{ background: `url(${ManagementCourseBG})` }} >
                <div className="hero-container d-flex">
                    <div className="container" >
                        <div className="row college-block">
                            <div className="top-college-header">
                                <h1><img src={imageUrl} style={{ width: `80px` }} alt={schooldetail.school_name} /> {schooldetail.school_name}</h1>
                                {schooldetail.avg_rating ? <div className="rating_box"><i className="bi bi-star-fill rating_act"></i> {schooldetail.avg_rating}</div> : ''}
                                <ul className='inlineflex' style={{ display: 'inlin-grid' }}>
                                    <li><i className="bi bi-pin-map-fill"></i>&nbsp; {schooldetail.address}</li>
                                    <li>Est Year:&nbsp;<strong>{schooldetail.established}</strong></li>
                                    <li>Gender Accepted:&nbsp;<strong>{schooldetail.genders_accepted}</strong></li>
                                    <li>Board:&nbsp;<strong>
                                        {
                                            schooldetail.boardschools && schooldetail.boardschools.map((element, index) => (
                                                <React.Fragment key={index}>
                                                    {index === 0 ? ' ' + element.schbrdname.name : ', ' + element.schbrdname.name}
                                                </React.Fragment>
                                            ))
                                        }
                                    </strong></li>
                                    <li>School Level:&nbsp;<strong>
                                        {
                                            schooldetail.schoollevels && schooldetail.schoollevels.map((element, index) => (
                                                <React.Fragment key={index}>
                                                    {index === 0 ? ' ' + element.schlevelname.level_name : ', ' + element.schlevelname.level_name}
                                                </React.Fragment>
                                            ))
                                        }</strong></li>
                                </ul>
                                <div className="spac_02"></div>
                                <div className="col-lg-12 button-section">
                                    {gurukul || nhis ?
                                    
                                    nhis ? <NHISnquiryForm className="medical-button btn" /> :  <NHGEnquiryForm className="medical-button btn" /> 
                                    
                                    : <GlobalPopupEnquiry className="medical-button btn" />}

                                   
                                    <a className="reviews-button btn" onClick={handleClick} style={{ cursor: 'pointer' }}>Write a Review</a>

                                    {gurukul || nhis ? 
                                    
                                    nhis ? <NHISnquiryForm className="broucher-button btn" title={<><GetAppIcon />Download Brochure</>} />  :
                                    <NHGEnquiryForm className="broucher-button btn" title={<><GetAppIcon />Download Brochure</>} /> 
                                    
                                    
                                    :
                                        <GlobalPopupEnquiry pagename={'Download Brochure'} className="broucher-button btn" title={<><GetAppIcon />Download Brochure</>} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SearchHeader;
