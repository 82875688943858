import React, { FC } from 'react';
//import './styles.css';

const Breadcrumbs: FC<{ collegedetail: any }> = ({ collegedetail }) => {   
    return (
        <div className="breadcrumbs">	
            <div className="container">
                <ol>
                    <li><a href="/" className="active blue-color">Home</a> </li>
                    <li><a href="/colleges">Colleges</a></li>
                    <li><a href="">{collegedetail.name}</a></li>
                </ol>
            </div>
	    </div>
    );
};

export default Breadcrumbs;