import React, { FC, useState, useEffect } from 'react';
import './styles.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import UniversityBg from 'src/assets/img/university.png';
import CallNow from 'src/assets/img/call-now.svg';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useSnackbar } from 'notistack';

import axios from 'src/utils/axiosintercpt';
import { useHistory } from 'react-router-dom';
import Helmet from 'react-helmet';



interface Props {
    className?: any;
    title?: any;
    pagename?: any;

}

// const handleShow = () => setShow(true);


const GlobalPopupEnquiry: FC<Props> = ({ className, title, pagename, ...rest }) => {



    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);

    const handleClose = () => setShow(false);


    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

  

    const [values, setValues] = useState({
        name: '', email: '', contact: '', gender: '', school: '', class: ''
    });





    const set = name => {
        return ({ target: { value } }) => {
          setValues(oldValues => ({ ...oldValues, [name]: value }));
        }
      };
    




    const onSubmit = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        if (values.gender == "") {
            return enqueueSnackbar('Please Select Gender', {
                variant: 'error',

            });

        }
        if (values.school == "") {
            return enqueueSnackbar('Please Select school', {
                variant: 'error',
            });
        }
        if (values.class == "") {
            return enqueueSnackbar('Please Select class', {
                variant: 'error',
            });
        }
   
        const formData = new FormData();
        formData.append('name', values.name);
        formData.append('email', values.email);
        formData.append('contact', values.contact);
        formData.append('gender', values.gender);
        formData.append('school', values.school);
        formData.append('class', values.class);
        formData.append('current_url', window.location.href);
        formData.append('sourse', 'Horizon Organic');

        try {
            const response = await axios.post('api/website/home/horizonschoolenquiry', formData);


            if (response.data.status) {

                history.push('/thank-you');
            }
        } catch (err) {
            console.error(err);

            enqueueSnackbar('Please try again', {
                variant: 'error',
            });
        }


    }






    return (

        <>
            {/* 
            <Helmet>

                <link rel="stylesheet" href="preprod/nursing_admission/assets/css/dental.css" />
                <link rel="stylesheet" href="preprod/nursing_admission/assets/css/style.min.css" />
                <link rel="stylesheet" href="preprod/nursing_admission/assets/css/resp.min.css" />
            </Helmet> */}

            <a onClick={() => setShow(true)} className={className ? className : 'active_bt'} style={{ cursor: 'pointer' }}>
                {title ? title : 'Apply Now'}
            </a>



            <Modal show={show} onHide={handleClose}>

                <Modal.Body>

                    <div className="heading-popup  text-center">
                        <h4 className='text-center pop-up' style={{ color: "red" }}>Admissions 2025-26 Open Now!</h4>
                        <span className='fHorizon2 text-center pb-2'>Fill in the Details to Get <br /> Started with the Admission Process.</span>
                    </div>
                    <form
                        onSubmit={onSubmit}

                    >

                        <fieldset>
                            <input
                                type="text"
                                id="name1"
                                name="name"
                                placeholder="Student’s Name* "
                                required
                                value={values.name} onChange={set('name')}
                            />
                        </fieldset>
                        <small className="text-danger " />
                        <fieldset className="form-group group_field">
                            <input
                                name="contact"
                                type="number"
                                id="contact1"
                                // min={0}
                                required
                                placeholder="Contact No.* "
                                // aria-required="true"
                                value={values.contact} onChange={set('contact')}

                            />
                        </fieldset>
                        <small className="text-danger " />
                        <fieldset className="form-group group_field">
                            <input
                                type="email"
                                id="email1"
                                name="email"
                                placeholder="Email ID* "
                                required
                                value={values.email} onChange={set('email')}
                            />
                        </fieldset>
                        <small className="text-danger " />
                        <fieldset className="form-group group_field">
                            <small className="text-danger " />
                            <fieldset className="form-group group_field">

                                <select name="gender" id="gender"
                                    value={values.gender} onChange={set('gender')} required>
                                    <option value=" ">Gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </select>


                            </fieldset>

                            <small className="text-danger " />
                            <fieldset className="form-group group_field">

                                <select name="school" id="school"
                                    value={values.school} onChange={set('school')} required>
                                    <option value=" ">Interested School</option>
                                    {/* <option value="New Horizon International School">New Horizon International School</option> */}
                                    <option value="New Horizon Public School">New Horizon Public School</option>
                                    <option value="New Horizon Gurukul Pre School">New Horizon Gurukul Pre School</option>
                                    <option value="NEW HORIZON GURUKUL">NEW Horizon Gurukul</option>
                                </select>


                            </fieldset>

                            <small className="text-danger " />
                            <fieldset className="form-group group_field">

                                <select name="class" id="class"
                                    value={values.class} onChange={set('class')} required>
                                    <option value=" ">Class</option>
                                    <option value="playgroup">Playgroup</option>
                                    <option value="nursery">Nursery</option>
                                    <option value="lkg">LKG</option>
                                    <option value="ukg">UKG</option>
                                    <option value="grade_1">Grade 1</option>
                                    <option value="grade_2">Grade 2</option>
                                    <option value="grade_3">Grade 3</option>
                                    <option value="grade_4">Grade 4</option>
                                    <option value="grade_5">Grade 5</option>
                                    <option value="grade_6">Grade 6</option>
                                    <option value="grade_7">Grade 7</option>
                                    <option value="grade_8">Grade 8</option>
                                    <option value="grade_9">Grade 9</option>
                                    <option value="grade_10">Grade 10</option>
                                    <option value="grade_11">Grade 11</option>
                                    <option value="grade_12">Grade 12</option>
                                </select>


                            </fieldset>



                            <small className="text-danger " />
                            <fieldset className="form-group ">
                                <button type="submit" className='btn btn-primary'>Submit</button>
                            </fieldset>
                        </fieldset>

                    </form>

                </Modal.Body>

            </Modal>


        </>

    );

};
export default GlobalPopupEnquiry;