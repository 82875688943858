import React, { FC, useEffect, useState, useContext, useCallback, ChangeEvent } from 'react';
import { makeStyles } from '@material-ui/core';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import './styles.css';

import EntranceExam from 'src/assets/img/entrance-exam.jpg';
import TopManagementCourseBG from 'src/assets/img/top-managment-course.jpg';
import { config } from 'src/config';
import moment from 'moment';
import axios1 from 'axios';
import axios from 'src/utils/axios';
import { Autocomplete } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Search from 'src/assets/img/icons/search.svg'
import SearchIcon from 'src/assets/img/icons/search.svg';
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';
import Pagination from '@material-ui/lab/Pagination/Pagination';
import CollegeIMG from 'src/assets/img/college.jpg';

const useStylesBootstrap = makeStyles(() => ({
    arrow: {
        color: '#000',
    },
    tooltip: {
        backgroundColor: '#000',
    },
}));

function BootstrapTooltip(props: TooltipProps) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}
interface schlorshiptype {
    name: string;
    slug: string;
}

const OverviewSection: FC<{ schlorshiplist: any,upcomingdataexam: any, page:any, totalpage:any, handlePageChange, topCollegeArr, searchByValue:any, promoBannerArr:any }> = ({ schlorshiplist,upcomingdataexam, page, totalpage, handlePageChange, topCollegeArr, searchByValue, promoBannerArr }) => {
    const [num, setNum] = React.useState(331231);
    let cancelToken;
    const [schlorshiplistserach, setschlorshiplistserach] = React.useState<schlorshiptype[]>([]);
    const [loading, setloading] = React.useState(false);
    const [query, setQuery] = useState<string>('');

    const getSuggestions = async (value) => {
        setloading(true);
        
        if (typeof cancelToken != typeof undefined) {
          cancelToken.cancel("Operation canceled due to new request.")
        }
        cancelToken = axios1.CancelToken.source()
        let url = '';

        const params = {}
        params['searchText'] = value;
        params['searchfrom'] = 'name';
        params['size'] = '10';
        const response = await axios.get('api/website/home/scholarship',  { cancelToken: cancelToken.token, params: params });
        const tags = await response.data.data;
        setschlorshiplistserach(tags);
        setloading(false);
    }

    const handleupdateBlog = (event) => {
        if (event && event.target && typeof event.target.value != typeof undefined && event.target.value != null && event.target.value.length > 1) {
          getSuggestions(event.target.value);
        }
    };

    const handleQueryChange = (event): void => {
        //event.persist();
        //setPage(1);
        setQuery(event.target.value);
        searchByValue(event.target.value);
    };

    useEffect(()=>{
        getSuggestions('');
    }, []);

    return (
        <section className="course-overviews-section " style={{padding : '0px'}}>
            <div className="container aos-init aos-animate" >
                <div className="row spac_01">
                    <div className="col-lg-2"></div>
                    <div className="header-search-box top-bottom-space col-lg-8">
                        <input type="text" 
                            onChange={handleQueryChange}
                            value={query}
                            placeholder="Search" className="search-box" />
                    {/* <Autocomplete

                        className="AutocompleteCCC"
                                options={schlorshiplistserach}
                                onInputChange={(e) => {
                                    handleupdateBlog(e);
                                }}
                                value=""
                                getOptionLabel={(option) => {
                                    // Value selected with enter, right from the input
                                    if (typeof option === 'string') {
                                      return option;
                                    }
                                    // Add "xxx" option created dynamically
                                    if (option.name) {
                                      return option.name;
                                    }
                                    // Regular option
                                    return option.name;
                                }}
                                renderOption={(option) => <li><a style={{ color:"#000" }} href={`scholarship`}>{option.name}</a></li>}
                                noOptionsText="No Data Found"
                                onChange={(e, value) => {
                                    //console.log(value)
                                }}
                                renderInput={(params) => <TextField  className="search-box" style={{  backgroundRepeat: "no-repeat", backgroundPosition: "20px center" }} placeholder="Search"  name="text" {...params}
                                InputProps={{
                                    ...params.InputProps,
                                   // className: "search-box",
                                    endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                    ),
                                }}
                                />}
                            /> */}

                       {/*  <form action="" method="post">
                            <input type="text" placeholder="Search" name="text" className="search-box" />
                        </form> */}
                    </div>
                    <div className="col-lg-2"></div>
                </div>

                <div className="row">
                    <div className="col-lg-8 overviews-course left-exam-section" >
                     {/*    <h3 className="main-heading left-align">Entrance <span>Exam</span></h3> */}
                     {
                        schlorshiplist && schlorshiplist.map((i) => {
                            return (
                        <div className="scholarship-news">
                            <div className='row'>
                                <div className="left-image col-lg-2 col-sm-4"><img alt={i.name}  src={ i.logo ? config.baseUrlfromconfig + i.logo : EntranceExam}  /></div>
                                <div className="right-desc-block  col-lg-9  col-sm-8">
                                    <h2>{i.name}</h2>
                                    <ul>
                                        <li><i className="bi bi-bank"></i> {i.conducted_by}</li>
                                        <li><i className="bi bi-bank2"></i> {i.region}</li>
                                        <li><i className="bi bi-building"></i> {i.rewards}</li>
                                        <li><i className="bi bi-calendar-day"></i> {moment(i.last_date).format('D MMM YYYY')}</li>
                                        {/* <li><i className="bi bi-calendar-day"></i> {i.status}</li> */}
                                    </ul>
                                    <div className="college_button_wrp">
                                        <GlobalPopupEnquiry  className="active_bt" />
                                        {/* <a href="#" className="active_bt">Apply Now</a><a href="#">View Details</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                    })
                    }
                    <div className="spac_02"></div>
                    <div className="button-section text-center clearfix">
                        <Pagination
                            page={page}
                            variant="outlined"
                            shape="rounded"
                            onChange={handlePageChange}
                            count={totalpage}
                            className="pagination"
                            />
                    </div>
                    <div className="spac_02"></div>
                      
                    </div>

                    <div className="col-lg-4" >
                        {/* <div className="widgets top-manag-course-widget text-center" style={{ background: `url(${TopManagementCourseBG})` }}>
                            <div className="course-center">
                                <h2>Top <span>Management</span> Courses in <span>Bangalore</span> </h2>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                            </div>
                        </div> */}
                        <div className="widgets get-details-now-form right-widget">
                            <h4 className="title-heading">Upcoming <span>Admissions</span></h4>
                            {
                                topCollegeArr && topCollegeArr.map((el, idx) => {
                                    return (
                                        <div className="coming-exam-block">
                                            <span className="icon">
                                                <img alt={el.name} src={ el.logo ? process.env.REACT_APP_IMG_URI+el.logo : CollegeIMG} />
                                                {/* <div>12<sub>th</sub><br/> Jan</div> */}
                                            </span>
                                           <a href={'/college/'+el.slug} className='nohyperlink'> <h2>{el.name}</h2></a>
                                        </div>
                                    )
                                })
                            }
                            <div className="button-section text-center">
                                <a href={'/admission'} className="button btn">View All</a>
                            </div>

                            {/* {
                                upcomingdataexam && upcomingdataexam.map((i) => {
                                    let examlink = '/exams/'+i.slug;
                                    let imageUrl = i.cover_image ? config.baseUrlfromconfig + i.cover_image : EntranceExam;
                                    return (
                                        <div className="coming-exam-block">
                                            
                                            <span className="icon">
                                                <img src={imageUrl} />
                                                <div>{moment(i.upcoming_date).format('D')}<sub>th</sub><br/>{moment(i.upcoming_date).format('MMM')}</div>
                                            </span>
                                            <a href={examlink}  className="nohyperlink">
                                                <h2>{i.meta_title}</h2>
                                            </a>
                                        </div>
                                    )
                                })
                            } */}
                            {/* 
                            <div className="coming-exam-block">
                                <span className="icon">
                                    <img src={EntranceExam} />
                                    <div>12<sub>th</sub><br/> Jan</div>
                                </span>
                                <h2>National Eligibility Cum Entrance Exam For Medicine.</h2>
                            </div>
                            <div className="coming-exam-block">
                                <span className="icon">
                                    <img src={EntranceExam} />
                                    <div>12<sub>th</sub><br/> Jan</div>
                                </span>
                                <h2>National Eligibility Cum Entrance Exam For Medicine.</h2>
                            </div>
                            <div className="coming-exam-block">
                                <span className="icon">
                                    <img src={EntranceExam} />
                                    <div>12<sub>th</sub><br/> Jan</div>
                                </span>
                                <h2>National Eligibility Cum Entrance Exam For Medicine.</h2>
                            </div>
                            <div className="coming-exam-block">
                                <span className="icon">
                                    <img src={EntranceExam} />
                                    <div>12<sub>th</sub><br/> Jan</div>
                                </span>
                                <h2>National Eligibility Cum Entrance Exam For Medicine.</h2>
                            </div>
                            <div className="coming-exam-block">
                                <span className="icon">
                                    <img src={EntranceExam} />
                                    <div>12<sub>th</sub><br/> Jan</div>
                                </span>
                                <h2>National Eligibility Cum Entrance Exam For Medicine.</h2>
                            </div> */}
                            {/* <div className="button-section text-center">
                                <a href="/exams" className="button btn">View All</a>
                            </div> */}
                        </div>

                        {/* <div className="widgets top-manag-course-widget text-center" style={{ background: `url(${TopManagementCourseBG})` }}>
                            <div className="course-center">
                                <h2>Top <span>Management</span> Courses in <span>Bangalore</span> </h2>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                            </div>
                        </div> */}
                        {
                            promoBannerArr && promoBannerArr.map((i) => {
                                let banner_image = i.image ? config.baseUrlfromconfig + i.image : TopManagementCourseBG;
                                return (
                                    <>
                                        <div className="widgets top-manag-course-widget text-center" style={{ background: `url(${banner_image})` }}>
                                            <div className="course-center">
                                                <h2>&nbsp;</h2>
                                                <p>&nbsp;</p>
                                                {/* <h2>Top Management Courses in Bangalore</h2>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p> */}
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OverviewSection;