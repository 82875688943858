import React from 'react';
import { useHistory } from "react-router-dom";
import type { FC } from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik,useField,Field } from 'formik';
import axios from 'src/utils/axios';
import { useTranslation,  } from 'react-i18next';
import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Typography,
  Link,
  makeStyles
} from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import useIsMountedRef from 'src/hooks/useIsMountedRef';

interface VerificationScreenViewProps {
  className?: string;
  email?: any;
  updatestateofview?: (type,email,token) => void;
}

const useStyles = makeStyles(() => ({
  root: {
    color:'black',
  },
  textContainer: {
    textAlign: 'center',
    margin: '0 auto',
    color: 'black'
  },
  textField: {
    color: 'black',
  },
  notchedOutline: {
    borderColor: '#99A3C8 !important'
  },
  focused: {
    "&$cssFocused $notchedOutline": {
      borderColor: "yellow !important"
    }
  },
  button: {
    backgroundColor: '#1BA6A0'
  },
  buttonColor:{
    height: '64px',
    backgroundColor: '#1BA6A0',
   borderRadius:'80px',
    '&:hover': {
      backgroundColor: '#1BA6A0'    
    }
  },
  cusInput: {
    borderRadius:'500px',
    border:'1px solid #E1E1E1',
    backgroundColor:'#ffffff',
    paddingRight:"0",
    position:"relative",
    ':-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px white inset',
      backgroundColor: 'red !important'
   },
   "& div":{
      position:"absolute",
      right:"0",
   },
    "&&&:before": {
      borderBottom: "none"
    },
    "&&:after": {
      borderBottom: "none"
    },
    "&:hover":{
      backgroundColor:"#ffffff"
    },
    "&:-webkit-autofill": {
      backgroundColor:'#ff0000'
    },
    "& input":{
      borderRadius:"500px",
      backgroundColor:"#ffffff",
    }
  },
}));

const VerificationScreenView: FC<VerificationScreenViewProps> = ({ className,email,updatestateofview, ...rest }) => {
 // const { t, i18n } = useTranslation(['en-us', 'en-uk']);
  const classes = useStyles();
  const { register } = useAuth() as any;
  const isMountedRef = useIsMountedRef();
  const history = useHistory();
  const emailaddress = email;

  //const forgetemail  = localStorage.getItem("forgetemail");
  const linktoforget =  (type,email,token) => {
    updatestateofview(type,email,token);
  
  };
  return (
    <Formik
      initialValues={{
        email: '',
        code: '',
        submit: null
      }}
      validationSchema={Yup.object().shape({
        //email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
        //name: Yup.string().max(255).required('Name is required'),
        //password: Yup.string().min(7).max(255).required('Password is required'),
        //policy: Yup.boolean().oneOf([true], 'This field must be checked')
         code: Yup.string().required('Code is required')
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(6, 'Must be exactly 6 digits')
        .max(6, 'Must be exactly 6 digits'),
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting
      }) => {  

       /*
        localStorage.setItem("verficiationcode", '1234');
        localStorage.setItem("forgetemail", 'pkumar@ptgenie.com');
    

        history.push({
          pathname: '/reset-password',
          
        })
        */
        
        try {
          //await register(values.email, values.name, values.password);
          const data1 = { email: emailaddress, token: values.code };  
          const response = await axios.post('api/auth/user/forgotPassword/tokenverify', data1)  ;

          if(response.data.status)
          {//if (isMountedRef.current) {


            localStorage.setItem("verficiationcode", values.code);
           // localStorage.setItem("forgetemail",emailaddress);
            linktoforget('forgetstep3',emailaddress,values.code);
            setStatus({ success: true });
            setSubmitting(false);
          }
          else{
        
            setStatus({ success: false });

            if(response.data.errors)
            {
              setErrors({ submit: response.data.errors[0].msg });
            }
            else{
              setErrors({ submit: response.data.message });
            }
           // setErrors({ submit: response.data.message });
            setSubmitting(false);

          }
        } catch (err) {
          console.error(err);
          setStatus({ success: false });
          if(err.errors)
            {
              setErrors({ submit: err.errors[0].msg });
            }
            else{
              setErrors({ submit: err.message });
            }
          setSubmitting(false);
        }
        
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form
          noValidate
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
            
 <div className="form-group">
<Field type="text" name="code"
           onChange={handleChange}
           onBlur={handleBlur}
           value={values.code}
           className="form-control" id="code" placeholder="Enter Code"  />
         {errors.code && touched.code && <p className="error">{errors.code}</p>}
  </div>
          
  {errors.submit && (
            <Box mt={2}>
              <FormHelperText error style={{fontSize:'16px'}}>
                {errors.submit}
              </FormHelperText>
            </Box>
          )}
          
<Box
              my={2}
              
            >
              <Link
  
                onClick={() => linktoforget('login','','')}
                variant="body2"
              
                style={{fontSize:'1rem'}}
              >
               Back to Login
              </Link>
            </Box>
          <Box mt={2}>
            
            <div className="text-center"><button type="submit">Verify</button></div>
           
          </Box>
        </form>
      )}
    </Formik>
  );
};

VerificationScreenView.propTypes = {
  className: PropTypes.string,
  updatestateofview: PropTypes.func,
};

export default VerificationScreenView;
