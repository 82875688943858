import React, { FC } from 'react';
import './stylesglobalpopup.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    PinterestShareButton,
    PinterestIcon,
    LinkedinShareButton,
    LinkedinIcon,
    WhatsappShareButton,
    WhatsappIcon
} from "react-share";


import CustomTwitterIcon from 'src/assets/img/twitter-icon.png';
interface Props {
    className?: any;
    title?: any;
    pathname?:any;
    logourl?:any;
}

function MyVerticallyCenteredModal(props) {
    return (
        <Modal
            {...props}
            // size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            id="share-this"
        >
            <Modal.Header closeButton className="close"></Modal.Header>
            <Modal.Body >
                <div className="backgroundwhite mt-30">
                    <h4>Share Our Blogs To</h4>
                    <ul>
                        <li>
                            {/* <a className="facebook" href=""><i className="bi bi-facebook"></i></a> */}
                            <FacebookShareButton url={props.pathname} quote={props.title} className="facebook">
                                <FacebookIcon size={32} className="bi bi-facebook" round={true}/>
                            </FacebookShareButton>     
                        </li>
                        <li>
                            {/* <a className="twitter" href=""><i className="bi bi-twitter"></i></a> */}
                            <TwitterShareButton url={props.pathname} className="twitter">
                            <img src={CustomTwitterIcon} className='twitter-icon rounded-circle' alt="Twitter Icon" />

                            </TwitterShareButton>        
                        </li>
                        <li>
                            {/* <a className="pinterest" href=""><i className="bi bi-pinterest"></i></a> */}
                            <PinterestShareButton url={props.pathname} media={props.logourl} className="pinterest">
                                <PinterestIcon size={32} round={true}/>
                            </PinterestShareButton>    
                        </li>
                        <li>
                            {/* <a className="linkedin" href=""><i className="bi bi-linkedin"></i></a> */}
                            <LinkedinShareButton url={props.pathname} className="linkedin" >
                                <LinkedinIcon size={32} round={true}/>
                            </LinkedinShareButton>     
                        </li>
                        <li>
                            {/* <a className="youtube" href=""><i className="bi bi-youtube"></i></a> */}
                            <WhatsappShareButton url={props.pathname}  className="youtube">
                                <WhatsappIcon size={32} round={true}/>
                            </WhatsappShareButton>   
                        </li>                 
                    </ul>
                </div>
            </Modal.Body>
        </Modal>
    );
}

const GlobalPopupShare: FC<Props> = ({ className, title, pathname, logourl, ...rest }) => {
    const [modalShow, setModalShow] = React.useState(false);
    return (
        <>
            <a className="share-this" onClick={() => setModalShow(true)}><i className="bi bi-share-fill"></i></a>
            {/* <a onClick={() => setModalShow(true)} className={className ? className : 'active_bt'}>
                {title ? title : 'Apply Now'}
            </a> */}
            <MyVerticallyCenteredModal
                title={title ? title : 'Apply Now'}
                show={modalShow}
                pathname={pathname}
                logourl={logourl}
                onHide={() => setModalShow(false)}
            />
        </>
    );
};

export default GlobalPopupShare;