import React, { FC } from 'react';
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import { pConfig } from 'src/config';
import Helmet from 'react-helmet';
import type { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    // minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3),
    paddingTop: 80,
    // paddingBottom: 80
  },
  image: {
    maxWidth: '100%',
    width: 560,
    maxHeight: 300,
    height: 'auto'
  }
}));

const ThankYouPage: FC = () => {

  const classes = useStyles();



  const handleClick = () => {

    window.location.href = "https://bangalorestudy.com"
  };

  return (
    <>

      <Helmet>

        <link rel="canonical" href={`${pConfig.siteUrl}thank-you`} />
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-990332405"></script>
        <script>
          {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'AW-990332405');
        gtag('event', 'conversion', {'send_to': 'AW-990332405/I8MyCLbR0MMBEPWLndgD'});
        `}
        </script>
      </Helmet>


      <Page
        className={classes.root}
        title="Thank-you"
      >
        <Container maxWidth="lg">

          <Box
            mt={6}
            mb={6}
            display="flex"
            justifyContent="center"
          >
            <img
              alt="Under development"
              className={classes.image}
              src="/static/images/thank-you-web.png"

            />
          </Box>
          <Typography

            align="center"
            variant="subtitle2"
            color="textPrimary"
          >
            Our counsellors will get in touch with you shortly. You could also call us on 18001208696 (toll-free) for further queries.
          </Typography>
          <Box
            mt={6}
            display="flex"
            justifyContent="center"
          >
            <Button
              color="secondary"
              variant="outlined"
              onClick={handleClick}
            >
              Back to home
            </Button>

          </Box>

        </Container>
      </Page>

    </>
  );
};

export default ThankYouPage;