import React, { FC, useEffect, useState, useContext } from 'react';
import SettingsContext from 'src/contexts/SettingsContext';
import axios from 'src/utils/axiosintercpt';
import { makeStyles } from '@material-ui/core';
import SearchHeader from './SearchHeader';
import Breadcrumbs from './Breadcrumbs';
import CoursesList from './CoursesList';
import TopCourses from './TopCourses';
import { pConfig } from 'src/config';
import Helmet from 'react-helmet';

const useStyles = makeStyles(() => ({
  root: {}
}));

const CourseList: FC = () => {
  const classes = useStyles();
  const setCtx = useContext(SettingsContext);

  const [bannerArr, setBannerArr] = useState([]);
  const [countBlockInfo, setCountBlockInfo] = useState({});
  const [courseStreamArr, setCourseStreamArr] = useState([]);

  const [topCollegeArr, setTopCollegeArr] = useState([]);
  const [topUniversityArr, setTopUniversityArr] = useState([]);
  const [topExamArr, setTopExamArr] = useState([]);
  const [testimonialsArr, setTestimonialsArr] = useState([]);
  
  const [blogArr, setBlogArr] = useState([]);
  const [newsArr, setNewsArr] = useState([]);

  const [isHeroLoaded, setHeroLoaded] = useState(false);
  const [isTopColLoaded, setTopColLoaded] = useState(false);
  const [isBlogLoaded, setBlogLoaded] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);

  const getHeroData = async ()=>{
    const formData = new FormData();
    formData.append('getdata', JSON.stringify([{data: "course_streams_head"}]));
    const heroResp = await axios.post('api/website/home/header', formData);

    //setBannerArr(heroResp.data.data.banners);
    //setCountBlockInfo(heroResp.data.data.count_block);
    //setCourseStreamArr(heroResp.data.data.course_streams_head);
    
    setCtx.setCourseData(heroResp.data.data.course_streams_head);
    setHeroLoaded(true);
  }


  const getCollageUnivData = async (pagenumber, courseStreamArr)=>{

    let params = {}
    params['page'] = pagenumber;
    params['columnname'] = 'stream_name';
    params['size'] = '12';
    params['orderby'] = 'asc';

    //let url = 'api/website/home/generalcourse?page=1&size=12&orderby=asc&columnname=stream_name';

    // params['page'] = 1;
    // params['size'] = 20;
    // params['orderby'] = 'asc';
    // params['columnname'] = 'stream_name';

    try {
      let response = await axios.post('api/website/home/generalcourse?page='+pagenumber+'&size=12&orderby=asc&columnname=listing_order');//await axios.post(url, params);
      console.log(response);
      if (response.data.status) {
        //setCourseStreamArr(response.data.data);
        setTotalPages(response.data.totalPages);        
        setPage(pagenumber);
        setCourseStreamArr([...courseStreamArr,...response.data.data]);
        setIsFetching(false);  
      }
    } catch (err) {
      console.error(err);
    }
    setTopColLoaded(true);
    /*
    const formData = new FormData();
    formData.append('getdata', JSON.stringify([{data: "college"},{data: "university"},{data: "exam"},{data: "testimonial"}]));
    const univColgResp = await axios.post('api/website/home/topcrousel', formData);

    setTopCollegeArr(univColgResp.data.data.topcollege.data);
    setTopUniversityArr(univColgResp.data.data.topuniversity.data);
    setTopExamArr(univColgResp.data.data.topexam.data);
    setTestimonialsArr(univColgResp.data.data.testimonials.data)
    setTopColLoaded(true);*/
  }

  const geBlogData = async ()=>{
    const formData = new FormData();
    formData.append('getdata', JSON.stringify([{data: "blogs"},{data: "news"}]));
    const blogResp = await axios.post('api/website/home/blogsandnews', formData);

    //console.log(blogResp.data.data.blogs.data)
    //setBlogArr(blogResp.data.data.blogs.data);
    //setNewsArr(blogResp.data.data.news.data);
    //setBlogLoaded(true);
  }
  const [metatitle, setmetatitle] = useState<any>([]);
  const [metadescription, setmetadescription] = useState<any>([]);
  const [coursedata, setcoursedata] = useState([]);

  const getcoursedata = async ()=>{
    const response = await axios.get('api/website/page/get/course');
    //console.log(response.data.data);
    setcoursedata(response.data.data);
    setmetatitle(response.data.data.meta_title)
    setmetadescription(response.data.data.meta_description)
  }

  useEffect(()=>{
    getHeroData();
    getcoursedata();
    getCollageUnivData(page, courseStreamArr);
    //geBlogData();
  }, []);

  return (
    <>
    {isHeroLoaded && isTopColLoaded ? 
      <>
       <Helmet>
            <title>{metatitle}</title>
            <meta name="description" content={metadescription} />
            <link rel="canonical" href={`${pConfig.siteUrl}courses`} />
        </Helmet>
        <SearchHeader courseStreamArr={courseStreamArr}/>
        <Breadcrumbs/>
        <CoursesList courseStreamArr={courseStreamArr}  totalPages={totalPages} isFetching={isFetching} getCollageUnivData={getCollageUnivData} page={page}/>
        <TopCourses coursedata={coursedata}/>
      </>
      :
      <p style={{height: '8000px'}}>Loading</p> }
    </>
  );
};

export default CourseList;