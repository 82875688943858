import React, { FC, useEffect, useState, useContext, useCallback } from 'react';
import SettingsContext from 'src/contexts/SettingsContext';
import axios from 'src/utils/axiosintercpt';
import { CircularProgress, makeStyles } from '@material-ui/core';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import Breadcrumbs from './Breadcrumbs';
import Header from './InnerHeader';
import OverviewSection from './OverviewSection';
import TopColleges from 'src/views/HomeNew/TopColleges/index';
import Features from './Features';
import Interested from './Interested';
import Location from './Location';
import GlobalReviewSection from 'src/components/GlobalReviewSection';

import { pConfig } from 'src/config';
import { useHistory, useParams } from "react-router-dom";
import Helmet from 'react-helmet';

const useStyles = makeStyles(() => ({
  root: {}
}));

const HomePage: FC = () => {
  const history = useHistory();
  const setCtx = useContext(SettingsContext);

  const classes = useStyles();

  const [bannerArr, setBannerArr] = useState([]);
  const [countBlockInfo, setCountBlockInfo] = useState({});
  const [courseStreamArr, setCourseStreamArr] = useState([]);
  const [topUniversityArr, setTopUniversityArr] = useState([]);
  const [topExamArr, setTopExamArr] = useState([]);
  const [testimonialsArr, setTestimonialsArr] = useState([]);

  const [blogArr, setBlogArr] = useState([]);
  const [newsArr, setNewsArr] = useState([]);
  const [isHeroLoaded, setHeroLoaded] = useState(false);
  const [isTopColLoaded, setTopColLoaded] = useState(false);
  const [isBlogLoaded, setBlogLoaded] = useState(false);
  const [topCollegeArr, setTopCollegeArr] = useState([]);

  const isMountedRef = useIsMountedRef();
  const [metatitle, setmetatitle] = useState<any>([]);
  const [metadescription, setmetadescription] = useState<any>([]);
  const [isColLoaded, setColLoaded] = useState(false);

  const [currentTab, setCurrentTab] = useState<string>('overview');

  const handleReviewButtonClick = () => {
    const reviewTab = document.getElementById('rtab');
    console.log(document);
    console.log(reviewTab);
    if (reviewTab) {
      reviewTab.scrollIntoView({ behavior: 'smooth' }); 
    }
    setCurrentTab('Reviews');
  };

  const { slug } = useParams();
  const getHeroData = async () => {
    const formData = new FormData();
    formData.append('getdata', JSON.stringify([{ data: "banners" }, { data: "course_streams_head" }, { data: "count_block" }]));
    const heroResp = await axios.post('api/website/home/header', formData);
    setBannerArr(heroResp.data.data.banners);
    setCountBlockInfo(heroResp.data.data.count_block);
    setCourseStreamArr(heroResp.data.data.course_streams_head);

    setCtx.setCourseData(heroResp.data.data.course_streams_head);
    setHeroLoaded(true);
  }


  const getCollageUnivData = async () => {
    const formData = new FormData();
    formData.append('getdata', JSON.stringify([{ data: "college" }, { data: "university" }, { data: "exam" }, { data: "testimonial" }]));
    const univColgResp = await axios.post('api/website/home/topcrousel', formData);

    setTopCollegeArr(univColgResp.data.data.topcollege.data);
    setTopUniversityArr(univColgResp.data.data.topuniversity.data);
    setTopExamArr(univColgResp.data.data.topexam.data);
    setTestimonialsArr(univColgResp.data.data.testimonials.data)
    setTopColLoaded(true);

  }

  const geBlogData = async () => {
    const formData = new FormData();
    formData.append('getdata', JSON.stringify([{ data: "blogs" }, { data: "news" }]));
    const blogResp = await axios.post('api/website/home/blogsandnews', formData);
    console.log(blogResp.data.data.blogs.data)
    setBlogArr(blogResp.data.data.blogs.data);
    setNewsArr(blogResp.data.data.news.data);

  }



  const [collegedetail, setcollegedetail] = useState<any>([]);

  const getdatabystreamid = useCallback(async () => {
    try {

      const id = slug;//'engineering';
      const response = await axios.get('api/website/home/college/get/' + id);
      if (isMountedRef.current) {
        // console.log("response.data.data");
        // console.log(response.data.data);

        if (response.data.data && response.data.data.type && response.data.data.type == 'university') {
          history.push("/university/" + response.data.data.slug);
        }
        if (response.data.data && response.data.data.type && response.data.data.type == 'board') {
          history.push("/board/" + response.data.data.slug);
        }
        setcollegedetail(response.data.data);
        setmetatitle(response.data.data.meta_title)
        setmetadescription(response.data.data.meta_description)
        setColLoaded(true)
      }
    } catch (err) {
      console.error(err);
      history.push("/404");
    }
  }, [isMountedRef]);

  const formattedData = collegedetail && collegedetail.faqs && collegedetail.faqs.map((item) => ({
    "@type": "Question",
    "name": item.questions,
    "acceptedAnswer": {
      "@type": "Answer",
      "text": item.answers,
    },
  }));

  useEffect(() => {
    getHeroData();

    getCollageUnivData();
    geBlogData();
    getdatabystreamid();
  }, []);

  useEffect(() => {

  }, []);

  return (
    <>
      {isColLoaded ?
        <>
          <Helmet>
            <title>{metatitle}</title>
            <meta name="description" content={metadescription} />
            <link rel="canonical" href={`${pConfig.siteUrl}college/${slug}`} />
            <script type="application/ld+json">
              {JSON.stringify(
                {
                  "@context": "https://schema.org",
                  "@type": "FAQPage",
                  "mainEntity": formattedData,
                }
              )}
            </script>
          </Helmet>
          <Header
            collegedetail={collegedetail}
            slug={slug}
            onReviewButtonClick={handleReviewButtonClick}
          />
          <Breadcrumbs
            collegedetail={collegedetail}
          />
          <OverviewSection collegedetail={collegedetail} currentTab={currentTab}
            setCurrentTab={setCurrentTab} />
          <Features collegedetail={collegedetail} />
          <Interested collegedetail={collegedetail} />
          <Location collegedetail={collegedetail} />

          {/* <GlobalReviewSection type="college" item_id={collegedetail.id?collegedetail.id:0}   /> */}
          {/* item_id={collegedetail.id?collegedetail.id:0} */}
          <TopColleges topCollegeArr={topCollegeArr} />
        </>
        :
        <div style={{ display: 'flex', justifyContent: 'center', height: '600px', flexWrap: 'wrap', alignContent: 'space-around' }}><CircularProgress /></div>}
    </>
  );
};

export default HomePage;