import React, { FC } from 'react';
import './styles.css';
import NewsBG from 'src/assets/img/new-Admission.jpg';
import EMIBG from 'src/assets/img/new-Emi-Banner.jpg';
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Carousel from 'react-bootstrap/Carousel';

const Header: FC = () => {

    const [num, setNum] = React.useState(331231);

    const owlOptions = {
        margin: 20, nav: true, autoplay: false,
        responsiveClass: true,
        responsive: {
            0: { items: 1 },
            400: { items: 1 },
            600: { items: 1 },
            700: { items: 1 }
        },
    };

    const bannerArr = [
        {'image': NewsBG},
        {'image': EMIBG},
    ];

    return (
        <>
        <div className="crslCont" >
                <Carousel controls={false}>
                    {
                        bannerArr.map((el, idx) => (
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={el.image}
                                    alt="First slide"
                                />
                            </Carousel.Item>
                        ))
                    }
                </Carousel>

                <div className="maskSection inner-top-header admissionsection" id="hero">
                    <div className="container-fluid" >
                        <div className="container">
                            <div className="row top-course-block">
                                <div className="top-course-header col-xl-7 col-lg-12 col-sm-12" style={{backgroundColor: 'rgba(0,0,0,0.6)', padding:'20px'}}>
                                    <h1>COLLEGE <span>ADMISSIONS</span></h1>
                                    {/* <p>Click "Apply Now" To Get Admission 2022 Details</p> */}
                                </div>
                            </div>
                            <div className="spac_10"></div>
                            <div className="col-lg-12 button-section">
                            
                                <GlobalPopupEnquiry className="medical-button btn" />
                            </div>
                        </div> {/* end container  */}
                    </div> {/* end container fluid  */}
                </div>
            </div>
        {/* <section className="inner-top-header" id="hero" style={{ background: `linear-gradient(359deg, rgb(0 0 0 / 80%), rgb(0 0 0 / 80%)) fixed, url(${NewsBG})` }} >
            <div className="hero-container d-flex">
                <div className="container" >
                    <div className="row top-course-block">
                        <div className="top-course-header">
                            <h1>COLLEGE <span>ADMISSIONS</span></h1>
                            <p>Click "Apply Now" To Get Admission 2022 Details</p>
                        </div>
                    </div>
                    <div className="spac_02"></div>
                    <div className="col-lg-12 button-section">
                       
                        <GlobalPopupEnquiry className="medical-button btn" />
                    </div>
                </div>
            </div>
        </section> */}
        </>
    );
};

export default Header;