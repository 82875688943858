import React, { FC, useEffect, useState, useContext, useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import './styles.css';
import moment from 'moment';
import EntranceExam from 'src/assets/img/entrance-exam.jpg';
import TopManagementCourseBG from 'src/assets/img/top-managment-course.jpg';
import GetMoreBlog from 'src/assets/img/get-more-blog.jpg';
import { config, pConfig } from 'src/config';
import EnquiryDetailForm from 'src/components/EnquiryDetailForm';
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    PinterestShareButton,
    PinterestIcon,
    LinkedinShareButton,
    LinkedinIcon,
    WhatsappShareButton,
    WhatsappIcon
} from "react-share";

import CustomTwitterIcon from 'src/assets/img/twitter-icon.png';
//import FacebookProvider, { Share, useShare } from 'react-facebook';


const useStylesBootstrap = makeStyles(() => ({
    arrow: {
        color: '#000',
    },
    tooltip: {
        backgroundColor: '#000',
    },
}));

function BootstrapTooltip(props: TooltipProps) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

const Breadcrumbs: FC<{ blogdata: any, blogdataarray: any, slug: any }> = ({ blogdata, blogdataarray, slug }) => {
    const [num, setNum] = React.useState(331231);
    let bloglink = '/blog/' + blogdata.slug;
    const pathname = 'https://bangalorestudy.com/static/media/uni_icon.74f88be1.svg';//pConfig.siteUrl+bloglink;
    const logoUrl = blogdata.cover_image ? config.baseUrlfromconfig + blogdata.cover_image : TopManagementCourseBG;
    let banner_image = blogdata.promo_banner && blogdata.promo_banner_status != 'Draft' ? config.baseUrlfromconfig + blogdata.promo_banner : TopManagementCourseBG;
    const quote = blogdata.title;
    console.log(blogdata.title);

    /*const shareContent = {
        quote: "Our Work",
        href: window.location.href,
        picture: "http://100.26.9.191:3000/blog_cover/FszRxsIAN24wCnOSRAdrIa4VsIkYJ3CKDQ7Tz9RW.jpeg",
        description: "test description"
    };

    function shareToFacebook(text, image, description) {
        window.FB.ui({
          method: 'share',
          quote: "Our Work",
          href: window.location.href,
          picture: "https://api.bangalorestudy.com:3000/banners/image1675496246342.jpg",
          description: "test description"
        }, function(response){});
      }

      function shareToTwitter(text, url) {
        const shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(url)}`;
        window.open(shareUrl, '_blank');
      }*/

    return (
        <>

            {/* <div className="sticky-container" data-aos="fade-up">
                <ul className="sticky">
                    <li>
                        <FacebookShareButton url={pathname} 
                            quote={blogdata.title}>
                            <FacebookIcon size={32} round={true}/>
                        </FacebookShareButton>  
                    </li>
                    <li>
                        <TwitterShareButton url={pathname}>
                            <TwitterIcon size={32} round={true}/>
                        </TwitterShareButton>        
                    </li>
                    <li>
                        <PinterestShareButton url={pathname} media={logoUrl}>
                            <PinterestIcon size={32} round={true}/>
                        </PinterestShareButton> 
                    </li>
                    <li>
                        <LinkedinShareButton url={pathname}>
                            <LinkedinIcon size={32} round={true}/>
                        </LinkedinShareButton>                           
                    </li>
                    <li>
                        <WhatsappShareButton url={pathname}>
                            <WhatsappIcon size={32} round={true}/>
                        </WhatsappShareButton>                           
                    </li>
                </ul>
            </div> */}
            <section className="course-overviews-section blogoverviewsection">
                <div className="container aos-init aos-animate" >
                    <div className="row">
                        <div className="col-lg-8 htmlcontentsection1" >
                            <div className='left-exam-section'>
                                <ul className='share-this'>
                                    <li>
                                        {/* <a className="sharing-btn btn-facebook" target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=http://localhost:3001/blog/orchids-the-international-school">
        <span className="icon i-facebook"></span>
		<span className="text">Share</span>
    </a> */}
                                        {/* <a href={getFacebookUrl({ url: "http://preprod.bangalorestudy.com/blog/orchids-the-international-school" })}>
    Share to Facebook
  </a> */}
                                        {/* <button onClick={() => shareToFacebook('Hello Facebook!', 'https://example.com/image.jpg', 'Check out my website.')}>Share on Facebook</button>

                                       <button onClick={() => shareToTwitter('Hello Twitter!', 'http://100.26.9.191:3000/blog_cover/FszRxsIAN24wCnOSRAdrIa4VsIkYJ3CKDQ7Tz9RW.jpeg')}>Share on Twitter</button> */}

                                        <FacebookShareButton url={pathname} quote={quote}>
                                            <FacebookIcon size={32} round={true} />
                                        </FacebookShareButton>
                                        {/* <FacebookShareButton
                                            url={"http://www.camperstribe.com"}
                                            quote={"CampersTribe - World is yours to explore"}
                                            hashtag="#camperstribe"
                                        >
                                            <FacebookIcon size={36} />
                                        </FacebookShareButton>  */}
                                    </li>
                                    <li>
                                        <TwitterShareButton url={pathname}>
                                            {/* <TwitterIcon size={32} round={true} /> */}
                                            <img src={CustomTwitterIcon} className='twitter-icon rounded-circle' alt="Twitter Icon" />
                                        </TwitterShareButton>
                                    </li>
                                    <li>
                                        <PinterestShareButton url={pathname} media={logoUrl}>
                                            <PinterestIcon size={32} round={true} />
                                        </PinterestShareButton>
                                    </li>
                                    <li>
                                        <LinkedinShareButton url={pathname}>
                                            <LinkedinIcon size={32} round={true} />
                                        </LinkedinShareButton>
                                    </li>
                                    <li>
                                        <WhatsappShareButton url={pathname}>
                                            <WhatsappIcon size={32} round={true} />
                                        </WhatsappShareButton>
                                    </li>
                                </ul>
                                <div className="spac_02"></div>
                            </div>
                            <div className='row bs-editor-text'>

                                {/* <img alt={blogdata.title}  src={ blogdata.cover_image ? config.baseUrlfromconfig + blogdata.cover_image : EntranceExam} /> */}
                                <span dangerouslySetInnerHTML={{ __html: blogdata.body }} />
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className="widgets get-details-now-form right-widget">

                                <h4 className="title-heading">Get <span>More</span> Details</h4>
                                <EnquiryDetailForm type="admissions" />
                                {/* <form action="forms/contact.php" method="post" role="form" className="php-email-form">

                                    <div className="form-group">
                                        <input type="text" className="form-control" name="subject" id="subject" placeholder="Enter Your Name" />
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-6 form-group">
                                            <input type="email" name="name" className="form-control" id="name" placeholder="Enter Email" />
                                        </div>
                                        <div className="col-lg-6 form-group">
                                            <input type="tel" className="form-control" name="email" id="email" placeholder="Enter Number" />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <input type="text" className="form-control" name="subject" id="subject" placeholder="Enter Course" />
                                    </div>

                                    <div className="form-group">
                                        <input type="text" className="form-control" name="subject" id="subject" placeholder="Enter Location" />
                                    </div>

                                    <div className="form-group">
                                        <textarea className="form-control" name="message" placeholder="Type Your Message" ></textarea>
                                    </div>


                                    <div className="text-center"><button type="submit">Submit</button></div>
                                </form> */}

                            </div>

                            <div className="widgets get-details-now-form right-widget">

                                <h4 className="title-heading">Latest <span>Blogs</span></h4>
                                {
                                    blogdataarray && blogdataarray.filter(o => o.slug != slug).slice(0, 5).map(i => {
                                        //blogdataarray && blogdataarray.map((i) => {
                                        let bloglink = '/blog/' + i.slug;
                                        return (
                                            <div className="coming-exam-block">
                                                <span className="icon">
                                                    <img alt={i.title} src={i.cover_image ? config.baseUrlfromconfig + i.cover_image : EntranceExam} />
                                                    {/* <div>{moment(i.created_at).format('D')}<sub>th</sub><br/>{moment(i.created_at).format('MMM')}</div> */}
                                                </span>
                                                <a href={bloglink} className='nohyperlink'><h2>{i.title}</h2></a>
                                            </div>
                                        )
                                    })
                                }
                                <div className="button-section text-center">
                                    <a href={'/blog'} className="button btn">View All</a>
                                </div>


                            </div>

                            {
                                blogdata.promo_banner && blogdata.promo_banner_status != 'Draft' ?
                                    <>
                                        {/* <div className="widgets top-manag-course-widget text-center" style={{ background: `url(${banner_image})` }}>
                                            <div className="course-center">
                                                <h2>&nbsp;</h2>
                                                <p>&nbsp;</p>
                                              
                                            </div>

                                            
                                        </div> */}

                                        <div className="testi_wrp top-streams-widget widgets">
                                            <div className="test_content_wrp col-lg-12 wrapexamcontent">
                                                <img alt={banner_image} src={banner_image} className="full_wdth" />
                                                <div className="clearfix"></div>

                                            </div>
                                        </div>
                                    </>
                                    : ''
                            }
                            {/* <div className="widgets top-manag-course-widget text-center" style={{ background: `url(${TopManagementCourseBG})` }}>
                                <div className="course-center">
                                    <h2>Top Management Courses in Bangalore</h2>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Breadcrumbs;