import React, { FC, useEffect, useState, useContext, useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import './styles.css';
import EnquiryFormAboutUs from 'src/components/EnquiryFormAboutUs';

import { config } from 'src/config';
import moment from 'moment';
const useStylesBootstrap = makeStyles(() => ({
    arrow: {
        color: '#000',
    },
    tooltip: {
        backgroundColor: '#000',
    },
}));

function BootstrapTooltip(props: TooltipProps) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

const LocationSection: FC<{}> = ({ }) => {
    const [num, setNum] = React.useState(331231);

    return (
        <section className="college-locations container-fluid spac_01" data-aos="fade-up">
            <div className="container "> 
                <div className="row">
                    <div className="col-lg-12" data-aos="fade-up">
                        <div className="widgets get-details-now-form no-bg right-widget">
                            <h4 className="title-heading">CONTACT US</h4>

                            <EnquiryFormAboutUs type={'aboutus'}/>   



                        </div>
                    </div>
                </div>
               
              
                
            </div> 
        </section>
    );
};

export default LocationSection;