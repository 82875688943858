export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};



export const config = { 
  baseUrlfromconfig: process.env.REACT_APP_PUBLIC_API_URI,//"https://api.bangalorestudy.com:3000/",
  baseUrlAccount: process.env.REACT_APP_PUBLIC_API_URI,//"https://api.bangalorestudy.com:3000/",
  videoServerBaseUrl: process.env.REACT_APP_PUBLIC_API_URI,//"https://api.bangalorestudy.com:3000/",
  siteUrl: process.env.REACT_APP_PUBLIC_API_URI,//"https://api.bangalorestudy.com:3000/"

};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
};

//https://preprod.bangalorestudy.com/login/linkedin/callback
export const pConfig = {
  baseUrl: process.env.REACT_APP_PUBLIC_API_URI,
  siteUrl:process.env.REACT_APP_SITE_URL,
  LI_REDIRECT_URI:process.env.REACT_APP_SITE_URL +'login',
  LI_CLIENT_ID: '77xvzj6mqjnmgt',
  LI_CLIENT_SECRET: 'caISg9aQCjzuoGHj',
 /// TW_consumerKey:'YIDyEUMR9PDHbl9T7Fkiysaqv',
 TW_consumerKey:'MW56MEoyam13WVFrVFYyVUN6djM6MTpjaQ',
  
  TW_consumerSecret:'jaFvIYvszIiSus9IU9yGY8DEYf6EesMCAgYtroRJDdWMiPGsM1',
  TW_callbackUrl:process.env.REACT_APP_SITE_URL+'login',
  G_clientID:'1056225267439-6bhrvskavfkm6ce8en6ml4qs30038udi.apps.googleusercontent.com',
  FB_appId:'744468493687986'
};
