import React, { FC } from 'react';
import './styles.css';

import CollageIcn from 'src/assets/img/college_icon.svg';
import UniversityIcn from 'src/assets/img/uni_icon.svg';
import ExamIcn from 'src/assets/img/exams_icon.svg';
import UniversityBg from 'src/assets/img/get-more-blog.jpg';
import Modal from 'react-bootstrap/Modal';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const CounterSection: FC<{ countBlockInfo: any, homevideosdata: any }> = ({ countBlockInfo, homevideosdata }) => {

    const [iframesrc, setIframesrc] = React.useState<string>('#');

    const owlOptions = {
        margin: 20, nav: true, autoplay: true,autoplayTimeout: 3000,
        responsiveClass: true,
        responsive: {
            0: {items:1, dots:false, nav:true,loop:true},
            400: {items:1, dots:false, nav:true,loop:true},
            600: {items: 1, dots:false, nav:true,loop:true},
            700: {items: 1,loop:true},
          
            1000:{
                items:1,
                nav:false,
                loop:true
            },
            1440:{
                items:1,
                nav:false,
                loop:true
            }
        },
    };

    const openframewindow = (youtubeid) => {
        let documenturl = "https://www.youtube.com/embed/"+youtubeid+ "?autoplay=1";
        setIframesrc(documenturl);
     
        setModalShow(true)
    };


    const [modalShow, setModalShow] = React.useState(false);



    function MyVerticallyCenteredModal(props) {
        return (
            <Modal
                {...props}
                // size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                id="enquiryformpopup"
            >
                <Modal.Header closeButton className='close'>
                </Modal.Header>
                <Modal.Body>
                    <div id="header-form" className="">
                        <iframe src={iframesrc} width="100%" height="360" allow="microphone; camera" ></iframe>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    return (
        <>
            <div className="banner_bottom countersection abouts m-0 spac_01" data-aos="fade-up">
                <div className="container">
                    <div className="">
                        <div className="row gy-4 d-flex align-items-center">
                            <div className="col-lg-6 position-relative  d-flex align-items-center order-lg-last order-first">

                            <OwlCarousel {...owlOptions} className="loop_nav owl-carousel owl-theme">
                                {homevideosdata.map((el, idx)  => {
                                    const youtubeid = el.embed_id;
                                    return (
                                        <div className="item" >
                                            <img loading="lazy" src={el?.Img_thumbnail? process.env.REACT_APP_IMG_URI+el.Img_thumbnail:UniversityBg} className="img-fluid" alt="" />
                                            <a onClick={() => openframewindow(youtubeid)} className="glightbox play-btn"></a>
                                        </div>
                                    )}
                                )}
                            </OwlCarousel>


                                
                            </div>
                            <div className="col-lg-6 content order-last  order-lg-first">
                                <ul>
                                    <li data-aos="fade-up" data-aos-delay="100" className="aos-init aos-animate">
                                        <div className="col-lg-4 col-sm-4">
                                            <div className="counter red">
                                                <div className="counter-content">
                                                    <div className="icon_box_new box_color"><img src={CollageIcn} /></div>
                                                    <h3>{countBlockInfo.college}</h3>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-lg-8 col-sm-8">
                                            <div>
                                                <h5><b>Colleges</b></h5>
                                                <p>Get complete information from the list of Courses to Amenities of various Colleges in Karnataka. Find the college of your choice from over 900+ colleges.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="fade-up" data-aos-delay="200" className="aos-init aos-animate">
                                        <div className="col-lg-4 col-sm-4">
                                            <div className="counter blue">
                                                <div className="counter-content">
                                                    <div className="icon_box_new box_color"><img src={UniversityIcn} /></div>
                                                    <h3>{countBlockInfo.university}</h3>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-lg-8 col-sm-8">
                                            <div>
                                                <h5><b>Universities</b></h5>
                                                <p>Explore all the Central, State and Private Universities of Karnataka to know more about courses offered, scholarships and other information.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="fade-up" data-aos-delay="300" className="aos-init aos-animate">
                                        <div className="col-lg-4 col-sm-4">
                                            <div className="counter orange">
                                                <div className="counter-content">
                                                    <div className="icon_box_new box_color"><img src={ExamIcn} /></div>
                                                    <h3>{countBlockInfo.exams}</h3>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-lg-8 col-sm-8">
                                            <div>
                                                <h5><b>Exams</b></h5>
                                                <p>Find all the latest information about different entrance exams. Search for any national level or state level entrance exams to know the dates, syllabus, eligibility criteria and more.</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <MyVerticallyCenteredModal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                        />


                    
                    </div>
                </div>
            </div>
        </>
    );
};

export default CounterSection;