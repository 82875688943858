import React, { FC, useRef, useState, useEffect } from 'react';
import axios from 'src/utils/axios';
import { useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import './styles.css';
import { useSnackbar } from 'notistack';
import { Field, Form, Formik, FormikHelpers, useField } from 'formik';
import clsx from 'clsx';
import * as Yup from 'yup';
import { saveAs } from 'file-saver'
import PhoneNumberField from 'src/components/PhoneInput'
import { pConfig } from 'src/config';
const styles = {
  search: {
    position: "relative",
  }
}


interface Props {
  className?: any;
  page?: any;
  label?: any;
  smallsize?: any;

  onChanges?: () => void;
}
// const HeroArea: FC<{ bannerArr: any }> = ({ bannerArr }) => { 
const EnquiryForm: FC<Props> = ({ className, label, page, onChanges, smallsize, ...rest }) => {


  const captchaRef = useRef(null)
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const downloadPDF = async (): Promise<void> => {
    var oReq = new XMLHttpRequest();

    var URLToPDF = pConfig.baseUrl + "brochure/B-study-Brochure-2023.pdf";

    oReq.open("GET", URLToPDF, true);

    oReq.responseType = "blob";

    oReq.onload = function () {
      // Once the file is downloaded, open a new window with the PDF
      // Remember to allow the POP-UPS in your browser
      const file = new Blob([oReq.response], { type: 'application/pdf' });


      //const fileURL = URL.createObjectURL(file);
      saveAs(file, "Bstudy Brochure 2023.pdf");
      // window.open(fileURL, "_blank");
    };

    oReq.send();
  }
  useEffect(() => {



  });


  return (

    <Formik
      initialValues={{

        fullname: '',
        phoneNumber: '',
        Email: '',
        CourseInMind: '',
        College: '',
        Location: '',
        message: ''


      }}
      validationSchema={
        Yup.object().shape({
          fullname: Yup.mixed().required('Name  is Required'),
          phoneNumber: Yup.string().matches(/^\+91-[6789]\d{9}$|^\+(?!91-)\d{1,3}-\d{1,10}$/, 'Phone number is not valid').required("Phone Number is required"),
          Email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
        })
      }

      onSubmit={async (values, actions) => {


        //  return  alert(JSON.stringify(values, null, 2));
        const formData = new FormData();

        formData.append('name', values.fullname);
        formData.append('contact', values.phoneNumber);
        formData.append('email', values.Email);
        formData.append('course_in_mind', values.CourseInMind);
        formData.append('college_name', values.College);
        formData.append('location', values.Location);
        formData.append('description', values.message);
        formData.append('current_url', window.location.href);
        let url = 'api/website/home/enquiry';
        // return alert(JSON.stringify(formData));

        try {

          let response = await axios.post(url, formData);
          // console.log('response: ', response);

          if (response.data.status) {


            actions.setSubmitting(true);
            actions.resetForm({
              values: {

                fullname: '',
                phoneNumber: '',
                Email: '',
                CourseInMind: '',
                College: '',
                Location: '',
                message: ''

              },
            });
            // enqueueSnackbar('Thankyou we will get back to you', {
            //   variant: 'success',
            // });

            if (page && page == 'Brochure') {
              downloadPDF();
              enqueueSnackbar('Thank you for downloading our brochure.', {
                variant: 'success',
              });

            }
            else {

              enqueueSnackbar('Thank you. We will get back to you.', {
                variant: 'success',
              });
            }
            history.push("/thank-you");
            //  onChanges();

          }
        } catch (err) {
          console.error(err);

          enqueueSnackbar("Please try again", {
            variant: 'error',

          });
        }


        captchaRef.current.reset();












      }}
    >
      {props => (
        <form onSubmit={props.handleSubmit}>
          {/* <p>&nbsp;</p> */}

          <div className="form-group">
            {/* <label htmlFor="fullName"></label> */}
            <Field type="text" name="fullname"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.fullname}
              className="form-control" id="name" placeholder="Full Name" required />
            {props.errors.fullname && props.touched.fullname && <p className="error">{props.errors.fullname}</p>}
          </div>
   
            <div className="form-group">
              {/* <label>Email</label> */}
              <Field type="email" className="form-control" name="Email"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.Email}
                id="Email" placeholder="Enter Email"
                required />
              {props.errors.Email && props.touched.Email && <p className="error">{props.errors.Email}</p>}
            </div>
            <div className="form-group">
              {/* <label>Phone Number</label> */}
              <PhoneNumberField />
              {/* <Field type="tel" className="form-control" name="phoneNumber"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.phoneNumber}
                id="PhoneNumber" placeholder="Phone Number"
                required /> */}
              {props.errors.phoneNumber && props.touched.phoneNumber && <p className="error">{props.errors.phoneNumber}</p>}
            </div>
         

          <div className="form-group">
            {/* <label>Course In Mind</label> */}
            <Field type="text" className="form-control" name="CourseInMind"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.CourseInMind}
              id="CourseInMind" placeholder="Enter Course" required
            />
          </div>

          {/* <div className="form-group">
            <label>College</label>
            <Field type="text" className="form-control" name="College"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.College}
              id="College" placeholder="Enter College" required
            />
          </div> */}

          <div className="form-group">
            {/* <label>Location</label> */}
            <Field type="text" className="form-control" name="Location"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.Location}
              id="Location" placeholder="Enter Location" required

            />
          </div>

          <div className="form-group mrb" >
            {/* <label>Message</label> */}

            <textarea className="form-control" name="message" id="inputID"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.message} placeholder="Type Your Message"
            ></textarea >
          </div>
          {/* <ReCAPTCHA
            sitekey="6LcbLSEjAAAAAEnZuOWLNsIJgCP3tptUhe2SpBIb"
            ref={captchaRef}
          /> */}

          <div className="text-center"><button type="submit" className={smallsize ? "small_submit_message home_submit_message" : "submit_message home_submit_message"}>{page == 'Brochure' || page == 'Download Brochure' ? 'Download Brochure' : page == "news" ? 'Predict Your Rank' : 'Get Guidance'}</button></div>



        </form>
      )}
    </Formik>




  );
};

export default EnquiryForm;