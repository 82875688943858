import React, { FC, useEffect, useState, useContext, useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import './styles.css';
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    PinterestShareButton,
    PinterestIcon,
    LinkedinShareButton,
    LinkedinIcon,
    WhatsappShareButton,
    WhatsappIcon



} from "react-share";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import EntranceExam from 'src/assets/img/entrance-exam.jpg';
import TopManagementCourseBG from 'src/assets/img/top-managment-course.jpg';
import NewsBG from 'src/assets/img/news.jpg';
import { config } from 'src/config';
import moment from 'moment';
import { pConfig } from 'src/config';
import EnquiryDetailForm from 'src/components/EnquiryDetailForm';
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';



import CustomTwitterIcon from 'src/assets/img/twitter-icon.png';

const useStylesBootstrap = makeStyles(() => ({
    arrow: {
        color: '#000',
    },
    tooltip: {
        backgroundColor: '#000',
    },
}));

function BootstrapTooltip(props: TooltipProps) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

const OverviewSection: FC<{ viewDetails: any, newsListing }> = ({ viewDetails, newsListing }) => {

    const [allianceuni, setallianceuni] = useState(false);
    const [validated, setValidated] = useState(false);


    const handleSubmit = (event: { currentTarget: any; preventDefault: () => void; stopPropagation: () => void; },) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        // <GlobalPopupEnquiry className="btn btn btn-primary" title="Please fill in your details to receive your detailed rank prediction." />
        event.preventDefault();
        console.log(event);
        setValidated(true);
        // <GlobalPopupEnquiry className="btn btn btn-primary" title="Please fill in your details to receive your detailed rank prediction." />

    };


    const getcheck = async () => {
        if (/neet-ug-2023-answer-key/.test(window.location.href)) {
            setallianceuni(true);
        }
    }


    useEffect(() => {
        getcheck();

    }, []);

    console.log(window.location.href);
    console.log(allianceuni, "allianceuni");
    const [num, setNum] = React.useState(331231);
    const logoUrl = viewDetails.cover_image ? config.baseUrlfromconfig + viewDetails.cover_image : NewsBG;
    //const pathname = 'http://preprod.bangalorestudy.com/news-and-event/ug-medical-courses-admission-date-extended';//window.location.href;
    let newslink = '/news-and-event/' + viewDetails.slug;
    const pathname = pConfig.siteUrl + newslink;
    return (
        <section className="course-overviews-section ">
            <div className="container aos-init aos-animate" >
                <div className="row">
                    <div className="col-lg-8 overviews-course left-exam-section" >
                        {/* <h3 className="main-heading left-align"><span>{viewDetails.title} </span> Date : {moment(viewDetails.created_at).format('D MMM YYYY')}</h3> */}
                        <h1 className="main-heading left-align"><span>{viewDetails.title} </span></h1>
                        <ul className='share-this'>
                            <li>
                                {/* <FacebookShareButton url={pathname} quote={'Test'}> */}
                                <FacebookShareButton url={pathname}>
                                    <FacebookIcon size={32} round={true} />
                                </FacebookShareButton>
                            </li>
                            <li>
                                <TwitterShareButton url={pathname}>
                                    <img src={CustomTwitterIcon} className='twitter-icon rounded-circle' alt="Twitter Icon" />
                                </TwitterShareButton>
                            </li>
                            <li>
                                <PinterestShareButton url={pathname} media={logoUrl}>
                                    <PinterestIcon size={32} round={true} />
                                </PinterestShareButton>
                            </li>
                            <li>
                                <LinkedinShareButton url={pathname}>
                                    <LinkedinIcon size={32} round={true} />
                                </LinkedinShareButton>
                            </li>
                            <li>
                                {/* <WhatsappShareButton url={pathname} title={''}> */}
                                <WhatsappShareButton url={pathname}>
                                    <WhatsappIcon size={32} round={true} />
                                </WhatsappShareButton>
                            </li>

                            {/* color: #0097e3;
    margin-bottom: 15px; */}
                            {/* <li><a className="facebook" href=""><i className="bi bi-facebook"></i></a></li>
                            <li><a className="twitter" href=""><i className="bi bi-twitter"></i></a></li>
                            <li><a className="pinterest" href=""><i className="bi bi-pinterest"></i></a></li>
                            <li><a className="linkedin" href=""><i className="bi bi-linkedin"></i></a></li>
                            <li><a className="whatsapp" href=""><i className="bi bi-whatsapp"></i></a></li>      
                            <li><a className="link" href=""><i className="bi bi-link"></i></a></li>              */}
                        </ul>
                        <div className="spac_02" style={allianceuni ? {} : { display: "none" }} >
                            <div className="widgets get-details-now-form right-widget">
                                {/* <h4 className="title-heading">NEET-UG 2023 Rank Predictor</h4> */}
                                <Row className="align-items-center  justify-content-center">

                                    <span style={{
                                        color: "#0097e3", marginBottom: "15px", fontSize: "18px",
                                    }} > Predict your Rank for NEET-UG 2023 here.</span>
                                </Row>
                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                    <Row className="align-items-center">
                                        <Col sm={4} style={{ fontSize: "18px", textAlign: "center" }} className="my-1 pl-sm-3 pb-sm-3 ">
                                            Out of 180 Questions

                                        </Col>
                                        <Col sm={4} className="my-1">
                                            <Form.Label htmlFor="inlineFormInputName" visuallyHidden>
                                                Name
                                            </Form.Label>
                                            <Form.Control id="inlineFormInputName" type="number" style={{ fontSize: "15px", textAlign: "center" }} placeholder="No. of Questions Attempted" required />
                                        </Col>
                                        <Col sm={4} className="my-1">
                                            <Form.Label htmlFor="inlineFormInputName" visuallyHidden>
                                                Name
                                            </Form.Label>
                                            <Form.Control id="inlineFormInputName" type="number" style={{ fontSize: "15px", textAlign: "center" }} placeholder="No. of Correct Answers" required />
                                        </Col>


                                    </Row>
                                    <Row className="align-items-center justify-content-center">
                                        <Col xs="auto" className="my-1">
                                            <GlobalPopupEnquiry className="btn btn btn-primary" pagename="news" title="Submit" />
                                            {/* <Button type="submit"> Submit</Button> */}


                                        </Col></Row>
                                </Form>


                                <div style={{ display: "none" }}>  <GlobalPopupEnquiry className="btn btn btn-primary" pagename="bb" /></div>
                            </div>
                        </div>



                        <div className="spac_02"></div>



                        <div className='row bs-editor-text'>
                            <span dangerouslySetInnerHTML={{ __html: viewDetails.body }} /></div>

                    </div>

                    <div className="col-lg-4" >
                        <div className="widgets  get-news-now-form right-widget">
                            <h4 className="title-heading">Get <span>Admission</span> Guidance</h4>
                            <EnquiryDetailForm type="admissions" />
                        </div>

                        {newsListing && newsListing.length > 0 ? <>
                            <div className="widgets get-news-now-form  right-widget"  >

                                <h4 className="title-heading">Other Recent <span>News</span></h4><div className='news-list'>
                                    {newsListing && newsListing.map((i, x) => {

                                        let eventlink = '/news-and-event/' + i.slug;
                                        return (

                                            <div className="coming-news-block"  >
                                                <span className="icon">
                                                    <img alt={i.title} src={i.cover_image ? config.baseUrlfromconfig + i.cover_image : EntranceExam} />
                                                </span>
                                                <h2><a href={eventlink} className="newshyperlink">{i.title}</a></h2>
                                            </div>



                                        )
                                    })}

                                </div>
                            </div> </> : ''}

                    </div>
                </div>
            </div>
        </section>
    );
};

export default OverviewSection;