import React, { FC } from 'react';
//import './styles.css';

const Breadcrumbs: FC<{ coursedetail: any}> = ({ coursedetail }) => {
    
    return (
        <div className="breadcrumbs">	
            <div className="container" >
                <ol>
                    <li><a href="/" className="active blue-color">Home</a> </li>
                    <li><a href="/universities">Universities</a></li>
                    <li><a href="">{coursedetail.name}</a></li>
                </ol>
            </div>
	    </div>
    );
};

export default Breadcrumbs;