import React, { FC, useEffect } from 'react';
import './styles.css';
import axios from 'src/utils/axios';
import axios1 from 'axios';

import ExamDetailHeaderBG from 'src/assets/img/exam-details-header2.jpg';
import Search from 'src/assets/img/icons/search.svg'

//import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { Autocomplete } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

interface BlogType {
    title: string;
    slug: string;
}

const SearchHeader: FC = () => {
    let cancelToken;

    //const [num, setNum] = React.useState(331231);
    const [bloglist, setBloglist] = React.useState<BlogType[]>([]);
    const [loading, setloading] = React.useState(false);

    // const owlOptions = {
    //     margin: 20, nav: true, autoplay: false,
    //     responsiveClass: true,
    //     responsive: {
    //         0: { items: 1 },
    //         400: { items: 1 },
    //         600: { items: 1 },
    //         700: { items: 3 }
    //     },
    // };

    const getSuggestions = async (value) => {
        setloading(true);
        
        if (typeof cancelToken != typeof undefined) {
          cancelToken.cancel("Operation canceled due to new request.")
        }
        cancelToken = axios1.CancelToken.source()
        //let url = '';

        const params = {}
        params['searchText'] = value;
        params['searchfrom'] = 'title,meta_description,author.author_name,categories.category_name';
        params['size'] = '10';
        //const id = slug;//'engineering';
        const response = await axios.get('api/website/blog/get?orderby=desc',  { cancelToken: cancelToken.token, params: params });

        //const response = await axios.get(url, { cancelToken: cancelToken.token, params: params });
        const tags = await response.data.data;
        setBloglist(tags);
        setloading(false);
    }

    const handleupdateBlog = (event) => {
        if (event && event.target && typeof event.target.value != typeof undefined && event.target.value != null && event.target.value.length > 1) {
          getSuggestions(event.target.value);
        }
    };

    useEffect(()=>{
        //getSuggestions('');
    }, []);

    return (
        <section className="inner-top-header" id="hero" style={{ background: `url(${ExamDetailHeaderBG})` }} >
            <div className="container">
                <div className="row">
                    <div className="col-lg-3"></div>
                    <div className="header-search-box blogsearchbox col-lg-6">
                        <div className="top-course-header text-center">
                            <h1>Educational <span>Blogs</span></h1>
                        </div>
                        <form action="" method="post">
                            {/* <input type="text" placeholder="Search" name="text" className="search-box" style={{ background: `#fff url(${Search})`, backgroundRepeat: "no-repeat", backgroundPosition: "20px center" }} /> */}
                            <Autocomplete
                                options={bloglist}
                                onInputChange={(e) => {
                                    handleupdateBlog(e);
                                }}
                                onFocus={(e) => {
                                    setBloglist([]);
                                }}
                                value=""
                                getOptionLabel={(option) => {
                                    // Value selected with enter, right from the input
                                    if (typeof option === 'string') {
                                      return option;
                                    }
                                    // Add "xxx" option created dynamically
                                    if (option.title) {
                                      return option.title;
                                    }
                                    // Regular option
                                    return option.title;
                                }}
                                renderOption={(option) => <li><a style={{ color:"#000" }} href={`blog/${option.slug}`}>{option.title}</a></li>}
                                noOptionsText=""
                                onChange={(e, value) => {
                                    //console.log(value)
                                }}
                                renderInput={(params) => <TextField  className="search-box" style={{ background: `#fff url(${Search})`, backgroundRepeat: "no-repeat", backgroundPosition: "20px center" }} placeholder="Search"  name="text" {...params}
                                InputProps={{
                                    ...params.InputProps,
                                    className: "search-box",
                                    endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                    ),
                                }}
                                />}
                            />
                        </form>
                    </div>
                    <div className="col-lg-3"></div>
                </div>
            </div>
        </section>
    );
};

export default SearchHeader;