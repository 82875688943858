import React, { FC, useState, ChangeEvent, useEffect } from 'react';
import { Box, makeStyles, Tab, Tabs } from '@material-ui/core';
import './styles.css';
import GetMoreDetails from 'src/assets/img/get-more-details.jpg';
import EducationCap from 'src/assets/img/education-cap.png';
import HeadingBg from 'src/assets/img/heading-bg.png';
import TopManagementCourseBG from 'src/assets/img/top-managment-course.jpg';
import InterestedinCourseBG from 'src/assets/img/interested-in-course-bg1.png';
import Bellicon from 'src/assets/img/top-left-orange.png';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';
import { config } from 'src/config';

const Overview: FC<{ coursedetail: any, newcourseStreamArr: any }> = ({ coursedetail, newcourseStreamArr }) => {
    //const [num, setNum] = React.useState(331231);
    const [currentTab, setCurrentTab] = useState<string>('overview');

    const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
        setCurrentTab(value);
    };

    let banner_image = coursedetail.promo_banner && coursedetail.promo_banner_status != 'Draft' ? config.baseUrlfromconfig + coursedetail.promo_banner : TopManagementCourseBG;
    return (
        <section className="course-overviews-section container-fluid  spac_01" >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 overviews-course">
                        <div className="row">
                            <div className="col-lg-8 col-md-12" >
                                <div className="row">
                                    <Tabs
                                        value={currentTab}
                                        onChange={handleTabsChange}
                                        variant="scrollable"
                                        scrollButtons="on"
                                        aria-label="visible arrows tabs example"
                                        className='mainexamtabs'
                                        style={{ width: 'auto', padding: '0px' }}
                                    >
                                        <Tab label="Overview" value="overview" />

                                        {
                                            coursedetail.str && coursedetail.str.filter(o => o.course_type == 'UG').length > 0 ?
                                                <Tab label="UG" value="ug_TAB" />
                                                : ''
                                        }

                                        {
                                            coursedetail.str && coursedetail.str.filter(o => o.course_type == 'PG').length > 0 ?
                                                <Tab label="PG" value="pg_TAB" />
                                                : ''
                                        }

                                        {
                                            coursedetail.str && coursedetail.str.filter(o => o.course_type == 'PhD').length > 0 ?
                                                <Tab label="Doctorate" value="doctorate_TAB" />
                                                : ''
                                        }

                                        {
                                            coursedetail.str && coursedetail.str.filter(o => o.course_type == 'Diploma').length > 0 ?
                                                <Tab label="Diploma" value="diploma_TAB" />
                                                : ''
                                        }
                                        <Tab label="FAQs" value="faq" />
                                    </Tabs>

                                    <div className="tab-content left-overviews-section bs-editor-text">
                                        {(currentTab === 'overview' && currentTab) ?
                                            <>
                                                {/* <h4>Overview</h4> */}
                                                <div className="bs-editor-text" id="overview">
                                                    <span dangerouslySetInnerHTML={{ __html: coursedetail.description_box }} />
                                                    <p>&nbsp;</p>
                                                    <span dangerouslySetInnerHTML={{ __html: coursedetail.eligibility_criteria }} />
                                                </div>
                                            </> :
                                            null}

                                        {(currentTab === 'ug_TAB' && currentTab) ?
                                            <>
                                                {
                                                    coursedetail.str && coursedetail.str.filter(o => o.course_type == 'UG').map((i, index) => {
                                                        let courseinnerlink = '';
                                                        let class_name = 'cursor_block';
                                                        if (i.description && i.course_short_name && i.description != '' && i.course_short_name != '') {
                                                            class_name = '';
                                                            courseinnerlink = '/courses/' + coursedetail.stream_slug + "/" + i.course_stream_slug;
                                                        }
                                                        return (
                                                            // <li><a className={class_name} title={i.course_stream_name} href={courseinnerlink}>{i.course_short_name ? i.course_short_name : i.course_stream_name.slice(0, 20)}</a></li>

                                                            <div id="bs-course-types" className="bs-course-detail-card bs-shadow-boxed col-lg-8">
                                                                <div className="row">
                                                                    <div className='col-lg-12'>
                                                                        <div className="row">
                                                                            <div className="col-md-12 coursestreamtag">
                                                                                <a className={class_name} href={courseinnerlink}><h5>{i.course_stream_name}</h5></a>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                &nbsp;
                                                                            </div>
                                                                        </div>
                                                                        <br />
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <GlobalPopupEnquiry className="btn" title="Apply for this course" />
                                                                                {/* <a href="#" className="btn" data-toggle="modal" data-target="#hs-course-form" data-value={i.course_stream_slug}>Apply for this course</a> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </> :
                                            null}

                                        {(currentTab === 'pg_TAB' && currentTab) ?
                                            <>
                                                {
                                                    coursedetail.str && coursedetail.str.filter(o => o.course_type == 'PG').map((i, index) => {
                                                        let courseinnerlink = '';
                                                        let class_name = 'cursor_block';
                                                        if (i.description && i.course_short_name && i.description != '' && i.course_short_name != '') {
                                                            class_name = '';
                                                            courseinnerlink = '/courses/' + coursedetail.stream_slug + "/" + i.course_stream_slug;
                                                        }
                                                        return (
                                                            <div id="bs-course-types" className="bs-course-detail-card bs-shadow-boxed col-lg-8">
                                                                <div className="row">
                                                                    <div className='col-lg-12'>
                                                                        <div className="row">
                                                                            <div className="col-md-12 coursestreamtag">
                                                                                <a className={class_name} href={courseinnerlink}><h5>{i.course_stream_name}</h5></a>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                &nbsp;
                                                                            </div>
                                                                        </div>
                                                                        <br />
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <GlobalPopupEnquiry className="btn" title="Apply for this course" />
                                                                                {/* <a href="#" className="btn" data-toggle="modal" data-target="#hs-course-form" data-value={i.course_stream_slug}>Apply for this course</a> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </> :
                                            null}

                                        {(currentTab === 'doctorate_TAB' && currentTab) ?
                                            <>
                                                {
                                                    coursedetail.str && coursedetail.str.filter(o => o.course_type == 'PhD').map((i, index) => {
                                                        let courseinnerlink = '';
                                                        let class_name = 'cursor_block';
                                                        if (i.description && i.course_short_name && i.description != '' && i.course_short_name != '') {
                                                            class_name = '';
                                                            courseinnerlink = '/courses/' + coursedetail.stream_slug + "/" + i.course_stream_slug;
                                                        }
                                                        return (
                                                            <div id="bs-course-types" className="bs-course-detail-card bs-shadow-boxed col-lg-8">
                                                                <div className="row">
                                                                    <div className='col-lg-12'>
                                                                        <div className="row">
                                                                            <div className="col-md-12 coursestreamtag">
                                                                                <a className={class_name} href={courseinnerlink}><h5>{i.course_stream_name}</h5></a>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                &nbsp;
                                                                            </div>
                                                                        </div>
                                                                        <br />
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <GlobalPopupEnquiry className="btn" title="Apply for this course" />
                                                                                {/* <a href="#" className="btn" data-toggle="modal" data-target="#hs-course-form" data-value={i.course_stream_slug}>Apply for this course</a> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </> :
                                            null}

                                        {(currentTab === 'diploma_TAB' && currentTab) ?
                                            <>
                                                {
                                                    coursedetail.str && coursedetail.str.filter(o => o.course_type == 'Diploma').map((i, index) => {
                                                        let courseinnerlink = '';
                                                        let class_name = 'cursor_block';
                                                        if (i.description && i.course_short_name && i.description != '' && i.course_short_name != '') {
                                                            class_name = '';
                                                            courseinnerlink = '/courses/' + coursedetail.stream_slug + "/" + i.course_stream_slug;
                                                        }
                                                        return (
                                                            <div id="bs-course-types" className="bs-course-detail-card bs-shadow-boxed col-lg-8">
                                                                <div className="row">
                                                                    <div className='col-lg-12'>
                                                                        <div className="row">
                                                                            <div className="col-md-12 coursestreamtag">
                                                                                <a className={class_name} href={courseinnerlink}><h5>{i.course_stream_name}</h5></a>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                &nbsp;
                                                                            </div>
                                                                        </div>
                                                                        <br />
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <GlobalPopupEnquiry className="btn" title="Apply for this course" />
                                                                                {/* <a href="#" className="btn" data-toggle="modal" data-target="#hs-course-form" data-value={i.course_stream_slug}>Apply for this course</a> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </> :
                                            null}

                                        {(currentTab === 'faq' && currentTab) ?
                                            <>
                                                <h5>FAQs</h5>
                                                {coursedetail.faqs && coursedetail.faqs.map((i, index) => {
                                                    return (
                                                        <>
                                                            <Accordion>
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMoreIcon />}
                                                                    aria-controls="panel1a-content"
                                                                    id={`panel${index}a-header`}
                                                                >
                                                                    <Typography>{index + 1 + "."} {i.questions}</Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <Typography>
                                                                        {i.answers}
                                                                    </Typography>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                            {/* <span><b>Question:</b>{i.questions}</span><br></br>
                                                    <span><b>Answer:</b> {i.answers}</span><br></br> */}
                                                        </>
                                                    )
                                                })}
                                            </> :
                                            null}
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-12 sidebar-widgets" >
                                <div className="widgets right-widget top-get-more-details" style={{ background: `url(${GetMoreDetails})`, backgroundSize: 'cover', backgroundPosition: 'center center' }}>
                                    <div className="management-courses-block">
                                        <div className="top-left-orange"><img src={Bellicon} /></div>
                                        <h2>Get More Details On</h2>
                                        <h3>{coursedetail.stream_name} Courses and Colleges.</h3>
                                        <GlobalPopupEnquiry className="apply-now-btn button btn" />
                                        {/** <a href="#" className="apply-now-btn button btn">Apply Now</a>/ */}
                                    </div>
                                    <div className="green-overly"></div>
                                </div>
                                <div className="widgets top-streams-widget">
                                    <div className="large-heading-bg text-center" style={{ background: `url(${HeadingBg})` }}>
                                        <h2><img src={EducationCap} /> Top Streams</h2>
                                    </div>
                                    <ul>
                                        {newcourseStreamArr && newcourseStreamArr.map((el) => {
                                            let link_course = "/courses/" + el.stream_slug;
                                            return (
                                                <li><a href={link_course}>{el.stream_name}</a></li>
                                            )
                                        })}
                                    </ul>
                                </div>
                                {
                                    coursedetail.promo_banner && coursedetail.promo_banner_status != 'Draft' ?
                                        <>
                                            {/* <div className="widgets top-manag-course-widget text-center" style={{ background: `url(${banner_image})` }}>
                                                <div className="course-center">
                                                    <h2>&nbsp;</h2>
                                                    <p>&nbsp;</p>

                                                </div>
                                            </div> */}
                                            <div className="testi_wrp top-streams-widget widgets">
                                        
                                                <div className="test_content_wrp col-lg-12 wrapexamcontent">

                                                    <img alt={banner_image} src={banner_image} className="full_wdth" />


                                                    <div className="clearfix"></div>
                                                    {/* </div> */}
                                                </div>
                                            </div>
                                        </>
                                        : ''
                                }

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row interested-in-course text-center" style={{ background: `url(${InterestedinCourseBG})` }}>
                    <div className="course-center">
                        <h2>Looking for Admissions?</h2>
                        <p>Get in Touch with us to Take the Next Step Now!</p>
                        <div className="text-center"><GlobalPopupEnquiry className="btn btn btn-primary" title="Request Call Back" /></div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Overview;