import React, { FC, useEffect, useState, useContext,useCallback } from 'react';
import SettingsContext from 'src/contexts/SettingsContext';
import axios from 'src/utils/axiosintercpt';
import { makeStyles } from '@material-ui/core';
import { useParams } from "react-router-dom";
import Breadcrumbs from './Breadcrumbs';
import Header from './InnerHeader';
import OverviewSection from './OverviewSection';
import Interested from './Interested';
// import TopColleges from './TopColleges';
import TopColleges from 'src/views/HomeNew/TopColleges/index';
import { pConfig } from 'src/config';  
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import Helmet from 'react-helmet';
const useStyles = makeStyles(() => ({
  root: {}
}));

const ExamList: FC = () => {
  const classes = useStyles();
  const setCtx = useContext(SettingsContext);
  const isMountedRef = useIsMountedRef();
  const [courseStreamArr, setCourseStreamArr] = useState([]);
  const [isBlogLoaded, setBlogLoaded] = useState(false);
  const { slug } = useParams();
  const [isHeroLoaded, setHeroLoaded] = useState(false);
  const [metatitle, setmetatitle] = useState<any>([]);
  const [metadescription, setmetadescription] = useState<any>([]);
  const [promoBannerArr, setPromoBannerArr] = useState<any>([]);

  const getHeroData = async ()=>{
    const formData = new FormData();
    formData.append('getdata', JSON.stringify([{data: "course_streams_head"}]));
    const heroResp = await axios.post('api/website/home/header', formData);

    setCourseStreamArr(heroResp.data.data.course_streams_head);
    
    setCtx.setCourseData(heroResp.data.data.course_streams_head);
    setHeroLoaded(true);
  }

  const [nriquota, setnriquota] = useState([]);
  const getnriquota = useCallback(async () => {    
    try {
      const response = await axios.get('api/website/nri/get');
      if (isMountedRef.current) {
          //  alert("asfd");
          //console.log("response.data.data");
          //console.log(response.data.data);
          setnriquota(response.data.data);
          setmetatitle(response.data.data.meta_title);
          setmetadescription(response.data.data.meta_description);
        }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  const [topCollegeArr, setTopCollegeArr] = useState([]);
  const getCollageUnivData = async ()=>{
    const formData = new FormData();
    formData.append('getdata', JSON.stringify([{data: "college"}]));
    const univColgResp = await axios.post('api/website/home/topcrousel', formData);

    setTopCollegeArr(univColgResp.data.data.topcollege.data);
  }

  const getpromobanner = useCallback(async () => {
    try {
      const response = await axios.get('api/website/promobanner?promo_banner=Nri_page');
      if (isMountedRef.current) 
      {
        setPromoBannerArr(response.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);


  useEffect(()=>{
    getHeroData();
    getnriquota();
    getCollageUnivData();
    getpromobanner();
  }, []);


  useEffect(()=>{
  
  }, [topCollegeArr,nriquota]);
  return (
    <>
    {/* {isTopColLoaded ? 
      <> */}
      <Helmet>
          <title>{metatitle && metatitle != '' ? metatitle : 'Get Admission at Top Colleges in Bangalore'}</title>
          <meta name="description" content={metadescription && metadescription != '' ?  metadescription : 'Looking for College/ University/ School admission? Bangalorestudy.com helps you with a list of Colleges, Universities and Schools to pursue your education in Bangalore. Call us now'} />
          <link rel="canonical" href={`${pConfig.siteUrl}nri-quota`} />
        </Helmet>
        <Header />
        <Breadcrumbs />
        <OverviewSection nriquota={nriquota} promoBannerArr={promoBannerArr} />
        <Interested />
        <TopColleges  topCollegeArr={topCollegeArr}  />
      {/* </>
      :
      <p style={{height: '8000px'}}>Loading</p> } */}
    </>
  );
};

export default ExamList;