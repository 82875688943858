import React, {
    useCallback,
    useEffect, useState
} from 'react';
import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import './filter.css';
import axios from 'src/utils/axios';
import {
    TextField,
    makeStyles,
    Checkbox,
    ListItem,
    ListItemText,
    List,
    InputAdornment, ListItemIcon
} from '@material-ui/core';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

interface SidebarFilterProps {
    className?: string;
    icon?: any;
    type?: any;
    data?: any;
    handleSidebarFilter?: (type, data) => void;
    autoselected?: any;
}

const useStyles = makeStyles(() => ({
    root: {},
    input: {
        height: 40,
        width: 'auto',
        marginTop: 5
    },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const SidebarFilter: FC<SidebarFilterProps> = ({ className, icon, type, data, handleSidebarFilter, autoselected, ...rest }) => {
    const [checked, setChecked] = React.useState([0]);
    const classes = useStyles();
    const [num, setNum] = React.useState(331231);
    const [showStreamSearch, setStreamSearch] = React.useState(false);
    const [query, setQuery] = useState<string>('');
    const [streams, setStreams] = useState([]);
    const [selected, setSelected] = useState([]);
    const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
        event.persist();
        setQuery(event.target.value);
    };

    const toggleStream = (e): any => { // event is implicitly passed to the function
        e.preventDefault();
        showStreamSearch ? setStreamSearch(false) : setStreamSearch(true);
    }

    const selectData = (e): any => { // event is implicitly passed to the function
        e.preventDefault();
        setSelected(selected.concat(e.target.value));
    }

    const handleToggle = (value: number, id: any) => () => {
        //const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        const currentIndex = selected.findIndex(a => a === id)
        if (currentIndex === -1) {
            //newChecked.push(value);
            setSelected(selected.concat(id));
        } else {
            //newChecked.splice(currentIndex, 1);
            setSelected(selected =>
                selected.filter(element => {
                    return element !== id;
                }),
            );
        }
        // setChecked(newChecked);
    };

    const applyFilters = (streams: any, query: string) => {
   
        return streams.filter((streamss) => {
            let matches = true;
           // Stream

           
            if (type == 'Stream') {
                if (query && !streamss.stream_name.toLowerCase().includes(query.toLowerCase())) {
                    matches = false;
                }
            }
            else if (type == 'City') {
                if (query && !streamss.city_name.toLowerCase().includes(query.toLowerCase())) {
                    matches = false;
                }
            }
            else if (type == 'Accreditations') {
                if (query && !streamss.accreditation_name.toLowerCase().includes(query.toLowerCase())) {
                    matches = false;
                }
            }
            else if (type == 'Levels') {
                if (query && !streamss.level_name.toLowerCase().includes(query.toLowerCase())) {
                    matches = false;
                }
            }
            else if (type == 'Management') {
                if (query && !streamss.management_name.toLowerCase().includes(query.toLowerCase())) {
                    matches = false;
                }
            }
            else if (type == 'General Course') {
                if (query && !streamss.course_stream_name.toLowerCase().includes(query.toLowerCase())) {
                    matches = false;
                }
            }
            else {
                if (query && !streamss.toLowerCase().includes(query.toLowerCase())) {
                    matches = false;
                }
            }
            return matches;
        });
    };

    const filteredStream = applyFilters(streams, query);

    useEffect(() => {
        setStreams(data);
    }, [data]);

    useEffect(() => {
   
        handleSidebarFilter(type, selected);
    }, [selected]);

    useEffect(() => {
        if(autoselected)
        {
            setSelected(autoselected.concat(selected));
        }
    }, [autoselected]);

    let i = 0;

    return (
        <React.Fragment >
            <div className="heading-title" >
                <img src={icon} className="small-icon" /> {type}
                <i onClick={toggleStream} id='toggle-search' className='bx bx-search'></i>
                {showStreamSearch ?
                    <>
                        <TextField
                            InputProps={{
                                className: classes.input,
                                startAdornment: (
                                    <InputAdornment position="start">
                                    </InputAdornment>
                                )
                            }}
                            onChange={handleQueryChange}
                            placeholder={type ? "Search " + type : ''}
                            value={query}
                            variant="outlined"
                        />
                    </>
                    : null}
            </div>

            {/* {showStreamSearch ? */}
                <div className="widget-scroll-box" >
                    <List className={classes.root}>
                        {filteredStream && filteredStream.map((value) => {
                            const labelId = `checkbox-list-label-${value}`;
                            let id = value;
                            let title = value;
                            
                            if (type == 'Board') {
                                id = value.id;
                                title = value.name;
                            }

                            if (type == 'School Level') {
                                id = value.id;
                                title = value.level;
                            }

                            if (type == 'School Types') {
                                id = value.id;
                                title = value.type;
                            }

                            if (type == 'Stream') {
                                id = value.id;
                                title = value.stream_name;
                            }

                            if (type == 'Accreditations') {
                                id = value.id;
                                title = value.accreditation_name;
                            }
                            if (type == 'Levels') {
                                id = value.id;
                                title = value.level_name;
                            }

                            if (type == 'City') {
                                id = value.id;
                                title = value.city_name;
                            }

                            if (type == 'Management') {
                                id = value.id;
                                title = value.management_name;
                            }

                            if (type == 'General Course') {
                                id = value.id;
                                title = value.course_stream_name;
                            }

                            i++;

                            return (
                                <ListItem key={id} role={undefined} dense button onClick={handleToggle(i, id)}>
                                    <ListItemIcon >
                                        {type == 'stream' ?
                                            <Checkbox
                                                edge="start"
                                                checked={selected.findIndex(a => a === id) !== -1}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': labelId }}
                                            />
                                        :
                                            <Checkbox
                                                edge="start"
                                                checked={selected.findIndex(a => a === id) !== -1}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': labelId }}
                                            />
                                        }
                                        
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={title} />

                                </ListItem>
                            );
                        })}
                    </List>
                </div> 
            {/* : null} */}
        </React.Fragment>
    );
};
/*</React.Fragment>SidebarFilter.propTypes = {
  className: PropTypes.string,
  dayId: PropTypes.number.isRequired,
  sessionId: PropTypes.number.isRequired,
  bodyparts:PropTypes.array.isRequired,
  handleAddExercise: PropTypes.func,
};   */
export default SidebarFilter;