import React, { FC, useEffect, useState, useContext,useCallback } from 'react';
import SettingsContext from 'src/contexts/SettingsContext';
import axios from 'src/utils/axiosintercpt';
import { makeStyles } from '@material-ui/core';
import './styles.css';
import './assets/css';
import './assets/template.css';
import { pConfig } from 'src/config';   
import useIsMountedRef from 'src/hooks/useIsMountedRef';

const useStyles = makeStyles(() => ({
  root: {}
}));

const HomePage: FC = () => {

  useEffect(()=>{

    if(navigator.userAgent.toLowerCase().indexOf("android") > -1){
        window.location.href = 'https://play.google.com/store/apps/details?id=com.ilearntech.app&hl=en';
    }
    if(navigator.userAgent.toLowerCase().indexOf("iphone") > -1){
    
        window.location.href = 'https://apps.apple.com/app/learntech/id1623567055?mt=8';
    }
  }, []);

  return (
    <>
          <div>
      
        <div className="sh-container" id="rootElement">{/* rootElement is important for sync data */}
          <div ng-show="hasAnyContentToDisplay() && view.code.directLinking != 1">
            <div className="sh-page" style={{height: 'auto'}}>
              <img src="../static/header.jpeg" />
              <div ng-show="view.code.app_store">
                <a className="sh-store-link" href="https://apps.apple.com/app/learntech/id1623567055" target="_blank">
                  <img className="sh-store-link__image" src="../static/apple-en.png" alt="" />
                </a>
              </div>
              <div ng-show="view.code.play_store">
                <a className="sh-store-link" data-test="google-play-link" href="https://play.google.com/store/apps/details?id=com.ilearntech.app" target="_blank">
                  <img className="sh-store-link__image" src="../static/google-en.png" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;