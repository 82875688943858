import React, { FC, useEffect, useState, useContext, useCallback } from 'react';
import './styles.css';
import SearchIcon from 'src/assets/img/icons/search.svg';
import NewsBG from 'src/assets/img/new-News.jpg';
import axios1 from 'axios';
import axios from 'src/utils/axios';
import { Autocomplete } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Search from 'src/assets/img/icons/search.svg'
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';
interface schlorshiptype {
    title: string;
    slug: string;
}
const SearchHeader: FC<{ newsListing: any }> = ({ newsListing }) => {
    const [num, setNum] = React.useState(331231);
    let cancelToken;
    const [collegelist, setcollegelist] = React.useState<schlorshiptype[]>([]);
    const [loading, setloading] = React.useState(false);
    const [open, setOpen] = useState(false);
    const getSuggestions = async (value) => {
        setloading(true);
        
        if (typeof cancelToken != typeof undefined) {
          cancelToken.cancel("Operation canceled due to new request.")
        }
        cancelToken = axios1.CancelToken.source()
        const params = {}
        params['searchText'] = value;
        params['searchfrom'] = 'title';
        params['size'] = '10';
        const response = await axios.get('api/website/home/news',  { cancelToken: cancelToken.token, params: params });
        const tags = await response.data.data;
        setcollegelist(tags);
        setloading(false);
        setOpen(true);
    }

    const handleupdateBlog = (event) => {
        if (event && event.target && typeof event.target.value != typeof undefined && event.target.value != null && event.target.value.length > 1) {
          getSuggestions(event.target.value);
        }
        else
        {
            setcollegelist([]);
            setOpen(false);
        }
    };

    useEffect(()=>{
        //getSuggestions('');
    }, []);

    return (
        <section className="inner-top-header" id="hero" style={{ background: `url(${NewsBG})` }} >
            <div className="hero-container d-flex">
            <div className="container" >
                    <div className="row no-gutters">
                        <div className="col-lg-3"></div>
                        <div className="header-search-box blogsearchbox col-lg-6" >
                            <div className="top-course-header text-center">
                                {/* <h1>Choose by <span>Interest</span></h1> */}
                                <h1><span>News </span>and Events</h1>
                            </div>
                            <Autocomplete
                                open={open}
                                options={collegelist}
                                onInputChange={(e) => {
                                    handleupdateBlog(e);
                                }}
                                onFocus={(e) => {
                                    setcollegelist([]);
                                    setOpen(false);
                                }}
                                value=""
                                getOptionLabel={(option) => {
                                    // Value selected with enter, right from the input
                                    if (typeof option === 'string') {
                                      return option;
                                    }
                                    // Add "xxx" option created dynamically
                                    if (option.title) {
                                      return option.title;
                                    }
                                    // Regular option
                                    //return option.stream_name;
                                }}
                                renderOption={(option) => <li><a style={{ color: "#000" }} href={`/news-and-event/${option.slug}`}>{option.title}</a></li>}
                                noOptionsText=""
                                onChange={(e, value) => {
                                    //console.log(value)
                                }}
                                renderInput={(params) => <TextField  className="search-box" style={{ background: `#fff url(${Search})`, backgroundRepeat: "no-repeat", backgroundPosition: "20px center" }} placeholder="Search"  name="text" {...params}
                                InputProps={{
                                    ...params.InputProps,
                                    className: "search-box",
                                    endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                    ),
                                }}
                                />}
                            />
                            {/* <Autocomplete
                                        open={open}
                                        options={collegelist}
                                        filterOptions={(collegelist) => collegelist}
                                        onInputChange={(e) => {
                                            handleupdateBlog(e);
                                        }}
                                        onFocus={(e) => {
                                            setcollegelist([]);
                                            setOpen(false);
                                        }}
                                        value=""
                                        getOptionLabel={(option) => {
                                            console.log(option);
                                            // Value selected with enter, right from the input
                                            if (typeof option === 'string') {
                                                return option;
                                            }
                                            // Add "xxx" option created dynamically
                                            if (option.title) {
                                                return option.title;
                                            }
                                            else {
                                                setOpen(false);
                                                return '';
                                            }
                                            // Regular option
                                            //return option.name;
                                        }}
                                        renderOption={(option) => <li><a style={{ color: "#000" }} href={`/news-and-event/${option.slug}`}>{option.title}</a></li>}
                                        noOptionsText=""
                                        onChange={(e, value) => {
                                            //console.log(value)
                                        }}
                                        renderInput={(params) => <TextField className="serachinlinepadding ex-field" style={{ background: `#fff url(${SearchIcon})`, backgroundRepeat: "no-repeat", backgroundPosition: "20px center" }} placeholder="Find your News" name="text" {...params}
                                            InputProps={{
                                                ...params.InputProps,
                                                className: "search-box",
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />}
                                    /> */}
                        
                        </div>
                        <div className="col-lg-3"></div>
                    </div> 
                    <div className="spac_01"></div>
                 
                </div>
                {/* <div className="container" >
                    <div className="row top-course-block">
                        <div className="top-course-header">
                        <h1><span>News </span>and Events</h1>
                            <div className="spac_02"></div>
                        
                            <div className="col-lg-8">
                                <div className="hero_explore">
                                    <Autocomplete
                                        open={open}
                                        options={collegelist}
                                        filterOptions={(collegelist) => collegelist}
                                        onInputChange={(e) => {
                                            handleupdateBlog(e);
                                        }}
                                        onFocus={(e) => {
                                            setcollegelist([]);
                                            setOpen(false);
                                        }}
                                        value=""
                                        getOptionLabel={(option) => {
                                            console.log(option);
                                           
                                            if (typeof option === 'string') {
                                                return option;
                                            }
                                            
                                            if (option.title) {
                                                return option.title;
                                            }
                                            else {
                                                setOpen(false);
                                                return '';
                                            }
                                        
                                        }}
                                        renderOption={(option) => <li><a style={{ color: "#000" }} href={`/news-and-event/${option.slug}`}>{option.title}</a></li>}
                                        noOptionsText=""
                                        onChange={(e, value) => {
                                            //console.log(value)
                                        }}
                                        renderInput={(params) => <TextField className="serachinlinepadding ex-field" style={{ background: `#fff url(${SearchIcon})`, backgroundRepeat: "no-repeat", backgroundPosition: "20px center" }} placeholder="Find your News" name="text" {...params}
                                            InputProps={{
                                                ...params.InputProps,
                                              
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />}
                                    />
                                 
                                </div>
                            </div>
                        
                       
                        </div>
                    </div>
                </div> */}
            </div>
        </section>
    );
};

export default SearchHeader;