import React, { FC, useEffect, useState, useContext,useCallback } from 'react';
import SettingsContext from 'src/contexts/SettingsContext';
import axios from 'src/utils/axiosintercpt';
import { CircularProgress, makeStyles } from '@material-ui/core';
import Breadcrumbs from './Breadcrumbs';
import Header from './InnerHeader';
import OverviewSection from './OverviewSection';
import TopColleges from './TopColleges';
import Talk from './Talk';
import Interested from './Interested';
import Scholarship from './Scholarship';
import { pConfig } from 'src/config';  
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import Helmet from 'react-helmet';
import axios1 from 'axios';

const useStyles = makeStyles(() => ({
  root: {}
}));
let axioscancelToken;

const HomePage: FC = () => {
  const classes = useStyles();
  const setCtx = useContext(SettingsContext);
  const isMountedRef = useIsMountedRef();
  const [courseStreamArr, setCourseStreamArr] = useState([]);

  const [isHeroLoaded, setHeroLoaded] = useState(false);
  const [searchFilters, setSearchFilters] = React.useState<any>([]);
  const [promoBannerArr, setPromoBannerArr] = useState<any>([]);

  const searchByFilters = (filters) => {
  //  console.log('filetsr:-', filters);
    setPage(1);
    setSearchFilters(filters);
  };

  const getHeroData = async ()=>{
    const formData = new FormData();
    formData.append('getdata', JSON.stringify([{data: "course_streams_head"}]));
    const heroResp = await axios.post('api/website/home/header', formData);

    //setCourseStreamArr(heroResp.data.data.course_streams_head);
    setCtx.setCourseData(heroResp.data.data.course_streams_head);
    //setHeroLoaded(true);
  }

  // alert(window.location);
  const [collegedetail, setcollegedetail] = useState([]);
  const [page, setPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [stream, setstream] = useState([]);
  const [HasMore, setHasMore] = useState(true);
  const [ownerships, setOwnerships] = useState([]);
  const [city, setcity] = useState([]);
  const [accreditations, setAccreditations] = useState([]);
  const [managments, setmanagments] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [generalCourse, setGeneralCourse] = useState([]);

  const getstream = useCallback(async () => {    
    try {
      const response = await axios.get('api/website/home/streams?page=1&size=10000&columnname=listing_order&orderby=asc');
      if (isMountedRef.current) {
      //  alert("asfd");
        // console.log("response.data.data");
        // console.log(response.data.data);
        setstream(response.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  const getOwnerships = useCallback(async () => {    
    try {
      const response = await axios.get('api/website/home/collegetype');
      if (isMountedRef.current) {
        //  alert("asfd");
        //console.log("response.data.data");
        //console.log(response.data.data);
        setOwnerships(response.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  const getcity = useCallback(async () => {    
    try {
      const response = await axios.get('api/website/home/citys');
      if (isMountedRef.current) {
        setcity(response.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  const getAccreditations = useCallback(async () => {    
    try {
      const response = await axios.get('api/website/home/accreditaions');
      if (isMountedRef.current) {
          setAccreditations(response.data.data);
        }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  const getmanagments= useCallback(async () => {    
    try {
      const response = await axios.get('api/website/home/managments');
      if (isMountedRef.current) {
        setmanagments(response.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  
  const getcollegelist = useCallback(async (page,collegedetail,searchFilters:any) => {
    setIsFetching(true);
    
    try {
      const params = {}
      params['page'] =page;
      params['size'] = '12';
      params['data_type'] = 'college';

      if (typeof axioscancelToken != typeof undefined) {
        axioscancelToken.cancel("Operation canceled due to new request.")
      }
      axioscancelToken = axios1.CancelToken.source();
      /*  params['columnname'] = 'listing_order';
      params['orderby'] = 'ASC';
      params['searchText'] = '0';
      params['searchfrom'] = '0';
      params['page'] = '0'; */

      if (searchFilters && searchFilters != '' && Object.keys(searchFilters).length !== 0) {
        // let dtkeyarr = ['poc_start_from', 'poc_start_to', 'poc_end_from', 'poc_end_to', 'last_activity_from_date', 'last_activity_to_date', 'video_call_schedule_from', 'video_call_schedule_to'];
      
    
        let dtkeyarr = [];
        for (let key of Object.keys(searchFilters)) {
          // alert(searchFilters);
          // alert(key);
        //  console.log(searchFilters);
          // alert("value");
          let value = searchFilters[key];
        
          if (value && value !== '' && value !== null && value !== undefined) {
            if (Array.isArray(value) === true) {
              // array type keys
              if (value.length > 0){
             
              
                params[key] = [value];
              

              }

            }

          }

        }

      }

      // const id = slug;//'engineering';
      const response = await axios.get('api/website/home/topcollegebangalore',{cancelToken: axioscancelToken.token, params: params});
      if (isMountedRef.current) {
        //  alert("asfd");
        // console.log("response.data.data");
        // console.log(response.data.data);
        setPage(page);
        setcollegedetail([...collegedetail,...response.data.data]);      
        // setPage((prevPageNumber) => prevPageNumber + 1);
        setHasMore(response.data.data.length > 0);
        setIsFetching(false);
        setTotalItems(response.data.totalItems);
        setHeroLoaded(true);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  const [metatitle, setmetatitle] = useState<any>([]);
  const [metadescription, setmetadescription] = useState<any>([]);
  const [collegedetaildata, setcollegedetaildata] = useState([]);

  const getcollegedata = async ()=>{
    const response = await axios.get('api/website/page/get/colleges');
    //console.log("college",response.data.data);
    setcollegedetaildata(response.data.data);
    setmetatitle(response.data.data.meta_title)
    setmetadescription(response.data.data.meta_description)
  }

  const [schlorshiplist, setgetschlorshiplist] = useState([]);
  const getschlorshiplist = useCallback(async () => {    
    try {
      const response = await axios.get('api/website/home/scholarship');
      if (isMountedRef.current) {
          setgetschlorshiplist(response.data.data);
        }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  const getpromobanner = useCallback(async () => {
    try {
      const response = await axios.get('api/website/promobanner?promo_banner=All_college_page');
      if (isMountedRef.current) 
      {
        setPromoBannerArr(response.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  const getGeneralCourse = useCallback(async () => {    
    try {
      const response = await axios.get('api/website/home/allgeneralcourse');
      if (isMountedRef.current) {
        //console.log(response.data.data);
        setGeneralCourse(response.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(()=>{
    getcollegedata();
    getHeroData();
    //getCollageUnivData();
    //geBlogData();
    getcollegelist(page,collegedetail,searchFilters);
    getstream();
    getcity();
    getOwnerships();
    getAccreditations();
    getmanagments();
    getschlorshiplist();
    getpromobanner();
    //getGeneralCourse();
  }, []);


  useEffect(()=>{
     getcollegelist(1,[],searchFilters);
  }, [searchFilters]);


  

  return (
    <>
    {isHeroLoaded ? 
      <>
        <Helmet>
            <title>{metatitle}</title>
            <meta name="description" content={metadescription} />
            <link rel="canonical" href={window.location.href} />

            {/* <link rel="canonical" href={`${pConfig.siteUrl}colleges`} /> */}
        </Helmet>
        <Header getcollegelist={getcollegelist}/>
        <Breadcrumbs/>
        <OverviewSection
         searchByFilters={(filters) => searchByFilters(filters)}
        page={page} stream={stream} ownerships={ownerships} city={city} accreditations={accreditations} managments={managments}   getcollegelist={getcollegelist} collegedetail={collegedetail} isFetching={isFetching} HasMore={HasMore} totalItems={totalItems} generalCourse={generalCourse} />
        <TopColleges collegedata={collegedetaildata}  />
        <Talk/>
        <Interested promoBannerArr={promoBannerArr}/>
        <Scholarship schlorshiplist={schlorshiplist}/>
      </>
      :
      <div style={{ display: 'flex', justifyContent: 'center', height:'600px',flexWrap: 'wrap', alignContent: 'space-around' }}><CircularProgress /></div> }
    </>
  );
};

export default HomePage;