import React, { FC, useEffect, useState, useContext ,useCallback} from 'react';
import SettingsContext from 'src/contexts/SettingsContext';
import axios from 'src/utils/axiosintercpt';
import { makeStyles } from '@material-ui/core';

import Breadcrumbs from './Breadcrumbs';
import Header from './InnerHeader';
import OverviewSection from './OverviewSection';
import { pConfig } from 'src/config';   
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import Helmet from 'react-helmet';


const useStyles = makeStyles(() => ({
  root: {}
}));

const HomePage: FC = () => {
  const classes = useStyles();
  const setCtx = useContext(SettingsContext);
  const [metatitle, setmetatitle] = useState<any>([]);
  const [metadescription, setmetadescription] = useState<any>([]);
  const isMountedRef = useIsMountedRef();
  const [CookiesPolicy, setCookiesPolicy] = useState([]);
  
  const getCookiesPolicy = useCallback(async () => {    
    try {
      const response = await axios.get('api/website/page/get/cookies-policy');
      if (isMountedRef.current) {
        //  alert("asfd");
          console.log("response.data.data");
          console.log(response.data.data);
          setCookiesPolicy(response.data.data);
          setmetatitle(response.data.data.meta_title);
          setmetadescription(response.data.data.meta_description);
        }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  const getHeroData = async () => {
    const formData = new FormData();
    formData.append('getdata', JSON.stringify([{ data: "course_streams_head" }]));
    const heroResp = await axios.post('api/website/home/header', formData);
    setCtx.setCourseData(heroResp.data.data.course_streams_head);
  }

  useEffect(()=>{
    getCookiesPolicy();
    getHeroData();
  }, []);

  return (
    <>
    <Helmet>
            <title>{metatitle}</title>
            <meta name="description" content={metadescription} />
            <link rel="canonical" href={`${pConfig.siteUrl}cookies-policy`} />
        </Helmet>
        <Header/>
        <Breadcrumbs/>
        <OverviewSection CookiesPolicy={CookiesPolicy}/>
    </>
  );
};

export default HomePage;