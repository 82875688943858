import React, { FC } from 'react';
import './styles.css';
import ExamDetailHeaderBG from 'src/assets/img/exam-details-header.jpg';
import moment from 'moment';
import { config } from 'src/config';
//import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';
import GlobalPopupAppDownload from 'src/components/GlobalPopupAppDownload';

const SearchHeader: FC<{ examdata: any }> = ({ examdata }) => {
    const imageUrl = examdata.cover_image ? config.baseUrlfromconfig + examdata.cover_image : ExamDetailHeaderBG;

    return (
        <section className="inner-top-header" id="hero" style={{ background: `linear-gradient(359deg, rgb(0 0 0 / 80%), rgb(0 0 0 / 80%)) fixed, url(${imageUrl})` }}  >
            <div className="container">	
                <div className="row"> 
                    {/* <div className="large-spac"></div> */}
                    <div className="col-lg-9 top-course-header">
                        <h1>{examdata.exam_title} : <span>{examdata.upcoming_date?(moment(examdata.upcoming_date).format('D MMM YYYY')):''}</span> </h1>
                    </div> 
                    <div className="col-lg-3">
                        <div className='floatright'>
                            <GlobalPopupAppDownload/>
                            {/* <GlobalPopupEnquiry  className="talk-to-expert btn btn btn-primary"  title="Get an Exam Alert" /> */}
                        </div> 
                    </div> 
                </div>
            </div>
        </section>
    );
};

export default SearchHeader;