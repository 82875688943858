import React from 'react';
import type { FC } from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik,Field,useField} from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Checkbox,
  FormHelperText,
  TextField,
  Typography,
  Link,
  makeStyles,

} from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import useIsMountedRef from 'src/hooks/useIsMountedRef';

interface JWTRegisterProps {
  className?: string;
}

const useStyles = makeStyles(() => ({
  root: {}
}));

const JWTRegisterNew: FC<JWTRegisterProps> = ({ className, ...rest }) => {
  const classes = useStyles();
  const { register } = useAuth() as any;
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Formik
      initialValues={{
        email: '',
        name: '',
        mobile: '',
        password: '',
        passwordConfirmation:'',
        policy: false,
        submit: null
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
        name: Yup.string().max(255).required('Name is required'),
        password: Yup.string().min(7).max(255).required('Password is required'),
        passwordConfirmation: Yup.string().min(7).max(255).oneOf([Yup.ref('password'), null], 'Passwords must match'),
        policy: Yup.boolean().oneOf([true], 'This field must be checked')
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting,
        resetForm
      }) => {
        try {
          await register(values.email, values.name, values.password,values.passwordConfirmation,values.mobile);

          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
            resetForm({
                values: {
                    email: '',
                    name: '',
                    mobile: '',
                    password: '',
                    passwordConfirmation:'',
                    policy: false,
                    submit: null
                 },
              });
            enqueueSnackbar('Successfully registered', {
                variant: 'success',
              });
          }
        } catch (err) {
          console.error(err);
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);

          enqueueSnackbar(err.message, {
            variant: 'error',});
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form
          noValidate
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
         

<div className="form-group">

   <Field type="text" name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              className="form-control" id="name" placeholder="Enter Name"  />
            {errors.name && touched.name && <p className="error">{errors.name}</p>}
        </div>

      

        <div className="form-group">

<Field type="email" name="email"
           onChange={handleChange}
           onBlur={handleBlur}
           value={values.email}
           className="form-control" id="email" placeholder="Email ID"  />
         {errors.email && touched.email && <p className="error">{errors.email}</p>}
     </div>


     <div className="form-group">
           <Field type="tel" name="mobile"
           onChange={handleChange}
           onBlur={handleBlur}
           value={values.mobile}
           className="form-control" id="mobile" placeholder="Mobile"  />
         {errors.mobile && touched.mobile && <p className="error">{errors.mobile}</p>}
     </div>

     <div className="form-group">
           <Field type="password" name="password"
           onChange={handleChange}
           onBlur={handleBlur}
           value={values.password}
           className="form-control" id="password" placeholder="Password"  />
         {errors.password && touched.password && <p className="error">{errors.password}</p>}
     </div>

     <div className="form-group">
           <Field type="password" name="passwordConfirmation"
           onChange={handleChange}
           onBlur={handleBlur}
           value={values.passwordConfirmation}
           className="form-control" id="passwordConfirmation" placeholder="Confirm Password"  />
         {errors.passwordConfirmation && touched.passwordConfirmation && <p className="error">{errors.passwordConfirmation}</p>}
     </div>

     
          
          <Box
            alignItems="center"
            display="flex"
            mt={2}
            ml={-1}
          >
            <Checkbox
              checked={values.policy}
              name="policy"
              onChange={handleChange}
            />
            <Typography
              variant="body2"
              color="textSecondary"
            >
             By signing up, you agree to our
              {' '}
              <Link
                component="a"
                href="/terms-and-conditions"
                color="secondary"
              >Terms</Link>, <Link
                component="a"
                href="/privacy-policy"
                color="secondary"
              >
                Data Policy 
              </Link> and <Link
                component="a"
                href="/cookies-policy"
                color="secondary"
              >Cookies Policy 
              </Link>
            </Typography>
          </Box>
          {Boolean(touched.policy && errors.policy) && (
            <FormHelperText error>
              {errors.policy}
            </FormHelperText>
          )}
          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>
                {errors.submit}
              </FormHelperText>
            </Box>
          )}
          <Box mt={2}>
            
            <div className="text-center"><button   disabled={isSubmitting} type="submit">Sign Up</button></div>
          </Box>
        </form>
      )}
    </Formik>
  );
};

JWTRegisterNew.propTypes = {
  className: PropTypes.string
};

export default JWTRegisterNew;
