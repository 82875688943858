import React, { FC, useEffect } from 'react';
import './styles.css';
//import ManagementCourseBG from 'src/assets/img/management-courses.jpg';
import CourseHeaderBG from 'src/assets/img/new-course-Courses.jpg';
//import CallNow from 'src/assets/img/call-now.svg';
import SearchIcon from 'src/assets/img/icons/search.svg';
import EntranceExam from 'src/assets/img/entrance-exam.jpg';
//import IIITBLOGO from 'src/assets/img/iiitb_logo.png';
import moment from 'moment';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import axios from 'src/utils/axios';
import axios1 from 'axios';
import { config } from 'src/config';
import { Autocomplete } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
//import Search from 'src/assets/img/icons/search.svg'
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core';

interface ExamType {
    exam_title: string;
    slug: string;
}
const useStylesBootstrap = makeStyles(() => ({
    arrow: {
        color: '#000',
    },
    tooltip: {
        backgroundColor: '#000',
    },
}));


function BootstrapTooltip(props: TooltipProps) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

const SearchHeader: FC<{ examlist: any, upcomingdataexam: any }> = ({ examlist, upcomingdataexam }) => {
    let cancelToken;
    //const [num, setNum] = React.useState(331231);
    const [examlistserach, setExamlistserach] = React.useState<ExamType[]>([]);
    const [loading, setloading] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    const owlOptions = {
        margin: 10, nav: true, autoplay: true,
        responsiveClass: true,
        responsive: {
            0: { items: 1, dots: false, nav: false, loop: true },
            400: { items: 1, dots: false, nav: false, loop: true },
            600: { items: 1, dots: false, nav: true },
            700: { items: 2, dots: false, nav: true },
            1000: {
                items: 3,
                nav: true,
                loop: false
            },
            1440: {
                items: 4,
                nav: true,
                loop: false
            }
        },
    };

    const getSuggestions = async (value) => {
        setloading(true);

        if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel("Operation canceled due to new request.")
        }
        cancelToken = axios1.CancelToken.source()
        //let url = '';

        const params = {}
        params['searchText'] = value;
        params['searchfrom'] = 'exam_title';
        params['size'] = '10';
        //const id = slug;//'engineering';
        const response = await axios.get('api/website/home/allentranceexams', { cancelToken: cancelToken.token, params: params });

        //const response = await axios.get(url, { cancelToken: cancelToken.token, params: params });
        const tags = await response.data.data;
        setExamlistserach(tags);
        setloading(false);
        setOpen(true);
    }

    const handleupdateBlog = (event) => {

        if (event && event.target && typeof event.target.value != typeof undefined && event.target.value != null && event.target.value.length > 1) {
            getSuggestions(event.target.value);
        }
        else {
            setExamlistserach([]);
            setOpen(false);
        }
    };

    const ordinalnew = (dom) => {

        if (dom == 31 || dom == 21 || dom == 1) return "st";
        else if (dom == 22 || dom == 2) return "nd";
        else if (dom == 23 || dom == 3) return "rd";
        else return "th";
        /*let ordinal = '';
        const last_digit = num % 10;
        ordinal = ((num % 100) / 10) === 1
            ? "th"
            : last_digit === 1
                ? "st"
                : last_digit === 2
                    ? "nd"
                    : last_digit === 3
                        ? "rd"
                        : "th";
        return ordinal;*/
    }

    /*const ordinal_name = moment.updateLocale("en", {
        ordinal: function (num) {
            var last_digit = num % 10;
            var ordinal = ~~((num % 100) / 10) === 1
                ? "`th"
                : last_digit === 1
                    ? "`st"
                    : last_digit === 2
                        ? "`nd"
                        : last_digit === 3
                            ? "`rd"
                            : "`th";
            return num + ordinal;
        },
    });*/

    useEffect(() => {
        //getSuggestions('');
    }, []);
    return (
        <section className="inner-top-header examlistheader" id="hero" style={{ background: `url(${CourseHeaderBG})` }} >
            <div className="hero-container d-flex">
                <div className="container" >
                    <div className="row top-course-block">
                        <div className="top-course-header">
                            <h1 className='d-inline-block '>Entrance <span>Exams </span> in India</h1>
                            <div className="spac_02"></div>
                            <div className="col-lg-8">
                                <div className="hero_explore">
                           

                                    <Autocomplete
                                        open={open}
                                        options={examlistserach}
                                        filterOptions={(examlistserach) => examlistserach}
                                        onInputChange={(e) => {
                                            handleupdateBlog(e);
                                        }}
                                        onFocus={(e) => {
                                            setExamlistserach([]);
                                            setOpen(false);
                                        }}
                                        value=""
                                        getOptionLabel={(option) => {
                                            // Value selected with enter, right from the input
                                            if (typeof option === 'string') {
                                                return option;
                                            }
                                            // Add "xxx" option created dynamically
                                            if (option.exam_title) {
                                                return option.exam_title;
                                            }
                                            else {
                                                setOpen(false);
                                                return '';
                                            }
                                            // Regular option
                                            //return option.exam_title;
                                        }}
                                        renderOption={(option) => <li><a style={{ color: "#000" }} href={`exams/${option.slug}`}>{option.exam_title}</a></li>}
                                        noOptionsText=""
                                        onChange={(e, value) => {
                                            //console.log(value)
                                        }}
                                        renderInput={(params) => <TextField className="serachinlinepadding ex-field" style={{ background: `#fff url(${SearchIcon})`, backgroundRepeat: "no-repeat", backgroundPosition: "20px center" }} placeholder="Search" name="text" {...params}
                                            InputProps={{
                                                ...params.InputProps,
                                                // className: "search-box",
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />}
                                    />


                                    {/* </form> */}
                                </div>
                            </div>

                  
                        </div>
                    </div>

                    <div className="spac_02"></div>

                    <div className="row upcoming-exam-list upcomingexamlist">
                        <h4 className="small-heading bg-dark1" >Upcoming Exams</h4>
                        <div className="spac_05"></div>
                        <OwlCarousel {...owlOptions} className="topTrendingCourses loop_nav owl-carousel owl-theme mt-30 upcommingexam-z">
                            {
                                upcomingdataexam && upcomingdataexam.slice(0, 12).map((i) => {
                                    let imageUrl = i.cover_image ? config.baseUrlfromconfig + i.cover_image : EntranceExam;
                                    let examlink = '/exams/' + i.slug;
                                    return (
                                        <div className="item">

                                            <div className="coming-exam-block">
                                                <a href={examlink} className='d-flex justify-content-center align-items-center'>
                                                    <span className="icon">
                                                        <img alt={i.meta_title} src={imageUrl} />
                                                        <div>{moment(i.upcoming_date).format('D')}<sub>{ordinalnew(moment(i.upcoming_date).format('D'))}</sub><br />{moment(i.upcoming_date).format('MMM')}</div>
                                                        {/* <div>{moment(i.upcoming_date).format('D MMM YYYY')}</div> */}
                                                        
                                                 
                                                    </span>
                                                    {/* <BootstrapTooltip placement="top" title={<span style={{ fontSize: "14px" }}>{i.exam_title}</span>}> */}
                                                   
                                                    <h2>{i.exam_short_name}</h2>
                                                    
                                                    {/* </BootstrapTooltip> */}
                                                </a>
                                            </div>

                                        </div>
                                    )
                                })
                            }
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SearchHeader;