/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';

import PostAddIcon from '@material-ui/icons/PostAdd';
import SchoolIcon from '@material-ui/icons/School';
import LocationCityIcon from '@material-ui/icons/LocationCity';
// import YouTubeIcon from '@mui/icons-material/YouTube';
import YouTubeIcon from '@material-ui/icons/YouTube';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined';
import FeedbackOutlinedIcon from '@material-ui/icons/FeedbackOutlined';
import PostAddOutlinedIcon from '@material-ui/icons/PostAddOutlined';
import ViewCarouselOutlinedIcon from '@material-ui/icons/ViewCarouselOutlined';
import TodayOutlinedIcon from '@material-ui/icons/TodayOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import ImportContactsOutlinedIcon from '@material-ui/icons/ImportContactsOutlined';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import EmojiTransportationOutlinedIcon from '@material-ui/icons/EmojiTransportationOutlined';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import AcUnitOutlinedIcon from '@material-ui/icons/AcUnitOutlined';
import DnsOutlinedIcon from '@material-ui/icons/DnsOutlined';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import BallotOutlinedIcon from '@material-ui/icons/BallotOutlined';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import ApartmentOutlinedIcon from '@material-ui/icons/ApartmentOutlined';
import SubjectOutlinedIcon from '@material-ui/icons/SubjectOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import ForumOutlinedIcon from '@material-ui/icons/ForumOutlined';
import FormatAlignJustifyOutlinedIcon from '@material-ui/icons/FormatAlignJustifyOutlined';
import FlightLandOutlinedIcon from '@material-ui/icons/FlightLandOutlined';
import EditAttributesOutlinedIcon from '@material-ui/icons/EditAttributesOutlined';
import StorageOutlinedIcon from '@material-ui/icons/StorageOutlined';
import ContactPhoneOutlinedIcon from '@material-ui/icons/ContactPhoneOutlined';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import EventNoteOutlinedIcon from '@material-ui/icons/EventNoteOutlined';
import HttpOutlinedIcon from '@material-ui/icons/HttpOutlined';
import BlurLinearOutlinedIcon from '@material-ui/icons/BlurLinearOutlined';
import PagesOutlinedIcon from '@material-ui/icons/PagesOutlined';
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import {
  Briefcase as BriefcaseIcon,
  Calendar as CalendarIcon,
  ShoppingCart as ShoppingCartIcon,
  Folder as FolderIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  UserPlus as UserPlusIcon,
  AlertCircle as AlertCircleIcon,
  Trello as TrelloIcon,
  User as UserIcon,
  Layout as LayoutIcon,
  Edit as EditIcon,
  DollarSign as DollarSignIcon,
  Mail as MailIcon,
  MessageCircle as MessageCircleIcon,
  PieChart as PieChartIcon,
  Share2 as ShareIcon,
  Users as UsersIcon
} from 'react-feather';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

interface Item {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: Item[];
  title: string;
}

interface Section {
  items: Item[];
  subheader: string;
}

const sections: Section[] = [
  {
    subheader: 'Dashboard',
    items: [
      {
        title: 'Dashboard',
        icon: DashboardOutlinedIcon,
        href: '/app/dashboard'
      },

    ]
  },
  {
    subheader: 'Main',
    items: [
      {
        title: 'University/Colleges',
        icon: SchoolIcon,
        href: '/app/dashboard/customers',
        items: [
          {
            title: 'Add College',
            href: '/app/dashboard/college/create'
          },
          {
            title: 'View College',
            href: '/app/dashboard/college'
          }
        ]
      },
      {
        title: 'Schools',
    
        icon: AccountBalanceOutlinedIcon,
        href: '/app/dashboard/school',
        items: [
          {
            title: 'Add School board',
            href: '/app/dashboard/schoolboard/create'
          },
          {
            title: 'View School board',
            href: '/app/dashboard/schoolboard'
          },
          {
            title: 'Add Schools',
            href: '/app/dashboard/school/create'
          },
          {
            title: 'View Schools',
            href: '/app/dashboard/school'
          }
        ]
      },
      // {
      //   title: 'Polytechnic',
      //   icon: AccountBalanceOutlinedIcon,
      //   href: '/app/dashboard/polytechnic',
      //   items: [
      //     {
      //       title: 'Add Polytechnic',
      //       href: '/app/dashboard/polytechnic/create'
      //     },
      //     {
      //       title: 'View Polytechnic',
      //       href: '/app/dashboard/polytechnic'
      //     }
      //   ]
      // },
      {
        title: 'Scholarship',
        icon: AccountBalanceWalletIcon,
        href: '/app/dashboard/scholarship',
        items: [
          {
            title: 'Add Scholarship',
            href: '/app/dashboard/scholarship/create'
          },
          {
            title: 'View Scholarship',
            href: '/app/dashboard/scholarship'
          }
        ]
      },
      {
        title: 'Course',
        icon: ReceiptIcon,
        href: '/app/dashboard/course',
        items: [
          {
            title: 'Add Course',
            href: '/app/dashboard/course/create'
          },
          {
            title: 'View Course',
            href: '/app/dashboard/course'
          },
          {
            title: 'Add UpcomingCourse',
            href: '/app/dashboard/upcoming_course/create'
          },
          {
            title: 'View UpcomingCourse',
            href: '/app/dashboard/upcoming_course'
          }
        ]
      },
      {
        title: 'Exams',
        icon: BorderColorOutlinedIcon,
        href: '/app/dashboard/exam',
        items: [
          {
            title: 'Add Exams',
            href: '/app/dashboard/exam/create'
          },
          {
            title: 'View Exams',
            href: '/app/dashboard/exam'
          }
        ]
      },
      {
          title: 'Users',
          href: '/app/dashboard/users',
          icon: PeopleOutlineOutlinedIcon
        },
        {
          title: 'Reviews',
          href: '/app/dashboard/reviews',
          icon: FeedbackOutlinedIcon
        }
    ]
  },
  {
    subheader: 'STARTER',
    items: [

      {
        title: 'Home page videos',
        icon: YouTubeIcon,
        href: '/app/dashboard/homepagevideos',
        items: [
          {
            title: 'Add videos',
            href: '/app/dashboard/homepagevideos/create'
          },
          {
            title: 'View videos',
            href: '/app/dashboard/homepagevideos'
          }
        ]
      },
      {
        title: 'Pages',
        icon: PostAddOutlinedIcon,
        href: '/app/dashboard/pages',
        items: [
          {
            title: 'Set Pages',
            href: '/app/dashboard/pages/create'
          },
          {
            title: 'Manage Pages',
            href: '/app/dashboard/pages'
          }
        ]
      },
     
      {
        title: 'Banners',
        icon: ViewCarouselOutlinedIcon,
        href: '/app/dashboard/banners',
        items: [
          {
            title: 'Add Banner',
            href: '/app/dashboard/banners/create'
          },
          {
            title: 'View Banners',
            href: '/app/dashboard/banners'
          }
        ]
      },
      {
        title: 'News & Event',
        icon: TodayOutlinedIcon,
        href: '/app/dashboard/newsandevents',
        items: [
          {
            title: 'Add News & Event',

            href: '/app/dashboard/news-event/create'
          },
          {
            title: 'View News & Event',
            href: '/app/dashboard/news-event'

          }
        ]
      },
      {
        title: 'Blog',
        icon: AssignmentOutlinedIcon,
        href: '/app/management/orders',
        items: [
          {
            title: 'Category',
            href: '/app/dashboard/blog/category'
          },
         
          {
            title: 'Author',
            href: '/app/dashboard/blog/author/create'
           
          },
          {
            title: 'View Author',
            href: '/app/dashboard/blog/author'
          },
          {
            title: 'Blog',
            href: '/app/dashboard/blog/create'
          },
          {
            title: 'View Blog',
            href: '/app/dashboard/blog'
          }
        ]
      },
   
      {
        title: 'General Course',
        icon: ImportContactsOutlinedIcon,
        href: '/app/dashboard/generalcourses',
        items: [
          {
            title: 'Add Course',
            href: '/app/dashboard/generalcourses/create'
          },
          {
            title: 'View Course',
            href: '/app/dashboard/generalcourses'
          }
        ]
      },
      {
        title: 'Management',
        icon: PersonOutlinedIcon,
        href: '/app/dashboard/management',
        items: [
          {
            title: 'Create Management',
            href: '/app/dashboard/management/create'
          },
          {
            title: 'View Management',
            href: '/app/dashboard/management'
          }
        ]
      },
      {
        title: 'Cities',
        icon: EmojiTransportationOutlinedIcon,
        href: '/app/dashboard/cities',
        items: [
          {
            title: 'Add Cities',
            href: '/app/dashboard/cities/create'
          },
          {
            title: 'View Cities',
            href: '/app/dashboard/cities'
          }
        ]
      },
      {
        title: 'Area',
        icon: LanguageOutlinedIcon,
        href: '/app/dashboard/area',
        items: [
          {
            title: 'Add Area',
            href: '/app/dashboard/area/create'
          },
          {
            title: 'View Area',
            href: '/app/dashboard/area'
          }
        ]
      },
      { 
        title: 'Accreditation',
        icon: AcUnitOutlinedIcon,
        href: '/app/dashboard/accreditations',
        items: [
          {
            title: 'Add Accreditation',
            href: '/app/dashboard/accreditations/create'
          },
          {
            title: 'View Accreditation',
            href: '/app/dashboard/accreditations'
          }
        ]
      },
      {
        title: 'Amenities',
        icon: DnsOutlinedIcon,
        href: '/app/dashboard/amenity',
        items: [
          {
            title: 'Add Amenities',
            href: '/app/dashboard/amenity/create'
          },
          {
            title: 'View Amenities',
            href: '/app/dashboard/amenity'
          }
        ]
      },
      {
        title: 'Recognition',
        icon: VerifiedUserOutlinedIcon,
        href: '/app/dashboard/recognitionandapproval',
        items: [
          {
            title: 'Add Recognition',
            href: '/app/dashboard/recognitionandapproval/create'
          },
          {
            title: 'View Recognition',
            href: '/app/dashboard/recognitionandapproval'
          }
        ]
      },
      {
        title: 'Affiliations',
        icon: BallotOutlinedIcon,
        href: '/app/dashboard/affilition',
        items: [
          {
            title: 'Add Affiliations',
            href: '/app/dashboard/affilition/create'
          },
          {
            title: 'View Affiliations',
            href: '/app/dashboard/affilition'
          }
        ]
      },
      {
        title: 'Companies',
        icon: BusinessOutlinedIcon,
        href: '/app/dashboard/company',
        items: [
          {
            title: 'Add Companies',
            href: '/app/dashboard/company/create'
          },
          {
            title: 'View Companies',
            href: '/app/dashboard/company'
          }
        ]
      },
      {
        title: 'College Group',
        icon: ApartmentOutlinedIcon,
        href: '/app/management/orders',
        items: [
          {
            title: 'Add  Group',
            href: '/app/dashboard/group/create'
          },
          {
            title: 'View  Group',
            href: '/app/dashboard/group'
          }
        ]
      },
      {
        title: 'Stream',
        icon: SubjectOutlinedIcon,
        href: '/app/dashboard/stream/create',
        items: [
          {
            title: 'Add Stream',
            href: '/app/dashboard/stream/create'
          },
          {
            title: 'View Stream',
            href: '/app/dashboard/stream'
          }
        ]
      },
      {
        title: 'Substream',
        icon: DescriptionOutlinedIcon,
        href: '/app/dashboard/substream',
        items: [
          {
            title: 'Add Substream',
            href: '/app/dashboard/substream/create'
          },
          {
            title: 'View Substream',
            href: '/app/dashboard/substream'
          }
        ]
      },
      {
        title: 'Testimonial',
        icon: ForumOutlinedIcon,
        href: '/app/dashboard/testimonial',
        items: [
          {
            title: 'Add Testimonial',
            href: '/app/dashboard/testimonial/create'
          },
          {
            title: 'View Testimonial',
            href: '/app/dashboard/testimonial'
          },
          {
            title: 'Add Video Testimonial',
            href: '/app/dashboard/video-testimonial/create'
          },
          {
            title: 'View Video Testimonial',
            href: '/app/dashboard/video-testimonial'
          }
        ]
      },
      {
        title: 'Students From',
        icon: FormatAlignJustifyOutlinedIcon,
        href: '/app/dashboard/students_from',
        items: [
          {
            title: 'Add Description',
            href: '/app/dashboard/students_from/create'
          },
          {
            title: 'View Description',
            href: '/app/dashboard/students_from'
          }
        ]
      },
      {
        title: 'Abroad Page',
        icon: AssignmentOutlinedIcon,
        href: '/app/management/orders',
        items: [
          {
            title: 'Abroad Countries',
            href: '/app/dashboard/abroadcountries/create'
          },
          {
            title: 'View  Abroad Countries',
            href: '/app/dashboard/abroadcountries'
          },
          {
            title: 'Abroad Universities',
            href: '/app/dashboard/abroaduniversities/create'
          },
          {
            title: 'View Abroad Universities',
            href: '/app/dashboard/abroaduniversities'
          }
        ]
      },
      {
        title: 'NRI Quota',
        icon: FlightLandOutlinedIcon,
        href: '/app/dashboard/nri',
      },
      {
        title: 'Recognition Editor',
        icon: EditAttributesOutlinedIcon,
        href: '/app/dashboard/recognition_editor',
      },
      {
        title: 'Services',
        icon: StorageOutlinedIcon,
        href: '/app/dashboard/service',
      },
      {
        title: 'Information',
        icon: ImportContactsOutlinedIcon,
        href: '/app/dashboard/information',
      },
      {
        title: 'Enquiries',
        icon: ContactPhoneOutlinedIcon,
        href: '/app/dashboard/enquiry',
      },
      {
        title: 'Team',
        icon: GroupOutlinedIcon,
        href: '/app/dashboard/team',
        items: [
          {
            title: 'Add Team Members',
            href: '/app/dashboard/team/create'
          },
          {
            title: 'View Team Members',
            href: '/app/dashboard/team'
          }
        ]
      },
      {
        title: 'Job Vacancies',
        icon: WorkOutlineOutlinedIcon,
        href: '/app/dashboard/job_vacancies',
        items: [
          {
            title: 'Add Vacancies',
            href: '/app/dashboard/job_vacancies/create'
          },
          {
            title: 'View Vacancies',
            href: '/app/dashboard/job_vacancies'
          }
        ]
      },
      {
        title: 'About',
        icon: EventNoteOutlinedIcon,
        href: '/app/dashboard/about',
      },
      {
        title: 'Website',
        icon: HttpOutlinedIcon,
        href: '/app/dashboard/website-image/view',
        items: [
          {
            title: 'Images',
            href: '/app/dashboard/website-image/view'
          },
          {
            title: 'PopUp Images',
            href: '/app/dashboard/website-popup-image/view'
          }
        ]
      },
      {
        title: 'Redirect Url',
        icon: BlurLinearOutlinedIcon,
        href: '/app/dashboard/redirect-url',
        items: [
          {
            title: 'Add Redirect Url',
            href: '/app/dashboard/redirect-url/create'
          },
          {
            title: 'Redirect Url',
            href: '/app/dashboard/redirect-url'
          }
        ]
      },
      {
        title: 'Promo Page',
        icon: PagesOutlinedIcon,
        href: '/app/dashboard/promo-page',
        items: [
          {
            title: 'Add Promo Page',
            href: '/app/dashboard/promo-page/create'
          },
          {
            title: 'Promo Page',
            href: '/app/dashboard/promo-page'
          }
        ]
      },
      {
        title: 'Data Backup',
        icon: BackupOutlinedIcon,
        href: '/app/dashboard/backup',
      },
     
    ]
  },
  // {
  //   subheader: 'Management',
  //   items: [
  //     {
  //       title: 'Customers',
  //       icon: UsersIcon,
  //       href: '/app/management/customers',
  //       items: [
  //         {
  //           title: 'List Customers',
  //           href: '/app/management/customers'
  //         },
  //         {
  //           title: 'View Customer',
  //           href: '/app/management/customers/1'
  //         },
  //         {
  //           title: 'Edit Customer',
  //           href: '/app/management/customers/1/edit'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Products',
  //       icon: ShoppingCartIcon,
  //       href: '/app/management/products',
  //       items: [
  //         {
  //           title: 'List Products',
  //           href: '/app/management/products'
  //         },
  //         {
  //           title: 'Create Product',
  //           href: '/app/management/products/create'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Orders',
  //       icon: FolderIcon,
  //       href: '/app/management/orders',
  //       items: [
  //         {
  //           title: 'List Orders',
  //           href: '/app/management/orders'
  //         },
  //         {
  //           title: 'View Order',
  //           href: '/app/management/orders/1'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Invoices',
  //       icon: ReceiptIcon,
  //       href: '/app/management/invoices',
  //       items: [
  //         {
  //           title: 'List Invoices',
  //           href: '/app/management/invoices'
  //         },
  //         {
  //           title: 'View Invoice',
  //           href: '/app/management/invoices/1'
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   subheader: 'Applications',
  //   items: [
  //     {
  //       title: 'Projects Platform',
  //       href: '/app/projects',
  //       icon: BriefcaseIcon,
  //       items: [
  //         {
  //           title: 'Overview',
  //           href: '/app/projects/overview'
  //         },
  //         {
  //           title: 'Browse Projects',
  //           href: '/app/projects/browse'
  //         },
  //         {
  //           title: 'Create Project',
  //           href: '/app/projects/create'
  //         },
  //         {
  //           title: 'View Project',
  //           href: '/app/projects/1'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Social Platform',
  //       href: '/app/social',
  //       icon: ShareIcon,
  //       items: [
  //         {
  //           title: 'Profile',
  //           href: '/app/social/profile'
  //         },
  //         {
  //           title: 'Feed',
  //           href: '/app/social/feed'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Kanban',
  //       href: '/app/kanban',
  //       icon: TrelloIcon
  //     },
  //     {
  //       title: 'Mail',
  //       href: '/app/mail',
  //       icon: MailIcon
  //     },
  //     {
  //       title: 'Chat',
  //       href: '/app/chat',
  //       icon: MessageCircleIcon,
  //       info: () => (
  //         <Chip
  //           color="secondary"
  //           size="small"
  //           label="Updated"
  //         />
  //       )
  //     },
  //     {
  //       title: 'Calendar',
  //       href: '/app/calendar',
  //       icon: CalendarIcon,
  //       info: () => (
  //         <Chip
  //           color="secondary"
  //           size="small"
  //           label="Updated"
  //         />
  //       )
  //     }
  //   ]
  // },
  // {
  //   subheader: 'Auth',
  //   items: [
  //     {
  //       title: 'Login',
  //       href: '/login-unprotected',
  //       icon: LockIcon
  //     },
  //     {
  //       title: 'Register',
  //       href: '/register-unprotected',
  //       icon: UserPlusIcon
  //     }
  //   ]
  // },
  // {
  //   subheader: 'Pages',
  //   items: [
  //     {
  //       title: 'Account',
  //       href: '/app/account',
  //       icon: UserIcon
  //     },
  //     {
  //       title: 'Error',
  //       href: '/404',
  //       icon: AlertCircleIcon
  //     },
  //     {
  //       title: 'Pricing',
  //       href: '/pricing',
  //       icon: DollarSignIcon
  //     }
  //   ]
  // },
  // {
  //   subheader: 'Extra',
  //   items: [
  //     {
  //       title: 'Charts',
  //       href: '/app/extra/charts',
  //       icon: BarChartIcon,
  //       items: [
  //         {
  //           title: 'Apex Charts',
  //           href: '/app/extra/charts/apex'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Forms',
  //       href: '/app/extra/forms',
  //       icon: EditIcon,
  //       items: [
  //         {
  //           title: 'Formik',
  //           href: '/app/extra/forms/formik'
  //         },
  //         {
  //           title: 'Redux Forms',
  //           href: '/app/extra/forms/redux'
  //         },
  //       ]
  //     },
  //     {
  //       title: 'Editors',
  //       href: '/app/extra/editors',
  //       icon: LayoutIcon,
  //       items: [
  //         {
  //           title: 'DraftJS Editor',
  //           href: '/app/extra/editors/draft-js'
  //         },
  //         {
  //           title: 'Quill Editor',
  //           href: '/app/extra/editors/quill'
  //         }
  //       ]
  //     }
  //   ]
  // }
];

function renderNavItems({
  items,
  pathname,
  depth = 0
}: {
  items: Item[];
  pathname: string;
  depth?: number;
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}: {
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box
            display="flex"
            justifyContent="center"
          >
          </Box>
          <Box
            mt={2}
            textAlign="center"
          >
              <Typography variant="h4">BANGALORE STUDY</Typography>
       
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {section.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        <Divider />
        {/* <Box p={2}>
          <Box
            p={2}
            borderRadius="borderRadius"
            bgcolor="background.dark"
          >
            <Typography
              variant="h6"
              color="textPrimary"
            >
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              component={RouterLink}
              to="/docs"
            >
              Settings
            </Link>
          </Box>
        </Box> */}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
