import React, { FC } from 'react';
import './styles.css';
import NewsBG from 'src/assets/img/news.jpg';
import { config } from 'src/config';

const SearchHeader: FC <{viewDetails: any}>= ({viewDetails}) => {
    const logoUrl = viewDetails.cover_image ? config.baseUrlfromconfig + viewDetails.cover_image : NewsBG;

    return (
        <section className="inner-top-header" id="hero" style={{ background: `linear-gradient(359deg, rgb(0 0 0 / 80%), rgb(0 0 0 / 80%)) fixed, url(${logoUrl})` }}  >
            <div className="container">
                <div className="row">
                    <div className="large-spac"></div>
                </div>
            </div>
        </section>
    );
};

export default SearchHeader;