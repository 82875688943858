import React, { FC, useEffect, useState, useContext ,useCallback} from 'react';
import SettingsContext from 'src/contexts/SettingsContext';
import axios from 'src/utils/axiosintercpt';
import { makeStyles } from '@material-ui/core';

import Breadcrumbs from './Breadcrumbs';
import Header from './InnerHeader';
import OverviewSection from './OverviewSection';
import { pConfig } from 'src/config';   
import useIsMountedRef from 'src/hooks/useIsMountedRef';

import Helmet from 'react-helmet';
const useStyles = makeStyles(() => ({
  root: {}
}));

const SignupPage: FC = () => {
  const classes = useStyles();
  const setCtx = useContext(SettingsContext);
  const isMountedRef = useIsMountedRef();
  const [metatitle, setmetatitle] = useState<any>([]);
  const [metadescription, setmetadescription] = useState<any>([]);
  const [courseStreamArr, setCourseStreamArr] = useState([]);
  const [isHeroLoaded, setHeroLoaded] = useState(false);

  const getHeroData = async () => {
    const formData = new FormData();
    formData.append('getdata', JSON.stringify([{ data: "course_streams_head" }]));
    const heroResp = await axios.post('api/website/home/header', formData);

    setCourseStreamArr(heroResp.data.data.course_streams_head);

    setCtx.setCourseData(heroResp.data.data.course_streams_head);
    setHeroLoaded(true);
}
  
  useEffect(()=>{
    getHeroData();
  }, []);

  return (
    <>
        <Helmet>
          <title>{metatitle && metatitle != '' ? metatitle : 'Get Admission at Top Colleges in Bangalore'}</title>
          <meta name="description" content={metadescription && metadescription != '' ?  metadescription : 'Looking for College/ University/ School admission? Bangalorestudy.com helps you with a list of Colleges, Universities and Schools to pursue your education in Bangalore. Call us now'} />
          <link rel="canonical" href={`${pConfig.siteUrl}login`} />
        </Helmet>
        <Header/>
        {/* <Breadcrumbs/> */}
        <OverviewSection/>
    </>
  );
};

export default SignupPage;