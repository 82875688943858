import React, { FC,useEffect } from 'react';
import './styles.css';
import CourseHeaderBG from 'src/assets/img/new-course-Courses.jpg';
// import CallNow from 'src/assets/img/call-now.svg';
// import SearchIcon from 'src/assets/img/icons/search.svg';
import axios1 from 'axios';
import axios from 'src/utils/axios';
import { Autocomplete } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Search from 'src/assets/img/icons/search.svg'
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';

interface BlogType {
    stream_name: string;
    stream_slug: string;
}
const SearchHeader: FC<{ courseStreamArr: any }> = ({ courseStreamArr }) => {
    let cancelToken;

    //const [num, setNum] = React.useState(331231);
    const [bloglist, setBloglist] = React.useState<BlogType[]>([]);
    const [loading, setloading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const getSuggestions = async (value) => {
        setloading(true);
        
        if (typeof cancelToken != typeof undefined) {
          cancelToken.cancel("Operation canceled due to new request.")
        }
        cancelToken = axios1.CancelToken.source()
        //let url = '';

        const params = {}
        params['searchText'] = value;
        params['searchfrom'] = 'stream_name';
        params['size'] = '10000';
        params['page'] = '1';
        //const id = slug;//'engineering';
        const response = await axios.post('api/website/home/generalcourse?page=1&size=10000',  { cancelToken: cancelToken.token, params: params  });

        //const response = await axios.get(url, { cancelToken: cancelToken.token, params: params });
        const tags = await response.data.data;
        setBloglist(tags);
        setloading(false);
        setOpen(true);
    }

    const handleupdateBlog = (event) => {
        if (event && event.target && typeof event.target.value != typeof undefined && event.target.value != null && event.target.value.length > 1) {
          getSuggestions(event.target.value);
        }
        else
        {
            setBloglist([]);
            setOpen(false);
        }
    };

    useEffect(()=>{
        //getSuggestions('');
    }, []);
    return (
        <section className="inner-top-header" id="hero" style={{background:`url(${CourseHeaderBG})`}} >
            <div className="hero-container d-flex">
                <div className="container" >
                    <div className="row no-gutters">
                        <div className="col-lg-3"></div>
                        <div className="header-search-box blogsearchbox col-lg-6" >
                            <div className="top-course-header text-center">
                                <h1>Choose by <span>Interest</span></h1>
                            </div>
                            <Autocomplete
                                open={open}
                                options={bloglist}
                                onInputChange={(e) => {
                                    handleupdateBlog(e);
                                }}
                                onFocus={(e) => {
                                    setBloglist([]);
                                    setOpen(false);
                                }}
                                value=""
                                getOptionLabel={(option) => {
                                    // Value selected with enter, right from the input
                                    if (typeof option === 'string') {
                                      return option;
                                    }
                                    // Add "xxx" option created dynamically
                                    if (option.stream_name) {
                                      return option.stream_name;
                                    }
                                    // Regular option
                                    //return option.stream_name;
                                }}
                                renderOption={(option) => <li><a style={{ color:"#000" }} href={`courses/${option.stream_slug}`}>{option.stream_name}</a></li>}
                                noOptionsText=""
                                onChange={(e, value) => {
                                    //console.log(value)
                                }}
                                renderInput={(params) => <TextField  className="search-box" style={{ background: `#fff url(${Search})`, backgroundRepeat: "no-repeat", backgroundPosition: "20px center" }} placeholder="Search"  name="text" {...params}
                                InputProps={{
                                    ...params.InputProps,
                                    className: "search-box",
                                    endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                    ),
                                }}
                                />}
                            />
                           {/*  <form action="" method="post">		    
                                <input type="text" placeholder="Choose by interest" name="text" className="choose-by-interest" />
                                <input type="text" placeholder="Search" name="text" className="search-box" style={{ background: `#fff url(${SearchIcon})`, backgroundRepeat: 'no-repeat', backgroundPosition: '20px center', borderRadius: "30px" }} />
                            </form> */}
                           {/*   <input type="text" placeholder="Search" name="text" className="search-box" style={{ background: `#fff url(${SearchIcon})`, backgroundRepeat: 'no-repeat', backgroundPosition: '20px center', borderRadius: "30px" }} /> */}
                        </div>
                        <div className="col-lg-3"></div>
                    </div> 
                    <div className="spac_01"></div>
                    <div className="col-lg-12 button-section">
                        <GlobalPopupEnquiry  className="btn btn btn-primary" pagename="CourseList" title="Talk to our Experts" />
                        {/*    <a href="#" className="medical-button btn">Medical (MBBS)</a> */}
                        {/* <a href="#" className="talk-to-expert btn">
                            <img src={CallNow} className="fcall" /> Talk to Our Experts
                        </a> */}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SearchHeader;