import React, { FC } from 'react';
import './styles.css';

import BestCollegeBG from 'src/assets/img/best-colleges.jpg';
import { config } from 'src/config';


const Breadcrumbs: FC<{promoBannerArr: any}> = ({ promoBannerArr }) => {
    return (
        // <section className="best-college container-fluid spac_01" data-aos="fade-up">
        //     <div className="container">
        //         <div className="row">
        //             <div className="row best-management-in-course text-center" style={{ background: `url(${BestCollegeBG})` }} >
        //                 <div className="course-center">
        //                     <h2>Best <span>Management</span> colleges and <span>course</span> in <span>bangalore</span></h2>
        //                     <p>It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. </p>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </section>
        <>
        {
            promoBannerArr && promoBannerArr.map((i) => {
                let banner_image = i.image ? config.baseUrlfromconfig + i.image : BestCollegeBG;
                return (
                    <>
                    <section className="best-college container-fluid spac_01" data-aos="fade-up">
                        <div className="container">
                            <div className="row">
                                <div className="best-management-in-course text-center" style={{ background: `url(${banner_image})` }} >
                                    <div className="course-center">
                                         <h2>&nbsp;</h2>
                                         <p>&nbsp;</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    </>
                )
            })
        }
        </>
    );
};

export default Breadcrumbs;