import React, { FC } from 'react';
//import './styles.css';

const Breadcrumbs: FC<{ coursedetail: any }> = ({ coursedetail }) => {

    return (
        <div className="breadcrumbs">
            <div className="container" >
                <ol>
                    <li><a href="/" className="active blue-color">Home</a> </li>
                    <li><a href="/courses">Courses</a></li>
                    {/* <li><a href="">{coursedetail.stream_name} Courses in Karnataka</a></li> */}
                    <li>{coursedetail.h1_title == null || coursedetail.h1_title == "" ? <a href="">{coursedetail.stream_name} Courses in Karnataka</a> : <a href="">{coursedetail.h1_title}</a>}</li>
                </ol>
            </div>
        </div>
    );
};

export default Breadcrumbs;