import React, { FC, useState } from 'react';
import './styles.css';
import ManagementCourseBG from 'src/assets/img/management-courses1.jpg';
import CallNow from 'src/assets/img/call-now.svg';
import SearchIcon from 'src/assets/img/icons/search.svg';
import { config } from 'src/config';
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';
const SearchHeader: FC<{ coursedetail: any }> = ({ coursedetail }) => {
    const [num, setNum] = React.useState(331231);
    //const imageUrl = coursedetail.logo ? config.baseUrlfromconfig + coursedetail.logo : ManagementCourseBG;
    const imageUrl = ManagementCourseBG;
    return (
        <section className="inner-top-header" id="hero" style={{ background: ` url(${imageUrl})` }} >
            <div className="hero-container d-flex">
                <div className="container" >
                    <div className="row top-course-block">
                        <div className="top-course-header">
                           {coursedetail.h1_title == null ||coursedetail.h1_title == "" ? <h1>Top <span>{coursedetail.stream_name}</span> Courses in <span>Karnataka</span></h1> :<h1>{coursedetail.h1_title}</h1>}
                            <ul>
                                {/* {coursedetail.str && coursedetail.str.slice(0, 10).map(i => {
                                    return (
                                        <>
                                            <li>{i.course_stream_name}</li>
                                        </>
                                    )
                                })} */}
                                {/*   <li>Post  Graduate Diploma in Management - PGDM</li> 
                                <li>Master of Commerce - M.com</li>
                                <li>Master in Computer Management - MCM</li>
                                <li>Post Graduate Diploma in Business Analytics - PGDBA</li>
                                <li>Post Graduate Program in Management - PGM</li>
                                <li>Master of Financial Management - MFM</li>
                                <li>Master in HOspital Management - MHM </li>
                                <li>Master of Philosophy in Management - Phil</li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="spac_02"></div>
                    <div className="col-lg-12 button-section">
                    <GlobalPopupEnquiry className="medical-button btn" />
                 
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SearchHeader;