import React, { FC } from 'react';
import './styles.css';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GlobalReviewSection from 'src/components/GlobalReviewSection';

const Breadcrumbs: FC<{collegedetail : any}> = ({collegedetail}) => {
    const [num, setNum] = React.useState(331231);

    return (
        <section className="course-overviews-section container-fluid  spac_01" >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 overviews-course">
                        <div className="row">
                            <div className="col-lg-12" >
                                <ul className="nav nav-tabs" id="myTab">
                                    <li className="nav-item"><a href="#info" className="nav-link active" data-bs-toggle="tab">Info</a></li>
                                    <li className="nav-item"><a href="#reviews" className="nav-link" data-bs-toggle="tab">Reviews</a></li>
                                    <li className="nav-item"><a href="#faq" className="nav-link" data-bs-toggle="tab">FAQs</a></li>
                                </ul>
                                <div className="tab-content left-overviews-section">

                                    <div className="tab-pane fade show active bs-editor-text" id="info">
                                        <span dangerouslySetInnerHTML={{ __html: collegedetail.about }} />
                                    </div>

                                    <div className="tab-pane fade" id="reviews">
                                    <GlobalReviewSection type="board"  item_id={collegedetail.id?collegedetail.id:0}  />
                                    </div>

                                    <div className="tab-pane fade" id="faq">
                                    {collegedetail.faqs && collegedetail.faqs.map((i, index) => {
                                            return (
                                            <>
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id={`panel${index}a-header`}
                                                    >
                                                        <Typography>{index + 1+"."} {i.questions}</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography>
                                                            {i.answers}
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                                {/* <span><b>Question:</b>{i.questions}</span><br></br>
                                                <span><b>Answer:</b> {i.answers}</span><br></br> */}
                                            </>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Breadcrumbs;