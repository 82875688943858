import React, { FC, useEffect, useState, useContext, useCallback, ChangeEvent } from 'react';
import { makeStyles } from '@material-ui/core';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import './styles.css';
import GetMoreDetails from 'src/assets/img/get-more-details.jpg';
import EducationCap from 'src/assets/img/education-cap.png';
import HeadingBg from 'src/assets/img/heading-bg.png';
import TopManagementCourseBG from 'src/assets/img/top-managment-course.jpg';
import InterestedinCourseBG from 'src/assets/img/interested-in-course-bg1.png';
import CollegeIMG from 'src/assets/img/college.jpg';
import SidebarFilter from 'src/components/SidebarFilter';
import StreamIMG from 'src/assets/img/stream.jpg';
import CityIMG from 'src/assets/img/city.jpg';
import AccreditaionIMG from 'src/assets/img/accreditaion.jpg';
import ManagementiconIMG from 'src/assets/img/management-icon.jpg';
import NotDataFoundView from 'src/views/errors/NotDataFoundView';
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';
import { config } from 'src/config';
import {
    Image as ImageIcon,
    Edit as EditIcon,
    ArrowRight as ArrowRightIcon,
    Search as SearchIcon,
    Filter as FilterIcon

} from 'react-feather';

import {
    Box,
    Button,
    Card,
    Checkbox,
    InputAdornment,
    FormControlLabel,
    IconButton,
    Link,
    SvgIcon,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Hidden, Divider,
    ListItem,
    ListItemText,
    List,
    ListItemIcon,
    CircularProgress
} from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'src/utils/axios';
import axios1 from 'axios';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import useIsMountedRef from 'src/hooks/useIsMountedRef';

const useStylesBootstrap = makeStyles(() => ({
    arrow: {
        color: '#000',
    },
    tooltip: {
        backgroundColor: '#000',
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
}));

interface coursetype {
    course_stream_name: string;
    id: string;
}

function BootstrapTooltip(props: TooltipProps) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}
type Anchor = 'top' | 'left' | 'bottom' | 'right';

const useStyles = makeStyles(() => ({
    root: {},
    input: {
        height: 40,
        width: 'auto',
        marginTop: 5
    },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

let axioscancelToken;

const Breadcrumbs: FC<{
    page: any, collegedetail: any, isFetching, HasMore, getcollegelist, searchByFilters, managments, stream, ownerships, city, accreditations, totalItems, generalCourse: any
}> = ({ page, collegedetail, isFetching, HasMore, getcollegelist, searchByFilters, managments, stream, ownerships, city, accreditations, totalItems, generalCourse }) => {
    const queryParams = new URLSearchParams(window.location.search);
    const streamdata = queryParams.get('stream');
    const coursedata = queryParams.get('course');
    //alert(coursedata);
    const classesnew = useStyles();
    const isMountedRef = useIsMountedRef();
    const [num, setNum] = React.useState(331231);
    const [showStreamSearch, setStreamSearch] = React.useState(false);
    const [streamsselected, setStreamsselected] = useState<any>([]);//useState<any>(streamdata ? [15,16] : []);//useState<any>(streamdata && streamdata != '');
    const [ownershipselected, setOwnershipselected] = useState<any>([]);
    const [cityselected, setCityselected] = useState<any>([]);
    const [mgselected, setMgselected] = useState<any>([]);
    const [gsselected, setGsselected] = useState<any>([]);
    const [accreditationsselected, setAccreditationsselected] = useState<any>([]);
    const classes = useStylesBootstrap();
    const [query, setQuery] = useState<string>('');
    const [ApplyFilters, setApplyFilters] = useState<any>([]);
    const [selected, setSelected] = useState([]);
    const [checked, setChecked] = React.useState([0]);

    const [autostreamsselected, setAutoStreamsselected] = useState<any>([]);//useState<any>(streamdata && streamdata != '');

    const handleSidebarFilter = async (type, data) => {

        if (type == 'Stream')
            setStreamsselected(data);

        if (type == 'Ownership')
            setOwnershipselected(data);

        if (type == 'City')
            setCityselected(data);

        if (type == 'Accreditations')
            setAccreditationsselected(data);

        if (type == 'Management')
            setMgselected(data);

        if (type == 'General Course')
            setGsselected(data);
    };

    const updateappliedFilters = async () => {
        console.log('streamsselected');
        console.log(streamsselected);
        console.log('---------------');
        let appliedFilters = {
            //'group_id': streamsselected ? streamsselected : [],
            'stream_id': streamsselected ? streamsselected : [],
            'college_type': ownershipselected ? ownershipselected : [],
            'city_id': cityselected ? cityselected : [],
            'accreditation_id': accreditationsselected ? accreditationsselected : [],
            'management_id': mgselected ? mgselected : [],
            'course_id': gsselected ? gsselected : [],
        }
        setApplyFilters(appliedFilters);
        searchByFilters(appliedFilters);
    }

    const toggleStream = (e): any => { // event is implicitly passed to the function
        e.preventDefault();
        showStreamSearch ? setStreamSearch(false) : setStreamSearch(true);
    }

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor: Anchor, open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };


    const [gcourselist, setgcourselist] = React.useState<coursetype[]>([]);
    const [loading, setloading] = React.useState(false);

    const getSuggestions = useCallback(async (value, searchslug) => {
        setloading(true);

        if (typeof axioscancelToken != typeof undefined) {
            axioscancelToken.cancel("Operation canceled due to new request.")
        }
        axioscancelToken = axios1.CancelToken.source()
        let url = '';

        const params = {}
        params['searchText'] = value;

        if (searchslug == 'slug') {
            params['searchfrom'] = 'course_stream_slug';
        }
        else {
            params['searchfrom'] = 'course_stream_name';
        }

        params['size'] = '10';
        const response = await axios.get('api/website/home/allgeneralcourse', { cancelToken: axioscancelToken.token, params: params });
        if (isMountedRef.current) {
            if (response) {
                const tags = response.data.data;
                setgcourselist(tags);
                if (searchslug == 'slug') {
                    let result;
                    if (tags) {
                        result = tags.filter(function (tag) {
                            return tag.course_stream_slug === coursedata;
                        }).map(function (tag) {
                            return tag.id;
                        });
                        console.log('----**---------------useeffect-----------------------');
                        console.log(result)
                        console.log('-----**--------------useeffect-----------------------');
                        result = result[0];//result[0].id;
                        setGsselected(gsselected.concat(result));

                    }
                }
            }
        }

        setloading(false);
    }, [isMountedRef]);

    const handleupdateCourse = (event) => {
        event.persist();
        setQuery(event.target.value);
        if (event && event.target && typeof event.target.value != typeof undefined && event.target.value != null && event.target.value.length > 1) {
            getSuggestions(event.target.value, '');

        }
    };

    const handleToggle = (value: number, id: any) => () => {
        //const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        const currentIndex = gsselected.findIndex(a => a === id)
        if (currentIndex === -1) {
            //newChecked.push(value);
            setGsselected(gsselected.concat(id));
        } else {
            //newChecked.splice(currentIndex, 1);
            setGsselected(gsselected =>
                gsselected.filter(element => {
                    return element !== id;
                }),
            );
        }
        // setChecked(newChecked);
    };

    let i = 0;
    /*
    useEffect(() => {
        handleSidebarFilter('General Course', gsselected);
    }, [gsselected]);
    */

    useEffect(() => {
        updateappliedFilters();
        if (coursedata == '' || coursedata == null) {
            getSuggestions('', '');
        }
    }, [streamsselected, ownershipselected, cityselected, accreditationsselected, mgselected, gsselected]);

    useEffect(() => {
        if (streamdata) {//alert(streamdata);
            let result = stream.filter(function (stream) {
                return stream.stream_slug === streamdata;
            }).map(function (stream) {
                return stream.id;
            });
            if (result) {
                result = result[0];
                // console.log('-------------------useeffect-----------------------');
                // console.log(result)
                // console.log('-------------------useeffect-----------------------');
            }

            //const result = stream.map((stream) => stream.name).join(',');
            setAutoStreamsselected([result]);
        }

        if (coursedata) {
            getSuggestions(coursedata, 'slug');
        }
        /*if(streamdata)
        {
            handleSidebarFilter('Stream', [18]);
            setStreamsselected([18]);
            
        }*/
    }, []);



    return (
        <section className="course-overviews-section container-fluid  spac_01" >
            <div className="container">
                <div className="row">
                    <div className="overviews-course">
                        <div className="row">
                            <h3 className="main-heading text-left">Top <b>Colleges</b> in Karnataka</h3>
                            <div className="spac_02"></div>

                            <Hidden lgUp mdUp smUp>

                                <Button onClick={toggleDrawer('top', true)}>
                                    <SvgIcon
                                        fontSize="small"
                                        color="action"
                                    >
                                        <SearchIcon />
                                    </SvgIcon>  {'Filter'}
                                </Button>
                                <Drawer className="drawerCl" anchor={'top'} open={state['top']} onClose={toggleDrawer('top', false)}>
                                    <AppBar position="fixed">
                                        <div className={classes.drawerHeader}>
                                            <IconButton className="btn-close" onClick={toggleDrawer('top', false)}>

                                            </IconButton>
                                        </div>
                                    </AppBar>
                                    <div>
                                        <div className="left-widgets widgets" style={{ marginTop: '52px' }}>

                                            {stream ?

                                                <SidebarFilter
                                                    data={stream}
                                                    icon={StreamIMG}
                                                    type={'Stream'}
                                                    handleSidebarFilter={(type, data) => handleSidebarFilter(type, data)}
                                                />
                                                : 'No Streams'}







                                        </div>

                                        <div className="left-widgets widgets">

                                            {ownerships ?

                                                <SidebarFilter
                                                    data={ownerships}
                                                    icon={StreamIMG}
                                                    type={'Ownership'}
                                                    handleSidebarFilter={(type, data) => handleSidebarFilter(type, data)}
                                                />
                                                : 'No Ownership'}

                                        </div>

                                        <div className="left-widgets widgets">

                                            {city ?

                                                <SidebarFilter
                                                    data={city}
                                                    icon={CityIMG}
                                                    type={'City'}
                                                    handleSidebarFilter={(type, data) => handleSidebarFilter(type, data)}
                                                />
                                                : 'No City'}

                                        </div>

                                        <div className="left-widgets widgets">
                                            {accreditations ?

                                                <SidebarFilter
                                                    data={accreditations}
                                                    icon={AccreditaionIMG}
                                                    type={'Accreditations'}
                                                    handleSidebarFilter={(type, data) => handleSidebarFilter(type, data)}
                                                />
                                                : 'No Accreditations'}

                                        </div>

                                        <div className="left-widgets widgets">
                                            {managments ?

                                                <SidebarFilter
                                                    data={managments}
                                                    icon={ManagementiconIMG}
                                                    type={'Management'}
                                                    handleSidebarFilter={(type, data) => handleSidebarFilter(type, data)}
                                                />
                                                : 'No Management'}
                                        </div>
                                    </div>
                                </Drawer>
                            </Hidden>

                            <div className="col-lg-3 col-md-3 sidebar-widgets d-none d-sm-block d-lg-block d-md-block" >
                                <div className="left-widgets widgets">
                                    {stream ?
                                        <SidebarFilter
                                            data={stream}
                                            icon={StreamIMG}
                                            type={'Stream'}
                                            handleSidebarFilter={(type, data) => handleSidebarFilter(type, data)}
                                            autoselected={autostreamsselected}
                                        />
                                        : 'No Streams'}
                                </div>

                                <div className="left-widgets widgets">
                                    {ownerships ?
                                        <SidebarFilter
                                            data={ownerships}
                                            icon={StreamIMG}
                                            type={'Ownership'}
                                            handleSidebarFilter={(type, data) => handleSidebarFilter(type, data)}
                                        />
                                        : 'No Ownership'}
                                </div>

                                <div className="left-widgets widgets">
                                    {city ?
                                        <SidebarFilter
                                            data={city}
                                            icon={CityIMG}
                                            type={'City'}
                                            handleSidebarFilter={(type, data) => handleSidebarFilter(type, data)}
                                        />
                                        : 'No City'}
                                </div>

                                <div className="left-widgets widgets">
                                    {accreditations ?
                                        <SidebarFilter
                                            data={accreditations}
                                            icon={AccreditaionIMG}
                                            type={'Accreditations'}
                                            handleSidebarFilter={(type, data) => handleSidebarFilter(type, data)}
                                        />
                                        : 'No Accreditations'}
                                </div>

                                <div className="left-widgets widgets">
                                    {managments ?
                                        <SidebarFilter
                                            data={managments}
                                            icon={ManagementiconIMG}
                                            type={'Management'}
                                            handleSidebarFilter={(type, data) => handleSidebarFilter(type, data)}
                                        />
                                        : 'No Management'}
                                </div>

                                <div className="left-widgets widgets">
                                    <div className="heading-title" >
                                        <img src={ManagementiconIMG} className="small-icon" /> Courses
                                        <i onClick={toggleStream} id='toggle-search' className='bx bx-search'></i>
                                        {showStreamSearch ?
                                            <>
                            
                                                <TextField
                                                    InputProps={{
                                                        className: classesnew.input,
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    onChange={(e) => {
                                                        handleupdateCourse(e);
                                                        //setQuery(e.target.value);
                                                    }}
                                                    //onChange={handleQueryChange}
                                                    placeholder={"Search Courses"}
                                                    value={query}
                                                    variant="outlined"
                                                />
                                            </>
                                            : null}
                                    </div>
                                    <div className="widget-scroll-box" >
                                        <List className={classesnew.root}>
                                            {gcourselist && gcourselist.map((value) => {
                                                const labelId = `checkbox-list-label-${value}`;

                                                let id = value.id;
                                                let title = value.course_stream_name;
                                                i++;
                                                return (
                                                    <ListItem key={id} role={undefined} dense button onClick={handleToggle(i, id)}>
                                                        <ListItemIcon >
                                                            <Checkbox
                                                                edge="start"
                                                                checked={gsselected.findIndex(a => a === id) !== -1}
                                                                tabIndex={-1}
                                                                disableRipple
                                                                inputProps={{ 'aria-labelledby': labelId }}
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText id={labelId} primary={title} />

                                                    </ListItem>
                                                );
                                            })}
                                        </List>
                                    </div>

                                   
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-9 top-colleges d-flex flex-wrap justify-content-center" >
                                {isFetching ?
                                    <div style={{ display: 'flex', justifyContent: 'center', height: '200px', flexWrap: 'wrap', alignContent: 'space-around' }}><CircularProgress /></div>
                                    :
                                    <>
                                        {
                                            collegedetail && collegedetail.map((i) => {
                                                let collegelink = '/college/' + i.slug;
                                                let finals = '';
                                                let n = 0;

                                                i.collegeRecognition.map((a) => {
                                                    n++;
                                                    let tesxp = a.recognitionname.recognition_approval_name;
                                                    var matches = tesxp.match(/\((.*?)\)/);
                                                    let submatch = tesxp;

                                                    if (matches) {
                                                        submatch = matches[1];
                                                    }
                                                    if (n == 1)
                                                        finals += submatch;
                                                    else
                                                        finals += "," + submatch;

                                                });





                                                return (
                                                    <div className="col-xl-4 col-lg-4 col-md-6">
                                                        <div className="topcollege-box">
                                                            <img src={i.logo ? config.baseUrlfromconfig + i.logo : CollegeIMG} className="full_wdth" alt={i.name} />
                                                            <div className="college-content">
                                                                <BootstrapTooltip placement="top" title={<span style={{ fontSize: "14px" }}>{i.name}</span>}>
                                                                    <h3>{i.name}</h3>
                                                                </BootstrapTooltip>
                                                                {i.avg_rating ? <div className="rating_box"><i className="bi bi-star-fill rating_act"></i> {i.avg_rating}</div> : ''}
                                                                <div className="clearfix"></div>
                                                                <p>{i.area.area_name}, {i.city.city_name}
                                                                    {finals = ! '' ? ', Approved by ' + finals : ''}

                                                                </p>
                                                                <hr />
                                                                <div><b>College Details</b></div>
                                                                <ul>
                                                                    <li>Est. Year: <b>{i.established}</b></li>
                                                                    <li>Ownership: <b>{i.college_type}</b></li>
                                                                </ul>
                                                                <div className="college_button_wrp">
                                                                    <GlobalPopupEnquiry /><a href={collegelink}>View Details</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        {collegedetail.length < 1 ? (
                                            <NotDataFoundView />
                                        ) : ''}

                                        {totalItems > 12 ? (
                                            <>
                                                <div className='clearfix'></div>
                                                <div className="spac_02"></div>
                                                <div className="button-section text-center">
                                                    {isFetching && <p>Loading more data...</p>}
                                                    {!isFetching && HasMore && (
                                                        <button className="button btn text-center"

                                                            onClick={() => getcollegelist(page + 1, collegedetail, ApplyFilters)}
                                                        >Load more</button>
                                                    )}
                                                </div>
                                                <div className="spac_02"></div>
                                            </>
                                        ) : ''}
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Breadcrumbs;