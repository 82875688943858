import React, { FC, useState } from 'react';
import './styles.css';

import College from 'src/assets/img/college.jpg';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const TopColleges: FC<{}> = ({}) => {

    const owlOptions = {
        margin: 20, nav: true, autoplay: false,
        responsiveClass: true,
        responsive: {
            0: {items:1, nav:true},
            400: {items:1, dots:false, nav:true},
            600: {items: 1, dots:false, nav:true},
            700: {items: 2, dots:false, nav:true},
            1000:{
                items:3,
                nav:true,
                loop:false
            },
            1440:{
                items:4,
                nav:true,
                loop:false
            }
        },
    };

    return (
        <section className="container-fluid spac_01 topColgs" data-aos="fade-up">
            <div className="container">
                <div className="row loop_colleges">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                        <div className="testi_wrp">
                            <div className="testi_hover"></div>
                            <div className="inner_wrp">
                                <div className="test_content_wrp col-lg-12">
                                    <img src={College} className="full_wdth" />
                                    <p>&nbsp;</p>
                                    <div className="clearfix"></div>
                                    <div className="col-lg-12 form-group no-padding college_button_wrp widthset"><a href="/colleges" className="active_bt">Top Colleges</a></div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                        <div className="testi_wrp">
                            <div className="testi_hover"></div>
                            <div className="inner_wrp">
                                <div className="test_content_wrp col-lg-12">
                                    <img src={College} className="full_wdth" />
                                    <p>&nbsp;</p>
                                    <div className="clearfix"></div>
                                    <div className="col-lg-12 form-group no-padding college_button_wrp widthset"><a href="/universities" className="active_bt">Top Universities</a></div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                        <div className="testi_wrp">
                            <div className="testi_hover"></div>
                            <div className="inner_wrp">
                                <div className="test_content_wrp col-lg-12">
                                    <img src={College} className="full_wdth" />
                                    <p>&nbsp;</p>
                                    <div className="clearfix"></div>
                                    <div className="col-lg-12 form-group no-padding college_button_wrp widthset"><a href="/exams" className="active_bt">Entrance Exams</a></div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TopColleges;