import React, { FC } from 'react';
import './styles.css';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


const Testimonials: FC<{ testimonialsArr: any }> = ({ testimonialsArr }) => {
    const owlOptions = {
        margin: 20, nav: true, autoplay: true, autoplayTimeout: 3000,
        responsiveClass: true,
        responsive: {
            0: { items: 1, nav: true },
            400: { items: 1, dots: false, nav: true, loop: true },
            600: { items: 1, dots: false, nav: true, loop: true },
            700: { items: 2, dots: false, nav: true, loop: true },
            1000: {
                items: 2,
                nav: true,
                loop: true
            },
            1440: {
                items: 2,
                nav: true,
                loop: true
            }
        },
    };


    return (

        <section className="container-fluid spac_01 testiSec p-4" data-aos="fade-up">
            <div className="container">
                <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-10 our_portal">
                        <h3 className="common_sec_h mb-3">Students' Speak!</h3>
                        <OwlCarousel {...owlOptions} className="loop_testi loop_nav owl-carousel owl-theme">
                            {testimonialsArr && testimonialsArr.map((el, idx) => (
                                <div className="item" key={`tstmnl${idx}`}>
                                    <div className="testi_wrp">
                                        <div className="testi_hover"></div>
                                        <div className="inner_wrp testimnl">
                                            <div className="test_content_wrp">
                                                <ul>
                                                    <li className="active"><i className="bi bi-star-fill"></i></li>
                                                    <li className="active"><i className="bi bi-star-fill"></i></li>
                                                    <li className="active"><i className="bi bi-star-fill"></i></li>
                                                    <li className="active"><i className="bi bi-star-fill"></i></li>
                                                    <li><i className="bi bi-star-fill"></i></li>
                                                </ul>
                                                <p style={{ overflow: "scroll" }}>{el.description}</p>
                                                <a href="#" className="testi_user">
                                                    <img alt={el.name} src={process.env.REACT_APP_IMG_URI + el.photo} className="testi_photo" /><span><h3>{el.name}</h3><p>{el.designation}</p></span>
                                                </a>
                                                <div className="clearfix"></div>
                                            </div>
                                            <img src={require("src/assets/img/l_quote.svg")} className="lquote" />
                                            <img src={require("src/assets/img/r_quote.svg")} className="rquote" />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </OwlCarousel>


                    </div>
                </div>
            </div>
        </section>

    );
};

export default Testimonials;