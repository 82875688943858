import React from 'react';
import { useFormikContext } from 'formik';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './PhoneInput.css'; // Import the custom CSS file

interface FormValues {
    phoneNumber: string;
}

const PhoneNumberField: React.FC = () => {
    const {
        setFieldValue,
        setFieldTouched,
        values,
        errors,
        touched,
    } = useFormikContext<FormValues>();

    const formatPhoneNumber = (phoneNumber: string, country: any) => {
        let spaceIndex = phoneNumber.indexOf(" ");
        let restOfString = spaceIndex !== -1 ? phoneNumber.substring(spaceIndex + 1) : "";
        let valueWithoutNonDigits = restOfString.replace(/\D/g, '');

        return "+" + country.dialCode + "-" + valueWithoutNonDigits;
    };

    return (
        <PhoneInput
            country={'in'}
          
            value={values.phoneNumber}
            onChange={(value, country: any, e, formattedValue) => {
                const formattedPhoneNumber = formatPhoneNumber(formattedValue, country);
                console.log(formattedPhoneNumber, "formattedPhoneNumber");
                setFieldValue('phoneNumber', formattedPhoneNumber);
            }}
            onBlur={() => setFieldTouched('phoneNumber', true)}
            inputProps={{
                name: 'phoneNumber',
                required: true,
                id: 'PhoneNumber',
                className: 'form-control'
            }}
            
            placeholder="Phone Number"
        />
    );
};

export default PhoneNumberField;
