import React from 'react';
import Defaultlogo from 'src/assets/assets/img/logo1.png';

const FooterLandingPage = (props) => {

  return (
    <footer className='pb-1 bgfooter'>
      <div className="container-fluid align-item-start justify-content-between d-none d-md-flex flex-wrap">
        <div className="footer-left  col-md-7 d-flex ps-5">
          <div className="col-md-8">

            <div className="ft-left mb-3 px-5" style={{ marginBottom: 20 }}>
              <img src={props.logo ? props.logo : Defaultlogo} className="imgfooter" alt="bangalorestudy " />
            </div>


          </div>

          <div className="col-md-6 footer-left text-center pe-5" >
            <p className="font-white23 pe-5">Social Connect</p>
            <p style={{ marginTop: 10, marginBottom: 14 }} className='d-block pe-5'>
              <a target="_blank " href="https://www.facebook.com/bangalorestudy ">
                <i className="fa fa-facebook" />&nbsp; &nbsp;&nbsp;
              </a>
              <a target="_blank " href="https://twitter.com/BangaloreStudy2 ">
              <i className="fa-brands fa-x-twitter" />&nbsp; &nbsp;&nbsp;
              </a>
              <a
                target="_blank "
                href="https://www.youtube.com/channel/UCZP40_ivVcdelNOVhmQFr7w "
              >
                {" "}
                <i className="fa fa-youtube" />&nbsp;&nbsp;&nbsp;
              </a>
              <a target="_blank " href="https://instagram.com/bangalorestudy ">
                <i className="fa fa-instagram" />&nbsp;&nbsp;&nbsp;
              </a>
              <a
                target="_blank "
                href="https://in.linkedin.com/in/bangalorestudy-com-63558488 "
              >
                {" "}
                <i className="fa fa-linkedin" />&nbsp;&nbsp;&nbsp;
              </a>
              <a target="_blank " href="https://in.pinterest.com/bangalorestudy7608/ ">
                <i className="fa fa-pinterest" />&nbsp;&nbsp;&nbsp;
              </a>
            </p>

          </div>

        </div>




        <div className="footer-right col-md-5 offeset-md-1  d-flex">
          <div className="social-unit col-md-5 w-100 px-3 d-flex justify-content-end" >
            <div><p className=" px-5 font-white23" style={{ marginBottom: 10 }}>Connect with us</p>

              <a
                href="tel:08022454991 "
                style={{ color: "white", fontSize: 14, fontWeight: 400 }}
              >
                <i className="fa fa-phone" style={{ fontSize: 13 }} /> 080-22454991
              </a>
              ,
              <a
                href="tel:08026631169 "
                style={{ color: "white", fontSize: 14, fontWeight: 400 }}
              >
                <i className="fa fa-phone" style={{ fontSize: 13 }} /> 080-26631169
              </a>
              <br />
              <div className="bs-phone " style={{ display: "contents" }}>
                <a
                  href="tel:18001208696 "
                  style={{ color: "white", fontWeight: 400, fontSize: 14 }}
                >
                  <i className="fa fa-phone" style={{ fontSize: 13 }} /> 1800 120 8696 (Toll
                  Free)
                </a>
              </div>
              <p>
                Dubai :{" "}
                <a
                  href="tel:971502436552 "
                  style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                >
                  <i className="fa fa-phone" style={{ fontSize: 13 }} /> +971502436552{" "}
                </a>
                ,{" "}
                <br />
                <a
                  href="tel:971504955123 "
                  style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                >
                  <i className="fa fa-phone" style={{ fontSize: 14 }} /> +971504955123{" "}
                </a>
              </p>



            </div>
          </div>
        </div>

        <div className='col-12 text-center'><p style={{ fontSize: 12 }}>
          {" "}
          © 2024 Bangalorestudy.com. All Rights Reserved.
        </p>

        </div>


      </div>
      <div className="container align-item-start justify-content-between d-md-none">
        <div className="footer-left pt-3 col-md-7 text-center">

          <div
            className="ft-left mb-3"
            style={{ justifyContent: "flex-start" }}
          >
            <img src="preprod/nursing_admission/assets/images/footer-bg.png " className="imgfooter" alt="bangalorestudy " />
          </div>
        </div>
        <div className="footer-right col-md-5 offeset-md-1 py-t d-flex">
          <div className="app-unit col-md-7  col-md-5 ">
            <div className="store-wrapper">
              <p className='font-white23 pb-2'>Social Connect</p>
              <a target="_blank " href="https://www.facebook.com/bangalorestudy ">
                <i className="fa fa-facebook" />&nbsp;&nbsp;
              </a>
              <a target="_blank " href="https://twitter.com/BangaloreStudy2 ">
                <i className="fa fa-twitter" />&nbsp;&nbsp;
              </a>
              <a
                target="_blank "
                href="https://www.youtube.com/channel/UCZP40_ivVcdelNOVhmQFr7w "
              >
                {" "}
                <i className="fa fa-youtube" />&nbsp;&nbsp;
              </a>
              <a target="_blank " href="https://instagram.com/bangalorestudy ">
                <i className="fa fa-instagram" />&nbsp;&nbsp;
              </a>
              <a
                target="_blank "
                href="https://in.linkedin.com/in/bangalorestudy-com-63558488 "
              >
                {" "}
                <i className="fa fa-linkedin" />&nbsp;&nbsp;
              </a>
              <a
                target="_blank "
                href="https://in.pinterest.com/bangalorestudy7608/ "
              >
                <i className="fa fa-pinterest" />&nbsp;&nbsp;
              </a>
            </div>
          </div>
          <div className="social-unit col-md-5 mt-4">
            <div>
              <p className="font-white23">Connect with us</p><a
                href="tel:08022454991 "
                style={{ color: "white", fontSize: 14, fontWeight: 400 }}
              ><i className="fa fa-phone" style={{ fontSize: 13 }} />080-22454991</a>,
              <a
                href="tel:08026631169 "
                style={{ color: "white", fontSize: 14, fontWeight: 400 }}
              >
                <i className="fa fa-phone" style={{ fontSize: 13 }} />
                080-26631169
              </a>
              <div className="bs-phone " style={{ display: "contents" }}>
                {" "}
                <br />
                <a
                  href="tel:18001208696 "
                  style={{ color: "white", fontWeight: 400, fontSize: 14 }}
                >
                  <i className="fa fa-phone" style={{ fontSize: 13 }} />
                  1800 120 8696(Toll Free)
                </a>
              </div>
              <p>
                Dubai :{" "}
                <a
                  href="tel:970502436552 "
                  style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                >
                  <i className="fa fa-phone" style={{ fontSize: 13 }} />{" "}
                  +970502436552{" "}
                </a>
                ,{" "}
                <a
                  href="tel:971504955123 "
                  style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                >
                  <i className="fa fa-phone" style={{ fontSize: 13 }} />{" "}
                  +971504955123{" "}
                </a>
              </p>
            </div>
          </div>
          <p className="">
            {" "}
            © 2024 Bangalorestudy.com. All Rights Reserved.
          </p>
        </div>
      </div>


    </footer>
  );

}

export default FooterLandingPage;