import React, { FC,useState } from 'react';
import './styles.css';
import LoginSignin from 'src/assets/img/login_signin.png';
import JWTRegisterNew from 'src/views/auth/RegisterView/JWTRegisterNew';
import JWTLoginNew from 'src/views/auth/LoginView/JWTLoginNew';
import SocialFacebook from 'src/components/SocialFacebook';
import SocialGoogle from 'src/components/SocialGoogle';
import SocialTwitter from 'src/components/SocialTwitter';
import SocialLinkedIn from 'src/components/SocialLinkedIn';
import ForgotView     from 'src/views/auth/ForgotPasswordView/ForgotView';
import VerificationView     from 'src/views/auth/VerificationView/VerificationScreenView';
import ResetPasswordScreenView     from 'src/views/auth/ResetPasswordView/ResetPasswordScreenView';

import {
    Box,
   
    Divider,
    Link,
  
  } from '@material-ui/core';
const Breadcrumbs: FC<{ }> = ({ }) => {

    const [stateofview, setStateofview] = useState<string>('login');
    const [email, setEmail]= useState<string>('');
    const [token, setToken]= useState<string>('');

    const updatestateofview = async (type,email=null,token=null) => {
          setStateofview(type);

          if(email)
          setEmail(email);

          if(token)
          setToken(token);

    };
    
    const handleCL = (e): any => { // event is implicitly passed to the function
        e.preventDefault();
        document.getElementById("logins").click();
      //  showStreamSearch ? setStreamSearch(false) : setStreamSearch(true);
    }
    const handleCLL = (e): any => { // event is implicitly passed to the function
        e.preventDefault();
        document.getElementById("signups").click();
      //  showStreamSearch ? setStreamSearch(false) : setStreamSearch(true);
    }
    const handleForget = (e): any => { // event is implicitly passed to the function
        e.preventDefault();
        setStateofview('forgetstep1');
    }
    
    
    
    return (
        <section className="course-overviews-section siginsection">
            <div className="container aos-init aos-animate" >
                <ul className="nav nav-pills nav-fill">
                    <li className="nav-item">
                        <a id="signups" className="nav-link " aria-current="page" href="#signup" data-bs-toggle="tab" role='tab'>Sign Up</a>
                    </li>
                    <li className="nav-item">
                        <a  id="logins" className="nav-link active"  aria-current="page" href="#login"  data-bs-toggle="tab" role='tab'>Log In</a>
                    </li>

                  
                </ul>
                <div className="tab-content left-overviews-section">
                    <div className="tab-pane fade show " id="signup" role="tabpanel">
                        <div className="row">
                            <div className="spac_02"></div>
                            <div className="col-lg-6" >
                                <div className="widgets get-details-now-form no-bg right-widget">
                                    <h4 className="title-heading textleft">Sign Up for <span>Free!!</span></h4>
                                    <JWTRegisterNew />
                                 
                                   
                                 {/*   <form action="#" method="post" role="form" className="php-email-form">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="subject" id="subject" placeholder="Full Name" />
                                        </div>
                                        <div className="form-group">
                                            <input type="email" name="name" className="form-control" id="name" placeholder="Email ID" />
                                        </div>
                                        <div className="form-group">
                                            <input type="tel" className="form-control" name="mobile" id="mobile" placeholder="Mobile Number" />
                                        </div>
                                        <div className="form-group">
                                            <input type="password" className="form-control" name="password" id="password" placeholder="Password" />
                                        </div>
                                        <div className="form-group">
                                            <input type="password" className="form-control" name="cpassword" id="cpassword" placeholder="Confirm Password" />
                                        </div>
                                        <div className="form-group">
                                            <input type='checkbox' name="agreeterms"></input> By signing up, you agree to our Terms, Data Policy and Cookies Policy 
                                        </div>
                                        <div className="text-center"><button type="submit">Sign Up</button></div>
                                    </form> <a className="facebook" href=""><i className="bi bi-facebook"></i></a>
                                    */ }
                                    <p className='text-center'><hr/>or<hr/></p>
                                    <div className='text-center'>Sign Up with Social Media</div>
                                    <div className="spac_02"></div>
                                    <ul className='share-this text-center' style={{padding:"0px"}}>

                                  

                                        <li><SocialFacebook /></li>
                                        <li> <SocialGoogle /></li>             
                                     <li>
                                            <SocialTwitter />
                                            
                                            </li> 
                                        <li><SocialLinkedIn /></li>
                                       
                                    </ul>

                                    <div className="spac_02"></div>
                          <p className='text-center'>Already have an account ? <a href="#login"  onClick={handleCL} >Login</a></p>
                                </div>
                            </div>
                            <div className="col-lg-1" >&nbsp;</div>
                            <div className="col-lg-5" >
                                <img title={'Signin'} src={LoginSignin} className="full_wdth" />
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade show active" id="login" role="tabpanel">
                    <div className="row">
                            <div className="spac_02"></div>
                            <div className="col-lg-5" >
                                <img src={LoginSignin} className="full_wdth" />
                            </div>
                            <div className="col-lg-1" >&nbsp;</div>
                            <div className="col-lg-5" >
                                <div className="widgets get-details-now-form no-bg right-widget">
                                    <h4 className="title-heading textleft">Welcome <span>Back</span></h4>
                                   
                                   {stateofview=='login'? <><JWTLoginNew  updatestateofview={updatestateofview} />
                                   <div className="spac_02"></div>
                          <p className='text-center'><a  href="#" onClick={handleForget}>Forgotten Password?</a></p>
                   
                          <p className='text-center'>New Here ? <a  href="signup" onClick={handleCLL} >Create an account</a></p>
                           
                                   
                                   <p className='text-center'><hr/>or<hr/></p>
                                    <div className='text-center'>Sign In with Social Media</div>
                                    <div className="spac_02"></div>
                                    <ul className='share-this text-center' style={{padding:"0px"}}>

                                  

                                        <li><SocialFacebook /></li>
                                        <li> <SocialGoogle /></li>             
                                        {/* <li>
                                            <SocialTwitter />
                                            
                                            </li> */}
                                        <li><SocialLinkedIn /></li>
                                       
                                    </ul>

                                   
                                   </>:''}
                                   {stateofview=='forgetstep1'? <ForgotView data-aos="fade-left" updatestateofview={updatestateofview} />:''}
                                   {stateofview=='forgetstep2'? <VerificationView email={email} data-aos="fade-left" updatestateofview={updatestateofview} />:''}
                                   {stateofview=='forgetstep3'? <ResetPasswordScreenView email={email} token={token}  data-aos="fade-left"/>:''}
                                  
                                   
         
                                  
                                </div>

                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Breadcrumbs;