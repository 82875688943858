import React, { FC } from 'react';
import './styles.css';
import ContactusBG from 'src/assets/img/new-contactus.jpg';

const Header: FC = () => {

    return (
        <section className="inner-top-header contactheader" id="hero" style={{ background: `linear-gradient(359deg, rgb(0 0 0 / 80%), rgb(0 0 0 / 80%)) fixed, url(${ContactusBG})` }} >
            <div className="hero-container d-flex">
                <div className="container" >
                    <div className="spac_02"></div>
                    <div className="row top-course-block">
                        <div className="top-course-header">
                            <h1>CONTACT <span>US</span></h1>
                            <p>Need guidance for admissions?<br/>
                            Looking to partner for promotions? <br/>
                            Just want to say hello?</p>

                            <p>We would love to hear from you.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Header;