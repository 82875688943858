import React, { FC, useEffect, useState, useContext, useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import './styles.css';
import EnquiryDetailForm from 'src/components/EnquiryDetailForm';
import CollegeIMG from 'src/assets/img/college.jpg';
import SidebarFilter from 'src/components/SidebarFilter';
import StreamIMG from 'src/assets/img/stream.jpg';
import CityIMG from 'src/assets/img/city.jpg';
import AccreditaionIMG from 'src/assets/img/accreditaion.jpg';
import ManagementiconIMG from 'src/assets/img/management-icon.jpg';
import NotDataFoundView from 'src/views/errors/NotDataFoundView';
import College from 'src/assets/img/college.jpg';
import EntranceExam from 'src/assets/img/entrance-exam.jpg';
import TopManagementCourseBG from 'src/assets/img/top-managment-course.jpg';
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';
import { config } from 'src/config';
import moment from 'moment';
import Pagination from '@material-ui/lab/Pagination/Pagination';

import {

    Button,
    SvgIcon,
    IconButton,
    Hidden
} from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';

import {
    Image as ImageIcon,
    Edit as EditIcon,
    ArrowRight as ArrowRightIcon,
    Search as SearchIcon,
    Filter as FilterIcon

} from 'react-feather';
const useStylesBootstrap = makeStyles(() => ({
    arrow: {
        color: '#000',
    },
    tooltip: {
        backgroundColor: '#000',
    }, drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
}));

function BootstrapTooltip(props: TooltipProps) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}
type Anchor = 'top' | 'left' | 'bottom' | 'right';
const OverviewSection: FC<{ page: any, totalpage, handlePageChange, collegedetail: any, isFetching, HasMore, getcollegelist, searchByFilters, managments, stream, ownerships, city, accreditations, admissionview }> = ({ page, totalpage, handlePageChange, collegedetail, isFetching, HasMore, getcollegelist, searchByFilters, managments, stream, ownerships, city, accreditations, admissionview }) => {
    const [num, setNum] = React.useState(331231);
    const [showStreamSearch, setStreamSearch] = React.useState(false);
    const [streamsselected, setStreamsselected] = useState<any>([]);
    const [ownershipselected, setOwnershipselected] = useState<any>([]);
    const [cityselected, setCityselected] = useState<any>([]);
    const [mgselected, setMgselected] = useState<any>([]);
    const [accreditationsselected, setAccreditationsselected] = useState<any>([]);
    const classes = useStylesBootstrap();
    const [query, setQuery] = useState<string>('');
    const [ApplyFilters, setApplyFilters] = useState<any>([]);
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor: Anchor, open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };
    const handleSidebarFilter = async (type, data) => {

        if (type == 'Stream')
            setStreamsselected(data);

        if (type == 'Ownership')
            setOwnershipselected(data);

        if (type == 'City')
            setCityselected(data);

        if (type == 'Accreditations')
            setAccreditationsselected(data);

        if (type == 'Management')
            setMgselected(data);
    };

    const updateappliedFilters = async () => {
        let appliedFilters = {
            'stream_id': streamsselected ? streamsselected : [],
            'college_type': ownershipselected ? ownershipselected : [],
            'city_id': cityselected ? cityselected : [],
            'accreditation_id': accreditationsselected ? accreditationsselected : [],
            'management_id': mgselected ? mgselected : [],
        }
        setApplyFilters(appliedFilters);
        searchByFilters(appliedFilters);
    }

    const toggleStream = (e): any => { // event is implicitly passed to the function
        e.preventDefault();
        showStreamSearch ? setStreamSearch(false) : setStreamSearch(true);
    }

    useEffect(() => {
        updateappliedFilters();
    }, [streamsselected, ownershipselected, cityselected, accreditationsselected, mgselected]);

    return (
        <section className="course-overviews-section ">
            <div className="container aos-init aos-animate" >
               

                <div className="row">
                    <div className="col-lg-8 overviews-course left-exam-section" >
                        <h3 className="main-heading left-align">Get <span>Admission</span> in Top <span>Colleges</span></h3>
                        <span dangerouslySetInnerHTML={{ __html: admissionview.description }} />
                    </div>

                    <div className="col-lg-4" >
                        <div className="widgets get-details-now-form right-widget">
                            <h4 className="title-heading">Get <span>Admission</span> Guidance</h4>
                            <EnquiryDetailForm type="admissions" />


                        </div>
                    </div>
                </div>

                <div className="row">
                    {/*   <h3 className="main-heading text-left">Top <b>Colleges</b> in Karnataka</h3> */}
                    <div className="spac_02"></div>
                    <Hidden lgUp mdUp smUp>

                        <Button onClick={toggleDrawer('top', true)}>
                            <SvgIcon
                                fontSize="small"
                                color="action"
                            >
                                <SearchIcon />
                            </SvgIcon>  {'Filter'}
                        </Button>
                        <Drawer className="drawerCl" anchor={'top'} open={state['top']} onClose={toggleDrawer('top', false)}>
                            <AppBar position="fixed">
                                <div className={classes.drawerHeader}>
                                    <IconButton className="btn-close" onClick={toggleDrawer('top', false)}>

                                    </IconButton>
                                </div>
                            </AppBar>
                            <div >
                                <div className="left-widgets widgets" style={{ marginTop: '52px' }}>
                                    {stream ?
                                        <SidebarFilter
                                            data={stream}
                                            icon={StreamIMG}
                                            type={'Stream'}
                                            handleSidebarFilter={(type, data) => handleSidebarFilter(type, data)}
                                        />
                                        : 'No Streams'}
                                </div>

                                <div className="left-widgets widgets">
                                    {ownerships ?
                                        <SidebarFilter
                                            data={ownerships}
                                            icon={StreamIMG}
                                            type={'Ownership'}
                                            handleSidebarFilter={(type, data) => handleSidebarFilter(type, data)}
                                        />
                                        : 'No Ownership'}
                                </div>

                                <div className="left-widgets widgets">
                                    {city ?
                                        <SidebarFilter
                                            data={city}
                                            icon={CityIMG}
                                            type={'City'}
                                            handleSidebarFilter={(type, data) => handleSidebarFilter(type, data)}
                                        />
                                        : 'No City'}
                                </div>

                                <div className="left-widgets widgets">
                                    {accreditations ?
                                        <SidebarFilter
                                            data={accreditations}
                                            icon={AccreditaionIMG}
                                            type={'Accreditations'}
                                            handleSidebarFilter={(type, data) => handleSidebarFilter(type, data)}
                                        />
                                        : 'No Accreditations'}
                                </div>

                                <div className="left-widgets widgets">
                                    {managments ?
                                        <SidebarFilter
                                            data={managments}
                                            icon={ManagementiconIMG}
                                            type={'Management'}
                                            handleSidebarFilter={(type, data) => handleSidebarFilter(type, data)}
                                        />
                                        : 'No Management'}
                                </div>
                            </div>
                        </Drawer>
                    </Hidden>







                    <div className="col-lg-3 col-md-3 sidebar-widgets d-none d-sm-block d-lg-block d-md-block" >
                        <div className="left-widgets widgets">
                            {stream ?
                                <SidebarFilter
                                    data={stream}
                                    icon={StreamIMG}
                                    type={'Stream'}
                                    handleSidebarFilter={(type, data) => handleSidebarFilter(type, data)}
                                />
                                : 'No Streams'}
                        </div>

                        <div className="left-widgets widgets">
                            {ownerships ?
                                <SidebarFilter
                                    data={ownerships}
                                    icon={StreamIMG}
                                    type={'Ownership'}
                                    handleSidebarFilter={(type, data) => handleSidebarFilter(type, data)}
                                />
                                : 'No Ownership'}
                        </div>

                        <div className="left-widgets widgets">
                            {city ?
                                <SidebarFilter
                                    data={city}
                                    icon={CityIMG}
                                    type={'City'}
                                    handleSidebarFilter={(type, data) => handleSidebarFilter(type, data)}
                                />
                                : 'No City'}
                        </div>

                        <div className="left-widgets widgets">
                            {accreditations ?
                                <SidebarFilter
                                    data={accreditations}
                                    icon={AccreditaionIMG}
                                    type={'Accreditations'}
                                    handleSidebarFilter={(type, data) => handleSidebarFilter(type, data)}
                                />
                                : 'No Accreditations'}
                        </div>

                        <div className="left-widgets widgets">
                            {managments ?
                                <SidebarFilter
                                    data={managments}
                                    icon={ManagementiconIMG}
                                    type={'Management'}
                                    handleSidebarFilter={(type, data) => handleSidebarFilter(type, data)}
                                />
                                : 'No Management'}
                        </div>
                    </div>

                    <div className="col-lg-9 col-md-9 top-colleges" >
                        {
                            collegedetail && collegedetail.map((i) => {
                                let collegelink = '';
                                let class_name = 'cursor_block';
                                if (i.admissions && i.admissions != '') {
                                    if (i.type == 'college') {
                                        class_name = '';
                                        collegelink = '/college/' + i.slug + '?admission=1';
                                    }
                                    if (i.type == 'university') {
                                        class_name = '';
                                        collegelink = '/university/' + i.slug + '?admission=1';
                                    }
                                }
                                else {
                                    if (i.type == 'college') {
                                        class_name = '';
                                        collegelink = '/college/' + i.slug;
                                    }
                                    if (i.type == 'university') {
                                        class_name = '';
                                        collegelink = '/university/' + i.slug;
                                    }
                                }

                                return (
                                    <div className="col-xl-4 col-lg-4 col-md-6">
                                        <div className="topcollege-box">
                                            <img src={i.logo ? config.baseUrlfromconfig + i.logo : CollegeIMG} className="full_wdth" alt={i.name} />
                                            <div className="college-content">
                                                <BootstrapTooltip placement="top" title={<span style={{ fontSize: "14px" }}>{i.name}</span>}>
                                                    <h3>{i.name}</h3>
                                                </BootstrapTooltip>
                                                {/* <div className="rating_box"><i className="bi bi-star-fill rating_act"></i> 4.8</div> */}
                                                <div className="clearfix"></div>
                                                {/* <p>{i.area.area_name},{i.city.city_name}
                                                    {i.collegeRecognition.map((element, index) => (
                                                        <>
                                                            {index == 0 ? ', Approved by '+element.recognitionname.recognition_approval_name : ', ' + element.recognitionname.recognition_approval_name}
                                                        </>
                                                    ))}
                                                    
                                                    </p>
                                                    <hr/>
                                                    <ul>
                                                        <li>Est Date: <b>{i.established}</b></li>
                                                        <li>Type: <b>{i.college_type}</b></li>
                                                    </ul> */}
                                                <div className="college_button_wrp">
                                                    <GlobalPopupEnquiry />
                                                    <a href={collegelink} className={class_name}>View Details</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {collegedetail.length < 1 ? (
                            <NotDataFoundView />
                        ) : ''}

                        <div className="spac_02 clearfix"></div>
                        {/* <div className="button-section text-center clearfix">
                                    {isFetching && <p>Loading more data...</p>}
                                    {!isFetching && HasMore && (
                                        <button className="button btn" 
                                        
                                        onClick={() => getcollegelist(page+1,collegedetail,ApplyFilters)}
                                        >Load more</button>
                                    )}
                                </div> */}
                        {totalpage > 1 ?
                            <>
                                <div className="button-section  text-center clearfix">
                                    <Pagination
                                        page={page}
                                        variant="outlined"
                                        shape="rounded"
                                        onChange={handlePageChange}
                                        count={totalpage}
                                        className="pagination"
                                    />
                                </div>
                                <div className="spac_02"></div>
                            </>
                            : ''}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OverviewSection;