import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import type { FC } from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik,useField,Field } from 'formik';
import PasswordStrengthBar from 'react-password-strength-bar';
import axios from 'src/utils/axios';
import { useTranslation,  } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  FormHelperText,
  TextField,
  makeStyles,
  IconButton,
  InputAdornment
} from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import {
  Visibility,
  VisibilityOff
} from '@material-ui/icons';

interface ResetScreenViewProps {
  className?: string;
  email?: any;
  token?: any;
}

const useStyles = makeStyles(() => ({
  
}));

const ResetScreenView: FC<ResetScreenViewProps> = ({ className,email,token, ...rest }) => {
  const { t, i18n } = useTranslation(['en-us', 'en-uk']);
  const classes = useStyles();
  const { resetpassword } = useAuth() as any;
  const isMountedRef = useIsMountedRef();
  const history = useHistory();
  const [pwToggle, setPwToggle] = useState(true);
  const [pwConfirmToggle, setPwConfirmToggle] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const togglePassword = (e) => {
    setPwToggle(!pwToggle);
  }

  const toggleConfirmPassword = (e) => {
    setPwConfirmToggle(!pwConfirmToggle);
  }
  
  const { loginuser } = useAuth() as any;
  return (
    <Formik
      initialValues={{
        password: '',
        passwordConfirmation: '',
        submit: null
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string().min(7).max(255).required('Password is required'),
        passwordConfirmation: Yup.string().min(7).max(255).oneOf([Yup.ref('password'), null], 'Passwords must match'),
      
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting
      }) => {

        let password= values.password;
        let confirm= values.passwordConfirmation;
       
     //const email = email;
   //   const code = token;
       // history.push("/login");
        
        try {
          const resp = await resetpassword(email,password,token,confirm);

          if (isMountedRef.current) {
          
          //  setStatus({ success: true });
           // setSubmitting(false);
         //   const getusername  = localStorage.getItem("getusername");
            try {
              await loginuser(email, password);
    
              if (isMountedRef.current) {
                enqueueSnackbar('Successfully loggedin.', {
                  variant: 'success',
                  persist: false
                });
    
               // localStorage.setItem("verficiationcode", '');
               // localStorage.setItem("forgetemail", '');
                
               // setStatus({ success: true });
               // setSubmitting(false);
              }
              
            } catch (err) {
              //console.error(err.data.error);
              if (isMountedRef.current) {
                setStatus({ success: false });

                if(err.errors)
          {
            setErrors({ submit: err.errors[0].msg });
          }
          else{
            setErrors({ submit: err.message });
          }
            //    setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }
          }
        } catch (err) {
          console.error(err);
         // setStatus({ success: false });
         if(err.errors)
         {
           setErrors({ submit: err.errors[0].msg });
         }
         else{
           setErrors({ submit: err.message });
         }
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form
          noValidate
          
          onSubmit={handleSubmit}
          {...rest}
        >
       <div className="form-group">
           <Field type="password" name="password"
           onChange={handleChange}
           onBlur={handleBlur}
           value={values.password}
           className="form-control" id="password" placeholder="Password"  />
         {errors.password && touched.password && <p className="error">{errors.password}</p>}
     </div>

     <div className="form-group">
           <Field type="password" name="passwordConfirmation"
           onChange={handleChange}
           onBlur={handleBlur}
           value={values.passwordConfirmation}
           className="form-control" id="passwordConfirmation" placeholder="Confirm Password"  />
         {errors.passwordConfirmation && touched.passwordConfirmation && <p className="error">{errors.passwordConfirmation}</p>}
     </div>
         
       
          {errors.submit && (
            <Box mt={2}>
              <FormHelperText error style={{fontSize:'16px'}}>
                {errors.submit}
              </FormHelperText>
            </Box>
          )}
          <Box mt={2}>
          <div className="text-center"><button type="submit">Submit</button></div>
          </Box>
          
        </form>
      )}
    </Formik>
  );
};

ResetScreenView.propTypes = {
  className: PropTypes.string
};

export default ResetScreenView;
