import React, { FC } from 'react';
import './styles.css';
import NewsBG from 'src/assets/img/new-News.jpg';

import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';

const Header: FC = () => {

    return (
        <section className="inner-top-header" id="hero" style={{ background: `url(${NewsBG})` }} >
            <div className="hero-container d-flex">
                <div className="container" >
                    <div className="row top-course-block">
                        <div className="top-course-header text-center">
                            <h1 className='d-inline-block px-3'>Study <span> Abroad</span> </h1>
                            <div className="spac_02"></div>
                            <div className='d-flex justify-content-center'>
                                <GlobalPopupEnquiry className="medical-button btn" />

                            </div>
                        </div>
                    </div>

                    {/* <div className="row top-course-block">
                        <div className="top-course-header">
                            <h1>Study <span>Abroad</span></h1>
                        </div>
                    </div>
                    <div className="spac_02"></div>
                    <div className="col-lg-12 button-section">
                        <GlobalPopupEnquiry className="medical-button btn" />

                    </div> */}
                </div>
            </div>
        </section>
    );
};

export default Header;