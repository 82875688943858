import React, { FC } from 'react';
import './styles.css';


const OverviewSection: FC<{ subcourse: any }> = ({ subcourse }) => {
    let streamname = subcourse.course ? subcourse.course.course_stream_name : null;

    return (
        <section className="course-overviews-section container-fluid  spac_01" >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 overviews-course">
                        <div className="row">

                            <div className="col-lg-12" >
                                <ul className="nav nav-tabs" id="myTab">
                                    <li className="nav-item"><a href="#overview" className="nav-link active" data-bs-toggle="tab">Basic Details</a></li>
                                    <li className="nav-item"><a href="#eligibility" className="nav-link" data-bs-toggle="tab">Eligibility</a></li>
                                    <li className="nav-item"><a href="#coursedetail" className="nav-link" data-bs-toggle="tab">Course Details</a></li>

                                    {
                                        subcourse.cousrsefees && subcourse.cousrsefees.length !== 0 && subcourse.cousrsefees[0].title !== null ?
                                            <li className="nav-item"><a href="#feestructure" className="nav-link" data-bs-toggle="tab">Fee Structure</a></li>
                                            : ''
                                    }
                                </ul>
                                <div className="tab-content left-overviews-section collegetabcontent">
                                    <div className="tab-pane fade show active" id="overview">
                                        {subcourse ?
                                            <>
                                                <div className='table-responsive'>
                                                    <table className="table style_table">
                                                        <tbody>
                                                            <tr>
                                                                <td><span className="bs-caption">Stream</span></td><td><span>{streamname}</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td><span className="bs-caption">Medium</span></td><td><span>{subcourse.medium ? subcourse.medium.medium : ""}</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td><span className="bs-caption">Duration</span></td><td><span>{subcourse.duration ? subcourse.duration : ""}</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td><span className="bs-caption">Modes</span></td>
                                                                <td>
                                                                    <>
                                                                        {
                                                                            subcourse.coursemodes ? subcourse.coursemodes.map((element, index) => {
                                                                                return (
                                                                                    <>
                                                                                        <span>{index == 0 ? ' ' + element.modess.mode : ', ' + element.modess.mode}</span>
                                                                                    </>
                                                                                )
                                                                            })
                                                                                : " "
                                                                        }
                                                                    </>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </>
                                            : ('')}
                                    </div>
                                    <div className="tab-pane fade" id="eligibility">
                                        {subcourse ?
                                            <>
                                                <ul>
                                                    {
                                                        subcourse.eligibilities ? subcourse.eligibilities.map((element, index) => {
                                                            return (
                                                                <>
                                                                    <li style={{ listStyle: "none" }}>{element.description}</li>
                                                                </>
                                                            )
                                                        }) : ""
                                                    }
                                                </ul>
                                                {
                                                    subcourse.courseexams && subcourse.courseexams.length > 0 ?
                                                        <>
                                                            <h5>Entrance Exams Accepted</h5>
                                                            <div className='d-flex flex-wrap'>
                                                                {
                                                                    subcourse.courseexams ? subcourse.courseexams.map((element, index) => {
                                                                        let examhref = '../../exams/' + element.exams.slug;
                                                                        return (
                                                                            <>
                                                                                <div className=' p-1 p-md-2'>
                                                                                <a href={examhref}  className="btn btn btn-primary">{element.exams.exam_title}</a>
                                                                                </div>
                                                                                
                                                                            </>
                                                                        )
                                                                    }) : ""
                                                                }
                                                            </div>
                                                        </>
                                                        : ('')
                                                }
                                            </>
                                            : ('')}
                                    </div>
                                    <div className="tab-pane fade" id="coursedetail">
                                        {subcourse ?
                                            <>
                                                <span dangerouslySetInnerHTML={{ __html: subcourse.course_details_structure }} />
                                            </>
                                            : ('')}
                                    </div>
                                    <div className="tab-pane fade" id="feestructure">
                                        <h5>Fee Structure</h5>
                                        {subcourse ?
                                            <>
                                                <>
                                                    {
                                                        subcourse.cousrsefees && subcourse.cousrsefees.map((element, index) => {
                                                            return (
                                                                <>
                                                                    <h6>{element.title} : {element.note}</h6>
                                                                    <>
                                                                        <div className='table-responsive'>
                                                                            <table className="table table-striped  style_table">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <th>Title</th>
                                                                                        <th>Amount</th>
                                                                                    </tr>
                                                                                    {
                                                                                        element && element.feedetail.map((fee, feeindex) => {
                                                                                            return (
                                                                                                <>

                                                                                                    <tr>
                                                                                                        <td>{fee.sub_title}</td>
                                                                                                        <td>{fee.amount}</td>
                                                                                                    </tr>

                                                                                                </>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </>
                                            </>
                                            : ('')}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </section>
    );
};

export default OverviewSection;