import React from 'react';
import type { FC } from 'react';
import { Helmet } from 'react-helmet';
// import ReactGA from "react-ga4";
// ReactGA.initialize("259269510");

const GA_MEASUREMENT_ID: string | undefined = process.env.REACT_APP_GA_MEASUREMENT_ID;

const GoogleAnalytics: FC = () => {

  // ReactGA.send({ hitType: "pageview", page: "/thank-you" });

  // // Send a custom event
  // ReactGA.event({
  //   category: "your category",
  //   action: "your action",
  //   label: "your label",
  //   value: 99,
  //   nonInteraction: true, 
  //   transport: "xhr", 
  // });
  return (
    <Helmet>

      {/* <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
      />
      <script>
        {`
          window.dataLayer = window.dataLayer || [];

          function gtag() {
            dataLayer.push(arguments);
          }

          gtag('js', new Date());
          gtag('config', '${GA_MEASUREMENT_ID}');
        `}
      </script> */}
      {/* <script async src="https://www.googletagmanager.com/gtag/js?id=UA-140595938-1"></script>
  <script>{`
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'UA-140595938-1');
    `}
  </script> */}

<script async src="https://www.googletagmanager.com/gtag/js?id=259269510"></script>
<script>{`
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag('js', new Date());

  gtag('config', '259269510');`}
</script>
       <script async src="https://www.googletagmanager.com/gtag/js?id=AW-990332405"></script>
    <script>
    {`
        window.dataLayer = window.dataLayer || [];

        function gtag() {
            dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', 'AW-990332405');
        `}
    </script>
    <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-MGDJ5RD');`}</script>
    </Helmet>
  );
};

export default GoogleAnalytics;
