import React, { FC } from 'react';
import './styles.css';
import AllCollegeAdmissionBG from 'src/assets/img/all-college-adminsion-bg.jpg';
//import EnquiryFormTalkToExpert from 'src/components/EnquiryFormTalkToExpert';
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';
import EnquiryDetailForm from 'src/components/EnquiryDetailForm';

const Talk: FC = () => {
    
    return (
        <section className="all-colleges-admission container-fluid spac_01 talksection" style={{ background: `#3e6eb0 url(${AllCollegeAdmissionBG})`, backgroundSize: "cover" }}  data-aos="fade-up">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 " data-aos="fade-up"> 
                        <h2>Get in Touch with us!</h2>
                        <p>Interested in our services?<br/> Or got some queries?<br/> Reach out to us now to take it forward.</p>
                        <p>&nbsp;</p>
                        <GlobalPopupEnquiry  className="talk-to-expert btn btn btn-primary floatleft"  title="Connect with us!" />
                    </div> 
                    <div className="col-lg-6 col-md-6 contact-form" data-aos="fade-up"> 
                        <h2>Talk to Our Expert</h2>
                        <EnquiryDetailForm type="admissions"/>
                        {/* <EnquiryFormTalkToExpert type={'admissions'}/> */}

                        {/*}  <form action="forms/contact.php" method="post" role="form" className="php-email-form">
                            <div className="form-group">
                                <input type="text" name="name" className="form-control" id="name" placeholder="Enter Name"  />
                            </div>
                            <div className="form-group">
                                <input type="email" className="form-control" name="email" id="email" placeholder="Enter Email"  />
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" name="phonenumber" id="phonenumber" placeholder="Phone Number"  />
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" name="collegename" id="collegename" placeholder="College Name"  />
                            </div>                     
                            <div className="text-right"><button type="submit">Talk to our Expert</button></div>
                        </form>
    */}
                    </div> 
                </div>
            </div>
        </section>
    );
};

export default Talk;