import React, { FC, useEffect, useState, useContext,useCallback } from 'react';
import SettingsContext from 'src/contexts/SettingsContext';
import axios from 'src/utils/axiosintercpt';
import { makeStyles } from '@material-ui/core';

import Breadcrumbs from './Breadcrumbs';
import Header from './InnerHeader';
import OverviewSection from './OverviewSection';
import Interested from './Interested';
import TopColleges from './TopColleges';

import { pConfig } from 'src/config';  
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useParams } from "react-router-dom";
import Helmet from 'react-helmet';
const useStyles = makeStyles(() => ({
  root: {}
}));

const StudyAbroadPage: FC = () => {
  const classes = useStyles();
  const setCtx = useContext(SettingsContext);
  const isMountedRef = useIsMountedRef();
  const [courseStreamArr, setCourseStreamArr] = useState([]);
  const { slug } = useParams();
  const [isHeroLoaded, setHeroLoaded] = useState(false);

  const getHeroData = async ()=>{
    const formData = new FormData();
    formData.append('getdata', JSON.stringify([{data: "course_streams_head"}]));
    const heroResp = await axios.post('api/website/home/header', formData);

    setCourseStreamArr(heroResp.data.data.course_streams_head);
    
    setCtx.setCourseData(heroResp.data.data.course_streams_head);
    setHeroLoaded(true);
  }

  const [pagedata, setpagedata] = useState([]);
  const [metatitle, setmetatitle] = useState<any>([]);
  const [metadescription, setmetadescription] = useState<any>([]);
  const [promoBannerArr, setPromoBannerArr] = useState<any>([]);
  const [abroadCountriesArr, setAbroadCountriesArr] = useState<any>([]);
  const [abroadUniversitiesArr, setAbroadUniversitiesArr] = useState<any>([]);

  const getpagedata = useCallback(async () => {    
    try {
      const response = await axios.get('api/website/page/get/studyabroad');
      if (isMountedRef.current) 
      {
        setpagedata(response.data.data);
        setmetatitle(response.data.data.meta_title);
        setmetadescription(response.data.data.meta_description);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  const getpromobanner = useCallback(async () => {
    try {
      const response = await axios.get('api/website/promobanner?promo_banner=Study_Abroad_page');
      if (isMountedRef.current) 
      {
        setPromoBannerArr(response.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  const getabroadcountries = useCallback(async () => {
    try {
      const response = await axios.get('api/website/home/abroadcountries');
      if (isMountedRef.current) 
      {
        setAbroadCountriesArr(response.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  const getabroaduniversities = useCallback(async () => {
    try {
      const response = await axios.get('api/website/home/abroaduniversities');
      if (isMountedRef.current) 
      {
        // const grouped = response.data.data.reduce((acc, item) => {
        //     // Append the item to the array for each country
        //     acc[item.country.country_name] = [...(acc[item.country.country_name] || []), item];
        //     //setAbroadUniversitiesArr(acc);
        //     return acc;
        // }, {})
        // console.log('-------')
        // console.log(grouped)
        // console.log('-------')
        // setAbroadUniversitiesArr(grouped);
        setAbroadUniversitiesArr(response.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  
  

  useEffect(()=>{
    getabroaduniversities();
    getHeroData();
    getpagedata();
    getpromobanner();
    getabroadcountries();
  }, []);

  return (
    <>
    {/* {isTopColLoaded ? 
      <> */}
        <Helmet>
          <title>{metatitle && metatitle != '' ? metatitle : 'Get Admission at Top Colleges in Bangalore'}</title>
          <meta name="description" content={metadescription && metadescription != '' ?  metadescription : 'Looking for College/ University/ School admission? Bangalorestudy.com helps you with a list of Colleges, Universities and Schools to pursue your education in Bangalore. Call us now'} />
          <link rel="canonical" href={`${pConfig.siteUrl}studyabroad`} />
        </Helmet>
        <Header/>
        <Breadcrumbs/>
        <OverviewSection pagedata={pagedata} promoBannerArr={promoBannerArr} abroadCountriesArr={abroadCountriesArr} abroadUniversitiesArr={abroadUniversitiesArr}/>
        <Interested/> 
        <TopColleges/>
      {/* </>
      :
      <p style={{height: '8000px'}}>Loading</p> } */}
    </>
  );
};

export default StudyAbroadPage;