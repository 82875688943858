import React, { FC,useEffect,useState,useCallback } from 'react';
import './stylesglobalpopup.css';

import useAuth from 'src/hooks/useAuth';
import { useSnackbar } from 'notistack';
import {pConfig} from 'src/config';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import {

  LoginSocialTwitter,
  IResolveParams
} from 'reactjs-social-login';
interface Props {
    className?: any;
    title?: any;
}   


const SocialTwitter: FC<Props> = ({ className,title,...rest }) => {
    const { sociallogin } = useAuth() as any;
    const { enqueueSnackbar } = useSnackbar();
    const isMountedRef = useIsMountedRef();
    
    const authHandler = (err, data) => {
   
        
      };    const [provider, setProvider] = useState('');
      const [profile, setProfile] = useState<any>();

      const TW_consumerKey= pConfig.TW_consumerKey;
      const TW_consumerSecret= pConfig.TW_consumerSecret;
      const TW_callbackUrl= pConfig.TW_callbackUrl;


      
      const [isLoggedIn, setIsLoggedIn] = useState(false);
      const [user, setUser] = useState({});

      const REDIRECT_URI = window.location.href;
    
      useEffect(() => {
   
    
      }, []);
      const onLoginStart = useCallback(() => {
       // alert('login start');
      }, []);
      const onLogoutSuccess = useCallback(() => {
        setProfile(null);
        setProvider('');
      //  alert('logout success');
      }, []);

    return (
 <>
 {/**
<TwitterLogin
      authCallback={authHandler}
      className={'twitter'}
      consumerKey={TW_consumerKey}
      consumerSecret={TW_consumerSecret}
      callbackUrl={TW_callbackUrl}
 
    ><i className="bi bi-twitter d"></i>
</TwitterLogin>/ */}

<LoginSocialTwitter

          client_id={TW_consumerKey}
        //  client_secret={TW_consumerSecret}
          redirect_uri={REDIRECT_URI}
          onLoginStart={onLoginStart}
         onLogoutSuccess={onLogoutSuccess}
          onResolve={({ provider, data }: IResolveParams) => {
            setProvider(provider);
            setProfile(data);
            
          }}
          onReject={(err: any) => {
            console.log(err);
          }}
        >
       <div className="twitter"> <i className="bi bi-twitter d"></i></div>
        </LoginSocialTwitter>

  </>
    );
};


export default SocialTwitter;