import React, { FC, useEffect, useState, useContext, useCallback } from 'react';
import SettingsContext from 'src/contexts/SettingsContext';
import axios from 'src/utils/axiosintercpt';
import { CircularProgress, makeStyles } from '@material-ui/core';

import Breadcrumbs from './Breadcrumbs';
import Header from './InnerHeader';
import OverviewSection from './OverviewSection';

import { pConfig } from 'src/config';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useParams,useHistory} from "react-router-dom";
import Helmet from 'react-helmet';

const useStyles = makeStyles(() => ({
    root: {}
}));


const NewsView: FC = () => {
    const history = useHistory();
    const classes = useStyles();
    const setCtx = useContext(SettingsContext);
    const isMountedRef = useIsMountedRef();
    const [courseStreamArr, setCourseStreamArr] = useState([]);
    const { slug } = useParams();
    const [isHeroLoaded, setHeroLoaded] = useState(false);
    const [viewDetails, setViewsDetails] = useState([]);
    const [newsListing, setNewsListing] = useState([]);
    const [metatitle, setmetatitle] = useState<any>([]);
    const [metadescription, setmetadescription] = useState<any>([]);
    const [isNewsLoaded, setNewsLoaded] = useState(false);

    const getHeroData = async () => {
        const formData = new FormData();
        formData.append('getdata', JSON.stringify([{ data: "course_streams_head" }]));
        const heroResp = await axios.post('api/website/home/header', formData);

        setCourseStreamArr(heroResp.data.data.course_streams_head);

        setCtx.setCourseData(heroResp.data.data.course_streams_head);
        setHeroLoaded(true);
    }

    const [blogdata, setgetblogdata] = useState([]);

    const getViewData = useCallback(async () => {
        try {
            const params = {}
            const id = slug;//'engineering';
            const response = await axios.get('api/website/home/news/get/' + id);
            if (isMountedRef.current) {
                setViewsDetails(response.data.data);
                setmetatitle(response.data.data.meta_title)
                setmetadescription(response.data.data.meta_description)
                setNewsLoaded(true);
            }
        } catch (err) {
            history.push("/404");
            // console.error(err);
        }
    }, [isMountedRef]);

    const getNewsEventData = useCallback(async () => {
        try {
            const paramss = {}
            paramss['page'] = 1;
            //paramss['news_type'] = 'general_news'; //general_news
            paramss['size'] = '12';
            paramss['orderby'] = 'desc';
            paramss['columnname'] = 'created_at';
            const response1 = await axios.get('api/website/home/news', { params: paramss });
            if (isMountedRef.current) {
                setNewsListing(response1.data.data);

            }
        } catch (err) {
            // console.error(err);
        }
    }, [isMountedRef]);

    useEffect(() => {
        getHeroData();
        getViewData();
        getNewsEventData();
    }, []);

    return (
        <>
            {isNewsLoaded ?
                <>
                    <Helmet>
                        <title>{metatitle && metatitle != '' ? metatitle : 'Get Admission at Top Colleges in Bangalore'}</title>
                        <meta name="description" content={metadescription && metadescription != '' ? metadescription : 'Looking for College/ University/ School admission? Bangalorestudy.com helps you with a list of Colleges, Universities and Schools to pursue your education in Bangalore. Call us now'} />
                        <link rel="canonical" href={`${pConfig.siteUrl}news-and-event/${slug}`} />
                    </Helmet>
                    <Header viewDetails={viewDetails} />
                    <Breadcrumbs viewDetails={viewDetails} />
                    <OverviewSection viewDetails={viewDetails} newsListing={newsListing} />
                </>
                :
                <div style={{ display: 'flex', justifyContent: 'center', height: '600px', flexWrap: 'wrap', alignContent: 'space-around' }}><CircularProgress /></div>
            }
        </>
    );
};

export default NewsView;