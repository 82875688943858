import React, { FC, useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSnackbar } from 'notistack';
import axios from 'src/utils/axiosintercpt';
import { useHistory } from 'react-router-dom';




interface Props {
    className?: any;
    title?: any;
    pagename?: any;

}



const GlobalPopupEnquiry: FC<Props> = ({ className, title, pagename, ...rest }) => {



    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);

    const handleClose = () => setShow(false);


    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

  

    const [values, setValues] = useState({
        name: '', email: '', contact: '', class: '', gender: '', message: ''
    });

    const set = name => {
        return ({ target: { value } }) => {
            setValues(oldValues => ({ ...oldValues, [name]: value }));
        }
    };

    const onSubmit = async (event: { preventDefault: () => void; }) => {


        event.preventDefault();

        const formData = new FormData();
        formData.append('name', values.name);
        formData.append('email', values.email);
        formData.append('contact', values.contact);
        formData.append('class', values.class);
        formData.append('gender', values.gender);
        formData.append('description', values.message);
        formData.append('current_url', window.location.href);
        formData.append('sourse', 'NHIS School PPC');



        // return
        try {
            const response = await axios.post('api/website/home/horizonschoolenquiry', formData);

            if (response.data.status) {

                history.push('/thank-you');
            }
        } catch (err) {
            console.error(err);

            enqueueSnackbar('Please try again', {
                variant: 'error',
            });
        }


    }





    return (

        <>
 
            <a onClick={() => setShow(true)} className={className ? className : 'active_bt'} style={{ cursor: 'pointer' }}>
                {title ? title : 'Apply Now'}
            </a>



            <Modal show={show} onHide={handleClose}>
                <Modal.Body>
                    <div className="heading-popup ">
                        <h4 className='text-center'>Fill in the New Horizon International
                            <br /> School Admissions Form 2025-26.
                        </h4>
                    </div>
                    <form
                        onSubmit={onSubmit}>
                        <fieldset>
                            <input
                                type="text"
                                id="name1"
                                name="name"
                                placeholder="Student Name"
                                required
                                value={values.name} onChange={set('name')}
                            />
                        </fieldset>
                        <small className="text-danger " />
                        <fieldset className="form-group group_field">
                            <input
                                name="contact"
                                type="number"
                                id="contact1"
                                // min={0}
                                required
                                placeholder="Parent Contact No"
                                // aria-required="true"
                                value={values.contact} onChange={set('contact')}
                            />
                        </fieldset>
                        <small className="text-danger " />
                        <fieldset className="form-group group_field">
                            <input
                                type="email"
                                id="email1"
                                name="email"
                                placeholder="Parent Email ID"
                                required
                                value={values.email} onChange={set('email')}
                            />
                        </fieldset>
                        <small className="text-danger " />
                        <fieldset className="form-group group_field">
                            <small className="text-danger " />
                            <fieldset className="form-group group_field">
                                <select
                                    name="gender"
                                    style={{ height: 50, padding: "0px 22px" }}
                                    id="gender"
                                    required
                                    value={values.gender}
                                    onChange={set('gender')}
                                >
                                    <option value="" label="Select Gender" />
                                    <option value="male" label="Male" />
                                    <option value="female" label="Female" />
                                    {/* <option value="other" label="Other" /> */}
                                </select>

                                <select
                                    name="class"
                                    id="class"
                                    // value={specializations}
                                    value={values.class} onChange={set('class')}
                                    required
                                >
                                    <option value="" label="Select Grade" />
                                    <option value="Nursery">Nursery</option>
                                    <option value="LKG">LKG</option>
                                    <option value="UKG">UKG</option>
                                    <option value="Grade 1">Grade 1</option>
                                    <option value="Grade 2">Grade 2</option>
                                    <option value="Grade 3">Grade 3</option>
                                    <option value="Grade 4">Grade 4</option>
                                    <option value="Grade 5">Grade 5</option>
                                    <option value="Grade 6">Grade 6</option>
                                    <option value="Grade 7">Grade 7</option>
                                    <option value="Grade 8">Grade 8</option>
                                    <option value="Grade 9">Grade 9</option>
                                    <option value="Grade 10">Grade 10</option>
                                </select>





                            </fieldset>
                            <fieldset className="form-group group_field">
                                <input
                                    type="textarea"
                                    id="message"
                                    name="message"
                                    placeholder="Message: (Optional)"
                            

                                    value={values.message} onChange={set('message')}
                                />
                            </fieldset>

                            <fieldset className="form-group ">
                                <button type="submit" className='btn btn-primary'>Submit</button>
                            </fieldset>
                        </fieldset>
                    </form>

                </Modal.Body>
            </Modal>


        </>

    );

};
export default GlobalPopupEnquiry;