import React, { FC } from 'react';
import './styles.css';



const Breadcrumbs: FC < {subcourse:any, collegeslug:any}> = ({subcourse, collegeslug})=> {
    let collegename = subcourse.college  ? subcourse.college.name : null;
    let coursesname = subcourse.course  ? subcourse.course.course_stream_name : null;
    return (
        <div className="breadcrumbs">	
            <div className="container" >
                <ol>
                    <li><a href="/" className="active blue-color">Home</a> </li>
                    <li><a href="/colleges">Colleges</a></li>
                    <li><a href={`../../../college/${collegeslug}`}>{collegename}</a></li>
                    <li>{coursesname}</li>
                </ol>
            </div>
	    </div>
    );
};

export default Breadcrumbs;