import React, { FC } from 'react';
import './styles.css';
import NewsBG from 'src/assets/img/new-News.jpg';
import EMIBG from 'src/assets/img/Emi-Banner.jpg';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';
import Carousel from 'react-bootstrap/Carousel';

const SearchHeader: FC<{ schlorshiplist: any }> = ({ schlorshiplist }) => {

    const [num, setNum] = React.useState(331231);

    const owlOptions = {
        margin: 20, nav: true, autoplay: false,
        responsiveClass: true,
        responsive: {
            0: { items: 1 },
            400: { items: 1 },
            600: { items: 1 },
            700: { items: 1 }
        },
    };

    const bannerArr = [
        { 'image': NewsBG },
        { 'image': EMIBG },
    ];

    return (
        <>
            <div className="crslCont" >
                <Carousel controls={false}>
                    {
                        bannerArr.map((el, idx) => (
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={el.image}
                                    alt="First slide"
                                />
                            </Carousel.Item>
                        ))
                    }
                </Carousel>

                <div className="maskSection inner-top-header" id="hero">
                    <div className="container-fluid">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 our_portal">
                                    <div className="row no-gutters">
                                        <div className="col-lg-3"></div>
                                        <div className="header-search-box blogsearchbox col-lg-6" >
                                            <div className="top-course-header text-center">
                                                <h1>Scholarships <span>in India</span> </h1>
                                            </div>
                                        </div>
                                        <div className="col-lg-3"></div>
                                    </div>
                                    <OwlCarousel {...owlOptions} className="topTrendingCourses heder-carsoual loop_nav owl-carousel owl-theme mt-30">
                                        <div className="item">
                                            {/*    <h4>Lorem Ipsum is simply dummy text</h4> */}
                                            <GlobalPopupEnquiry className="apply-now-btn" />
                                        </div>
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
};

export default SearchHeader;