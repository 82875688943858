import React, { FC, useEffect, useState, useContext,useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import Tooltip, {TooltipProps} from '@material-ui/core/Tooltip';
import './styles.css';
// import GetMoreDetails from 'src/assets/img/get-more-details.jpg';
// import EducationCap from 'src/assets/img/education-cap.png';
// import HeadingBg from 'src/assets/img/heading-bg.png';
// import TopManagementCourseBG from 'src/assets/img/top-managment-course.jpg';
// import InterestedinCourseBG from 'src/assets/img/interested-in-course-bg.jpg';
import CollegeIMG from 'src/assets/img/college.jpg';
import StreamIMG from 'src/assets/img/stream.jpg';
import CityIMG from 'src/assets/img/city.jpg';
import AccreditaionIMG from 'src/assets/img/accreditaion.jpg';
import ManagementiconIMG from 'src/assets/img/management-icon.jpg';
import NotDataFoundView from 'src/views/errors/NotDataFoundView';
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';

import SidebarFilter from 'src/components/SidebarFilter';

import {

    Button,
    SvgIcon,
    IconButton,
    Hidden
} from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import {
    //Image as ImageIcon,
    //Edit as EditIcon,
    //ArrowRight as ArrowRightIcon,
    Search as SearchIcon,
    //Filter as FilterIcon
} from 'react-feather';
import { config } from 'src/config';

const useStylesBootstrap = makeStyles(() => ({
    arrow: {
        color: '#000',
    },
    tooltip: {
        backgroundColor: '#000',
    },drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
      
}));

function BootstrapTooltip(props: TooltipProps) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}
type Anchor = 'top' | 'left' | 'bottom' | 'right';

const Breadcrumbs: FC < {page:any,collegedetail:any,isFetching,HasMore,getcollegelist,searchByFilters,managments,stream,ownerships,city,accreditations, totalItems}> = ({page,collegedetail,isFetching,HasMore,getcollegelist,searchByFilters,managments,stream,ownerships,city,accreditations, totalItems})=> {
    //const [num, setNum] = React.useState(331231);
    const classes = useStylesBootstrap();
    //const [showStreamSearch, setStreamSearch] = React.useState(false);
    const [streamsselected, setStreamsselected] = useState<any>([]);
    const [ownershipselected, setOwnershipselected] = useState<any>([]);
    const [cityselected, setCityselected] = useState<any>([]);
    const [mgselected, setMgselected] = useState<any>([]);
    const [accreditationsselected, setAccreditationsselected] = useState<any>([]);
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
      });

      const toggleDrawer = (anchor: Anchor, open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
      ) => {
        if (
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }
    
        setState({ ...state, [anchor]: open });
      };
    const [ApplyFilters, setApplyFilters] = useState<any>([]);
        const handleSidebarFilter = async (type, data) => {

        if(type=='Stream')
            setStreamsselected(data);

        if(type=='Ownership')
            setOwnershipselected(data);

        if(type=='City')
            setCityselected(data);

        if(type=='Accreditations')
            setAccreditationsselected(data);

        if(type=='Management')
            setMgselected(data);
    };

    const updateappliedFilters = async () => {
        let appliedFilters = {
            'stream_id': streamsselected?streamsselected:[],
            'college_type': ownershipselected?ownershipselected:[],
            'city_id': cityselected?cityselected:[],
            'accreditation_id': accreditationsselected?accreditationsselected:[],
            'management_id': mgselected?mgselected:[],
        }
        setApplyFilters(appliedFilters);
        searchByFilters(appliedFilters); 
    }

    useEffect(()=>{
        updateappliedFilters();
     
        }, [streamsselected,ownershipselected,cityselected,accreditationsselected,mgselected]);
  
    return (
        <section className="course-overviews-section container-fluid  spac_01" >
            <div className="container">
                <div className="">
                    <div className="overviews-course">
                        <div className="row">
                            <h3 className="main-heading text-left">Top <b>Universities</b> in Karnataka</h3>
                            <div className="spac_02"></div>
                            <Hidden lgUp mdUp smUp>   

<Button onClick={toggleDrawer('top', true)}>
<SvgIcon
             fontSize="small"
             color="action"
           >
             <SearchIcon />
           </SvgIcon>  {'Filter'}
</Button>           
<Drawer className="drawerCl"  anchor={'top'} open={state['top']} onClose={toggleDrawer('top', false)}>
<AppBar position="fixed">
<div className={classes.drawerHeader}>
   <IconButton className="btn-close" onClick={toggleDrawer('top', false)}>
     
   </IconButton>
 </div>
 </AppBar>
 <div >
                                <div className="left-widgets widgets" style={{marginTop:'52px'}}>
                                    
                                    { stream ?  
                                        <SidebarFilter 
                                            data={stream} 
                                            icon={StreamIMG} 
                                            type={'Stream'} 
                                            handleSidebarFilter={(type, data) => handleSidebarFilter(type,data)}
                                            />
                                            : 'No Streams' 
                                    }

                                    {/* <div className="heading-title">
                                        <img src={StreamIMG} className="small-icon" /> Stream
                                        <i id='toggle-search' className='bx bx-search'></i>
                                        <input className='displaynone' id='searchBar' name='search' type='search' placeholder='Search&hellip;' />
                                    </div>
                                    <div className="widget-scroll-box">
                                        <ul>
                                        {
                                            stream && stream.map((i) => {
                                                return (
                                                    <li><a href="" title={i.title}>{i.group}</a></li>
                                                )
                                            })
                                        }
                                        </ul>
                                    </div> */}
                                </div>

                                <div className="left-widgets widgets">

                                    { 
                                        ownerships ?  
                                            <SidebarFilter 
                                            data={ownerships} 
                                            icon={StreamIMG} 
                                            type={'Ownership'} 
                                            handleSidebarFilter={(type, data) => handleSidebarFilter(type,data)}
                                            />
                                        : 'No Ownership' 
                                    }

                                    {/* <div className="heading-title">
                                        <img src={StreamIMG} className="small-icon" /> Ownership
                                        <i id='toggle-search' className='bx bx-search'></i>
                                        <input className='displaynone' id='searchBar1' name='search' type='search' placeholder='Search&hellip;' />
                                    </div>
                                    <div className="widget-scroll-box">
                                        <ul>
                                        {
                                            ownerships && ownerships.map((i) => {
                                                return (
                                                    <li><a href="">{i}</a></li>
                                                )
                                            })
                                        }
                                        </ul>
                                    </div> */}
                                </div>

                                <div className="left-widgets widgets">
                                    { 
                                        city ?  
                                            <SidebarFilter 
                                            data={city} 
                                            icon={CityIMG} 
                                            type={'City'} 
                                            handleSidebarFilter={(type, data) => handleSidebarFilter(type,data)}
                                            />
                                        : 'No City' 
                                    }
                                    {/* <div className="heading-title">
                                        <img src={CityIMG} className="small-icon" /> City
                                        <i id='toggle-search' className='bx bx-search'></i>
                                        <input className='displaynone' id='searchBar1' name='search' type='search' placeholder='Search&hellip;' />
                                    </div>
                                    <div className="widget-scroll-box">
                                        <ul>
                                        {
                                            city && city.map((i) => {
                                                return (
                                                    <li><a href="">{i.city_name}</a></li>
                                                )
                                            })
                                        }
                                        </ul>
                                    </div> */}
                                </div>
                                
                                <div className="left-widgets widgets">
                                    { 
                                        accreditations ?  
                                            <SidebarFilter 
                                                data={accreditations} 
                                                icon={AccreditaionIMG} 
                                                type={'Accreditations'} 
                                                handleSidebarFilter={(type, data) => handleSidebarFilter(type,data)}
                                                />
                                        : 'No Accreditations' 
                                    }
                                    {/* <div className="heading-title">
                                        <img src={AccreditaionIMG} className="small-icon" /> Accreditations
                                        <i id='toggle-search' className='bx bx-search'></i>
                                        <input className='displaynone' id='searchBar1' name='search' type='search' placeholder='Search&hellip;' />
                                    </div>
                                    <div className="widget-scroll-box">
                                        <ul>
                                        {
                                            accreditations && accreditations.map((i) => {
                                                return (
                                                    <li><a href="">{i.accreditation_name}</a></li>
                                                )
                                            })
                                        }
                                        </ul>
                                    </div> */}
                                </div>

                                <div className="left-widgets widgets">
                                    { 
                                        managments ?  
                                            <SidebarFilter 
                                            data={managments} 
                                            icon={ManagementiconIMG} 
                                            type={'Management'} 
                                            handleSidebarFilter={(type, data) => handleSidebarFilter(type,data)}
                                            />
                                        : 'No Management' 
                                    }
                                    {/* <div className="heading-title">
                                        <img src={ManagementiconIMG} className="small-icon" /> Management
                                        <i id='toggle-search' className='bx bx-search'></i>
                                        <input className='displaynone' id='searchBar1' name='search' type='search' placeholder='Search&hellip;' />
                                    </div>
                                    <div className="widget-scroll-box">
                                        <ul>
                                        {
                                            managments && managments.map((i) => {
                                                return (
                                                    <li><a href="">{i.management_name}</a></li>
                                                )
                                            })
                                        }
                                        </ul>
                                    </div> */}
                                </div>
                            </div>

                           
                           
                            </Drawer>
          </Hidden> 







          <div className="col-lg-3 col-md-3 sidebar-widgets d-none d-sm-block d-lg-block d-md-block" >
                                <div className="left-widgets widgets">
                                    
                                    { stream ?  
                                        <SidebarFilter 
                                            data={stream} 
                                            icon={StreamIMG} 
                                            type={'Stream'} 
                                            handleSidebarFilter={(type, data) => handleSidebarFilter(type,data)}
                                            />
                                            : 'No Streams' 
                                    }

                                    {/* <div className="heading-title">
                                        <img src={StreamIMG} className="small-icon" /> Stream
                                        <i id='toggle-search' className='bx bx-search'></i>
                                        <input className='displaynone' id='searchBar' name='search' type='search' placeholder='Search&hellip;' />
                                    </div>
                                    <div className="widget-scroll-box">
                                        <ul>
                                        {
                                            stream && stream.map((i) => {
                                                return (
                                                    <li><a href="" title={i.title}>{i.group}</a></li>
                                                )
                                            })
                                        }
                                        </ul>
                                    </div> */}
                                </div>

                                <div className="left-widgets widgets">

                                    { 
                                        ownerships ?  
                                            <SidebarFilter 
                                            data={ownerships} 
                                            icon={StreamIMG} 
                                            type={'Ownership'} 
                                            handleSidebarFilter={(type, data) => handleSidebarFilter(type,data)}
                                            />
                                        : 'No Ownership' 
                                    }

                                    {/* <div className="heading-title">
                                        <img src={StreamIMG} className="small-icon" /> Ownership
                                        <i id='toggle-search' className='bx bx-search'></i>
                                        <input className='displaynone' id='searchBar1' name='search' type='search' placeholder='Search&hellip;' />
                                    </div>
                                    <div className="widget-scroll-box">
                                        <ul>
                                        {
                                            ownerships && ownerships.map((i) => {
                                                return (
                                                    <li><a href="">{i}</a></li>
                                                )
                                            })
                                        }
                                        </ul>
                                    </div> */}
                                </div>

                                <div className="left-widgets widgets">
                                    { 
                                        city ?  
                                            <SidebarFilter 
                                            data={city} 
                                            icon={CityIMG} 
                                            type={'City'} 
                                            handleSidebarFilter={(type, data) => handleSidebarFilter(type,data)}
                                            />
                                        : 'No City' 
                                    }
                                    {/* <div className="heading-title">
                                        <img src={CityIMG} className="small-icon" /> City
                                        <i id='toggle-search' className='bx bx-search'></i>
                                        <input className='displaynone' id='searchBar1' name='search' type='search' placeholder='Search&hellip;' />
                                    </div>
                                    <div className="widget-scroll-box">
                                        <ul>
                                        {
                                            city && city.map((i) => {
                                                return (
                                                    <li><a href="">{i.city_name}</a></li>
                                                )
                                            })
                                        }
                                        </ul>
                                    </div> */}
                                </div>
                                
                                <div className="left-widgets widgets">
                                    { 
                                        accreditations ?  
                                            <SidebarFilter 
                                                data={accreditations} 
                                                icon={AccreditaionIMG} 
                                                type={'Accreditations'} 
                                                handleSidebarFilter={(type, data) => handleSidebarFilter(type,data)}
                                                />
                                        : 'No Accreditations' 
                                    }
                                    {/* <div className="heading-title">
                                        <img src={AccreditaionIMG} className="small-icon" /> Accreditations
                                        <i id='toggle-search' className='bx bx-search'></i>
                                        <input className='displaynone' id='searchBar1' name='search' type='search' placeholder='Search&hellip;' />
                                    </div>
                                    <div className="widget-scroll-box">
                                        <ul>
                                        {
                                            accreditations && accreditations.map((i) => {
                                                return (
                                                    <li><a href="">{i.accreditation_name}</a></li>
                                                )
                                            })
                                        }
                                        </ul>
                                    </div> */}
                                </div>

                                <div className="left-widgets widgets">
                                    { 
                                        managments ?  
                                            <SidebarFilter 
                                            data={managments} 
                                            icon={ManagementiconIMG} 
                                            type={'Management'} 
                                            handleSidebarFilter={(type, data) => handleSidebarFilter(type,data)}
                                            />
                                        : 'No Management' 
                                    }
                                    {/* <div className="heading-title">
                                        <img src={ManagementiconIMG} className="small-icon" /> Management
                                        <i id='toggle-search' className='bx bx-search'></i>
                                        <input className='displaynone' id='searchBar1' name='search' type='search' placeholder='Search&hellip;' />
                                    </div>
                                    <div className="widget-scroll-box">
                                        <ul>
                                        {
                                            managments && managments.map((i) => {
                                                return (
                                                    <li><a href="">{i.management_name}</a></li>
                                                )
                                            })
                                        }
                                        </ul>
                                    </div> */}
                                </div>
                            </div>

                            <div className="col-lg-9 col-md-9 top-colleges" >
                                {
                                    collegedetail && collegedetail.map((i) => {
                                        let collegelink = '/university/'+i.slug;
                                        let finals='';
                                        let n=0;

                                        i.collegeRecognition.map((a) => {
                                          n++;
                                            let tesxp=a.recognitionname.recognition_approval_name;
                                            var matches = tesxp.match(/\((.*?)\)/);
                                            let submatch=tesxp;

                                            if (matches) {
                                                 submatch = matches[1];
                                            }
                                            if(n==1)
                                            finals +=submatch;
                                            else
                                            finals +=","+submatch;

                                          });



                                        return (
                                            <div className="col-xl-4 col-lg-4 col-md-6">
                                            <div className="topcollege-box">
                                                <img src={ i.logo ? config.baseUrlfromconfig + i.logo : CollegeIMG} className="full_wdth" alt={i.name} />
                                                <div className="college-content">
                                                    <BootstrapTooltip placement="top" title={<span style={{ fontSize: "14px" }}>{i.name}</span>}>
                                                       <h3>{i.name}</h3>
                                                    </BootstrapTooltip>
                                                    {i.avg_rating ? <div className="rating_box"><i className="bi bi-star-fill rating_act"></i> {i.avg_rating}</div> : '' }
                                                    {/* <div className="rating_box"><i className="bi bi-star-fill rating_act"></i> 4.8</div> */}
                                                    
                                                    <p> {/* {i.area.area_name}, */}
                                                    {i.city ? i.city.city_name : ''}
                                                 {/*   {i.collegeRecognition.map((element, index) => (
                                                        <>
                                                            {index == 0 ? ', Approved by '+element.recognitionname.recognition_approval_name : ', ' + element.recognitionname.recognition_approval_name}
                                                        </>
                                                    ))} */}
                                                     {finals =! '' ? ', Approved by ' +finals:''}
                                                       
                                                    </p>
                                                    <div className="clearfix"></div>
                                                    <hr/>
                                                    <div><b>University Details</b></div>
                                                    <ul>
                                                        <li>Est. Year: <b>{i.established}</b></li>
                                                        <li>Ownership: <b>{i.college_type}</b></li>
                                                    </ul>
                                                    <div className="college_button_wrp">
                                                    <GlobalPopupEnquiry />
                                                     <a href={collegelink}>View Details</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        )
                                    })
                                }

                                {collegedetail.length<1? (
                                       <NotDataFoundView/>
                                ):''}

                                {totalItems > 12 ? (
                                    <>
                                        <div className="spac_02"></div>
                                        <div className="button-section text-center">
                                            {isFetching && <p>Loading more data...</p>}
                                            {!isFetching && HasMore && (
                                                <button className="button btn" 
                                                
                                                onClick={() => getcollegelist(page+1,collegedetail,ApplyFilters)}
                                                >Load more</button>
                                            )}
                                        </div>
                                        <div className="spac_02"></div>
                                    </>
                                ):''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Breadcrumbs;