import React, { FC } from 'react';
import './styles.css';

const Breadcrumbs: FC<{ blogdata: any }> = ({ blogdata }) => {
    
    return (
        <div className="breadcrumbs">	
            <div className="container" >
            <ol>
                <li><a href="/" className="active blue-color">Home</a> </li>
                <li><a href="/blog" className="active blue-color">Blog</a> </li>
                <li><a href="">{blogdata.title}</a></li>
            </ol>
            </div>
	    </div>
    );
};

export default Breadcrumbs;