import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Defaultlogo from 'src/assets/assets/img/logo1.png';


const NavbarLandingPage = (props) => {

    const navigate = (e, id) => {
        e && e.preventDefault(); // to avoid the link from redirecting
        const elementToView = document.getElementById(id);
        elementToView.scrollIntoView();
      }

    const { logo, navLinks } = props;

    // const navLinks = [
    //     { label: 'Home', id: 'home' },
    //     { label: 'About MRADC', id:'about' },
    //     { label: 'Highlights & Infra', id:'Highlights' },
    //     { label: 'BDS @ MRADC', id:'BDS' },
    //     { label: 'Admissions', id:'admission' },
    //     { label: 'Why Us', id:'whyus' },
    //     { label: 'FAQs', id:'FAQs' },
    //     { label: 'Contact Us' , id:'Contactus'},
    
    // ];
    return (
        <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary" bg="light" fixed="top" variant="light">
            <Container fluid>
                <Navbar.Brand className="hov m-2 " >
                    <img
                        src={props.logo ? props.logo : Defaultlogo } 
                        width="172"
                        height="57"
                        className="d-inline-block align-top hov"
                        alt="BAMS Admission in Karnataka 2023-24"
                    /></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav my-3">
                    <Nav style={{ marginLeft: "auto" }}>
                        {navLinks.map((link, index) => (
                            <Nav.Link key={index} href="" onClick={(e => navigate(e, link.id))} >
                                {link.label}
                            </Nav.Link>
                        ))}

                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavbarLandingPage;
