import React, { FC, useEffect, useState } from 'react';
import './styles.css';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';
import CollegeIMG from 'src/assets/img/college.jpg';
import { config } from 'src/config';
import NHGEnquiryForm from 'src/components/NHGEnquiryForm/GlobalPopupEnquiry';
const TopColleges: FC<{ topschoolArr: any }> = ({ topschoolArr }) => {
    const [gurukul, setGurukul] = useState(false);

    const linksToCheck = [
        'new-horizon-gurukul-school-bangalore',
        'new-horizon-vidya-mandir-nhvm-bangalore',
        'new-horizon-public-school-nhps-bangalore',
        'new-horizon-international-school-nhis-bangalore',

    ];


    const getcheck = () => {
        try {
            topschoolArr.map((el, idx) => {

                const currentURL = el.school_slug;
                const isGurukul = linksToCheck.some(link => currentURL.includes(link));

                setGurukul(isGurukul);
            }
            )


        } catch (error) {
            console.error('Error in URL checking:', error);
        }

    };

    useEffect(() => {
        getcheck();

    }, []);
    const owlOptions = {
        margin: 20, nav: true, autoplay: false,
        responsiveClass: true,
        responsive: {
            0: { items: 1, dots: false, nav: true },
            400: { items: 1, dots: false, nav: true },
            600: { items: 1, dots: false, nav: true },
            700: { items: 2, dots: false, nav: true },
            1000: {
                items: 3,
                nav: true,
                loop: false
            },
            1440: {
                items: 4,
                nav: true,
                loop: false
            }
        },
    };

    return (


        <section className="container-fluid spac_01 topColgs schoollist" >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 our_portal exam_section_slider examdesignslider">
                        <h3 className="common_sec_h">Top Schools</h3>
                        <OwlCarousel {...owlOptions} className="loop_colleges loop_colleges_0 loop_nav owl-carousel owl-theme">
                            {topschoolArr.map((el, idx) => (

                                <div className="item" key={`colgs${idx}`}>
                                    <div className="testi_wrp">
                                        <div className="testi_hover"></div>
                                        <div className="inner_wrp">
                                            <div className="test_content_wrp col-lg-12 wrapcontent">
                                                {el.avg_rating ? <div className="rating_box"><i className="bi bi-star-fill rating_act"></i> {el.avg_rating}</div> : ''}
                                                {/* <div className="rating_box"><i className="bi bi-star-fill rating_act"></i> 4.8</div> */}
                                                <img alt={el.school_name} src={el.school_logo ? config.baseUrlfromconfig + el.school_logo : CollegeIMG} className="full_wdth" />
                                                <h3>{el.school_name}</h3>
                                                {/* <p>Lorem ipsum dolor sit amet, conseaunt ut labore et...</p> */}
                                                <div className="college_button_wrp">
                                                    {/* <GlobalPopupEnquiry /> */}
                                                    {
                                                        linksToCheck.some(link => el.school_slug.includes(link)) ? <NHGEnquiryForm /> : <GlobalPopupEnquiry />
                                                    }

                                                    {/* {isGurukul ? <NHGEnquiryForm  />: <GlobalPopupEnquiry />} */}






                                                    <a href={`../school/${el.school_slug}`}>View Details</a>
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </OwlCarousel>
                    </div>
                </div>
                <div className="button-section text-center">
                    <a href={'/school'} className="button btn">View All</a>
                </div>
            </div>
        </section>
    );
};

export default TopColleges;