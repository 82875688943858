import React, { FC, useEffect, useState, useContext,useCallback } from 'react';
import SettingsContext from 'src/contexts/SettingsContext';
import axios from 'src/utils/axiosintercpt';
import { makeStyles } from '@material-ui/core';
import { useParams } from "react-router-dom";
import Breadcrumbs from './Breadcrumbs';
import Header from './InnerHeader';
import OverviewSection from './OverviewSection';
import { pConfig } from 'src/config';  
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import Helmet from 'react-helmet';
const useStyles = makeStyles(() => ({
  root: {}
})); 

const ExamList: FC = () => {
  const classes = useStyles();
  const setCtx = useContext(SettingsContext);
  const isMountedRef = useIsMountedRef();
  const [courseStreamArr, setCourseStreamArr] = useState([]);
  const [isBlogLoaded, setBlogLoaded] = useState(false);
  const { slug } = useParams();
  const [isHeroLoaded, setHeroLoaded] = useState(false);
  const [metatitle, setmetatitle] = useState<any>([]);
  const [metadescription, setmetadescription] = useState<any>([]);
  const [promoBannerArr, setPromoBannerArr] = useState<any>([]);

  const getHeroData = async ()=>{
    const formData = new FormData();
    formData.append('getdata', JSON.stringify([{data: "course_streams_head"}]));
    const heroResp = await axios.post('api/website/home/header', formData);

    setCourseStreamArr(heroResp.data.data.course_streams_head);
    
    setCtx.setCourseData(heroResp.data.data.course_streams_head);
    setHeroLoaded(true);
  }

  const [examlist, setexamdetail] = useState([]);
  const [upcomingdataexam, setupcomingdataexam] = useState([]);
  const [limit, setLimit] = useState<number>(18);
  const [total, setTotal] = useState<number>(0);
  const [totalpage, setTotalpage] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [isFetching, setIsFetching] = useState(false);
  const [HasMore, setHasMore] = useState(true);

  const [newcourseStreamArr, setNewCourseStreamArr] = useState<any>([]);
  const [isgetload, setIsgetload] = useState<boolean>(true);
  
  const getdataexam = useCallback(async (page, limit) => {
    try {
      setIsgetload(true);
      const params = {}
      let usePagination = true;
     // alert(page);
      let pages = page;
      let count = limit;
      params['usePagination'] = usePagination;
    //  params['data_type'] = 'board';
      params['page'] = pages;
      params['size'] = count;
      //const id = slug;//'engineering';
      const response = await axios.get('api/website/home/allentranceexams',{params: params});
      if (isMountedRef.current) 
      {
        setexamdetail(response.data.data);
        let pagetobeshow = response.data.currentPage;
        setIsgetload(false);
        setPage(pagetobeshow);
        setLimit(count);
        setTotal(response.data.totalItems);
        let pagecount = Math.ceil(response.data.totalItems / count);
        setTotalpage(pagecount);
        //  alert("asfd");
        //console.log("response.data.data");
        //console.log(response.data.data);
        //setexamdetail(response.data.data);
        /* setPage(page);
        setexamdetail([...examlist,...response.data.data]); 
        setHasMore(response.data.data.length > 0);
        setIsFetching(false); */
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  const getupcomingdataexam = useCallback(async () => {
    try {
     
      const id = slug;//'engineering';
      const response = await axios.get('api/website/home/upcomingexam?orderby=asc&columnname=upcoming_date');
      if (isMountedRef.current) {
      //  alert("asfd");
        console.log("response.data.data");
        console.log(response.data.data);
        setupcomingdataexam(response.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);
  
  const getCourseData = async ()=>{
    const formData = new FormData();
    //formData.append('getdata', JSON.stringify([{data: "college"},{data: "university"},{data: "exam"},{data: "testimonial"}]));
    const getstreams = await axios.get('api/website/home/streams?columnname=listing_order&orderby=asc&home_view_status=home_page&page=1&size=10000');
    setNewCourseStreamArr(getstreams.data.data);
  }

  const handlePageChange = (event: any, value: number): void => {
   // alert(limit);
    //alert(page);
    // setPage(value);
    getdataexam(value,limit);
  };

  const getpromobanner = useCallback(async () => {
    try {
      const response = await axios.get('api/website/promobanner?promo_banner=All_Exam_page');
      if (isMountedRef.current) 
      {
        setPromoBannerArr(response.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  const getPageData = useCallback(async () => {    
    try {
      const response = await axios.get('api/website/page/get/exams');
      if (isMountedRef.current) {
          setmetatitle(response.data.data.meta_title);
          setmetadescription(response.data.data.meta_description);
        }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(()=>{
    getHeroData();
    getdataexam(page,limit);
    getupcomingdataexam();
    getCourseData();
    getpromobanner();
    getPageData();
  }, []);

  /* useEffect(()=>{
    getdataexam(1,[]);
  }, []); */

  return (
    <>
    {/* {isTopColLoaded ? 
      <> */}
        <Helmet>
          <title>{metatitle && metatitle != '' ? metatitle : 'Get Admission at Top Colleges in Bangalore'}</title>
          <meta name="description" content={metadescription && metadescription != '' ?  metadescription : 'Looking for College/ University/ School admission? Bangalorestudy.com helps you with a list of Colleges, Universities and Schools to pursue your education in Bangalore. Call us now'} />
          <link rel="canonical" href={`${pConfig.siteUrl}exams`} />
        </Helmet>
        <Header examlist={examlist} upcomingdataexam={upcomingdataexam}/>
        <Breadcrumbs examlist={examlist}/>
        <OverviewSection page={page} totalpage={totalpage} handlePageChange={handlePageChange} isgetload={isgetload} examlist={examlist} upcomingdataexam={upcomingdataexam} isFetching={isFetching} HasMore={HasMore} getdataexam={getdataexam} courseStreamArr={newcourseStreamArr} promoBannerArr={promoBannerArr} />
      {/* </>
      :
      <p style={{height: '8000px'}}>Loading</p> } */}
    </>
  );
};

export default ExamList;