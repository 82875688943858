import React, { FC } from 'react';
import './styles.css';
import BoardsHeaderBG from 'src/assets/img/new-Boards2.jpg';

//import OwlCarousel from 'react-owl-carousel';
//import 'owl.carousel/dist/assets/owl.carousel.css';
//import 'owl.carousel/dist/assets/owl.theme.default.css';

const SearchHeader: FC = () => {
    
    /*const [num, setNum] = React.useState(331231);

    const owlOptions = {
        margin: 20, nav: true, autoplay: false,
        responsiveClass: true,
        responsive: {
            0: { items: 1 },
            400: { items: 1 },
            600: { items: 1 },
            700: { items: 3 }
        },
    };*/

    return (
        <section className="inner-top-header" id="hero" style={{ background: `url(${BoardsHeaderBG})` }}  >
            <div className="hero-container d-flex">	
            </div>
        </section>
    );
};

export default SearchHeader;