import React, { FC, useEffect, useState, useContext, useCallback } from 'react';
import SettingsContext from 'src/contexts/SettingsContext';
import axios from 'src/utils/axiosintercpt';
import { makeStyles } from '@material-ui/core';

import Breadcrumbs from './Breadcrumbs';
import Header from './InnerHeader';
import OverviewSection from './OverviewSection';
import { pConfig } from 'src/config';
import useIsMountedRef from 'src/hooks/useIsMountedRef';

import Helmet from 'react-helmet';
const useStyles = makeStyles(() => ({
    root: {}
}));

const StudentsSpeakPage: FC = () => {
    const classes = useStyles();
    const setCtx = useContext(SettingsContext);
    const isMountedRef = useIsMountedRef();
    const [metatitle, setmetatitle] = useState<any>([]);
    const [metadescription, setmetadescription] = useState<any>([]);
    const [courseStreamArr, setCourseStreamArr] = useState([]);
    const [isHeroLoaded, setHeroLoaded] = useState(false);
    const [studentspeakdata, setstudentspeakdata] = useState([]);

    const getHeroData = async () => {
        const formData = new FormData();
        formData.append('getdata', JSON.stringify([{ data: "course_streams_head" }]));
        const heroResp = await axios.post('api/website/home/header', formData);

        setCourseStreamArr(heroResp.data.data.course_streams_head);

        setCtx.setCourseData(heroResp.data.data.course_streams_head);
        setHeroLoaded(true);
    }

    const [limit, setLimit] = useState<number>(12);
    const [total, setTotal] = useState<number>(0);
    const [totalpage, setTotalpage] = useState<number>(0);
    const [page, setPage] = useState<number>(0);
    const [isgetload, setIsgetload] = useState<boolean>(true);

    const [searchValue, setSearchValue] = React.useState<any>([]);

    const searchByValue = (textvalue) => {
        setPage(1);
        setSearchValue(textvalue);
    };

    const getstudentspeakdata = useCallback(async (page, limit, searchValue) => {
        try {
            setIsgetload(true);
            const params = {}
            let usePagination = true;
            // alert(page);
            let pages = page;
            let count = limit;
            params['usePagination'] = usePagination;
            params['columnname'] = 'created_at';
            params['orderby'] = 'desc';
            params['page'] = pages;
            params['size'] = count;
            if(searchValue && searchValue!='')
            {
                params['searchText'] = searchValue;
                params['searchfrom'] = 'title,name,designation,description';
            }
            const response = await axios.get('api/website/testimonial/get', { params: params });
            //const response = await axios.get('api/website/testimonial/get?columnname=updated_at&orderby=desc');
            if (isMountedRef.current) {
                //  alert("asfd");
                //console.log("response.data.data");
                //console.log(response.data.data);
                setstudentspeakdata(response.data.data);
                //  setmetatitle(response.data.data.meta_title);
                //  setmetadescription(response.data.data.meta_description);
                let pagetobeshow = response.data.currentPage;
                setIsgetload(false);
                setPage(pagetobeshow);
                setLimit(count);
                setTotal(response.data.totalItems);
                let pagecount = Math.ceil(response.data.totalItems / count);
                setTotalpage(pagecount);
            }
        } catch (err) {
            console.error(err);
        }
    }, [isMountedRef]);

    const handlePageChange = (event: any, value: number): void => {
        getstudentspeakdata(value, limit, searchValue);
    };

    const getstudentspeak = useCallback(async () => {
        try {
            const response = await axios.get('api/website/page/get/students-speak');
            if (isMountedRef.current) {
                setmetatitle(response.data.data.meta_title);
                setmetadescription(response.data.data.meta_description);
            }
        } catch (err) {
            console.error(err);
        }
    }, [isMountedRef]);

    useEffect(() => {
        getHeroData();
        getstudentspeakdata(page, limit, searchValue);
        getstudentspeak();
    }, [searchValue]);

    return (
        <>
            <Helmet>
                <title>{metatitle && metatitle != '' ? metatitle : 'Get Admission at Top Colleges in Bangalore'}</title>
                <meta name="description" content={metadescription && metadescription != '' ? metadescription : 'Looking for College/ University/ School admission? Bangalorestudy.com helps you with a list of Colleges, Universities and Schools to pursue your education in Bangalore. Call us now'} />
                <link rel="canonical" href={`${pConfig.siteUrl}students-speak`} />
            </Helmet>
            <Header searchByValue={(textvalue) => searchByValue(textvalue)} />
            <Breadcrumbs />
            <OverviewSection studentspeakdata={studentspeakdata} page={page} totalpage={totalpage} handlePageChange={handlePageChange}  />
        </>
    );
};

export default StudentsSpeakPage;