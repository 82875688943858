import React, { FC, useEffect, useState, useContext, useCallback, ChangeEvent } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, makeStyles, Tab, Tabs, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import './styles.css';
import EnquiryDetailForm from 'src/components/EnquiryDetailForm';
import moment from 'moment';
import EntranceExam from 'src/assets/img/entrance-exam.jpg';
import TopManagementCourseBG from 'src/assets/img/top-managment-course.jpg';
import { config } from 'src/config';
import useIsMountedRef from 'src/hooks/useIsMountedRef';

//import Tabs, { tabsClasses } from '@material-ui/Tabs';
//import Tab from '@material-ui/Tab';

const useStylesBootstrap = makeStyles(() => ({
    arrow: {
        color: '#000',
    },
    tooltip: {
        backgroundColor: '#000',
    },
}));

function BootstrapTooltip(props: TooltipProps) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

const Breadcrumbs: FC<{ examdata: any,upcomingdataexam: any }> = ({ examdata,upcomingdataexam }) => {
    const [num, setNum] = React.useState(331231);
    let banner_image = examdata.promo_banner && examdata.promo_banner_status != 'Draft' ? config.baseUrlfromconfig + examdata.promo_banner : TopManagementCourseBG;

    const [value, setValue] = React.useState(0);
    const [currentTab, setCurrentTab] = useState<string>('overview');

    const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
        setCurrentTab(value);
    };

    const ordinalnew = (dom) => {
        if (dom == 31 || dom == 21 || dom == 1) return "st";
        else if (dom == 22 || dom == 2) return "nd";
        else if (dom == 23 || dom == 3) return "rd";
        else return "th";
        /*let ordinal = '';
        const last_digit = num % 10;
        ordinal = ((num % 100) / 10) === 1
            ? "th"
            : last_digit === 1
                ? "st"
                : last_digit === 2
                    ? "nd"
                    : last_digit === 3
                        ? "rd"
                        : "th";
        return ordinal;*/
    }

    return (
        <section className="course-overviews-section ">
            <div className="container aos-init aos-animate" >
                <div className="row">
                    <div className="col-lg-8 overviews-course-dep left-exam-section" >
                        {/* <h3 className="main-heading left-align">{examdata.meta_title}</h3> */}

                        
                            <Tabs
                                value={currentTab}
                                onChange={handleTabsChange}
                                variant="scrollable"
                                scrollButtons="on"
                                aria-label="visible arrows tabs example"
                                className='mainexamtabs'
                            >
                                <Tab label="Overview" value="overview" />
                                <Tab label="Important Dates" value="exam-date" />
                                <Tab label="Exam Pattern & Syllabus" value="exam-patt-sybs" />
                                <Tab label="Eligibility & Registration" value="eligibilty-registration" />
                                {examdata.centers && examdata.centers !='' ? 
                                    <Tab label="Exam Centres" value="centres" />
                                : ''}
                                {examdata.tips && examdata.tips !='' ? 
                                    <Tab label="Preparation Tips"  value="tips" />
                                : ''}
                                {examdata.card && examdata.card !='' ? 
                                    <Tab label="Admit Card" value="card" />
                                : ''}
                                {examdata.colleges && examdata.colleges !='' ? 
                                    <Tab label="Colleges" value="colleges" />
                                : ''}
                                {examdata.results && examdata.results !='' ? 
                                    <Tab label="Results" value="results" />
                                : ''}                                
                                <Tab label="FAQs" value="faqs" />
                            </Tabs>

                        {/* <ul className="nav nav-tabs" id="myTab">
                            <li className="nav-item"><a href="#overview" className="nav-link active" data-bs-toggle="tab">Overview</a></li>
                            <li className="nav-item"><a href="#exam-date" className="nav-link" data-bs-toggle="tab">Exam Date </a></li>
                            <li className="nav-item"><a href="#exam-patt-sybs" className="nav-link" data-bs-toggle="tab">Exam Pattern & Syllabus</a></li>
                            <li className="nav-item"><a href="#eligibilty-registration" className="nav-link" data-bs-toggle="tab">Eligibilty & Registration</a></li>
                        </ul>  */}

                        <div className="tab-content left-overviews-section bs-editor-text">
                            {(currentTab === 'overview' && currentTab) ? 
                                <>
                                    {/* <h4>Overview</h4> */}
                                    <div className="bs-editor-text">
                                        <span dangerouslySetInnerHTML={{ __html: examdata.exam_description }} />
                                    </div>
                                </> : 
                            null}
                            
                            {(currentTab === 'exam-date' && currentTab) ? 
                                <>
                                    {/* <h4>Important Dates</h4> */}
                                    <div className="bs-editor-text">
                                        <span dangerouslySetInnerHTML={{ __html: examdata.important_dates }} />
                                    </div>
                                </>
                            :  null}

                            {(currentTab === 'exam-patt-sybs' && currentTab) ? 
                            <>
                                {/* <h4>Exam Pattern & Syllabus</h4> */}
                                <div className="bs-editor-text">
                                    <span dangerouslySetInnerHTML={{ __html: examdata.exam_pattern }} />
                                </div>
                            </>
                            :  null}

                            {(currentTab === 'eligibilty-registration' && currentTab) ? 
                                <>
                                    {/* <h4>Eligibility & Registration</h4> */}
                                    <div className="bs-editor-text">
                                        <span dangerouslySetInnerHTML={{ __html: examdata.eligibility_criteria }} />
                                    </div>
                                </>
                            :  null}

                            {(currentTab === 'centres' && currentTab) ? 
                                <>
                                    {/* <h4>Exam Centres</h4> */}
                                    <div className="bs-editor-text">
                                        <span dangerouslySetInnerHTML={{ __html: examdata.centers }} />
                                    </div>
                                </>
                            :  null}

                            {(currentTab === 'tips' && currentTab) ? 
                                <>
                                    {/* <h4>Preparation Tips</h4> */}
                                    <span dangerouslySetInnerHTML={{ __html: examdata.tips }} />
                                </>
                            :  null}

                            {(currentTab === 'card' && currentTab) ? 
                                <>
                                    {/* <h4>Admit Card</h4> */}
                                    <span dangerouslySetInnerHTML={{ __html: examdata.card }} />
                                </>
                            :  null}

                            {(currentTab === 'colleges' && currentTab) ? 
                                <>
                                    {/* <h4>Colleges</h4> */}
                                    <span dangerouslySetInnerHTML={{ __html: examdata.colleges }} />
                                </>
                            :  null}

                            {(currentTab === 'results' && currentTab) ? 
                                <>
                                    {/* <h4>Results</h4> */}
                                    <span dangerouslySetInnerHTML={{ __html: examdata.results }} />
                                </>
                            :  null}

                            {(currentTab === 'faqs' && currentTab) ? 
                                <>
                                    <h5>FAQs</h5>
                                    {examdata.examfaqs && examdata.examfaqs.map((i, index) => {
                                        return (
                                        <>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id={`panel${index}a-header`}
                                                >
                                                    <Typography>{index + 1+"."} {i.questions}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        {i.answers}
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        </>
                                        )
                                    })}
                                    {/* <span dangerouslySetInnerHTML={{ __html: examdata.results }} /> */}
                                </>
                            :  null}


                        </div>

                    </div>

                    <div className="col-lg-4" >

                        <div className="widgets get-exam-now-form right-widget">

                            <h4 className="title-heading">Get <span> Counselling</span></h4>
                            <EnquiryDetailForm type='admissions' />
                            {/* <form action="forms/contact.php" method="post" role="form" className="php-email-form">

                                <div className="form-group">
                                    <input type="text" className="form-control" name="subject" id="subject" placeholder="Enter Your Name" />
                                </div>

                                <div className="row">
                                    <div className="col-lg-6 form-group">
                                        <input type="email" name="name" className="form-control" id="name" placeholder="Enter Email" />
                                    </div>
                                    <div className="col-lg-6 form-group">
                                        <input type="tel" className="form-control" name="email" id="email" placeholder="Enter Number" />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <input type="text" className="form-control" name="subject" id="subject" placeholder="Enter Course" />
                                </div>

                                <div className="form-group">
                                    <input type="text" className="form-control" name="subject" id="subject" placeholder="Enter Location" />
                                </div>

                                <div className="form-group">
                                    <textarea className="form-control" name="message"  placeholder="Type Your Message" ></textarea>
                                </div>


                                <div className="text-center"><button type="submit">Submit</button></div>
                            </form> */}

                        </div>


                        <div className="widgets get-exam-now-form right-widget" >

                            <h4 className="title-heading">Upcoming <span>Exam</span></h4><div style={{ overflowX: "auto", maxHeight: "457px" ,position: "relative" }}>

                            {
                                upcomingdataexam && upcomingdataexam.slice(0, 8).map((i) => {
                                    let examlink = '/exams/'+i.slug;
                                    let imageUrl = i.cover_image ? config.baseUrlfromconfig + i.cover_image : EntranceExam;
                                    return (
                                        <div className="coming-exam-update-block">
                                            
                                            <span className="icon">
                                                <img alt={i.meta_title} src={imageUrl} />
                                                <div>{moment(i.upcoming_date).format('D')}<sub>{ordinalnew(moment(i.upcoming_date).format('D'))}</sub><br/>{moment(i.upcoming_date).format('MMM')}</div>
                                            </span>
                                            <a href={examlink}  className="nohyperlink">
                                                <h2>{i.exam_short_name}</h2>
                                            </a>
                                        </div>
                                    )
                                })
                            }

                            </div>
                            
                            
                        </div>
                        
                        {
                            examdata.promo_banner && examdata.promo_banner_status != 'Draft' ? 
                            <>
                                <div className="widgets top-manag-course-widget text-center" style={{ background: `url(${banner_image})` }}>
                                    <div className="course-center">
                                        <h2>&nbsp;</h2>
                                        <p>&nbsp;</p>
                                        {/* <h2>Top Management Courses in Bangalore</h2>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p> */}
                                    </div>
                                </div>
                            </>
                            : ''
                        }
                        {/* <div className="widgets top-manag-course-widget text-center" style={{ background: `url(${TopManagementCourseBG})` }} >
                            <div className="course-center">
                                <h2>Top <span>Management</span> Courses in <span>Bangalore</span> </h2>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                            </div>
                        </div> */}

                        <div className="widgets get-exam-now-form right-widget" > 

                            <h4 className="title-heading">{examdata.exam_title} <span>UPDATE</span></h4>
                            <div style={{ overflowX: "auto", maxHeight: "466px" }}>
                            {
                                examdata.examnews && examdata.examnews.map((i) => {
                                    //let imageUrl = i.cover_image ? config.baseUrlfromconfig + i.cover_image : EntranceExam;

                                    let newslink = '/news-and-event/'+i.slug;
                                    return (
                                        <div className="coming-exam-update-block">
                                            <span className="icon">
                                                <img alt={i.title} src={EntranceExam} />
                                            </span>
                                            <a href={newslink}  className="nohyperlink">
                                            <h2>{i.title}</h2>
                                            </a>
                                        </div>
                                    )
                                })
                            }


                            </div>
                           
                            {/* <div className="coming-exam-block">
                                <span className="icon">
                                    <img src={EntranceExam} />
                                </span>
                                <h2>National Eligibility Cum Entrance Exam For Medicine.</h2>
                            </div>

                            <div className="coming-exam-block">
                                <span className="icon">
                                    <img src={EntranceExam} />
                                </span>
                                <h2>National Eligibility Cum Entrance Exam For Medicine.</h2>
                            </div>

                            <div className="coming-exam-block">
                                <span className="icon">
                                    <img src={EntranceExam} />
                                </span>
                                <h2>National Eligibility Cum Entrance Exam For Medicine.</h2>
                            </div> */}

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Breadcrumbs;