import React, { FC } from 'react';
import './styles.css';
import NCTE from 'src/assets/img/ncte.jpg';
import { config } from 'src/config';
import Pagination from '@material-ui/lab/Pagination/Pagination';

const BoardsList: FC < {boardArr,page,totalpage,handlePageChange,isgetload}> = ({boardArr,page,totalpage,handlePageChange,isgetload})=> {
    //console.log('m here'+ page);
    //console.log(boardArr);
    
    /*const LongText = ({ content,limit}) => {
        const [showAll, setShowAll] = React.useState(false);
      
        const showMore = () => setShowAll(true);
        const showLess = () => setShowAll(false);
      
        if (content.length <= limit) {
          // there is nothing more to show
          return <div>{content}</div>
        }
        if (showAll) {
          // We show the extended text and a link to reduce it
          return <div> 
            {content} 
            <button onClick={showLess}>Read less</button> 
          </div>
        }
        // In the final case, we show a text with ellipsis and a `Read more` button
        const toShow = content.substring(0, limit) + "...";
        return <span dangerouslySetInnerHTML={{ __html: toShow }} />
        // return <div> 
        //   {toShow} 
        //   <button onClick={showMore}>Read more</button>
        // </div>
    }*/
    
    return (
        <section className="container-fluid spac_01 light-blue-bg"  >
            <div className="container">
                <div className="row">
                {isgetload ? ( 
                    <h4>Loading...</h4>
                ): ( 
                    boardArr.data && boardArr.data.map((i, index) => {
                        let bloglink = '/board/'+i.slug;
                        const logoUrl = i.logo ? config.baseUrlfromconfig + i.logo : NCTE;
                        return (
                            
                        <>
                            <div className="col-lg-6 col-md-6 ">
                            <div className="boards-block" >
                                <h3>
                                    <img alt={i.name} src={logoUrl} className="img-fluid" />
                                    {i.name}, {i.city.city_name}
                                </h3>
                                <ul>
                                <li>Location: {i.area.area_name}, {i.city.city_name}</li>
                                <li>Approvals and Recognition:&nbsp;<br/>
                                {i.collegeRecognition.length > 0 ?
                                <>
                                    {i.collegeRecognition && i.collegeRecognition.map((row,j) => (
                                        <span>{row.recognitionname.recognition_approval_name}</span>
                                    ))} 
                                </>
                                : <span>&nbsp;</span> }
                              </li>
                                <li>Est year: {i.established}</li>
                                <li>Genders Accepted: {i.genders_accepted}</li>
                            </ul>

                                
                               {/*  {i.established}
                                {i.genders_accepted}
                                {i.collegeRecognition && i.collegeRecognition.map((row,j) => (
                                    <h4>{row.recognitionname.recognition_approval_name}</h4>
                                ))} */}
                                
                                
                                {/* <p><span dangerouslySetInnerHTML={{ __html: i.about }} /></p> */}
                             {/*    <LongText content = { i.about } limit = {150} />  */}
                                
                                <a href={bloglink}>View Details <i className="bi bi-arrow-right"></i></a>
                            </div>
                        </div>
                        </>
                        )
                    }) 
                )}
                
                    {/* <div className="col-lg-6 col-md-6 ">
                        <div className="boards-block" >
                            <h3>
                                <img src={ICAR} className="img-fluid" />
                                Central Agricultral Research Institue, Port Blair
                            </h3>
                            <h4>The Indian Council of Agricultural Research ( ICAR)</h4>
                            <p>The Indian Councial of Agricultural Research (ICAR) is an autonomous organisation under the Depaertment of Agricultural Research and Education Ministry of Agriculture and Farmers Walfare, Government of India.</p>
                            <a href="">View Details <i className="bi bi-arrow-right"></i></a>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="boards-block" >
                            <h3>
                                <img src={NCTE} className="img-fluid" />
                                Shaheed Bhagat Singh College of Education, Sirsa
                            </h3>
                            <h4>The Indian Council of Agricultural Research ( ICAR)</h4>
                            <p>The Indian Councial of Agricultural Research (ICAR) is an autonomous organisation under the Depaertment of Agricultural Research and Education Ministry of Agriculture and Farmers Walfare, Government of India.</p>
                            <a href="">View Details <i className="bi bi-arrow-right"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 ">
                        <div className="boards-block" >
                            <h3>
                                <img src={MCI} className="img-fluid" />
                                Post Graduate Institute of Medical Education & Research - [PGIMER], Chandigarh
                            </h3>
                            <h4>The Indian Council of Agricultural Research ( ICAR)</h4>
                            <p>The Indian Councial of Agricultural Research (ICAR) is an autonomous organisation under the Depaertment of Agricultural Research and Education Ministry of Agriculture and Farmers Walfare, Government of India.</p>
                            <a href="">View Details <i className="bi bi-arrow-right"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="boards-block" >
                            <h3>
                                <img src={DCI} className="img-fluid" />
                                Anil Neerukonda Indtitue of Dental Sciences, Visakhapatnam
                            </h3>
                            <h4>The Indian Council of Agricultural Research ( ICAR)</h4>
                            <p>The Indian Councial of Agricultural Research (ICAR) is an autonomous organisation under the Depaertment of Agricultural Research and Education Ministry of Agriculture and Farmers Walfare, Government of India.</p>
                            <a href="">View Details <i className="bi bi-arrow-right"></i></a>
                        </div>
                    </div> */}
                    <div className="spac_02"></div>
                    <div className="button-section text-center">
                    <Pagination

                        page={page}
                        variant="outlined"
                        shape="rounded"
                        onChange={handlePageChange}
                        count={totalpage}
                        className="pagination"
                    />
                        {/* <ul className="pagination">
                            <li><a href="#">«</a></li>
                            <li><a href="#">1</a></li>
                            <li><a className="active" href="#">2</a></li>
                            <li><a href="#">3</a></li>
                            <li><a href="#">4</a></li>
                            <li><a href="#">5</a></li>
                            <li><a href="#">6</a></li>
                            <li><a href="#">7</a></li>
                            <li><a href="#">»</a></li>
                        </ul> */}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BoardsList;