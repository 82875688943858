import React, { FC, useState,useCallback,useEffect } from 'react';
import './styles.css';

import College from 'src/assets/img/college.jpg';
import CourseBG from 'src/assets/img/course-bg.jpg'
import { config } from 'src/config';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import axios from 'src/utils/axiosintercpt';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';

const TopColleges: FC<{ examdata: any }> = ({ examdata }) => {

    const owlOptions = {
        margin: 20, nav: true, autoplay: false,
        responsiveClass: true,
        responsive: {
            0: {items:1, dots:false, nav:true},
            400: {items:1, dots:false, nav:true},
            600: {items: 1, dots:false, nav:true},
            700: {items: 2, dots:false, nav:true},
            1000:{
                items:3,
                nav:true,
                loop:false
            },
            1440:{
                items:4,
                nav:true,
                loop:false
            }
        },
    };
    const [isdataloaded, setdataloaded] = useState(false);

    const [getexamss, setgetexamss] = useState<any>([]);
    //const isMountedRef = useIsMountedRef();

    const getdataexamdata = async (streamid, examid)=>{
        try {
            const response = await axios.get('api/website/home/allentranceexams?stream_id='+streamid+'&exam_id='+examid);
            //if (isMountedRef.current) {
                if(response.data.data)
                {
                    //console.log("fffsss");
                    //console.log(response.data.data);
                    setgetexamss(response.data.data);
                    //console.log(response.data.data);
                    //console.log(getexamss);
                    setdataloaded(true);
                    
                }
            //}
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(()=>{
        getdataexamdata(examdata.stream_id, examdata.id);
      }, []);

    return (
        <section className="top-management-colleges container-fluid spac_01 light-color-bg" style={{ background: `#eff3f6 url(${CourseBG})` }} data-aos="fade-up">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 our_portal">
                        {
                            examdata.stream_id ? <h3 className="main-heading">Other <b>Exams</b></h3> :''
                        }
                        
                    {/*     <p className="text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit, <br />sed do tempor incididunt ut labore et dolore. </p> */}
                    <div className="spac_02"></div>
                    {isdataloaded ?
                    <OwlCarousel {...owlOptions} className="loop_colleges loop_colleges_0 loop_nav owl-carousel owl-theme">
                    {
                        
                        getexamss.map((i) => 
                            {
                                let examlink = '/exams/'+i.slug;
                                return (
                                    <>
                            <div className="item">
                                <div className="testi_wrp">
                                    <div className="testi_hover"></div>
                                    <div className="inner_wrp">
                                        <div className="test_content_wrp col-lg-12 wrapblogcontent">
                                            {/* <div className="rating_box"><i className="bi bi-star-fill rating_act"></i> 4.8</div> */}
                                            <img alt={i.exam_title} src={ i.cover_image ? config.baseUrlfromconfig + i.cover_image : College}  className="full_wdth" />
                                            <h3>{i.exam_title}</h3>
                                           {/*  <p>Lorem ipsum dolor sit amet, conseaunt ut labore et...</p> */}

                                            <div className="college_button_wrp">
                                                <GlobalPopupEnquiry />
                                                <a href={examlink}>View Details</a>
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </>
                                )
                            })
                            
                        }
                        </OwlCarousel>
                        :'' }
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TopColleges;