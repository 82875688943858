import React, { FC } from 'react';
import './styles.css';
import GetMoreDetails from 'src/assets/img/get-more-details.jpg';
import EducationCap from 'src/assets/img/education-cap.png';
import HeadingBg from 'src/assets/img/heading-bg.png';
import TopManagementCourseBG from 'src/assets/img/top-managment-course.jpg';
import InterestedinCourseBG from 'src/assets/img/interested-in-course-bg.jpg';
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';
import Bellicon from 'src/assets/img/top-left-orange.png';
import { config } from 'src/config';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';




const Breadcrumbs: FC<{ coursedetail: any, subcourse: any, newcourseStreamArr: any, streamslug: any, promoBannerArr: any }> = ({ coursedetail, subcourse, newcourseStreamArr, streamslug, promoBannerArr }) => {
    //const [num, setNum] = React.useState(331231);
    let banner_image = coursedetail.promo_banner && coursedetail.promo_banner_status != 'Draft' ? config.baseUrlfromconfig + coursedetail.promo_banner : TopManagementCourseBG;
    return (
        <section className="course-overviews-section container-fluid  spac_01" >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 overviews-course">
                        <div className="row">

                            <div className="col-lg-8 col-md-6" >
                                <ul className="nav nav-tabs" id="myTab">
                                    <li className="nav-item"><a href="#overview" className="nav-link active" data-bs-toggle="tab">Overview</a></li>
                                    <li className="nav-item"><a href="#ug" className="nav-link" data-bs-toggle="tab">Admissions</a></li>
                                    {/* <li className="nav-item"><a href="#pg" className="nav-link" data-bs-toggle="tab">Top Colleges</a></li> */}
                                    <li className="nav-item"><a href="#doctorate" className="nav-link" data-bs-toggle="tab">Career Opportunities</a></li>
                                    <li className="nav-item"><a href="#faq" className="nav-link" data-bs-toggle="tab">FAQs</a></li>
                                    {/*  <li className="nav-item"><a href="#diploma" className="nav-link" data-bs-toggle="tab">Diploma</a></li>
                                    <li className="nav-item"><a href="#best-colleges" className="nav-link" data-bs-toggle="tab">Best Colleges</a></li>
                                    <li className="nav-item"><a href="#faq" className="nav-link" data-bs-toggle="tab">Faq's</a></li> */}
                                </ul>

                                <div className="tab-content left-overviews-section bs-editor-text">

                                    <div className="tab-pane fade show active bs-editor-text" id="overview">
                                        <span dangerouslySetInnerHTML={{ __html: subcourse.description }} />
                                    </div>

                                    <div className="tab-pane fade bs-editor-text" id="ug">
                                        <span dangerouslySetInnerHTML={{ __html: subcourse.admission }} />

                                    </div>

                                    {/* <div className="tab-pane fade" id="pg">
                                    <span dangerouslySetInnerHTML={{ __html: subcourse.course_type }} />   
                                  
                                    </div> */}

                                    <div className="tab-pane fade bs-editor-text" id="doctorate">
                                        <span dangerouslySetInnerHTML={{ __html: subcourse.carrier_opportunities }} />

                                    </div>
                                    <div className="tab-pane fade" id="faq">
                                    <h5>FAQs</h5>
                                        {subcourse.faqs && subcourse.faqs.map((i, index) => {
                                            return (
                                            <>
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id={`panel${index}a-header`}
                                                    >
                                                        <Typography>{index + 1+"."} {i.questions}</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography>
                                                            {i.answers}
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                                {/* <span><b>Question:</b>{i.questions}</span><br></br>
                                                <span><b>Answer:</b> {i.answers}</span><br></br> */}
                                            </>
                                            )
                                        })}
                                        {/* 
                                        {coursedetail.faqs.map(i => {
                                           
                                                <h2>{i.id}</h2>
                                           
                                        })} */}
                                   
                                        {/* {coursedetail.faqs.map(element => {
                                            return (
                                                <h2>{element.id}</h2>
                                            )
                                        // return <h2>{element.id}</h2>
                                        })} */}
                                    </div>

                                    {/* 
                                    <div className="tab-pane fade" id="diploma">
                              
                                    <span dangerouslySetInnerHTML={{ __html: coursedetail.diploma_box }} />  
                                    </div>


                                    <div className="tab-pane fade" id="best-colleges">
                                    {coursedetail.diploma_box}

                                    </div>


                                    <div className="tab-pane fade" id="faq">
                                        {coursedetail.faqs && coursedetail.faqs.map(i => {
                                            return (
                                            <>
                                                <span><b>Question:</b>{i.questions}</span><br></br>
                                                <span><b>Answer:</b> {i.answers}</span><br></br>
                                            </>
                                            )
                                        })}
                               
                                    </div> */}


                                </div>

                            </div>

                            <div className="col-lg-4 col-md-6 sidebar-widgets" >
                                {/* <div className="widgets right-widget">
                                    <a href="#"><img src={GetMoreDetails} width="100%" /></a>
                                </div> */}
                                <div className="widgets right-widget top-get-more-details" style={{ background: `url(${GetMoreDetails})`, backgroundSize: 'cover', backgroundPosition: 'center center' }}>
                                    <div className="management-courses-block">
                                        <div className="top-left-orange"><img src={Bellicon} /></div>
                                        <h2>Get More Details On</h2>
                                        <h3>{coursedetail.stream_name} Courses and Colleges.</h3>
                                        <GlobalPopupEnquiry className="apply-now-btn button btn" />
                                        {/** <a href="#" className="apply-now-btn button btn">Apply Now</a>/ */}
                                    </div>
                                    <div className="green-overly"></div>
                                </div>
                                <div className="widgets top-streams-widget">
                                    <div className="large-heading-bg text-center" style={{ background: `url(${HeadingBg})` }}>
                                        <h2><img src={EducationCap} /> Other Courses</h2>
                                    </div>
                                    <ul className='fullwidth'>
                                        <>
                                            {coursedetail.str && coursedetail.str.slice(0, 12).map((el) => {
                                                let link_course = "/courses/" + streamslug + "/" + el.course_stream_slug;
                                                return (
                                                    <li><a href={link_course}>{el.course_stream_name}</a></li>
                                                )
                                            })}
                                        </>
                                        {/* {newcourseStreamArr && newcourseStreamArr.map((el) => {
                                            let link_course = "/courses/" + el.stream_slug;
                                            return (
                                                <li><a href={link_course}>{el.stream_name}</a></li>
                                            )
                                        })} */}

                                        {/* <li><a href="">Post Graduate Diploma in Management</a></li>
                                        <hr></hr>
                                        <li><a href="">Master of Commerce</a></li>
                                        <hr></hr>
                                        <li><a href="">Master in Computer Management</a></li>
                                        <hr></hr>
                                        <li><a href="">Medical</a></li>
                                        <hr></hr>
                                        <li><a href="">Nursing</a></li>
                                        <hr></hr> */}
                                    </ul>
                                </div>
                                {
                                    coursedetail.promo_banner && coursedetail.promo_banner_status != 'Draft' ?
                                        <>
                                            <div className="widgets top-manag-course-widget text-center" style={{ background: `url(${banner_image})` }}>
                                                <div className="course-center">
                                                    <h2>&nbsp;</h2>
                                                    <p>&nbsp;</p>
                                                  
                                                    {/* <h2>Top Management Courses in Bangalore</h2>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p> */}
                                                </div>
                                            </div>
                                        </>
                                        : ''
                                }
                                {/* {
                                    promoBannerArr && promoBannerArr.map((i) => {
                                        let banner_image = i.image ? config.baseUrlfromconfig + i.image : TopManagementCourseBG;
                                        return (
                                            <>
                                                <div className="widgets top-manag-course-widget text-center" style={{ background: `url(${banner_image})` }}>
                                                    <div className="course-center">
                                                        <h2>&nbsp;</h2>
                                                        <p>&nbsp;</p>
                                                        {/* <h2>Top Management Courses in Bangalore</h2>
                                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p> 
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })
                                } */}
                                {/* <div className="widgets top-manag-course-widget text-center" style={{background:`url(${TopManagementCourseBG})`}}>
                                    <div className="course-center">
                                        <h2>Top Management Courses in Bangalore</h2>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row interested-in-course text-center" style={{ background: `url(${InterestedinCourseBG})` }}>
                    <div className="course-center">
                        <h2>Interested to Pursue this Course?</h2>
                        <p>Get in Touch with us to Start the Application Process Now! </p>
                        <div className="text-center"><GlobalPopupEnquiry className="btn btn btn-primary" title="Request Call Back" /></div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Breadcrumbs;