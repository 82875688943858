import React, { FC, useEffect, useState, useContext, useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import './styles.css';
import EnquiryDetailForm from 'src/components/EnquiryDetailForm';
import College from 'src/assets/img/college.jpg';
import EntranceExam from 'src/assets/img/entrance-exam.jpg';
import TopManagementCourseBG from 'src/assets/img/top-managment-course.jpg';
import { config } from 'src/config';
import moment from 'moment';
const useStylesBootstrap = makeStyles(() => ({
    arrow: {
        color: '#000',
    },
    tooltip: {
        backgroundColor: '#000',
    },
}));

function BootstrapTooltip(props: TooltipProps) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

const OverviewSection: FC<{}> = ({ }) => {
    const [num, setNum] = React.useState(331231);

    return (
        <section className="course-overviews-section ">
            <div className="container aos-init aos-animate" >
                <div className="row">
                    <div className="col-lg-8 overviews-course left-exam-section" >
                        <h3 className="main-heading left-align">Contact <span>Us</span></h3>
                        {/* <h2><p><b>Bangalore (Head quarters)</b></p></h2> */}
                        <h3 className="main-heading left-align">Bangalore (Headquarters)</h3>
                        <p>Bangalore office<br /> #3/75, 'D' Main, East End, 9th Block <br />Jayanagar, Bangalore-560 069
                            <br />Tel: 080-22454991, 26631169
                            <br />Fax: 080 22454991
                            {/* <br />PRO Admission : +91 9036020005/16 */}
                        </p>
                        <p><i className='bi bi-envelope'></i> info@bangalorestudy.com info@learntechww.com</p>
                        <p><i className='bi bi-telephone'></i> PRO Admission : +91 9036020005/16</p>
                        
                        <div className="spac_02"></div>
                        <div className="row">
                            <div className="col-lg-6 overviews-course left-exam-section" >
                                <h3 className="main-heading left-align">Dubai</h3>
                                <p><b>UAE Office:</b></p>
                                <p>
                                    6, First Floor, Al Mamzar Centre<br />

                                    Al Qiyadah Metro Station,<br />
                                    Dubai<br />
                                </p>
                                <p><i className='bi bi-telephone'></i> Ph: +971 502436552, +971 504955123</p>
                            </div>
                            <div className="col-lg-6 overviews-course left-exam-section" >
                                <h3 className="main-heading left-align">Bahrain</h3>
                                <p><b>Bahrain Office:</b></p>
                                <p>
                                    Shop 240-Y, Road No: 5607,<br />

                                    Block No: 756, Aali,<br />
                                    Manama, Bahrain<br />
                                </p>
                                <p><i className='bi bi-telephone'></i> Ph: +973 35480190, +973 38780368</p>
                                {/* <p><b>Gnana Bharathi Main Rd, opp. NAAC, Teachers Colony,<br />Naagarabhaavi, Bengaluru, Karnataka 560072</b></p>
                                <p><i className='bi bi-envelope'></i> bangalorestudy34@gmail.com</p>
                                <p><i className='bi bi-telephone'></i> +91 - 856 - 985 - 2564</p> */}
                            </div>
                        </div>

                    </div>


                    <div className="col-lg-4" >
                        <div className="widgets get-details-now-form right-widget">
                            <h4 className="title-heading">GET IN TOUCH WITH US</h4>
                            <EnquiryDetailForm type="contact"/>
                            {/* <form action="forms/contact.php" method="post" role="form" className="php-email-form">
                                <div className="form-group">
                                    <input type="text" className="form-control" name="subject" id="subject" placeholder="Enter Your Name" />
                                </div>
                                <div className="form-group">
                                    <input type="email" name="name" className="form-control" id="name" placeholder="Enter Email" />
                                </div>
                                <div className="form-group">
                                    <input type="tel" className="form-control" name="email" id="email" placeholder="Enter Submit" />
                                </div>
                                <div className="form-group">
                                    <textarea className="form-control" name="message" placeholder="Type Your Message" ></textarea>
                                </div>
                                <div className="text-center"><button type="submit">Submit</button></div>
                            </form> */}

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OverviewSection;