import React, { FC } from 'react';
import './styles.css';
import ManagementCourseBG from 'src/assets/img/management-courses-new.jpg';
// import CallNow from 'src/assets/img/call-now.svg';
// import SearchIcon from 'src/assets/img/icons/search.svg';
// import IIITBLOGO from 'src/assets/img/iiitb_logo.png';

const SearchHeader: FC = () => {
    //const [num, setNum] = React.useState(331231);

    return (
        <section className="inner-top-header" id="hero" style={{ background: ` url(${ManagementCourseBG})` }} >
            <div className="hero-container d-flex">
                <div className="container" >
                    <div className="row top-course-block">
                        <div className="top-course-header">
                            <h1>WELCOME TO <span>BANGALORESTUDY.COM</span></h1>
                            <h4 className='bg-dark1'>Recognitions and Approvals</h4>
                        </div>
                    </div>
                    <div className="spac_02"></div>
                </div>
            </div>
        </section>
    );
};

export default SearchHeader;