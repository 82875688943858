import React, { FC } from 'react';
import './styles.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import UniversityBg from 'src/assets/img/unnnn.png';
import EnquiryForm from '../PopupEnquiryForm';



const MidFormBanner: FC = () => {
    const [modalShow, setModalShow] = React.useState(false);

    function onChanges() {

        setModalShow(false);
    }
    function MyVerticallyCenteredModal(props) {
        return (
            <Modal
                {...props}
                // size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Interested? Send us an enquiry
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div id="header-form" className="mt-30">

                        <EnquiryForm onChanges={onChanges} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
    return (

        <section className="signUp aos-init aos-animate" data-aos="fade-up" style={{ background: `linear-gradient(359deg, rgba(175, 88, 88, 24%), rgba(90, 95, 95, 64%)) fixed, url(${UniversityBg})` }}>
            <div className="hero-container d-flex">
                <div className="container aos-init aos-animate" data-aos="fade-up">
                    <div className="row no-gutters">
                        <div className="col-lg-8">
                            <div className="carousel slide hero_spc_top" data-ride="carousel">
                                <div className="carousel-inner text-white m-auto">
                                    <div className="carousel-item active">
                                        <h1><b>Admissions in Karnataka</b></h1>
                                        <p className="text-justify">
                                            Are you looking for Admissions in Karnataka, but need guidance to choose a course or college? Reach out to our Educational Experts to show you all the paths for a successful career!
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="hero_explore">
                                {/* <a href="#" className="contactUs">Contact Us</a> */}
                                <Button variant="primary" onClick={() => setModalShow(true)}>
                                    Connect with us!
                                </Button>
                                <MyVerticallyCenteredModal
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                />
                            </div>
                        </div>
                        <div className="header-form col-lg-4 d-flex flex-column justify-content-center">
                            <div id="header-form" className="">
                                <EnquiryForm smallsize />
                                {/* <form action="#" method="post" role="form" className="php-email-form">
                                    <div className="row">
                                        <div className="col-lg-6 form-group">
                                            <label>Full Name</label>
                                            <input type="text" name="name" className="form-control" id="name" placeholder="Full Name" required/>
                                        </div>
                                        <div className="col-lg-6 form-group">
                                            <label>Phone Number</label>
                                            <input type="tel" className="form-control" name="Phone Number" id="PhoneNumber" placeholder="Phone Number" required />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input type="email" className="form-control" name="Email" id="Email" placeholder="Enter Email" required />
                                    </div>
                                    <div className="form-group">
                                        <label>Course In Mind</label>
                                        <input type="text" className="form-control" name="Course In Mind" id="CourseInMind" placeholder="Enter Course" required />
                                    </div>
                                    <div className="form-group">
                                        <label>Location</label>
                                        <input type="text" className="form-control" name="Location" id="Location" placeholder="Enter Location" required />
                                    </div>
                                    <div className="form-group">
                                        <label>Message</label>
                                        <textarea className="form-control" name="message" rows={5} placeholder="Type Your Message" required ></textarea>
                                    </div>
                                    <div className="text-center"><button type="submit" className="submit_message">Submit</button></div>
                                </form> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MidFormBanner;