import React, { FC } from 'react';
import './styles.css';
import AllCollegeAdmissionBG from 'src/assets/img/all-college-adminsion-bg.jpg';
import EnquiryFormTalkToExpert from 'src/components/EnquiryFormTalkToExpert';

const Talk: FC = () => {
    
    return (
        <section className="all-colleges-admission container-fluid spac_01" style={{ background: `#3e6eb0 url(${AllCollegeAdmissionBG})` }}  data-aos="fade-up">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 " data-aos="fade-up"> 
                        <h2>Did you Choose a University?</h2>
                        <p>Did you find your desired University?<br/>Need more Assistance?<br/>Get customised counseling from our Experts now!</p>
                    </div> 

                    <div className="col-lg-6 col-md-6 contact-form" data-aos="fade-up"> 
                        <h2>Talk to Our Experts</h2>

                        <EnquiryFormTalkToExpert type={'University'}/>
                        {/*<form action="forms/contact.php" method="post" role="form" className="php-email-form">
                            <div className="row">
                                <div className="col-lg-6 form-group">
                                <input type="text" name="name" className="form-control" id="name" placeholder="Your Name"  />
                                </div>
                                <div className="col-lg-6 form-group">
                                <input type="email" className="form-control" name="email" id="email" placeholder="Your Email"  />
                                </div>
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" name="phonenumber" id="phonenumber" placeholder="Phone Number"  />
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" name="course" id="course" placeholder="Interested Course"  />
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" name="universotyname" id="universotyname" placeholder="University Name"  />
                            </div>                    
                            <div className="text-center"><button type="submit">Send Message</button></div>
                        </form>*/}
                    </div> 
                </div>
            </div>
        </section>
    );
};

export default Talk;