import React, { useState,FC,useCallback,useEffect } from 'react';


import {pConfig} from 'src/config';
import axios from 'src/utils/axios';
import * as qs from 'qs'
import useAuth from 'src/hooks/useAuth';
import { useSnackbar } from 'notistack';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import {

  LoginSocialLinkedin,
  IResolveParams
} from 'reactjs-social-login';
interface Props {
    className?: any;
    title?: any;
}   

const LI_REDIRECT_URI= pConfig.LI_REDIRECT_URI;
const LI_CLIENT_ID= pConfig.LI_CLIENT_ID;
const LI_CLIENT_SECRET= pConfig.LI_CLIENT_SECRET;



const SocialLinkedIn: FC<Props> = ({ className,title,...rest }) => {

  const { sociallogin } = useAuth() as any;
    const [provider, setProvider] = useState('');
    const [profile, setProfile] = useState<any>();


    const { enqueueSnackbar } = useSnackbar();
    const isMountedRef = useIsMountedRef();
    const onLoginStart = useCallback(() => {
     // alert('login start');
    }, []);

    const responseSetback = async (profile)=>{
      if(profile && profile.id)
      {

      let userId=profile.id?profile.id:null;
      let providername='linkedin';
      let name=profile.localizedFirstName?profile.localizedFirstName:''+" "+profile.localizedLastName?profile.localizedLastName:'';
      let email=profile.email?profile.email:userId+"@"+providername+'.com';
     
      let accessTokens=profile.access_token?profile.access_token:'';
          try {
              await sociallogin(userId,providername,email,name,accessTokens);
              if (isMountedRef.current) {
              //  setStatus({ success: true });
               // setSubmitting(false);
      enqueueSnackbar('Successfully Loggedin', {
                  variant: 'success',
                });
              }
            } catch (err) {
              console.error(err);
              if (isMountedRef.current) {
              //  setStatus({ success: false });
                // if(err.success == false)
                // {
                //   setErrors({ submit: err.message });
                // }
               // setErrors({ submit: err.message });
                //setErrors({ submit: err.message });
              //  setSubmitting(false);
                
              enqueueSnackbar(err.message, {
                variant: 'error',});
              }
            }
      
          }
      
      
      }



    return (
 <>


<LoginSocialLinkedin
          client_id={LI_CLIENT_ID}
          client_secret={LI_CLIENT_SECRET}
          redirect_uri={LI_REDIRECT_URI}
          onLoginStart={onLoginStart}
          onResolve={({ provider, data }: IResolveParams) => {
            setProvider(provider);
            setProfile(data);
           responseSetback(data);
          }}
          onReject={(err: any) => {
            console.log(err);
          }}
        >
        <a className="linkedin"> <i className="bi bi-linkedin"></i></a>
        </LoginSocialLinkedin>

  </>
    );
};

export default SocialLinkedIn;