import React, { FC, useRef, useState } from 'react';
import axios from 'src/utils/axios';
import ReCAPTCHA from "react-google-recaptcha";

import { useSnackbar } from 'notistack';
import { Field, Form, Formik, FormikHelpers, useField } from 'formik';
import clsx from 'clsx';
import * as Yup from 'yup';
import { useHistory } from "react-router-dom";
import PhoneNumberField from 'src/components/PhoneInput'
const styles = {
    search: {
        position: "relative",
    }
}

interface EnquiryFormFilterProps {
    type?: any;
    page?: any;
}

const EnquiryFormTalkToExpert: FC<EnquiryFormFilterProps> = ({ type, page, ...rest }) => {

    const captchaRef = useRef(null)
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    return (
        <Formik
            initialValues={{

                fullname: '',
                phoneNumber: '',
                Email: '',
                CourseInMind: '',
                College: '',
                Location: '',
                //   message: ''
            }}
            validationSchema={
                Yup.object().shape({
                    fullname: Yup.mixed().required('Name  is Required'),
                    phoneNumber: Yup.string().matches(/^\+91-[6789]\d{9}$|^\+(?!91-)\d{1,3}-\d{1,10}$/, 'Phone number is not valid').required("Phone Number is required"),
                    Email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                })
            }

            onSubmit={async (values, actions) => {
                //  return  alert(JSON.stringify(values, null, 2));
                const formData = new FormData();
                formData.append('name', values.fullname);
                formData.append('contact', values.phoneNumber);
                formData.append('email', values.Email);
                //  formData.append('course_in_mind', values.CourseInMind);
                if (type == 'Exam' || type == 'admissions')
                    formData.append('location', values.Location);
                if (type == 'College' || type == 'University' || type == 'admissions')
                    formData.append('course_in_mind', values.CourseInMind);
                if (type == 'Location')
                    formData.append('location', values.College);
                else if (type == 'Newsletter')
                    formData.append('Newsletter', '');
                else if (type == 'Exam')
                    formData.append('course_in_mind', values.College);
                else
                    formData.append('college_name', values.College);
                //formData.append('location', values.Location);
                // formData.append('description', values.message);
                formData.append('current_url', window.location.href);
                let url = 'api/website/home/enquiry';
                // return alert(JSON.stringify(formData));

                try {
                    let response = await axios.post(url, formData);
                    // console.log('response: ', response);
                    if (response.data.status) 
                    {
                        actions.setSubmitting(true);
                        actions.resetForm({
                            values: {
                                fullname: '',
                                phoneNumber: '',
                                Email: '',
                                CourseInMind: '',
                                College: '',
                                Location: '',
                                //  message: ''
                            },
                        });
                        enqueueSnackbar('Thank you. We will get back to you.', {
                            variant: 'success',
                        });
                        history.push("/thank-you");
                    }
                } catch (err) {
                    console.error(err);
                    enqueueSnackbar("Please try again", {
                        variant: 'error',
                    });
                }
                captchaRef.current.reset();
            }}
        >
            {props => (
                <form onSubmit={props.handleSubmit}>

                    <div className="row">
                        <div className="col-lg-6 form-group">
                            <Field type="text" name="fullname"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.fullname}
                                className="form-control" id="name" placeholder="Enter Name" />
                            {props.errors.fullname && props.touched.fullname && <p className="error">{props.errors.fullname}</p>}
                        </div>

                        <div className="col-lg-6 form-group">
                            <Field type="email" className="form-control" name="Email"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.Email}
                                id="Email" placeholder="Enter Email"
                            />
                            {props.errors.Email && props.touched.Email && <p className="error">{props.errors.Email}</p>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 form-group react-phone-p-0">
                        <PhoneNumberField /> 
                            {/* <Field type="tel" className="form-control" name="phoneNumber"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.phoneNumber}
                                id="PhoneNumber" placeholder="Enter Phone Number"
                            /> */}
                            {props.errors.phoneNumber && props.touched.phoneNumber && <p className="error">{props.errors.phoneNumber}</p>}
                        </div>

                        {type == 'College' || type == 'admissions' || type == 'University'? <><div className="col-lg-6 form-group">
                            <Field type="text" className="form-control" name="CourseInMind"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.CourseInMind}
                                id="CourseInMind" placeholder={"Interested Course"} />
                        </div>
                        </> : ''}

                        { type == 'University'   ? 
                        
                       
                        <div className="col-lg-6 form-group">
                            <Field type="text" className="form-control" name="College"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.College}
                                id="College" placeholder="University Name"
                            />  </div> : ''}

                        {type != 'Newsletter' && type != 'Exam' && type != 'University'   ? 
                        
                       
                        <div className="col-lg-6 form-group">
                            <Field type="text" className="form-control" name="College"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.College}
                                id="College" placeholder={type == 'admissions' ? "Interested College" : 'Enter ' + type}
                            />  </div> : ''}


{type == 'admissions' ? 
                        <>
                           
                            <div className="col-lg-6 form-group">
                                <Field type="text" className="form-control" name="Location"
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.Location}
                                    id="Location" placeholder={page == 'blogdetail' ? "Location" : 'Enter Location'}
                                /> 
                            </div>
                        </> 
                        : ''}

                        {type == 'Exam' ? 
                        <>
                            <div className="col-lg-6 form-group">
                                <Field type="text" className="form-control" name="College"
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.College}
                                    id="College" placeholder={"Interested Course"} />
                            </div>
                            <div className="col-lg-6 form-group">
                                <Field type="text" className="form-control" name="Location"
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.Location}
                                    id="Location" placeholder={page == 'blogdetail' ? "Location" : 'Enter Location'}
                                /> 
                            </div>
                        </> 
                        : ''}
                    </div>
                    <div className="text-center">
                        <button type="submit" className={type == 'admissions' ? "submit_message long_submit_message" : "submit_message"}>{type == 'admissions' ? 'Talk to our Experts' : 'Submit'}</button>
                    </div>
                </form>
            )}
        </Formik>
    );
};

export default EnquiryFormTalkToExpert;