import React, { FC, useEffect, useState, useContext,useCallback } from 'react';
import SettingsContext from 'src/contexts/SettingsContext';
import axios from 'src/utils/axiosintercpt';
import { useHistory,useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import Breadcrumbs from './Breadcrumbs';
import Header from './InnerHeader';
import OverviewSection from './OverviewSection';
import TopColleges from './TopColleges';

import { pConfig } from 'src/config';  
import Helmet from 'react-helmet';


const useStyles = makeStyles(() => ({
  root: {}
}));

const HomePage: FC = () => {
  const classes = useStyles();
  const { streamslug, slug } = useParams();
  const history = useHistory();
  const setCtx = useContext(SettingsContext);
  const [courseStreamArr, setCourseStreamArr] = useState([]);
  const [topCollegeArr, setTopCollegeArr] = useState([]);
  const [isHeroLoaded, setHeroLoaded] = useState(false);
  const [isTopColLoaded, setTopColLoaded] = useState(false);
  const isMountedRef = useIsMountedRef();
  const [coursedetail, setcoursedetail] = useState([]);
  const [newcourseStreamArr, setNewCourseStreamArr] = useState<any>([]);
  const [subcourse, setsubcourse] = useState([]);
  const [promoBannerArr, setPromoBannerArr] = useState<any>([]);
  const [metatitle, setmetatitle] = useState<any>([]);
  const [metadescription, setmetadescription] = useState<any>([]);

  const getHeroData = async ()=>{
    const formData = new FormData();
    formData.append('getdata', JSON.stringify([{data: "course_streams_head"}]));
    const heroResp = await axios.post('api/website/home/header', formData);
    
    setCourseStreamArr(heroResp.data.data.course_streams_head);
    
    setCtx.setCourseData(heroResp.data.data.course_streams_head);
    setHeroLoaded(true);
  }
  
  const getsubcourse = useCallback(async () => {
    try {
      const id = slug;//'engineering';
      const response = await axios.get('api/website/home/courses/'+id);
      if (isMountedRef.current) {
        setsubcourse(response.data.data);


          //  console.log("response.data.data");
        // console.log(response.data.data);
        // setcoursedetail(response.data.data);
        setmetatitle(response.data.data.meta_title);
        setmetadescription(response.data.data.meta_description);
      }
    } catch (err) {

      console.error(err);
      history.push("/404");
    }
  }, [isMountedRef]);

  const getCollageUnivData = async ()=>{
    const formData = new FormData();
    formData.append('getdata', JSON.stringify([{data: "college"}]));
    const univColgResp = await axios.post('api/website/home/topcrousel', formData);

    setTopCollegeArr(univColgResp.data.data.topcollege.data);
    setTopColLoaded(true);
  }

  const getdatabystreamid = useCallback(async () => {
    try {
     
      const id = streamslug;//slug;//'engineering';
      const response = await axios.get('api/admin/stream/get/'+id);
      if (isMountedRef.current) {
        // alert("asfd");
        // console.log("response.data.data");
        // console.log(response.data.data);
        setcoursedetail(response.data.data);
        // setmetatitle(response.data.data.meta_title);
        // setmetadescription(response.data.data.stream_description);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  const getCourseData = async ()=>{
    const formData = new FormData();
    //formData.append('getdata', JSON.stringify([{data: "college"},{data: "university"},{data: "exam"},{data: "testimonial"}]));
    const getstreams = await axios.get('api/website/home/streams?columnname=listing_order&orderby=asc&home_view_status=home_page&page=1&size=10000');
    setNewCourseStreamArr(getstreams.data.data);
  }

  const getpromobanner = useCallback(async () => {
    try {
      const response = await axios.get('api/website/promobanner?promo_banner=All_Exam_page');
      if (isMountedRef.current) 
      {
        setPromoBannerArr(response.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(()=>{
    getHeroData();
    getsubcourse();
    getCollageUnivData();
    //geBlogData();
    getdatabystreamid();
    getCourseData();
    //getpromobanner();
  }, []);

  return (
    <>
    {/* {isTopColLoaded ? 
      <> */}
        <Helmet>
          <title>{metatitle && metatitle != '' ? metatitle : 'Get Admission at Top Colleges in Bangalore'}</title>
          <meta name="description" content={metadescription && metadescription != '' ?  metadescription : 'Looking for College/ University/ School admission? Bangalorestudy.com helps you with a list of Colleges, Universities and Schools to pursue your education in Bangalore. Call us now'} />
          <link rel="canonical" href={`${pConfig.siteUrl}courses/${streamslug}/${slug}`} />
        </Helmet>
        <Header subcourse={subcourse} coursedetail={coursedetail}/>
        <Breadcrumbs subcourse={subcourse} streamslug={streamslug}/>
        <OverviewSection subcourse={subcourse} streamslug={streamslug} coursedetail={coursedetail} newcourseStreamArr={newcourseStreamArr} promoBannerArr={promoBannerArr}/>
        {isTopColLoaded && <>
          <TopColleges subcourse={subcourse} topCollegeArr={topCollegeArr}/>
        </>}
        
      {/* </>
      :
      <p style={{height: '8000px'}}>Loading</p> } */}
    </>
  );
};

export default HomePage;