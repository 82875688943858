import React, { FC, useEffect, useState, useContext, useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import './styles.css';
import EnquiryDetailForm from 'src/components/EnquiryDetailForm';
import TopManagementCourseBG from 'src/assets/img/top-managment-course.jpg';
import { config } from 'src/config';

const useStylesBootstrap = makeStyles(() => ({
    arrow: {
        color: '#000',
    },
    tooltip: {
        backgroundColor: '#000',
    },
}));

function BootstrapTooltip(props: TooltipProps) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

const Breadcrumbs: FC<{ nriquota: any, promoBannerArr : any }> = ({ nriquota, promoBannerArr }) => {
    const [num, setNum] = React.useState(331231);

    return (
        <section id="entrance-exam-section" className="services">
            <div className="container aos-init aos-animate" >
                <div className="row">
                    <div className="col-lg-8 left-exam-section" >
                        <div className="col-lg-12 our_portal">
                            {/* <h3 className="main-heading left-align">NRI Quota <span>Admission</span></h3> */}
                            <span dangerouslySetInnerHTML={{ __html: nriquota.editor }} />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="widgets get-details-now-form right-widget">
                            <h4 className="title-heading">Get <span>Details</span> Now</h4>
                            <EnquiryDetailForm/>
                            {/* <form action="forms/contact.php" method="post" role="form" className="php-email-form">

                                <div className="form-group">
                                    <input type="text" className="form-control" name="subject" id="subject" placeholder="Enter Your Name" />
                                </div>

                                <div className="row">
                                    <div className="col-lg-6 form-group">
                                        <input type="email" name="name" className="form-control" id="name" placeholder="Enter Email" />
                                    </div>
                                    <div className="col-lg-6 form-group">
                                        <input type="tel" className="form-control" name="email" id="email" placeholder="Enter Number" />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <input type="text" className="form-control" name="subject" id="subject" placeholder="Enter Course" />
                                </div>

                                <div className="form-group">
                                    <input type="text" className="form-control" name="subject" id="subject" placeholder="Enter Location" />
                                </div>

                                <div className="form-group">
                                    <textarea className="form-control" name="message" placeholder="Type Your Message" ></textarea>
                                </div>
                                <div className="text-center"><button type="submit">Submit</button></div>
                            </form> */}
                        </div>

                        {
                            promoBannerArr && promoBannerArr.map((i) => {
                                let banner_image = i.image ? config.baseUrlfromconfig + i.image : TopManagementCourseBG;
                                return (
                                    <>
                                        <div className="widgets right-lerge-news top-manag-course-widget text-center" style={{ background: `url(${banner_image})` }}>
                                            <div className="course-center">
                                                <h2>&nbsp;</h2>
                                                <p>&nbsp;</p>
                                                {/* <h2>Top Management Courses in Bangalore</h2>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p> */}
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }

                        {/* <div className="widgets top-manag-course-widget text-center" style={{ background: `url(${TopManagementCourseBG})` }}>
                            <div className="course-center">
                                <h2>Top Management Courses in Bangalore</h2>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                            </div>
                        </div>

                        <div className="widgets top-manag-course-widget text-center" style={{ background: `url(${TopManagementCourseBG})` }}>
                            <div className="course-center">
                                <h2>Top Management Courses in Bangalore</h2>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Breadcrumbs;