import React, { FC, useState } from 'react';
import './styles.css';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const TopSchools: FC = () => {
    const owlOptions = {
        margin: 20, nav: true, autoplay: false,
        responsiveClass: true,
        responsive: {
            0: {items:1},
            400: {items:1},
            600: {items: 1},
            700: {items: 4}
        },
    };

    return (
        <section className="top-management-colleges container-fluid spac_01 light-color-bg"  data-aos="fade-up">
            <div className="container">
            <div className="row">
                <div className="col-lg-12 our_portal">

                    <h3 className="main-heading">Top <b>Schools</b> in Karnataka</h3>
                    {/* <p className="text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit, <br/>sed do tempor incididunt ut labore et dolore. </p> */}
                    <div className="spac_02"></div>
                    <p>The hunt for a perfect school begins after a child turns 3. Parents are haunted by hundreds of questions and they start their enquiry sessions to find the best school for their child. The decision is never easy as there are alot of options to choose from. </p>
                    <p>It is a tedious task to find the perfect school and getting lost in the process is quite normal. Remember to enrol your child/ children in the school that suits them rather than opting for a school everyone chooses.</p>
                    <p>Numerous people reside in the massive state of Karnataka. With increasing population, the enrolment rate into schools of Karnataka has also increased considerably. Due to the large demand, the number of schools in Karnataka is also increasing day by day.</p>

                    <p>A school education in Karnataka will lay the perfect foundation to one’s career. Schools in Karnataka provide quality education and encourage students to participate in extracurricular activities. With the literacy rate scaling upwards each year, pursuing school education in Karnataka will be one of the best decisions for your child.</p>

                    <p>There are both Government Schools and Private Schools in Karnataka. Parents can choose between a private school in Karnataka and a public school according to facilities available and the convenience of the student.</p>

                    <p>Karnataka Private Schools have better infrastructure and good teaching faculties. They also give importance to sports and other extracurricular activities and help in the overall growth of a child. Karnataka Public Schools are well managed and education is imparted in the mother tongue as well as English.</p>
                    <p> Karnataka Private schools follow the syllabus of one of the boards mentioned below:</p>

                    <ul>
                        <li>Karnataka School Examination and Assessment Board (KSEAB)/ State Board</li>
                        <li>Indian Certificate of Secondary Education (ICSE)</li>
                        <li>Central Board of Secondary Education (CBSE) </li>
                    </ul>

                    <p>The State Board Programme is offered by a long list of schools in Karnataka. Students who have enrolled in a school that offers State Board Programme need to take up the SSLC exam in 10th grade to acquire the Secondary School Leaving Certificate (SSLC). The KSEAB conducts the SSLC examination.</p>
                    <p>The government of India is in charge of the Central Board of Secondary Education (CBSE). CBSE is a nationalised board of education for both government and private schools. The headquarters is located in Delhi. Most of the top 5 schools in Karnataka offer a CBSE programme.</p>
                    <p>The Indian Board of Secondary Education (ICSE) is the second-most popular choice and is offered by some of the top rated schools in Karnataka. The ICSE exam is conducted by the Council for the Indian Certificate of Secondary Education (CISCE). The headquarters is located at Delhi.</p>
                    <p>Picking an ICSE or CBSE Programme is quite common. But Schools offering the State Board Program are excellent as well. Various accomplished State Board Schools come under top Schools in Karnataka.</p>
                    <p>Schools are built in every nook and corner of Karnataka, and every district has top notch schools. To enrol your child in a school that comes under top 10 schools in Karnataka is your choice, but make sure to do due diligence & research before finalising your choice.</p>
                    <p>Let's narrow down the spectrum from the entire state of Karnataka to the capital city and look into the list of best private schools in Bangalore.</p>
                    <p><b>Top Schools in Bangalore: </b></p>
                    <p>Bangalore is the IT hub of India. People from all over the country come to Bangalore in search of a job. More crowded than it has ever been in the past, Bangalore has some of the most prestigious schools in Karnataka.</p>
                    <p>Ranging from International Schools to Online Schools the list of Schools in Bangalore are endless. The different types of Schools in Bangalore are listed below: </p>
                    <ul>
                        <li>International Schools</li>
                        <li>National Schools</li>
                        <li>Schools for Disabled</li>
                        <li>Pre Schools</li>
                        <li>Primary Schools</li>
                        <li>Home Schooling</li>
                    </ul>
                    <p><b>International Schools: </b></p>
                    <p>International Schools are very trendy in recent times. The multicultural environment in these schools allow students to enrich their knowledge about different cultures and also build their base to acquire a degree from a foreign university in the future. Hence, parents are bound to admit their children into internationally recognized top Schools in Bangalore.</p>
                    <p>International schools follow the foreign education curriculum and it is recognised globally. This will help students to move seamlessly from one country to another. Many countries in the world including some of the top schools in Bangalore (International Schools) follow the International Baccalaureate (IB) programme. </p>
                    <p>International Schools are super advanced and their quality of education is excellent. Some of the famous schools in Bangalore provide facilities like Swimming Pool, Indoor Games, Smart Classes, Robotics Lab, Basketball Court and more. </p>
                    <p><b>National Schools: </b></p>
                    <p>National Schools are schools that are located in different parts/states of the country. Parents who get frequent transfers can opt to admit their children into a National school. There are various National public and private schools in Bangalore.</p>
                    <p>The CBSE program is followed by National Schools in Bangalore. The top 10 best schools in Bangalore provide facilities like Digitized classrooms, health and physical education, skating rinks, AC buses, hostels and more.</p>
                    <p><b>Pre Schools: </b></p>
                    <p>Preschools or play schools are for children between the ages of three and six. These schools are established to provide an early childhood education. The top 20 schools in Bangalore help children read, write and understand some basics of science and maths. They are trained to become school ready.</p>
                    <p><b>Primary Schools: </b></p>
                    <p>These are schools that offer only Primary education which is from grade 1 to grade 5. Most of the top best schools in Bangalore are divided into Primary School, Middle School and High School. But there are a lot of schools that exclusively provide only Primary Education.</p>
                    <p><b>Schools for Disabled: </b></p>
                    <p>Schools for disabled are also called Special Schools. These schools provide education for students taking their disability into consideration. Schools plan different teaching procedures and adapt equipment or material learning systems. Good schools in Bangalore offer financial support as well.</p>
                    <p>Special Schools admission criteria have no upper age limit. These schools provide counselling for both parents and their children with disabilities. Free books, uniforms and learning aids are provided to students in some of the schools.</p>
                    <p><b>Home Schooling: </b></p>
                    <p>If you are constantly googling 'Best Schools in Bangalore near me' and nothing pops in the vicinity then homeschooling is the best choice for you. Home Schooling is the latest form of education and is gaining popularity over time. Cities like Bangalore and Mumbai offer home schooling. </p>
                    <p>This modern way of education is a way of educating children at the comfort of their homes rather than going to a public or private school. The main focus is on what the child is interested in. Individual attention is provided to every child and hence children improve both emotionally and academically.</p>
                    <p>Different education boards have different curriculums. The general curriculum of the top 10 schools in Bangalore which includes CBSE, ICSE and State Boards are as follows:</p>
                    <ul>
                        <li>Languages</li>
                        <li>Maths</li>
                        <li>Science</li>
                        <li>Social Science</li>
                    </ul>
                    <p>and many more including extracurricular activities.</p>
                    <p>Students spend most of their time in Schools. The duration of each stage of education is mentioned below:</p>
                    <p>Nursery School - 2 Years<br/>Primary School - 4 Years<br/>Middle School - 3 Years<br/>High School - 3 Years</p>
                    <p>Some of the top schools in North Bangalore and the top schools in south Bangalore offer Co-ed systems. But there are various Schools that provide education exclusively for Boys and exclusively for girls. Boys are allowed to study up to 4th standard in selective Girls School.</p>
                    <p>If you're looking for top 5 Schools in Bangalore or the cheapest private schools in Bangalore, reach out to our Counselors for School Admissions.</p>
                </div>
            </div>
            </div>
        </section>
    );
};

export default TopSchools;