import React, { FC } from 'react';
import './styles.css';

import BestCollegeBG from 'src/assets/img/best-colleges.jpg';
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';
import { config } from 'src/config';

const Interested: FC<{ promoBannerArr: any }> = ({ promoBannerArr }) => {

    return (
        <>
        <section className="best-college container-fluid spac_01" data-aos="fade-up">
            <div className="container">
                <div className="row">
                    <div className="row best-management-in-course text-center" style={{ background: `url(${BestCollegeBG})` }} >
                        <div className="course-center">
                            <h2>Interested in <span>Our Services?</span></h2>
                            <p>Looking for admissions or interested in partnering with us? Get in touch to take it forward!</p>
                            <div className="text-center"><GlobalPopupEnquiry  className="btn btn btn-primary"  title="Connect with us!" /></div>
                            {/* <div className="text-center"><button type="button">Connect with us!</button></div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {
            promoBannerArr && promoBannerArr.map((i) => {
                let banner_image = i.image ? config.baseUrlfromconfig + i.image : BestCollegeBG;
                return (
                    <>
                    <section className="container-fluid spac_01 "  data-aos="fade-up">
                        <div className="container">
                            <div className="row">
                                <div className="row interested-in-course text-center" style={{ background: `url(${banner_image})` }}>
                                    <div className="course-center">
                                        <h2>&nbsp;</h2>
                                        <p>&nbsp;</p>
                                    </div>
                                </div>
                            </div>     
                        </div>
                    </section>
                    </>
                )
            })
        }
        </>
    );
};

export default Interested;