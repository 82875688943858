import React, { FC, useEffect, useState, useContext, useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import './styles.css';
import EnquiryDetailForm from 'src/components/EnquiryDetailForm';
import UpcomingImage from 'src/assets/img/upcoming-exam.jpg';
import EducationCap from 'src/assets/img/education-cap.png';
import HeadingBg from 'src/assets/img/heading-bg.png';
import TopManagementCourseBG from 'src/assets/img/top-managment-course.jpg';
import { config } from 'src/config';
import Pagination from '@material-ui/lab/Pagination/Pagination';

const useStylesBootstrap = makeStyles(() => ({
    arrow: {
        color: '#000',
    },
    tooltip: {
        backgroundColor: '#000',
    },
}));

function BootstrapTooltip(props: TooltipProps) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

const Breadcrumbs: FC<{page:any,totalpage:any,handlePageChange,isgetload,isFetching,HasMore,getdataexam,examlist: any,upcomingdataexam: any, courseStreamArr:any, promoBannerArr:any }> = ({ page,totalpage,handlePageChange,isgetload,isFetching, HasMore, getdataexam, examlist, upcomingdataexam, courseStreamArr , promoBannerArr}) => {
    const [num, setNum] = React.useState(331231);
    console.log("totalpage");

    console.log(totalpage);

    return (
        <section id="entrance-exam-section" className="services">
            <div className="container aos-init aos-animate" >
                <div className="row">
                    <div className="col-lg-8 left-exam-section top-colleges examlistsection" >
                        {
                            examlist && examlist.map((i) => {
                                let examlink = '/exams/'+i.slug;
                                return (
                                    <div className="col-xl-4 col-lg-4 col-md-6">
                                        <div className="article-loop examcard">
                                            <a href={examlink}>
                                                <img src={ i.cover_image ? config.baseUrlfromconfig + i.cover_image : UpcomingImage} alt={ i.exam_short_name ? i.exam_short_name : i.exam_title}  className="img-fluid" />
                                                <BootstrapTooltip placement="top" title={<span style={{ fontSize: "14px" }}>{i.exam_title}</span>}>
                                                    <h2>{ i.exam_short_name ? i.exam_short_name : i.exam_title}</h2>
                                                </BootstrapTooltip>
                                            </a>
                                        </div>
                                    </div>
                                )
                            })
                        }
                      <div className="spac_02"></div>
                        <div className="button-section text-center clearfix">
                        <Pagination
                            page={page}
                            variant="outlined"
                            shape="rounded"
                            onChange={handlePageChange}
                            count={totalpage}
                            className="pagination"
                            />
                        </div>
                        <div className="spac_02"></div>
                   
              {/*           <div className="spac_02"></div>
                        <div className="button-section text-center clearfix">
                            {isFetching && <p>Loading more data...</p>}
                            {!isFetching && HasMore && (
                                <button className="button btn" 
                                onClick={() => getdataexam(page+1,examlist)}
                                >Load more</button>
                            )}
                        </div>
                        <div className="spac_02"></div> */}
                    </div>

                    <div className="col-lg-4">
                        <div className="widgets get-details-now-form right-widget">
                            <h4 className="title-heading">CONTACT US</h4>

                            <EnquiryDetailForm type="admissions"/>
                            {/* <form action="forms/contact.php" method="post" role="form" className="php-email-form">

                                <div className="form-group">
                                    <input type="text" className="form-control" name="subject" id="subject" placeholder="Enter Your Name" />
                                </div>

                                <div className="row">
                                    <div className="col-lg-6 form-group">
                                        <input type="email" name="name" className="form-control" id="name" placeholder="Enter Email"  />
                                    </div>
                                    <div className="col-lg-6 form-group">
                                        <input type="tel" className="form-control" name="email" id="email" placeholder="Enter Number" />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <input type="text" className="form-control" name="subject" id="subject" placeholder="Enter Course"  />
                                </div>

                                <div className="form-group">
                                    <input type="text" className="form-control" name="subject" id="subject" placeholder="Enter Location"  />
                                </div>

                                <div className="form-group">
                                    <textarea className="form-control" name="message" placeholder="Type Your Message" ></textarea>
                                </div>
                                <div className="text-center"><button type="submit">Submit</button></div>
                            </form> */}
                        </div>

                        <div className="widgets top-streams-widget streamwidget">
                            <div className="large-heading-bg text-center" style={{ background: `url(${HeadingBg})` }} >
                                <h2><img src={EducationCap} /> Top Streams</h2>
                            </div>
                            <ul>
                                {courseStreamArr && courseStreamArr.map((el) => {
                                    let link_course = "/courses/" + el.stream_slug;
                                    return (
                                        <li><a href={link_course}>{el.stream_name}</a></li>
                                    )
                                })}
                               
                            </ul>
                        </div>

                        {
                            promoBannerArr && promoBannerArr.map((i) => {
                                let banner_image = i.image ? config.baseUrlfromconfig + i.image : TopManagementCourseBG;
                                return (
                                    <>
                                        <div className="widgets top-manag-course-widget text-center" style={{ background: `url(${banner_image})` }}>
                                            <div className="course-center">
                                                <h2>&nbsp;</h2>
                                                <p>&nbsp;</p>
                                                {/* <h2>Top Management Courses in Bangalore</h2>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p> */}
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }

                        {/* <div className="widgets top-manag-course-widget text-center" style={{ background: `url(${TopManagementCourseBG})` }}>
                            <div className="course-center">
                                <h2>Top Management Courses in Bangalore</h2>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Breadcrumbs;