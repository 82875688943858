import React, { FC } from 'react';
import './styles.css';
import CourseHeaderBG from 'src/assets/img/new-course-Courses.jpg';
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';

//import { config } from 'src/config';

const SearchHeader: FC = () => {

    return (
        <section className="inner-top-header" id="hero" style={{ background: `url(${CourseHeaderBG})` }} data-aos="fade-up">
            <div className="hero-container d-flex">
                <div className="container" data-aos="fade-up">
                    <div className="row top-course-block">
                        <div className="top-course-header text-center">
                            <h1 className='d-inline-block px-3'>Our <span> Services</span> </h1>
                            <div className="spac_02"></div>
                            <div className='d-flex justify-content-center'>
                            <GlobalPopupEnquiry className="talk-to-expert btn btn btn-primary" />
                            </div>

                        </div>
                        {/* <div className="top-course-header">
                            <h1>Our <span>Services</span></h1>
                            <div className="spac_02"></div>
                            <div className='floatleft'>
                                <button type="button" className="talk-to-expert btn btn btn-primary" data-toggle="modal" data-target="#getanexam-alert">Apply Now</button>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SearchHeader;