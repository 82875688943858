import React, { FC } from 'react';
import './styles.css';
//import ManagementCourseBG from 'src/assets/img/management-courses.jpg';
import CourseHeaderBG from 'src/assets/img/new-course-Courses.jpg';
// import CallNow from 'src/assets/img/call-now.svg';
// import SearchIcon from 'src/assets/img/icons/search.svg';
// import EntranceExam from 'src/assets/img/entrance-exam.jpg';
// import IIITBLOGO from 'src/assets/img/iiitb_logo.png';
// import moment from 'moment';
// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
// import { config } from 'src/config';
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';

const SearchHeader: FC<{ }> = ({ }) => {
    // const [num, setNum] = React.useState(331231);
    
    // const owlOptions = {
    //     margin: 20, nav: true, autoplay: false,
    //     responsiveClass: true,
    //     responsive: {
    //         0: { items: 1 },
    //         400: { items: 1 },
    //         600: { items: 1 },
    //         700: { items: 3 }
    //     },
    // };

    return (
        <section className="inner-top-header" id="hero" style={{ background: `url(${CourseHeaderBG})` }} >
            <div className="hero-container d-flex">
                <div className="container" >
                    <div className="row top-course-block">
                        <div className="top-course-header text-center">
                            <h1 className='d-inline-block px-3'>NRI <span> Quota</span> </h1>
                            <div className="spac_02"></div>
                            <div className='d-flex justify-content-center'>
                            <GlobalPopupEnquiry className="talk-to-expert btn btn btn-primary" />
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SearchHeader;