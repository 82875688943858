import React, { FC, useEffect, useState, useContext, useCallback } from 'react';
import SettingsContext from 'src/contexts/SettingsContext';
import axios from 'src/utils/axiosintercpt';
import { useParams, useHistory } from "react-router-dom";
import { CircularProgress, makeStyles } from '@material-ui/core';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import Breadcrumbs from './Breadcrumbs';
import Header from './InnerHeader';
import OverviewSection from './OverviewSection';
import TopColleges from './TopColleges';
import Talk from './Talk';

import { pConfig } from 'src/config';
import Helmet from 'react-helmet';


const useStyles = makeStyles(() => ({
  root: {}
}));

const CollegeCoursePage: FC = () => {
  const history = useHistory();
  const { collegeslug, courseslug } = useParams();
  const [topCollegeArr, setTopCollegeArr] = useState([]);
  const [isTopColLoaded, setTopColLoaded] = useState(false);
  const [metatitle, setMetatitle] = useState("");
  const [metadescription,setMetadescription] = useState("");
  const isMountedRef = useIsMountedRef();
  const [subcourse, setsubcourse] = useState([]);
  const [collegCourseArr, setCollegeCourseArr] = useState<any>([]);

  const [isSubCOurseLoaded, setSubCOurseLoaded] = useState(true);



  const getsubcourse = useCallback(async () => {
    try {
      const id = courseslug;
      const response = await axios.get('api/website/home/collegecourses/' + id + "?collegeslug=" + collegeslug);
      if (isMountedRef.current) {
        setsubcourse(response.data.data.rows[0]);
        console.log("setsubcourse");
        console.log(subcourse);
        if(response.data.data.rows[0]){
          setMetatitle(response.data.data.rows[0].meta_title);
          setMetadescription(response.data.data.rows[0].meta_description);
        }
        if (response.data.data1) {

          let collegearray = response.data.data1.colllegegroup && response.data.data1.colllegegroup.length > 0 ? response.data.data1.colllegegroup : [];
          setTopCollegeArr(collegearray);
        }
     
        setSubCOurseLoaded(true);
        setTopColLoaded(true);
      }
    } catch (err) {
      history.push("/404");
      console.error(err);
    }
  }, [isMountedRef]);






  useEffect(() => {
    getsubcourse();
  }, []);

  return (
    <>
      {isSubCOurseLoaded ?
        <>

      
            <Helmet>
              <title>{metatitle && metatitle != '' ? metatitle : 'Get Admission at Top Colleges in Bangalore'}</title>
              <meta name="description" content={metadescription && metadescription != '' ? metadescription : 'Looking for College/ University/ School admission? Bangalorestudy.com helps you with a list of Colleges, Universities and Schools to pursue your education in Bangalore. Call us now'} />
              <link rel="canonical" href={`${pConfig.siteUrl}${collegeslug}/courses/${courseslug}`} />
            </Helmet>
         

          <Header collegeslug={collegeslug} subcourse={subcourse} />
          <Breadcrumbs subcourse={subcourse} collegeslug={collegeslug} />
          <OverviewSection subcourse={subcourse} />
          <Talk />
          {isTopColLoaded && <>
            <TopColleges subcourse={topCollegeArr} />
          </>}

        </>
        :
        <div style={{ display: 'flex', justifyContent: 'center', height: '600px', flexWrap: 'wrap', alignContent: 'space-around' }}><CircularProgress /></div>}
    </>
  );
};

export default CollegeCoursePage;