import axios from 'axios';
import { pConfig } from 'src/config';


const axiosInstance = axios.create({
  baseURL: pConfig.baseUrl,
  // baseURL: "http://localhost:8081/",
 });


axiosInstance.interceptors.response.use(
  (response) => {
    if (response.data) {
      if (response.status === 200 || response.status === 201) {
        return response;
      }
      return Promise.reject(response);
    }
    return Promise.reject(response);
  },
  (error) =>{
    console.log(error.response);
    return Promise.reject((error.response && error.response.data) || 'Something went wrong')
  } 
);

export default axiosInstance;
