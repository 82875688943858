import React, { FC, useEffect, useState, useContext, useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import './styles.css';
import NewsBG from 'src/assets/img/news.jpg';
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    PinterestShareButton,
    PinterestIcon,
    LinkedinShareButton,
    LinkedinIcon,
    WhatsappShareButton,
    WhatsappIcon
} from "react-share";
import EntranceExam from 'src/assets/img/entrance-exam.jpg';
import TopManagementCourseBG from 'src/assets/img/top-managment-course.jpg';
import GetMoreBlog from 'src/assets/img/get-more-blog.jpg';
import { config } from 'src/config';
import EnquiryDetailForm from 'src/components/EnquiryDetailForm';
import moment from 'moment';
import { pConfig } from 'src/config';
import GlobalPopupShare from 'src/components/GlobalPopupShare';
import Pagination from '@material-ui/lab/Pagination/Pagination';
import CollegeIMG from 'src/assets/img/college.jpg';

const useStylesBootstrap = makeStyles(() => ({
    arrow: {
        color: '#000',
    },
    tooltip: {
        backgroundColor: '#000',
    },
}));

function BootstrapTooltip(props: TooltipProps) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

const Breadcrumbs: FC < {page:any, blogdata:any, isFetching, HasMore, getblogdata, totalpage:any, handlePageChange, topCollegeArr}> = ({page, blogdata, isFetching,HasMore, getblogdata, totalpage, handlePageChange, topCollegeArr})=> {
    const [num, setNum] = React.useState(331231);

    return (
        <section className="course-overviews-section ">
            <div className="container aos-init aos-animate" >
                <div className="row">
                    <div className="col-lg-8 top-blogs" >
                        {
                            blogdata && blogdata.map((i) => {
                                let bloglink = '/blog/'+i.slug;
                                const pathname = pConfig.siteUrl+bloglink;//window.location.href;
                                //console.log(pathname)
                                const logoUrl = i.cover_image ? config.baseUrlfromconfig + i.cover_image : NewsBG;
                                return (
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 blog-post">
                                        
                                        {/* <a href={bloglink}> */}
                                        <img alt={i.title} src={ i.cover_image ? config.baseUrlfromconfig + i.cover_image : GetMoreBlog} className="img-fluid" />
                                        <div className="blog-content">
                                            <BootstrapTooltip placement="top" title={<span style={{ fontSize: "14px" }}>{i.title}</span>}>
                                                <a href={bloglink}> <h3>{i.title}</h3></a>
                                            </BootstrapTooltip>
                                            <p>&nbsp;</p><p>&nbsp;</p>
                                            {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
                                            {/* <span className="posted-on-date">Posted On: {moment(i.created_at).format('D-MMM-YYYY')}</span> */}
                                            
                                            {/* <a className="share-this" data-toggle="modal" data-target="#share-this"><i className="bi bi-share-fill"></i> */}
                                            {/* <nav id="navbar" className={`navbar navbar-expand-lg`}>
                                                <ul 
                                                    className="navbar-nav me-auto ps-lg-0 mainnav" 
                                                    style={{paddingLeft: '0.15rem'}} 
                                                >
                                                    <li className="dropdown">                                                    
                                                                                                            
                                                        <a className="share-this" data-toggle="modal" data-target="#share-this"><i className="bi bi-share-fill"></i></a>
                                                        <ul className='secondnavbar'>                                                        
                                                        <li>
                                                                <FacebookShareButton url={pathname}>
                                                                    <FacebookIcon size={32} round={true}/>
                                                                </FacebookShareButton>                           
                                                            </li>
                                                            <li>
                                                                <TwitterShareButton url={pathname}>
                                                                    <TwitterIcon size={32} round={true}/>
                                                                </TwitterShareButton>                           
                                                            </li>
                                                            <li>
                                                                <PinterestShareButton url={pathname} media={logoUrl}>
                                                                    <PinterestIcon size={32} round={true}/>
                                                                </PinterestShareButton>                           
                                                            </li>
                                                            <li>
                                                                <LinkedinShareButton url={pathname}>
                                                                    <LinkedinIcon size={32} round={true}/>
                                                                </LinkedinShareButton>                           
                                                            </li>
                                                            <li>
                                                                <WhatsappShareButton url={pathname}>
                                                                    <WhatsappIcon size={32} round={true}/>
                                                                </WhatsappShareButton>                           
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </nav> */}
                                            {/* </a> */}
                                            <div className="button_wrp">
                                                <a href={bloglink} className='firstlink'>View Details</a><GlobalPopupShare pathname={pathname} title={i.title} logourl={logoUrl}/>
                                            </div>
                                            <p>&nbsp;</p>
                                        </div>
                                        {/* </a> */}
                                    </div>
                                )
                            })
                        }

                    <div className="spac_02"></div>
                    <div className="button-section text-center clearfix">
                        <Pagination
                            page={page}
                            variant="outlined"
                            shape="rounded"
                            onChange={handlePageChange}
                            count={totalpage}
                            className="pagination"
                            />
                    </div>
                        
                        {/* <div className="spac_02"></div>
                        <div className="button-section text-center clearfix">
                            {isFetching && <p>Loading more data...</p>}
                            {!isFetching && HasMore && (
                                <button className="button btn" 
                                onClick={() => getblogdata(page+1,blogdata)}
                                >Load more</button>
                            )}
                        </div> 
                        <div className="spac_02"></div>*/}
                    </div>

                    <div className="col-lg-4" >
                        <div className="widgets get-details-now-form right-widget">
                        
                            <h4 className="title-heading">Talk to our <span>Experts</span></h4>
                            <EnquiryDetailForm type="admissions" />
                            {/* <form action="forms/contact.php" method="post" role="form" className="php-email-form">

                                <div className="form-group">
                                    <input type="text" className="form-control" name="subject" id="subject" placeholder="Enter Your Name" />
                                </div>

                                <div className="row">
                                    <div className="col-lg-6 form-group">
                                        <input type="email" name="name" className="form-control" id="name" placeholder="Enter Email" />
                                    </div>
                                    <div className="col-lg-6 form-group">
                                        <input type="tel" className="form-control" name="email" id="email" placeholder="Enter Number" />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <input type="text" className="form-control" name="subject" id="subject" placeholder="Enter Course" />
                                </div>

                                <div className="form-group">
                                    <input type="text" className="form-control" name="subject" id="subject" placeholder="Enter Location" />
                                </div>

                                <div className="form-group">
                                    <textarea className="form-control" name="message" placeholder="Type Your Message" ></textarea>
                                </div>


                                <div className="text-center"><button type="submit">Submit</button></div>
                            </form> */}

                        </div>

                        <div className="widgets get-details-now-form right-widget">
                            <h4 className="title-heading">Upcoming <span>Admissions</span></h4>
                            {
                                topCollegeArr && topCollegeArr.map((el, idx) => {
                                    let collegelink = ''; 
                                    if(el.type == 'college')
                                    {
                                        collegelink = '/college/'+el.slug+'?admission=1';
                                    }
                                    if(el.type == 'university')
                                    {
                                        collegelink = '/university/'+el.slug+'?admission=1';
                                    }
                                    return (
                                        <div className="coming-exam-block">
                                            <span className="icon">
                                                <img src={ el.logo ? process.env.REACT_APP_IMG_URI+el.logo : CollegeIMG} />
                                                {/* <div>12<sub>th</sub><br/> Jan</div> */}
                                            </span>
                                           <a href={collegelink} className='nohyperlink'> <h2>{el.name}</h2></a>
                                        </div>
                                    )
                                })
                            }
                            <div className="button-section text-center">
                                <a href={'/admission'} className="button btn">View All</a>
                            </div>
                            {/*                             
                            <h4 className="title-heading">Latest <span>Blog</span></h4>
                            {
                                blogdata && blogdata.slice(0, 6).map(i => {
                                    let bloglink = '/blog/'+i.slug;
                                    return (
                                        <div className="coming-exam-block">
                                            <span className="icon">
                                                <img src={ i.cover_image ? config.baseUrlfromconfig + i.cover_image : EntranceExam} />
                                                <div>{moment(i.created_at).format('D')}<sub>th</sub><br/>{moment(i.created_at).format('MMM')}</div>
                                            </span>
                                            <a href={bloglink} className='nohyperlink'><h2>{i.title}</h2></a>
                                        </div>
                                    )
                                })
                            } */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Breadcrumbs;