import React, { FC, useEffect, useState, useContext,useCallback } from 'react';
import SettingsContext from 'src/contexts/SettingsContext';
import axios from 'src/utils/axiosintercpt';
import { makeStyles } from '@material-ui/core';

import Breadcrumbs from './Breadcrumbs';
import Header from './InnerHeader';
import OverviewSection from './OverviewSection';
import { pConfig } from 'src/config';  
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useParams } from "react-router-dom";
import Helmet from 'react-helmet';

const useStyles = makeStyles(() => ({
  root: {}
}));

const BlogList: FC = () => {
  const classes = useStyles();
  const setCtx = useContext(SettingsContext);
  const isMountedRef = useIsMountedRef();
  const [courseStreamArr, setCourseStreamArr] = useState([]);
  //const { slug } = useParams();
  const [isHeroLoaded, setHeroLoaded] = useState(false);
  const [page, setPage] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [HasMore, setHasMore] = useState(true);
  const [metatitle, setmetatitle] = useState<any>([]);
  const [metadescription, setmetadescription] = useState<any>([]);

  const getHeroData = async ()=>{
    const formData = new FormData();
    formData.append('getdata', JSON.stringify([{data: "course_streams_head"}]));
    const heroResp = await axios.post('api/website/home/header', formData);

    setCourseStreamArr(heroResp.data.data.course_streams_head);
    
    setCtx.setCourseData(heroResp.data.data.course_streams_head);
    setHeroLoaded(true);
  }

  const [blogdata, setgetblogdata] = useState([]);
  const [limit, setLimit] = useState<number>(12);
  const [total, setTotal] = useState<number>(0);
  const [totalpage, setTotalpage] = useState<number>(0);
  const [isgetload, setIsgetload] = useState<boolean>(true);
  const [topCollegeArr, setTopCollegeArr] = useState([]);

  const getblogdata = useCallback(async (page, blogdata, limit) => {
    try {
      setIsgetload(true);
      const params = {}
      let usePagination = true;

      let pages = page;
      let count = limit;
      params['usePagination'] = usePagination;
      params['page'] = pages;
      params['size'] = count;
      const response = await axios.get('api/website/blog/get?columnname=created_at&orderby=desc',{params: params});

      //const params = {}
      //params['page'] =page;
      //params['size'] = '10';
      //const id = slug;//'engineering';
      //const response = await axios.get('api/website/blog/get?orderby=desc',{params: params});
      if (isMountedRef.current) {
        //  alert("asfd");
        //console.log("response.data.data");
        //console.log(response.data.data);
        setPage(page);
        //setgetblogdata([...blogdata,...response.data.data]); 
        setgetblogdata(response.data.data);
        setHasMore(response.data.data.length > 0);
        setIsFetching(false);

        let pagetobeshow = response.data.currentPage;
        setIsgetload(false);
        setPage(pagetobeshow);
        setLimit(count);
        setTotal(response.data.totalItems);
        let pagecount = Math.ceil(response.data.totalItems / count);
        setTotalpage(pagecount);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  const handlePageChange = (event: any, value: number): void => {
    getblogdata(value, blogdata, limit);
  };

  const getCollageUnivData = async ()=>{
    const params = {}
    params['page'] =page;
    params['size'] = '8';
    const univColgResp = await axios.get('api/website/home/topcollegebangalore', {params: params});
    //console.log(univColgResp);
    //console.log('her');
    setTopCollegeArr(univColgResp.data.data);    
  }

  const getPageData = useCallback(async () => {    
    try {
      const response = await axios.get('api/website/page/get/blog');
      if (isMountedRef.current) {
          setmetatitle(response.data.data.meta_title);
          setmetadescription(response.data.data.meta_description);
        }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(()=>{
    getHeroData();
    getblogdata(page, blogdata, limit);
    getCollageUnivData();
    getPageData();
  }, []);

  useEffect(()=>{
    //getblogdata(1,[], limit);
  }, []);

  return (
    <>
    {/* {isTopColLoaded ? 
      <> */}
        <Helmet>
            <title>{metatitle}</title>
            <meta name="description" content={metadescription} />
            <link rel="canonical" href={`${pConfig.siteUrl}blog`} />
        </Helmet>
        <Header/>
        <Breadcrumbs/>
        <OverviewSection blogdata={blogdata} page={page} isFetching={isFetching} HasMore={HasMore} getblogdata={getblogdata} totalpage={totalpage} handlePageChange={handlePageChange} topCollegeArr={topCollegeArr} />
      {/* </>
      :
      <p style={{height: '8000px'}}>Loading</p> } */}
    </>
  );
};

export default BlogList;