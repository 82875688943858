import React, { FC } from 'react';
import './styles.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import LearntechLogo from 'src/assets/img/learntech.svg';
import KeralaStudyLogo from 'src/assets/img/kerala_study.svg';
import TopMbaLogo from 'src/assets/img/top_mba.svg';
import CoimbatoreLogo from 'src/assets/img/coimbatore_study.svg';

const ProtalsSection: FC = () => {

    const owlOptions = {
        margin: 20, autoplay: false,
        responsiveClass: true,
        responsive: {
            0: {items:1, dots:false, nav:true},
            400: {items:1, dots:false, nav:true},
            600: {items: 1, dots:false, nav:true},
            700: {items: 2, dots:false, nav:true},
            1000:{
                items:3,
                nav:true,
                loop:false
            },
            1440:{
                items:4,
                nav:true,
                loop:false
            }
        },
    };

    return (
        <section className="container-fluid light_gray spac_01" data-aos="fade-up">
            <div className="container">
                <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-10 our_portal">
                        <h3 className="common_sec_h">Our <span>Portals</span></h3>
                        <OwlCarousel {...owlOptions}  className="portals loop owl-carousel owl-theme">

                            <div className="item">
                                <a href="http://keralastudy.com">
                                <img alt="keralastudy" src={KeralaStudyLogo} className="img-fluid" />
                                </a>
                            </div>
                            <div className="item">
                            <a href="http://www.learntechww.com/">
                                <img alt="learntech" src={LearntechLogo} className="img-fluid" />
                                </a>
                               
                            </div>
                            <div className="item">
                            <a href="https://topmbastudy.com/">
                                <img alt="topmbastudy" src={TopMbaLogo} className="img-fluid" />
                                </a>
                            </div>
                            <div className="item">
                            <a href="http://coimbatorestudy.com/">
                                <img alt="coimbatorestudy" src={CoimbatoreLogo} className="img-fluid" />
                                </a>
                            </div>
                            {/* <div className="item">
                            <a href="http://www.bangaloreedu.com/">
                                <img alt="bangaloreedu" src={process.env.REACT_APP_IMG_URI+'web_dashboard/assets/images/edu.jpg'} className="img-fluid" />
                                </a>        
                            </div> */}
                           
                        </OwlCarousel>
                    </div>
                    <div className="col-lg-1"></div>
                </div>
            </div>
        </section>
    );
};

export default ProtalsSection;