import React, { FC } from 'react';
import './styles.css';

// import InterestedCourseBG from 'src/assets/img/interested-in-course-bg.jpg';
import InterestedCourseBG from 'src/assets/img/interested-in-course-bg1.png';
//import { config } from 'src/config';
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';

const Interested: FC<{ collegedetail: any }> = ({ collegedetail }) => {
    //const [num, setNum] = React.useState(331231);
    //const imageUrl = collegedetail.logo ? config.baseUrlfromconfig + collegedetail.logo : InterestedCourseBG;
    return (
        <section className="container-fluid spac_01 "  data-aos="fade-up">
            <div className="container">
                <div className="row">
                    <div className="interested-in-course text-center"  style={{ background: `#fff url(${InterestedCourseBG})` }}>
                        <div className="course-center">   
                            <p>Interested in this College ? Get Admission and Placement updates about</p>
                            <h2>{collegedetail.name}</h2>
                            <div className="text-center"><GlobalPopupEnquiry  className="btn btn btn-primary"  title="Request Call Back" /></div>
                            {/* <div className="text-center"><button type="button">Request Call Back</button></div> */}
                        </div>
                    </div>
                </div>     
            </div>
        </section>
    );
};

export default Interested;