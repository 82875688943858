import React, { FC } from 'react';
import './styles.css';

const WebCounters: FC = () => {
    //const [num, setNum] = React.useState(331231);

    return (
        <section className="counts webcountersection p-4" data-aos="fade-up">
            <div className="container">
                <div className="row">
                    <div className="col-lg-1  col-md-0"></div>
                    <div className="col-lg-10  col-md-12">
                        <div className="row g-3">
                            <div className="col-lg-4 col-md-4 text-center" data-aos="fade-up">
                                <div className="count-box students">
                                    <div className="students_icons"><img src={require('src/assets/img/student-blue.svg')} className="testi_photo" alt="Students" /></div>
                                    <div className="d-inline-flex"><span data-purecounter-start="0" data-purecounter-end="1500" data-purecounter-duration="1" className="purecounter blue"></span><span className="blue">18,00,000+</span></div>
                                    <p>Students</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 text-center" data-aos="fade-up">
                                <div className="count-box students">
                                    <div className="students_icons"><img src={require('src/assets/img/student-red.svg')} className="testi_photo" alt="Successful Admissions" /></div>
                                    <div className="d-inline-flex"><span data-purecounter-start="0" data-purecounter-end="1500" data-purecounter-duration="1" className="purecounter red"></span><span className="red">8,00,000+</span></div>
                                    <p>Successful Admissions</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 text-center" data-aos="fade-up">
                                <div className="count-box students">
                                    <div className="students_icons"><img src={require('src/assets/img/student-orange.svg')} className="testi_photo" alt="Students" /></div>
                                    <div className="d-inline-flex"><span data-purecounter-start="0" data-purecounter-end="1500" data-purecounter-duration="1" className="purecounter orange"></span><span className="orange">1500+</span></div>
                                    <p>Students</p>
                                </div>
                            </div>
                        </div >
                    </div >
                    <div className="col-lg-1 col-md-0"></div>
                </div >
            </div >
        </section >

    );
};

export default WebCounters;