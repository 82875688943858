import React, { FC } from 'react';
import './styles.css';
import 'animate.css';

import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  

const TrendingCourses: FC<{courseStreamArr:any}> = ({courseStreamArr}) => {
    const owlOptions = {
        margin: 10,autoplay: true,autoplayTimeout: 3000,
        responsiveClass: true,
        responsive: {
            0: {items:1, dots:false, nav:true,loop:true},
            400: {items:1, dots:false, nav:true,loop:true},
            600: {items: 1, dots:false, nav:true,loop:true},
            700: {items: 2, dots:false, nav:true,loop:true},
            1000:{
                items:3,
                nav:true,
                loop:true
            },
            1440:{
                items:4,
                nav:true,
                loop:true
            }
        },
    };

    let finalStrmArr = [];
    let i = 0;
    while (i < courseStreamArr.length) {
        finalStrmArr.push({first: courseStreamArr[i], second: courseStreamArr[i+1]});
        i = i+2;
    }

    return (
        <section className="container-fluid light_gray spac_01" data-aos="fade-up">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 our_portal">
                        <h3 className="common_sec_h">Top Trending Courses</h3>

                            <OwlCarousel {...owlOptions} className="topTrendingCourses loop_nav owl-carousel owl-theme">
                                {finalStrmArr && finalStrmArr.map((el, idx)=>{
                                    let link_course = "/courses/" + el.first.stream_slug;
                                    return (
                                        <div className="item" key={`trndcrs${idx}`}>
                                            <div className="full_wdth">
                                                <div className="courses_list">
                                                    <span className="icon"><img loading="lazy" alt={el.first.stream_name} src={el.first.icon?process.env.REACT_APP_IMG_URI+el.first.icon:process.env.REACT_APP_IMG_URI+'stream_logo/engineering.svg'} /></span><h2><a href={link_course} className="list-group-item list-group-item-action">{el.first.stream_name}</a></h2>
                                                </div>
                                            </div>
                                            {el.second &&
                                                <div className="full_wdth mt-30">
                                                    <div className="courses_list">
                                                        <span className="icon"><img loading="lazy" alt={el.second.stream_name} src={el.second.icon?process.env.REACT_APP_IMG_URI+el.second.icon:process.env.REACT_APP_IMG_URI+'stream_logo/engineering.svg'} /></span><h2><a href={`/courses/${el.second.stream_slug}`} className="list-group-item list-group-item-action">{el.second.stream_name}</a></h2>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    )
                                })}
                            </OwlCarousel>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TrendingCourses;