import React, { FC, useEffect, useState, useContext, useCallback } from 'react';
import './styles.css';
import ManagementCourseBG from 'src/assets/img/management-courses-new.jpg';
import CallNow from 'src/assets/img/call-now.svg';
import SearchIcon from 'src/assets/img/icons/search.svg';
import IIITBLOGO from 'src/assets/img/iiitb_logo.png';
import axios1 from 'axios';
import axios from 'src/utils/axios';
import { Autocomplete } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Search from 'src/assets/img/icons/search.svg'
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';

interface schlorshiptype {
    name: string;
    slug: string;
}
const SearchHeader: FC<{ getcollegelist: any }> = ({ getcollegelist }) => {
    //const [num, setNum] = React.useState(331231);
    let cancelToken;
    const [collegelist, setcollegelist] = React.useState<schlorshiptype[]>([]);
    const [loading, setloading] = React.useState(false);
    const [open, setOpen] = useState(false);
    const getSuggestions = async (value) => {
        setloading(true);
        
        if (typeof cancelToken != typeof undefined) {
          cancelToken.cancel("Operation canceled due to new request.")
        }
        cancelToken = axios1.CancelToken.source()
        let url = '';

        const params = {}
        params['data_type'] = 'university';
        params['searchText'] = value;
        params['searchfrom'] = 'name';
        params['size'] = '10';
        const response = await axios.get('api/website/home/topcollegebangalore',  { cancelToken: cancelToken.token, params: params });
        const tags = await response.data.data;
        setcollegelist(tags);
        setloading(false);
        setOpen(true);
    }

    const handleupdateBlog = (event) => {
        if (event && event.target && typeof event.target.value != typeof undefined && event.target.value != null && event.target.value.length > 1) {
          getSuggestions(event.target.value);
        }
        else
        {
            setcollegelist([]);
            setOpen(false);
        }
    };

    useEffect(()=>{
        //getSuggestions('');
    }, []);
    return (
        <section className="inner-top-header" id="hero" style={{ background: `url(${ManagementCourseBG})` }} >
            <div className="hero-container d-flex">
                <div className="container" >
                    <div className="row top-course-block">
                        <div className="top-course-header">
                            <h1>Find Top <span>Universities</span>, Courses, <span>Admissions</span>, fee <span>structure</span> and <span>placements.</span></h1>
                            <div className="spac_02"></div>
                            {/* <form action="" method="post"> */}
                                <div className="col-lg-8">
                                    <div className="hero_explore">
                                    <Autocomplete
                                    open={open}
                                options={collegelist}
                                filterOptions={(collegelist) => collegelist}
                                onInputChange={(e) => {
                                    handleupdateBlog(e);
                                }}
                                onFocus={(e) => {
                                    setcollegelist([]);
                                    setOpen(false);
                                }}
                                value=""
                                getOptionLabel={(option) => {
                                    // Value selected with enter, right from the input
                                    if (typeof option === 'string') {
                                      return option;
                                    }
                                    // Add "xxx" option created dynamically
                                    if (option.name) {
                                      return option.name;
                                    }
                                    // Regular option
                                    return option.name;
                                }}
                                renderOption={(option) => {
                                    return (
                                        <li><a style={{ color:"#000" }} href={`/university/${option.slug}`}>{option.name}</a></li>
                                    )
                                }}
                                noOptionsText=""
                                onChange={(e, value) => {
                                    //console.log(value)
                                }}
                                renderInput={(params) => <TextField  className="serachinlinepadding ex-field" style={{ background: `#fff url(${SearchIcon})`, backgroundRepeat: "no-repeat", backgroundPosition: "20px center" }} placeholder="Find Your Universities"  name="text" {...params}
                                InputProps={{
                                    ...params.InputProps,
                                   // className: "search-box",
                                    endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                    ),
                                }}
                                />}
                            />
                                   {/*  <img src={SearchIcon} />
                                    <input type="text" placeholder="Find Your Universities" name="text" className="ex-field"/>
                                    <input type="submit" className="ex-submit" value="Explore"/> */}
                                    </div>
                                </div>
                                <div className='floatright'>
                                <GlobalPopupEnquiry  className="medical-button btn" />
                                    
                                </div>
                            {/* </form> */}
                        </div>
                    </div>
                </div>
            </div>
        </section >
    
  );
};

export default SearchHeader;