import React, { FC } from 'react';
import './stylesglobalpopup.css';
import FacebookLogin from 'react-facebook-login';
import useAuth from 'src/hooks/useAuth';
import { useSnackbar } from 'notistack';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import {pConfig} from 'src/config';
interface Props {
    className?: any;
    title?: any;
}   


const SocialFacebook: FC<Props> = ({ className,title,...rest }) => {
    const { sociallogin } = useAuth() as any;
    const { enqueueSnackbar } = useSnackbar();
    const FB_appId= pConfig.FB_appId;
    const isMountedRef = useIsMountedRef();
    const responseFacebook = async (response)=>{

    


if(response && response.id)
{
  let providername='facebook';
//let email=response.email?response.email:null;
let userId=response.id?response.id:null;
let email=response.email?response.email:userId+"@"+providername+'.com';

let name=response.name?response.name:'';
let accessTokens=response.accessToken?response.accessToken:'';
    try {
        await sociallogin(userId,providername,email,name,accessTokens);
        if (isMountedRef.current) {
        //  setStatus({ success: true });
         // setSubmitting(false);
enqueueSnackbar('Successfully Loggedin', {
            variant: 'success',
          });
        }
      } catch (err) {
        console.error(err);
        if (isMountedRef.current) {
        //  setStatus({ success: false });
          // if(err.success == false)
          // {
          //   setErrors({ submit: err.message });
          // }
         // setErrors({ submit: err.message });
          //setErrors({ submit: err.message });
        //  setSubmitting(false);
          
        enqueueSnackbar(err.message, {
          variant: 'error',});
        }
      }




}

        


      }


    return (
 <>
<FacebookLogin
appId={FB_appId}
autoLoad={false}
fields="name,email"
callback={responseFacebook}
cssClass="facebook"
textButton=""
tag="a"
icon="bi bi-facebook"
/>      
  </>
    );
};

export default SocialFacebook;