import React from 'react';
import { useHistory } from "react-router-dom";
import type { FC } from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useTranslation,  } from 'react-i18next';
import { Formik,useField,Field } from 'formik';
import axios from 'src/utils/axios';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  FormHelperText,
  TextField,
  makeStyles,Link
} from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import useIsMountedRef from 'src/hooks/useIsMountedRef';

interface ForgotViewProps {
  className?: string;
  updatestateofview?: (type,email) => void;
}

const useStyles = makeStyles(() => ({
  root: {
    color:'black',
  },
  textContainer: {
    textAlign: 'center',
    margin: '0 auto',
    color: 'black'
  },
  textField: {
    color: 'black',
    backgroundColor:'#e8f0fe'
  },
  notchedOutline: {
    borderColor: '#99A3C8 !important'
  },
  focused: {
    "&$cssFocused $notchedOutline": {
      borderColor: "yellow !important"
    }
  },
  button: {
    backgroundColor: '#1BA6A0'
  },
  buttonColor:{
    height: '64px',
    backgroundColor: '#1BA6A0',
    borderRadius:'80px',
    '&:hover': {
      backgroundColor: '#1BA6A0'    
    }
  },
  cusInput: {
    borderRadius:'500px',
    border:'1px solid #E1E1E1',
    backgroundColor:'#ffffff',
    paddingRight:"0",
    position:"relative",
    ':-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px white inset',
      backgroundColor: 'red !important'
   },
   "& div":{
      position:"absolute",
      right:"0",
   },
    "&&&:before": {
      borderBottom: "none"
    },
    "&&:after": {
      borderBottom: "none"
    },
    "&:hover":{
      backgroundColor:"#ffffff"
    },
    "&:-webkit-autofill": {
      backgroundColor:'#ff0000'
    },
    "& input":{
      borderRadius:"500px",
      backgroundColor:"#ffffff",
    }
  },
}));

const ForgotView: FC<ForgotViewProps> = ({ className, updatestateofview,...rest }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation(['en-us', 'en-uk']);
  const { forgotpassword } = useAuth() as any;
  const isMountedRef = useIsMountedRef();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

 
  
const linktoforget =  (type,email) => {
  updatestateofview(type,email);

};

  return (
    <Formik
      initialValues={{
        email: '',
        name: '',
        password: '',
        policy: false,
        submit: null
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting
      }) => {
        //history.push("/verification");
        /*
        export const getNotificationCartCount = async () => {
        var val = '';
        await AsyncStorage.getItem('authToken')
          .then((value) => {
            val = value;
          })
          .then((res) => {
            //do something else
          });
        const header = {
          Authorization: `Bearer ${val}`,
        };

        return axios.get(
          `${Constant.App.BaseUrl}cart/count${`?device_token=${val}`}`,
          {
            headers: header,
          },
        );
      };*/

      /*
      let params = {}
      params['email']= values.email;
      let url = '/Auth/ForgotPassword/';
  
      try {
        let response = await axios.post(url, params);
        console.log('response: ', response);

        if (response.data.success) {
          setStatus({ success: true });
          setSubmitting(false);
          enqueueSnackbar('Email sent.', {
            variant: 'success',
            persist: false
          });
          history.push({
            pathname : '/verification',
            state :{
              email : values.email,
            }
          });
          
        }else
        {
          setStatus({ success: false });
          setErrors({ submit: 'Email not exists. Please try again.' });
          setSubmitting(false);
          enqueueSnackbar('Email not exists. Please try again.', {
            variant: 'error',
          });
        }
      } catch (err) {
        console.error(err);
        setStatus({ success: false });
        setErrors({ submit: err.message });
        setSubmitting(false);

        enqueueSnackbar('Please try again', {
          variant: 'error',
        });
      }

      */


      

        try {
          await forgotpassword(values.email);

          if (isMountedRef.current) {

            //localStorage.setItem("forgetemail",values.email);
           // alert(values.email);
            setStatus({ success: true });
            setSubmitting(false);
            //history.push("/verification");
            enqueueSnackbar('Email sent.', {
              variant: 'success',
              persist: false
            });

            linktoforget('forgetstep2',values.email);
         //   localStorage.setItem("verficiationcode", values.code);
            
            /*
          history.push({
              pathname : '/verification',
              state :{
                email : values.email,
              }
            });*/
          }
        } catch (err) {
          console.error(err);
          if(err.status == false)
          {
            setErrors({ submit: 'Email Does not exist' });
          }
          setStatus({ success: false });
          if(err.errors)
          {
            setErrors({ submit: err.errors[0].msg });
          }
          else{
            setErrors({ submit: err.message });
          }
          
          
          setSubmitting(false);
        }
        
        /*
        try {
          await register(values.email, values.name, values.password);

          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          console.error(err);
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
        */
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form
          noValidate
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
         <div className="form-group">

<Field type="text" name="email"
           onChange={handleChange}
           onBlur={handleBlur}
           value={values.email}
           className="form-control" id="email" placeholder="Email Id"  />
         {errors.email && touched.email && <p className="error">{errors.email}</p>}
  </div>
          {errors.submit && (
            <Box mt={2}>
              <FormHelperText error style={{fontSize:'16px'}}>
                {errors.submit}
              </FormHelperText>
            </Box>
          )}

<Box
              my={2}
              
            >
              <Link
              onClick={() => linktoforget('login','')}
          
                variant="body2"
              
                style={{fontSize:'1rem'}}
              >
               Back to Login
              </Link>
            </Box>
          <Box mt={2}>
          
            <div className="text-center"><button type="submit">Get code</button></div>
          </Box>
        </form>
      )}
    </Formik>
  );
};

ForgotView.propTypes = {
  className: PropTypes.string,
  updatestateofview: PropTypes.func,
};

export default ForgotView;
