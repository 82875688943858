import React, { FC, useEffect, useState, useContext, useCallback } from 'react';
import SettingsContext from 'src/contexts/SettingsContext';
import axios from 'src/utils/axiosintercpt';
import { CircularProgress, makeStyles } from '@material-ui/core';

import Breadcrumbs from './Breadcrumbs';
import Header from './InnerHeader';
import OverviewSection from './OverviewSection';
import TopColleges from './TopColleges';
import Talk from './Talk';
import Interested from './Interested';
import Scholarship from './Scholarship';
import { pConfig } from 'src/config';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import Helmet from 'react-helmet';

const useStyles = makeStyles(() => ({
    root: {}
}));

const HomePage: FC = () => {
    const classes = useStyles();
    const setCtx = useContext(SettingsContext);
    const isMountedRef = useIsMountedRef();
    const [bannerArr, setBannerArr] = useState([]);
    const [countBlockInfo, setCountBlockInfo] = useState({});
    const [courseStreamArr, setCourseStreamArr] = useState([]);

    const [topCollegeArr, setTopCollegeArr] = useState([]);
    const [topUniversityArr, setTopUniversityArr] = useState([]);
    const [topExamArr, setTopExamArr] = useState([]);
    const [testimonialsArr, setTestimonialsArr] = useState([]);

    const [blogArr, setBlogArr] = useState([]);
    const [newsArr, setNewsArr] = useState([]);

    const [isHeroLoaded, setHeroLoaded] = useState(false);
    const [isTopColLoaded, setTopColLoaded] = useState(false);
    const [isBlogLoaded, setBlogLoaded] = useState(false);
    const [promoBannerArr, setPromoBannerArr] = useState<any>([]);
    const [searchFilters, setSearchFilters] = React.useState<any>([]);

    const searchByFilters = (filters) => {
        console.log('filetsr:-', filters);
        setPage(1);
        setSearchFilters(filters);
    };

    const getHeroData = async () => {
        const formData = new FormData();
        formData.append('getdata', JSON.stringify([{ data: "course_streams_head" }]));
        const heroResp = await axios.post('api/website/home/header', formData);
        setCtx.setCourseData(heroResp.data.data.course_streams_head);
        //setHeroLoaded(true);
    }

    const getCollageUnivData = async () => {
        const formData = new FormData();
        formData.append('getdata', JSON.stringify([{ data: "college" }, { data: "university" }, { data: "exam" }, { data: "testimonial" }]));
        const univColgResp = await axios.post('api/website/home/topcrousel', formData);

        //setTopCollegeArr(univColgResp.data.data.topcollege.data);
        //setTopUniversityArr(univColgResp.data.data.topuniversity.data);
        //setTopExamArr(univColgResp.data.data.topexam.data);
        //setTestimonialsArr(univColgResp.data.data.testimonials.data)
        //setTopColLoaded(true);
    }

    const geBlogData = async () => {
        const formData = new FormData();
        formData.append('getdata', JSON.stringify([{ data: "blogs" }, { data: "news" }]));
        const blogResp = await axios.post('api/website/home/blogsandnews', formData);

        console.log(blogResp.data.data.blogs.data)
        setBlogArr(blogResp.data.data.blogs.data);
        setNewsArr(blogResp.data.data.news.data);

        setBlogLoaded(true);
    }

    const [collegedetail, setcollegedetail] = useState([]);
    const [page, setPage] = useState(1);
    const [isFetching, setIsFetching] = useState(false);
    const [stream, setstream] = useState([]);
    const [HasMore, setHasMore] = useState(true);
    const [ownerships, setOwnerships] = useState([]);
    const [city, setcity] = useState([]);
    const [accreditations, setAccreditations] = useState([]);
    const [managments, setmanagments] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [isUniColLoaded, setUniColLoaded] = useState(false);

    const getstream = useCallback(async () => {
        try {
            //  const response = await axios.get('api/website/home/group');
            const response = await axios.get('api/website/home/streams?page=1&size=10000&columnname=listing_order&orderby=asc');

            if (isMountedRef.current) {
                //  alert("asfd");
                console.log("response.data.data");
                console.log(response.data.data);
                setstream(response.data.data);
            }
        } catch (err) {
            console.error(err);
        }
    }, [isMountedRef]);

    const getOwnerships = useCallback(async () => {
        try {
            const response = await axios.get('api/website/home/collegetype');
            if (isMountedRef.current) {
                //  alert("asfd");
                console.log("response.data.data");
                console.log(response.data.data);
                setOwnerships(response.data.data);
            }
        } catch (err) {
            console.error(err);
        }
    }, [isMountedRef]);

    const getcity = useCallback(async () => {
        try {
            const response = await axios.get('api/website/home/citys');
            if (isMountedRef.current) {
                //  alert("asfd");
                console.log("response.data.data");
                console.log(response.data.data);
                setcity(response.data.data);
            }
        } catch (err) {
            console.error(err);
        }
    }, [isMountedRef]);

    const getAccreditations = useCallback(async () => {
        try {
            const response = await axios.get('api/website/home/accreditaions');
            if (isMountedRef.current) {
                //  alert("asfd");
                console.log("response.data.data");
                console.log(response.data.data);
                setAccreditations(response.data.data);
            }
        } catch (err) {
            console.error(err);
        }
    }, [isMountedRef]);

    const getmanagments = useCallback(async () => {
        try {
            const response = await axios.get('api/website/home/managments');
            if (isMountedRef.current) {
                //  alert("asfd");
                console.log("response.data.data");
                console.log(response.data.data);
                setmanagments(response.data.data);
            }
        } catch (err) {
            console.error(err);
        }
    }, [isMountedRef]);

    const getuniversitylist = useCallback(async (page, collegedetail, searchFilters: any) => {
        setIsFetching(true);
        try {
            const params = {}
            params['data_type'] = 'university';
            params['page'] = page;
            params['size'] = '12';
            /*  params['columnname'] = 'listing_order';
            params['orderby'] = 'ASC';
            params['searchText'] = '0';
            params['searchfrom'] = '0';
            params['page'] = '0'; */

            if (searchFilters && searchFilters != '' && Object.keys(searchFilters).length !== 0) {
                // let dtkeyarr = ['poc_start_from', 'poc_start_to', 'poc_end_from', 'poc_end_to', 'last_activity_from_date', 'last_activity_to_date', 'video_call_schedule_from', 'video_call_schedule_to'];
                let dtkeyarr = [];
                for (let key of Object.keys(searchFilters)) {
                    let value = searchFilters[key];
                    if (value && value !== '' && value !== null && value !== undefined) {
                        if (Array.isArray(value) === true) {
                            // array type keys
                            if (value.length > 0) {
                                params[key] = [value];
                            }
                        }
                    }
                }
            }

            // const id = slug;//'engineering';
            //const response = await axios.get('api/website/home/topcollegebangalore?page=1&size=12&data_type=university', {params: params});
            const response = await axios.get('api/website/home/topcollegebangalore', { params: params });
            if (isMountedRef.current) {
                //  alert("asfd");
                //console.log("response.data.data");
                //console.log(response.data.data);
                setPage(page);
                setcollegedetail([...collegedetail, ...response.data.data]);
                // setPage((prevPageNumber) => prevPageNumber + 1);
                setHasMore(response.data.data.length > 0);
                //setcollegedetail(response.data.data);
                //setPage((prevPageNumber) => prevPageNumber + 1);
                //setHasMore(response.data.data.length > 0);
                setIsFetching(false);
                setTotalItems(response.data.totalItems);
                setUniColLoaded(true);
            }
        } catch (err) {
            console.error(err);
        }
    }, [isMountedRef]);


    const [metatitle, setmetatitle] = useState<any>([]);
    const [metadescription, setmetadescription] = useState<any>([]);
    const [universitydata, setuniversitydata] = useState([]);

    const getcollegedata = async () => {
        const response = await axios.get('api/website/page/get/universities');
        console.log("college", response.data.data);
        setuniversitydata(response.data.data);
        setmetatitle(response.data.data.meta_title)
        setmetadescription(response.data.data.meta_description)

    }

    const [schlorshiplist, setgetschlorshiplist] = useState([]);
    const getschlorshiplist = useCallback(async () => {
        try {
            const response = await axios.get('api/website/home/scholarship');
            if (isMountedRef.current) {
                setgetschlorshiplist(response.data.data);
            }
        } catch (err) {
            console.error(err);
        }
    }, [isMountedRef]);

    const getpromobanner = useCallback(async () => {
        try {
            const response = await axios.get('api/website/promobanner?promo_banner=All_university_page');
            if (isMountedRef.current) {
                setPromoBannerArr(response.data.data);
            }
        } catch (err) {
            console.error(err);
        }
    }, [isMountedRef]);

    useEffect(() => {
        getcollegedata();
        getHeroData();
        //getCollageUnivData();
        //geBlogData();
        getuniversitylist(page, collegedetail, searchFilters);
        getstream();
        getcity();
        getOwnerships();
        getAccreditations();
        getmanagments();
        getschlorshiplist();
        getpromobanner();
    }, []);

    useEffect(() => {
        getuniversitylist(1, [], searchFilters);
    }, [searchFilters]);

    return (
        <>
            {isUniColLoaded ?
                <>
                    <Helmet>
                        <title>{metatitle && metatitle != '' ? metatitle : 'Get Admission at Top Colleges in Bangalore'}</title>
                        <meta name="description" content={metadescription && metadescription != '' ? metadescription : 'Looking for College/ University/ School admission? Bangalorestudy.com helps you with a list of Colleges, Universities and Schools to pursue your education in Bangalore. Call us now'} />
                        <link rel="canonical" href={`${pConfig.siteUrl}universities`} />
                    </Helmet>
                    <Header getcollegelist={getuniversitylist} />
                    <Breadcrumbs />
                    <OverviewSection
                        searchByFilters={(filters) => searchByFilters(filters)}
                        page={page} stream={stream} ownerships={ownerships} city={city} accreditations={accreditations} managments={managments} getcollegelist={getuniversitylist} collegedetail={collegedetail} isFetching={isFetching} HasMore={HasMore} totalItems={totalItems} />
                    <TopColleges universitydata={universitydata} />
                    <Talk />
                    <Interested promoBannerArr={promoBannerArr} />
                    <Scholarship schlorshiplist={schlorshiplist} />
                </>
            :
            <div style={{ display: 'flex', justifyContent: 'center', height: '600px', flexWrap: 'wrap', alignContent: 'space-around' }}><CircularProgress /></div>}
        </>
    );
};

export default HomePage;