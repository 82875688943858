import React, { FC, useEffect, useState, useContext,useCallback } from 'react';
import SettingsContext from 'src/contexts/SettingsContext';
import axios from 'src/utils/axiosintercpt';
import { makeStyles } from '@material-ui/core';
import Helmet from 'react-helmet';
import Breadcrumbs from './Breadcrumbs';
import Header from './InnerHeader';
import OverviewSection from './OverviewSection';
import { pConfig } from 'src/config';   
import useIsMountedRef from 'src/hooks/useIsMountedRef';

const useStyles = makeStyles(() => ({
  root: {}
}));

const HomePage: FC = () => {
  const classes = useStyles();
  const setCtx = useContext(SettingsContext);
  const [termconditiondata, settermconditiondata] = useState([]);
  const isMountedRef = useIsMountedRef();
  const [metatitle, setmetatitle] = useState<any>([]);
  const [metadescription, setmetadescription] = useState<any>([]);

  const gettermconditiondata = useCallback(async () => {    
    try {
      const response = await axios.get('api/website/page/get/terms-and-conditions');
      if (isMountedRef.current) {
        //  alert("asfd");
          console.log("response.data.data");
          console.log(response.data.data);
          settermconditiondata(response.data.data);
          setmetatitle(response.data.data.meta_title);
          setmetadescription(response.data.data.meta_description);
        }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  const getHeroData = async () => {
    const formData = new FormData();
    formData.append('getdata', JSON.stringify([{ data: "course_streams_head" }]));
    const heroResp = await axios.post('api/website/home/header', formData);
    setCtx.setCourseData(heroResp.data.data.course_streams_head);
  }

  useEffect(()=>{
    gettermconditiondata();
    getHeroData();
  }, []);

  return (
    <>
       <Helmet>
          <title>{metatitle && metatitle != '' ? metatitle : 'Get Admission at Top Colleges in Bangalore'}</title>
          <meta name="description" content={metadescription && metadescription != '' ?  metadescription : 'Looking for College/ University/ School admission? Bangalorestudy.com helps you with a list of Colleges, Universities and Schools to pursue your education in Bangalore. Call us now'} />
          <link rel="canonical" href={`${pConfig.siteUrl}terms-and-conditions`} />
        </Helmet>
        <Header/>
        <Breadcrumbs/>
        <OverviewSection termconditiondata={termconditiondata}/>
    </>
  );
};

export default HomePage;