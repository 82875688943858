import React, { FC, useRef, useState } from 'react';
import axios from 'src/utils/axios';
import ReCAPTCHA from "react-google-recaptcha";
// import './styles.css';
import { useSnackbar } from 'notistack';
import { Field, Form, Formik, FormikHelpers, useField } from 'formik';
import clsx from 'clsx';
import * as Yup from 'yup';
import { useHistory } from "react-router-dom";
import PhoneNumberField from 'src/components/PhoneInput'
const styles = {
    search: {
        position: "relative",
    }
}

interface EnquiryFormFilterProps {
    type?: any;
}

const EnquiryDetailForm: FC<EnquiryFormFilterProps> = ({ type, ...rest }) => {
    const captchaRef = useRef(null)
    const { enqueueSnackbar } = useSnackbar();
    let formtype = "";
    const history = useHistory();
    if (type == 'contact') {
        formtype = type;
    }
    if (type == 'admissions') {
        formtype = type;
    }
    const phoneRegExp = /^\+91-[6789]\d{9}$|^\+(?!91-)\d{1,3}-\d{1,10}$/;
    return (

        <Formik
            initialValues={{
                fullname: '',
                phoneNumber: '',
                Email: '',
                CourseInMind: '',
                // College: '',
                Location: '',
                message: ''
            }}
            validationSchema={
                Yup.object().shape({
                    fullname: Yup.mixed().required('Name  is Required'),
                    phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required("Phone Number is required"),
                    Email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                })
            }
            onSubmit={async (values, actions) => {
                //  return  alert(JSON.stringify(values, null, 2));
                const formData = new FormData();

                formData.append('name', values.fullname);
                formData.append('contact', values.phoneNumber);
                formData.append('email', values.Email);
                formData.append('course_in_mind', values.CourseInMind);
                // formData.append('college_name', values.College);
                formData.append('location', values.Location);
                formData.append('description', values.message);
                formData.append('current_url', window.location.href);
                let url = 'api/website/home/enquiry';
                // return alert(JSON.stringify(formData));

                try {

                    let response = await axios.post(url, formData);
                    // console.log('response: ', response);
                    if (response.data.status) {
                        actions.setSubmitting(true);
                        actions.resetForm({
                            values: {
                                fullname: '',
                                phoneNumber: '',
                                Email: '',
                                CourseInMind: '',
                                // College: '',
                                Location: '',
                                message: ''
                            },
                        });
                        enqueueSnackbar('Thank you. We will get back to you.', {
                            variant: 'success',
                        });
                        history.push("/thank-you");
                    }
                } catch (err) {
                    console.error(err);
                    enqueueSnackbar("Please try again", {
                        variant: 'error',
                    });
                }
                captchaRef.current.reset();
            }}
        >
            {props => (

                <form onSubmit={props.handleSubmit}>

                    <div className="form-group">
                        {/* <label htmlFor="fullName">Full Name</label> */}
                        <Field type="text" name="fullname"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.fullname}
                            className="form-control" id="name" placeholder={formtype == 'contact' ? 'Enter Your Name' : 'Enter Your Name'} required />
                        {props.errors.fullname && props.touched.fullname && <p className="error">{props.errors.fullname}</p>}
                    </div>
                    {formtype == 'contact' ?
                        <>
                            <div className="form-group">
                                <Field type="email" className="form-control" name="Email"
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.Email}
                                    id="Email" placeholder="Enter Email"
                                    required />
                                {props.errors.Email && props.touched.Email && <p className="error">{props.errors.Email}</p>}
                            </div>
                            <div className="form-group react-phone-p-0 mb-3">
                                <PhoneNumberField />
                                {/* <Field type="tel" className="form-control" name="phoneNumber"
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.phoneNumber}
                                    id="PhoneNumber" placeholder="Enter Phone Number"
                                    required /> */}
                                {props.errors.phoneNumber && props.touched.phoneNumber && <p className="error">{props.errors.phoneNumber}</p>}
                            </div>
                        </>
                        :
                        <>
                            <div className="form-group">
                                {/* <label>Email</label> */}
                                <Field type="email" className="form-control" name="Email"
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.Email}
                                    id="Email" placeholder="Enter Email"
                                    required />
                                {props.errors.Email && props.touched.Email && <p className="error">{props.errors.Email}</p>}
                            </div>
                            <div className="form-group react-phone-p-0 mb-3">
                                {/* <label>Phone Number</label> */}
                                <PhoneNumberField />
                                {props.errors.phoneNumber && props.touched.phoneNumber && <p className="error">{props.errors.phoneNumber}</p>}
                            </div>

                        </>

                    }
                    {formtype != 'contact' ?
                        <>
                            <div className="form-group">
                                {/* <label>Course In Mind</label> */}
                                <Field type="text" className="form-control" name="CourseInMind"
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.CourseInMind}
                                    id="CourseInMind" placeholder={formtype == 'admissions' ? 'Interested Course' : 'Course In Mind'} required
                                />
                            </div>
                            <div className="form-group">
                                {/* <label>Location</label> */}
                                <Field type="text" className="form-control" name="Location"
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.Location}
                                    id="Location" placeholder={formtype == 'admissions' ? 'Enter Location' : 'Location'} required
                                />
                            </div>

                        </>
                        : <div className="form-group">
                            {/* <label>Course In Mind</label> */}
                            <Field type="text" className="form-control" name="CourseInMind"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.CourseInMind}
                                id="CourseInMind" placeholder="Interested Course?" required
                            />
                        </div>
                    }
                    <div className="form-group mrb" >
                        {/* <label>Message</label> */}

                        <textarea className="form-control" name="message"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.message} placeholder="Type Your Message"
                        ></textarea >
                    </div>
                    {/* <ReCAPTCHA
                        sitekey="6LcbLSEjAAAAAEnZuOWLNsIJgCP3tptUhe2SpBIb"
                        ref={captchaRef}
                    /> */}

                    <div className="text-center"><button type="submit" className="submit__message">Submit</button></div>




                </form>
            )}
        </Formik>




    );
};

export default EnquiryDetailForm;