import React, { FC, useState, ChangeEvent, useEffect  } from 'react';
import './styles.css';
import { Box, makeStyles, Tab, Tabs} from '@material-ui/core';
// import GetMoreDetails from 'src/assets/img/get-more-details.jpg';
// import EducationCap from 'src/assets/img/education-cap.png';
// import HeadingBg from 'src/assets/img/heading-bg.png';
// import TopManagementCourseBG from 'src/assets/img/top-managment-course.jpg';
// import InterestedinCourseBG from 'src/assets/img/interested-in-course-bg.jpg';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from'@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
// import Paper from '@material-ui/core/Paper';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GlobalReviewSection from 'src/components/GlobalReviewSection';


const Breadcrumbs: FC <{ coursedetail: any,currentTab: string,
    setCurrentTab: (tab: string) => void }>= ({coursedetail,currentTab, setCurrentTab}) => {
    //const [num, setNum] = React.useState(331231);
    const queryParams = new URLSearchParams(window.location.search);
    const admission = queryParams.get('admission');

    // const [currentTab, setCurrentTab] = useState<string>('overview');

    const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
        setCurrentTab(value);
    };

    useEffect(()=>{
        admission ? setCurrentTab('admissions_TAB') : setCurrentTab('overview_TAB');
    }, [admission]);

    return ( 
        <section className="course-overviews-section container-fluid  spac_01" >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 overviews-course">
                        <div className="row">
                            <Tabs
                                value={currentTab}
                                onChange={handleTabsChange}
                                variant="scrollable"
                                scrollButtons="on"
                                aria-label="visible arrows tabs example"
                                className='mainexamtabs'
                                style={{width:'auto'}}
                            >
                                <Tab label="Overview" value="overview_TAB" />
                                {coursedetail.undergraduate && coursedetail.undergraduate !='' ? 
                                    <Tab label="UG" value="ug_TAB" />
                                : ''}
                                {coursedetail.postgraduate && coursedetail.postgraduate !='' ? 
                                    <Tab label="PG" value="pg_TAB" />
                                : ''}
                                {coursedetail.Doctorate && coursedetail.Doctorate !='' ? 
                                    <Tab label="Doctorate" value="doctorate_TAB" />
                                : ''}
                                {coursedetail.Diploma && coursedetail.Diploma !='' ? 
                                    <Tab label="Diploma" value="diploma_TAB" />
                                : ''}

                                {coursedetail.admissions && coursedetail.admissions !='' ? 
                                    <Tab label="Admissions" value="admissions_TAB" />
                                : ''}

                                {coursedetail.scholarship && coursedetail.scholarship !='' && coursedetail.scholarship !='null' ? 
                                    <Tab label="Scholarships" value="scholarships_TAB" />
                                : ''}
                                {coursedetail.placements && coursedetail.placements !='' && coursedetail.placements !='null' ? 
                                    <Tab label="Placements" value="placements_TAB" />
                                : ''}
                                <Tab label="Review" value="review_TAB" />
                                <Tab label="FAQs" value="faq_TAB" />
                            </Tabs>

                            <div className="tab-content left-overviews-section bs-editor-text">
                                {(currentTab === 'overview_TAB' && currentTab) ? 
                                    <>
                                        <div className="bs-editor-text" id="overview_TAB">
                                            <span dangerouslySetInnerHTML={{ __html: coursedetail.about }} />
                                        </div>
                                    </> : 
                                null}

                                {(currentTab === 'ug_TAB' && currentTab) ? 
                                    <>
                                        <div className="bs-editor-text" id="ug_TAB">
                                            <span dangerouslySetInnerHTML={{ __html: coursedetail.undergraduate }} />
                                        </div>
                                    </> : 
                                null}
                                
                                {(currentTab === 'pg_TAB' && currentTab) ? 
                                    <>
                                        <div className="bs-editor-text" id="pg_TAB">
                                            <span dangerouslySetInnerHTML={{ __html: coursedetail.postgraduate }} />
                                        </div>
                                    </> : 
                                null}

                                {(currentTab === 'doctorate_TAB' && currentTab) ? 
                                    <>
                                        <div className="bs-editor-text" id="doctorate_TAB">
                                            <span dangerouslySetInnerHTML={{ __html: coursedetail.Doctorate }} />
                                        </div>
                                    </> : 
                                null}

                                {(currentTab === 'diploma_TAB' && currentTab) ? 
                                    <>
                                        <div className="bs-editor-text" id="diploma_TAB">
                                            <span dangerouslySetInnerHTML={{ __html: coursedetail.Diploma }} />
                                        </div>
                                    </> : 
                                null}

                                {(currentTab === 'admissions_TAB' && currentTab) ? 
                                    <>
                                        <div className="bs-editor-text" id="admissions_TAB">
                                            <span dangerouslySetInnerHTML={{ __html: coursedetail.admissions }} />
                                        </div>
                                    </> : 
                                null}

                                {(currentTab === 'scholarships_TAB' && currentTab) ? 
                                    <>
                                        <div className="bs-editor-text" id="scholarships_TAB">
                                            <span dangerouslySetInnerHTML={{ __html: coursedetail.scholarship }} />
                                        </div>
                                    </> : 
                                null}

                                {(currentTab === 'placements_TAB' && currentTab) ? 
                                    <>
                                        <div className="bs-editor-text" id="placements_TAB">
                                            <span dangerouslySetInnerHTML={{ __html: coursedetail.placements }} />
                                        </div>
                                    </> : 
                                null}

                                {(currentTab === 'review_TAB' && currentTab) ? 
                                    <>
                                        <div className="" id="review_TAB">
                                        <GlobalReviewSection type="university" item_id={coursedetail && coursedetail.id?coursedetail.id:0}  />
                                        </div>
                                    </> : 
                                null}

                                {(currentTab === 'faq_TAB' && currentTab) ? 
                                    <>
                                        <div className="" id="faq_TAB">
                                            <h5>FAQs</h5>
                                            {coursedetail.faqs && coursedetail.faqs.map((i, index) => {
                                                return (
                                                    <>
                                                        <Accordion>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls="panel1a-content"
                                                                id={`panel${index}a-header`}
                                                            >
                                                                <Typography>{index + 1+"."} {i.questions}</Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <Typography>
                                                                    {i.answers}
                                                                </Typography>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                        {/* <span><b>Question:</b>{i.questions}</span><br></br>
                                                        <span><b>Answer:</b> {i.answers}</span><br></br> */}
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </> : 
                                null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Breadcrumbs;