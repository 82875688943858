import React, { FC } from 'react';
import './styles.css';

//import Testimonials from 'src/assets/img/testimonials.jpg';
import Pagination from '@material-ui/lab/Pagination/Pagination';

const Breadcrumbs: FC < {studentspeakdata:any, page:any, totalpage:any, handlePageChange}> = ({studentspeakdata, page, totalpage, handlePageChange })=> {

    return (
        <section className="course-overviews-section ">
            <div className="container aos-init aos-animate" >
                <div className="row">
                    {
                        studentspeakdata && studentspeakdata.map((i) => {
                            let aaa=i.video_url;
                            let substring = "=";
                            let aaa1 ='';
                            let aaa11 ='';
                            // if(aaa.includes(substring))
                            // {
                            //     let aaas=aaa.split('=');
                            //     aaa1 = aaas[1];
                            //     let aaas1=aaa1.split('&');
                            //     aaa11 = aaas1[0];
                            // }

                            const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
                            const match = aaa.match(regExp);

                            aaa11 =  (match && match[2].length === 11) ? match[2] : null;
                            let youtubefullurl = 'https://www.youtube.com/embed/'+aaa11;
                            
                            //console.log(aaas);
                            
                            return (
                                <div className="col-lg-6 aos-init aos-animate" >
                                    <div className="testimonials-block">
                                        <div className="audio-block">
                                            <iframe src={youtubefullurl} style={{width:'100%', height: '310px'}}></iframe>
                                        </div>
                                        <div className="testmonila-content">
                                        <img src={require("src/assets/img/l_quote.svg")} className="lquote w30" />
                                            {/* <i className="bi bi-quote"></i> */}
                                            <p>{i.name} </p>
                                            {i.description && i.description!='' ?
                                                <p>{i.description}</p> : ''                                            
                                            }
                                            <p>{i.designation} </p>
                                            <img src={require("src/assets/img/r_quote.svg")} className="rquote w30" />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                    <div className="spac_02"></div>
                    <div className="button-section text-center clearfix">
                        <Pagination
                            page={page}
                            variant="outlined"
                            shape="rounded"
                            onChange={handlePageChange}
                            count={totalpage}
                            className="pagination"
                            />
                    </div>

                    {/* <div className="spac_02"></div>

                    <div className="button-section text-center">
                        <a href="#" className="button btn">Explore all reviews</a>
                    </div> */}
                </div>
            </div>
        </section>
    );
};

export default Breadcrumbs;