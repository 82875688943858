import React, { FC, useEffect, useState, useContext,useCallback } from 'react';
import SettingsContext from 'src/contexts/SettingsContext';
import axios from 'src/utils/axiosintercpt';
import { makeStyles } from '@material-ui/core';

import Breadcrumbs from './Breadcrumbs';
import Header from './InnerHeader';
import OverviewSection from './OverviewSection';
import Talk from './Talk';
import { pConfig } from 'src/config';  
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useParams } from "react-router-dom";
import Helmet from 'react-helmet';
const useStyles = makeStyles(() => ({
  root: {}
}));

const TeamPage: FC = () => {
  const classes = useStyles();
  const setCtx = useContext(SettingsContext);
  const isMountedRef = useIsMountedRef();
  const [courseStreamArr, setCourseStreamArr] = useState([]);
  //const { slug } = useParams();
  const [isHeroLoaded, setHeroLoaded] = useState(false);

  const getHeroData = async ()=>{
    const formData = new FormData();
    formData.append('getdata', JSON.stringify([{data: "course_streams_head"}]));
    const heroResp = await axios.post('api/website/home/header', formData);

    setCourseStreamArr(heroResp.data.data.course_streams_head);
    
    setCtx.setCourseData(heroResp.data.data.course_streams_head);
    setHeroLoaded(true);
  }

  const [teamview, setteamview] = useState([]);
  const [teamdata, setteamdata] = useState([]);
  const [metatitle, setmetatitle] = useState<any>([]);
  const [metadescription, setmetadescription] = useState<any>([]);

  const getteamview = useCallback(async () => {    
    try {
      const response = await axios.get('api/website/page/get/team');
      if (isMountedRef.current) {
          setteamview(response.data.data);
          setmetatitle(response.data.data.meta_title);
          setmetadescription(response.data.data.meta_description);
        }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  const [isFetching, setIsFetching] = useState(false);
 
  const getTeamData = useCallback(async () => {
    setIsFetching(true);
    try {
      const params = {}
      const response = await axios.get('api/website/home/team',{params: params});
      if (isMountedRef.current) {
        setIsFetching(false);
        setteamdata(response.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);
  
  useEffect(()=>{
    getHeroData();
    getTeamData();
    getteamview();
  }, []);

  return (
    <>
    {/* {isTopColLoaded ? 
      <> */}
       <Helmet>
          <title>{metatitle && metatitle != '' ? metatitle : 'Get Admission at Top Colleges in Bangalore'}</title>
          <meta name="description" content={metadescription && metadescription != '' ?  metadescription : 'Looking for College/ University/ School admission? Bangalorestudy.com helps you with a list of Colleges, Universities and Schools to pursue your education in Bangalore. Call us now'} />
          <link rel="canonical" href={`${pConfig.siteUrl}team`} />
        </Helmet>
        <Header />
        <Breadcrumbs/>
        <OverviewSection teamview={teamview} teamdata={teamdata} isFetching={isFetching} />
        <Talk/>
       {/*  <TopColleges/> */}
      {/* </>
      :
      <p style={{height: '8000px'}}>Loading</p> } */}
    </>
  );
};

export default TeamPage;