import React, { FC, useState } from 'react';
import './styles.css';

const WhatWeDo: FC<{countBlockInfo:any}> = ({countBlockInfo}) => {
   
    return (
        <>
        <section className="top-management-colleges container-fluid spac_01 light-blue-bg"  data-aos="fade-up">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 our_portal">
                        <h3 className="main-heading">What <b>We</b> Do</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-10">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 text-center" data-aos="fade-up">
                                <div className="count-box students">
                                    <div className="whatwedo_icons"><img src={require('src/assets/img/whatwedo1.png')} className="testi_photo" /></div>
                                    <div className="d-inline-flex"><span className="black">Connecting colleges directly with students </span></div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 text-center" data-aos="fade-up">
                                <div className="count-box students">
                                    <div className="whatwedo_icons"><img src={require('src/assets/img/whatwedo2.png')} className="testi_photo" /></div>
                                    <div className="d-inline-flex"><span className="black">Providing better career and placement option</span></div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 text-center" data-aos="fade-up">
                                <div className="count-box students">
                                    <div className="whatwedo_icons"><img src={require('src/assets/img/whatwedo3.png')} className="testi_photo" /></div>
                                    <div className="d-inline-flex"><span className="black">Helping in admission and course selection</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="top-management-colleges container-fluid spac_01"  data-aos="fade-up">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 our_portal">
                        <h3 className="main-heading">Our <b>Growth</b> Till <b>Date</b></h3>
                        <div className="spac_02"></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-10">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 text-center" data-aos="fade-up">
                                <div className="container p-5">
                                    <div className="progress" data-percentage="74">
                                        <span className="progress-left">
                                            <span className="progress-bar bluecolor"></span>
                                        </span>
                                        <span className="progress-right">
                                            <span className="progress-bar bluecolor"></span>
                                        </span>
                                        <div className="progress-value bluecolor">
                                            <div>
                                            {countBlockInfo.college}<br/>
                                                <span>Colleges</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 text-center" data-aos="fade-up">
                                <div className="container p-5">
                                    <div className="progress" data-percentage="52">
                                        <span className="progress-left">
                                            <span className="progress-bar pinkcolor"></span>
                                        </span>
                                        <span className="progress-right">
                                            <span className="progress-bar pinkcolor"></span>
                                        </span>
                                        <div className="progress-value pinkcolor">
                                            <div>
                                            {countBlockInfo.university}<br/>
                                                <span>Universities</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 text-center" data-aos="fade-up">
                                <div className="container p-5">
                                    <div className="progress" data-percentage="89">
                                        <span className="progress-left">
                                            <span className="progress-bar yellowcolor"></span>
                                        </span>
                                        <span className="progress-right">
                                            <span className="progress-bar yellowcolor"></span>
                                        </span>
                                        <div className="progress-value yellowcolor">
                                            <div>
                                            {countBlockInfo.exams}<br/>
                                                <span>Exams</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
};

export default WhatWeDo;