import React, { FC, useEffect, useState, useContext,useCallback } from 'react';
import SettingsContext from 'src/contexts/SettingsContext';
import axios from 'src/utils/axiosintercpt';
import { CircularProgress, makeStyles } from '@material-ui/core';

import Breadcrumbs from './Breadcrumbs';
import Header from './InnerHeader';
import OverviewSection from './OverviewSection';
import Talk from './Talk';
import TopColleges from './TopColleges';
import { pConfig } from 'src/config';  
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useParams } from "react-router-dom";
import Helmet from 'react-helmet';
import { Redirect,useLocation,useHistory } from 'react-router-dom';
const useStyles = makeStyles(() => ({
  root: {}
}));

const ExamList: FC = () => {

  const classes = useStyles();
  const setCtx = useContext(SettingsContext);
  const isMountedRef = useIsMountedRef();
  const [courseStreamArr, setCourseStreamArr] = useState([]);
  const { slug } = useParams();
  const [isHeroLoaded, setHeroLoaded] = useState(false);
  const [isdataloaded, setdataloaded] = useState(false);
  const [metatitle, setmetatitle] = useState<any>([]);
  const [metadescription, setmetadescription] = useState<any>([]);
  const [isExamLoaded, setExamLoaded] = useState(false);
  const history = useHistory();
  const getHeroData = async ()=>{
    const formData = new FormData();
    formData.append('getdata', JSON.stringify([{data: "course_streams_head"}]));
    const heroResp = await axios.post('api/website/home/header', formData);

    setCourseStreamArr(heroResp.data.data.course_streams_head);
    setCtx.setCourseData(heroResp.data.data.course_streams_head);
    setHeroLoaded(true);
  }
 
  const [examdata, setexamdetail] = useState<any>([]);
  const [upcomingdataexam, setupcomingdataexam] = useState([]);

  const getdataexamdata = useCallback(async () => {
   
    try {
      const id = slug;//'engineering';
      const response = await axios.get('api/website/home/allentranceexams/get/'+id);
  
      if (isMountedRef.current) {
  

        
        //console.log("response.data.data");
        //console.log(response.data.data);
        setexamdetail(response.data.data);
        setmetatitle(response.data.data.meta_title)
        setmetadescription(response.data.data.meta_description)
        setdataloaded(true);
        setExamLoaded(true);
      
      }
    } catch (err) {

     // alert("bb");
      history.push("/404");
    
    }
  }, [isMountedRef]);

  const getupcomingdataexam = useCallback(async () => {
    try {
      
     
      const id = slug;//'engineering';
      const response = await axios.get('api/website/home/upcomingexam' , {
        params: {
          orderby: 'asc',
          columnname: 'upcoming_date',
          size: 12,
          page: 1, // Requesting the second page
        },
      });
      console.log(response ,"response data")
      if (isMountedRef.current) {
        setupcomingdataexam(response.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  const formattedData = examdata && examdata.examfaqs && examdata.examfaqs.map((item) => ({
      "@type": "Question",
      "name": item.questions,
      "acceptedAnswer": {
          "@type": "Answer",
          "text": item.answers,
      },
  }));

  useEffect(()=>{
    getHeroData();
    getdataexamdata();
    getupcomingdataexam();
  }, []);

  return (
    <>
      {isExamLoaded ? 
        <>
        <Helmet>
            <title>{metatitle}</title>
            <meta name="description" content={metadescription} />
            <link rel="canonical" href={`${pConfig.siteUrl}exams/${slug}`} />
            <script type="application/ld+json">
              {JSON.stringify(
                  {
                      "@context": "https://schema.org",
                      "@type": "FAQPage",
                      "mainEntity": formattedData,
                  }
              )}
              </script>
        </Helmet>
        <Header examdata={examdata}/>
        <Breadcrumbs examdata={examdata}/>
        <OverviewSection examdata={examdata} upcomingdataexam={upcomingdataexam}/>
        <Talk/>
        { isdataloaded ?   <TopColleges examdata={examdata}/> :'' }
       
      </>
      :
      <div style={{ display: 'flex', justifyContent: 'center', height:'600px',flexWrap: 'wrap', alignContent: 'space-around' }}><CircularProgress /></div> }
    </>
  );
};

export default ExamList;