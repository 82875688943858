import React, { FC } from 'react';
import './stylesglobalpopup.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import MobileAlertImg from 'src/assets/img/mobile-alert.jpg';
import Gpay from 'src/assets/img/gpay.jpg';
import AppleStore from 'src/assets/img/app-store.jpg';
import AppQrCode from 'src/assets/img/appqr_code.jpg';

interface Props {
    className?: any;
    title?: any;
    pathname?: any;
    logourl?: any;
}

function MyVerticallyCenteredModal(props) {

    return (
        <Modal
            {...props}
            // size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            id="globalappdownload"
        >
            <Modal.Header closeButton className="close"></Modal.Header>
            <Modal.Body >
                <div className="left-mobile-app-details">
                    <a href=""><img src={MobileAlertImg} width="100%" /></a>
                </div>

                <div className="right-exam-alert-details">
                    <h3>Download Our App</h3>
                    <ul>
                        <li>Get Exam Alert </li>
                        <li>Get Previous Question Papers</li>
                        <li>Mock Tests</li>
                        <li>College Recommendations</li>
                        <li> Much More Information</li>
                    </ul>

                    <div className="spac_03"></div>

                    <a href='/learntechapp' target='_blank' className="btn btn-secondary">Download</a>

                    <div className="spac_02"></div>

                    <div className="col-lg-3 col-md-6 rgt-app-qrcode">
                        <img src={AppQrCode} />
                    </div>

                    <div className="col-lg-3 col-md-6 lft-app-icon">
                        <a href=""><img src={Gpay} /></a>
                        <a href=""><img src={AppleStore} /></a>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

const GlobalPopupAppDownload: FC<Props> = ({ className, title, pathname, logourl, ...rest }) => {
    const [modalShow, setModalShow] = React.useState(false);
    return (
        <>
            <a className="talk-to-expert btn btn btn-primary" onClick={() => setModalShow(true)}>Get an Exam Alert</a>

            <MyVerticallyCenteredModal
                title={title ? title : 'Apply Now'}
                show={modalShow}
                pathname={pathname}
                logourl={logourl}
                onHide={() => setModalShow(false)}
            />
        </>
    );
};

export default GlobalPopupAppDownload;