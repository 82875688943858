import React, { FC, useEffect, useState, useContext, useCallback } from 'react';
import SettingsContext from 'src/contexts/SettingsContext';
import axios from 'src/utils/axiosintercpt';
import { makeStyles } from '@material-ui/core';

import Breadcrumbs from './Breadcrumbs';
import Header from './InnerHeader';
import OverviewSection from './OverviewSection';
import Talk from './Talk';
import TopColleges from './TopColleges';
import Conclusion from './Conclusion';
import { pConfig } from 'src/config';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useParams,useHistory } from "react-router-dom";
import Helmet from 'react-helmet';
import { config } from 'src/config';
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(() => ({
    root: {}
}));

const BlogView: FC = () => {
    const history = useHistory();
    const classes = useStyles();
    const setCtx = useContext(SettingsContext);
    const isMountedRef = useIsMountedRef();
    const [courseStreamArr, setCourseStreamArr] = useState([]);
    const { slug } = useParams();
    const [isHeroLoaded, setHeroLoaded] = useState(false);
    const [metatitle, setmetatitle] = useState<any>([]);
    const [metadescription, setmetadescription] = useState<any>([]);
    const [blogcolleges, setBlogColleges] = useState<any>([]);
    const [showcolleges, setShowcolleges] = useState(false);

    const getHeroData = async () => {
        const formData = new FormData();
        formData.append('getdata', JSON.stringify([{ data: "course_streams_head" }]));
        const heroResp = await axios.post('api/website/home/header', formData);

        setCourseStreamArr(heroResp.data.data.course_streams_head);

        setCtx.setCourseData(heroResp.data.data.course_streams_head);
        setHeroLoaded(true);
    }

    const [blogdata, setblogdata] = useState([]);
    let imageurl = '';
    const getblogdata = useCallback(async () => {
        try {

            const id = slug;//'engineering';
            const response = await axios.get('api/website/blog/get/' + id);
            if (isMountedRef.current) {
                //console.log("response.data.data");
                //console.log(response.data.data);
                setblogdata(response.data.data);
                setmetatitle(response.data.data.meta_title)
                setmetadescription(response.data.data.meta_description)
                if (response.data.data.groups && response.data.data.groups != null) {
                    setBlogColleges(response.data.colleges.rows);
                    setShowcolleges(true);
                }
                imageurl = response.data.data && response.data.data.cover_image && config.baseUrlfromconfig + response.data.data.cover_image;
            }
        } catch (err) {
            console.error(err);
            history.push("/404");
        }
    }, [isMountedRef]);

    const [blogdataarray, setgetblogdataarray] = useState([]);
    const getblogdataarray = useCallback(async () => {
        try {

            const id = slug;//'engineering';
            const response = await axios.get('api/website/blog/get?columnname=created_at&orderby=desc');
            if (isMountedRef.current) {
                //console.log("response.data.data");
                //console.log(response.data.data);
                setgetblogdataarray(response.data.data);
            }
        } catch (err) {
            console.error(err);
        }
    }, [isMountedRef]);

    useEffect(() => {
        getblogdata();
        getHeroData();
        getblogdataarray();
    }, [showcolleges]);

    let location = useLocation();
    let currentUrl = pConfig.siteUrl + location.pathname;

    //const imageUrl = blogdata && blogdata.cover_image && config.baseUrlfromconfig + blogdata.cover_image;

    return (
        <>
            {/* {isTopColLoaded ? 
        <> */}
            <Helmet>
                <title>{metatitle}</title>
                <meta name="description" content={metadescription} />
                <link rel="canonical" href={`${pConfig.siteUrl}blog/${slug}`} />


                {/* <meta property="og:title" key="og:title" content={metatitle} />
                <meta property="og:quote" content="" />
                <meta property="og:hashtag" content="" />
                <meta property="og:image" content="https://media.gettyimages.com/photos/bogota-at-sunset-picture-id107069344?s=612x612" />
                <meta property="og:url" content={`${pConfig.siteUrl}blog/${slug}`} />
                <meta property="og:description" content={metadescription} /> */}

                <meta property="title" content={metatitle} />
                <meta property="quote" content="" />
                <meta property="image" content={imageurl} />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={metatitle} />
                <meta property="og:quote" content="" />
                <meta property="og:hashtag" content="" />
                <meta property="og:image" content={imageurl} />
                <meta content="image/*" property="og:image:type" />
                <meta property="og:url" content={`${pConfig.siteUrl}blog/${slug}`} />
                <meta property="og:site_name" content="BangaloreStudy" />
                <meta property="og:description" content={metadescription} />
                <meta property="twitter:title" content={metatitle} />
                <meta property="twitter:description" content={metadescription} />
            </Helmet>
            <Header blogdata={blogdata} />
            <Breadcrumbs blogdata={blogdata} />
            <OverviewSection blogdata={blogdata} blogdataarray={blogdataarray} slug={slug} />
            <Talk />
            {/* <Conclusion/> */}
            <TopColleges blogcolleges={blogcolleges} blogdata={blogdata} showcolleges={showcolleges} />
            {/* </>
      :
      <p style={{height: '8000px'}}>Loading</p> } */}
        </>
    );
};

export default BlogView;