import React, { FC } from 'react';
import './styles.css';
import ExamDetailHeaderBG from 'src/assets/img/exam-details-header2.jpg';
//import Search from 'src/assets/img/icons/search.svg'
// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
import { config } from 'src/config';
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';

const SearchHeader: FC<{ blogdata: any }> = ({ blogdata }) => {
    const imageUrl = blogdata.cover_image ? config.baseUrlfromconfig + blogdata.cover_image : ExamDetailHeaderBG;
    // const owlOptions = {
    //     margin: 20, nav: true, autoplay: false,
    //     responsiveClass: true,
    //     responsive: {
    //         0: { items: 1 },
    //         400: { items: 1 },
    //         600: { items: 1 },
    //         700: { items: 3 }
    //     },
    // };

    return (
        <section className="inner-top-header" id="hero" style={{ background: `linear-gradient(359deg, rgb(0 0 0 / 80%), rgb(0 0 0 / 80%)) fixed, url(${imageUrl})` }} >
            <div className="container">
                <div className="row">      
                    <div className="top-college-header px-5 px-md-0 "> 
                        <h1 className='blog-align'>{blogdata.title}  </h1>
                    </div>
                    <div className="spac_02"></div>
                    <div className="col-lg-12 button-section d-flex justify-content-center justify-content-md-start">
                    {/* <div className="col-lg-12 button-section px-5 px-md-0"> */}
                    <GlobalPopupEnquiry className="medical-button btn" title="Talk to Experts" />
                 
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SearchHeader;