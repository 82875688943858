import React, { FC } from 'react';
import './styles.css';
import ManagementCourseBG from 'src/assets/img/management-courses-new.jpg';

const SearchHeader: FC < {searchByValue:any}> = ({searchByValue})=> {
    const [query, setQuery] = React.useState<string>('');

    const handleQueryChange = (event): void => {
        //event.persist();
        //setPage(1);
        setQuery(event.target.value);
        searchByValue(event.target.value);
    };

    return (
        <section className="inner-top-header" id="hero" style={{ background: `url(${ManagementCourseBG})` }} >
            <div className="container" >
                <div className="row">
                    <div className="study-aborad-block top-course-header text-left">
                        <h1>Success stories of Bangalorestudy.com</h1>
                    </div>
                    <div className="row spac_01">
                        <div className="col-lg-2"></div>
                        <div className="header-search-box top-bottom-space col-lg-8">
                            <input type="text" 
                                onChange={handleQueryChange}
                                value={query}
                                placeholder="Search" className="search-box" />
                        </div>
                        <div className="col-lg-2"></div>
                    </div>
                </div>
                <div className="spac_02"></div>
            </div>
        </section>
    );
};

export default SearchHeader;