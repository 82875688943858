import React, { FC } from 'react';
import './styles.css';

import WhyChooseBG from 'src/assets/img/new-why_choose.jpg';

import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';

const ServicesSection: FC = () => {
    ;

    return (
        // <section className="why_choose p-2" style={{ background: `linear-gradient(359deg, rgba(77 95, 98, 77%), rgba(62, 53, 53, 11%)) fixed,url(${WhyChooseBG})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
            <section className="why_choose p-2" style={{ background: `linear-gradient(359deg, rgba(77, 95, 98, 77%), rgba(60, 53, 53, 11%)) fixed, url(${WhyChooseBG})` }}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <h2>Our Services</h2>
                        <p>Bangalorestudy.com is an information portal that caters to all the needs of students looking for admissions in Bangalore, Mysore, Davanagere and other districts of Karnataka.</p>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="choose_list">
                                    <div className="icon"><img src={require('src/assets/img/student-blue.svg')} className="testi_photo" /></div>
                                    <span>
                                        <h3>Educational Counselling to Students</h3>
                                        <p className="hide" > &nbsp;</p>
                                        {/* <p>Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor.</p> */}
                                    </span>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="choose_list">
                                    <div className="icon"><img src={require('src/assets/img/student-blue.svg')} className="testi_photo" /></div>
                                    <span>
                                        <h3>Seat Reservation Facilities</h3>
                                        <p className="hide" > &nbsp;</p>
                                        {/* <p>Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor.</p> */}
                                    </span>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="choose_list">
                                    <div className="icon"><img src={require('src/assets/img/student-blue.svg')} className="testi_photo" /></div>
                                    <span>
                                        <h3>Direct Meeting with College Authorities</h3>
                                        <p className="hide" > &nbsp;</p>
                                        {/* <p>&nbsp;</p> */}
                                        {/* <p>Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor.</p> */}
                                    </span>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="choose_list">
                                    <div className="icon"><img src={require('src/assets/img/student-blue.svg')} className="testi_photo" /></div>
                                    <span>
                                        <h3>Assistance to Secure Educational Loans and Scholarships</h3>
                                        <p className="hide" > &nbsp;</p>
                                        {/* <p>&nbsp;</p> */}
                                        {/* <p>Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor.</p> */}
                                    </span>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="choose_list">
                                    <div className="icon"><img src={require('src/assets/img/student-blue.svg')} className="testi_photo" /></div>
                                    <span>
                                        <h3>Pre and Post-Admission Care</h3>
                                        <p className="hide" > &nbsp;</p>
                                        {/* <p>&nbsp;</p> */}
                                        {/* <p>Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor.</p> */}
                                    </span>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="choose_list">
                                    <div className="icon"><img src={require('src/assets/img/student-blue.svg')} className="testi_photo" /></div>
                                    <span>
                                        <h3>Guidance to Pay Fees in Easy EMIs</h3>
                                        <p className="hide" > &nbsp;</p>
                                        {/* <p>&nbsp;</p> */}
                                        {/* <p>Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor.</p> */}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-lg-1"></div> */}
                    <div className="col-lg-4">
                        <h2>Why Choose Us</h2>
                        <p>Bangalorestudy.com is one of the fastest-growing Educational Service Provider. We power top-quality education with pioneering services. We have successfully guided 40,000+ students to pursue their desired course at top colleges. So, what are you waiting for? Reach out to us now!</p>
                        <div className="row">
                            <div className="col-lg-12 mb-3">
                                <GlobalPopupEnquiry className="get_call" title="Your Destiny Awaits!" />
                                {/* <a href="#" className="get_call">Your Destiny Awaits!</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
};

export default ServicesSection;